import { useState } from "react";

// Enums
import { CartNavigation } from "../enums/CartNavigation";

export const useCartNavigation = () => {
    /**
     * useState
     */

    const [currentSection, setCurrentSection] = useState(CartNavigation.MAIN);
    const [headerText, setHeaderText] = useState("Checkout");

    /**
     * End Hooks
     */

    function resetCartNavigation() {
        setCurrentSection(() => CartNavigation.MAIN);
        setHeaderText(() => "Checkout");
    }

    return {
        currentSection,
        headerText,
        resetCartNavigation,
        setCurrentSection,
        setHeaderText,
    };
};
