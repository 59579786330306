import React from "react";
import cx from "classnames";

// Components
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Text } from "components/Text/Text";

// CSS
import styles from "./sandboxx-plus-badge.module.scss";

// Utils
import { CurrentUser } from "utils/sandboxx";

export const SandboxxPlusBadge = ({ className }) => {
    const { hasSandboxxPlus } = CurrentUser.getUser();
    if (hasSandboxxPlus) {
        return (
            <div className={cx(styles.sandboxxPlusBadge, className)}>
                <Text classes={styles.text}>Sandboxx+ Active</Text>
                <CheckCircleOutlineIcon
                    className={styles.icon}
                    color="success"
                />
            </div>
        );
    } else {
        return null;
    }
};
