import React from "react";
import { Modal } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { ButtonInvisible, ButtonSecondary } from "components/Buttons/Buttons";

// Hooks
import { useAuthModalLogout } from "./hooks/useAuthModalLogout";
import { Text } from "components/Text/Text";

const AuthModalLogout = (props) => {
    const { setShouldShowLogOutModal, shouldShowLogOutModal } = props;

    /**
     * Custom Hooks
     */

    const { buttonText, closeLogOutModal, handleLogout, isLoading } =
        useAuthModalLogout({
            setShouldShowLogOutModal,
        });

    /**
     * End Hooks
     **/

    return (
        <Modal
            aria-labelledby="contained-modal-title"
            show={shouldShowLogOutModal}
            onHide={closeLogOutModal}
        >
            <div className="logout-modal-body-container modal__body">
                <Text isBold type="h3">Log Out</Text>
                <Text>Are you sure you want to leave?</Text>
            </div>
            <div className="logout-modal-button-container button-container">
                <ButtonSecondary 
                    isDisabled={isLoading}
                    onClick={handleLogout}
                >
                    {buttonText}
                </ButtonSecondary>
                <ButtonInvisible
                    onClick={closeLogOutModal}
                >
                    Cancel
                </ButtonInvisible>                
            </div>
        </Modal>
    );
};

export const AuthModalLogoutWithRouter = withRouter(AuthModalLogout);
