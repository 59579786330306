import React, { useEffect } from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// Components
import { ButtonInvisible, ButtonPrimary } from "components/Buttons/Buttons";

// CSS
import styles from "./support-squad-donate-tokens-modal-enter-amount.module.scss";

// Constants
import { NavigationEnum } from "../../constants/constants";
const { CONFIRM } = NavigationEnum;

export const SupportSquadDonateTokensModalEnterAmount = (props) => {
    const {
        credits,
        handleChangeTokensToDonate,
        handleTriggerPurchaseFlow,
        isInvalidTokensToDonateAmount,
        setCurrentSection,
        setHeaderText,
        setHeaderSubText,
        selectedSquad,
        tokensToDonate,
    } = props;

    /**
     * useEffect
     */

    useEffect(() => {
        setHeaderText("Enter Donation Amount");
        setHeaderSubText(
            `Enter the amount of letters you would like to donate to ${selectedSquad.supporteeFirstName}'s Support Squad`
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Constants
     */

    const canSubmit =
        tokensToDonate && tokensToDonate > 0 && !isInvalidTokensToDonateAmount;

    /**
     * End Hooks & Constants
     */

    function handleSubmit() {
        if (canSubmit) {
            setCurrentSection(CONFIRM);
        }
    }

    return (
        <div
            className={styles["support-squad-donate-tokens-modal-enter-amount"]}
        >
            <form className={styles.form} onSubmit={handleSubmit}>
                <label className={styles.label} htmlFor="tokensToDonate">
                    Letter Donation Amount
                </label>
                <input
                    className={cx(styles.input, {
                        [styles["input--invalid"]]:
                            isInvalidTokensToDonateAmount,
                    })}
                    data-cy="donate-tokens-input"
                    name="tokensToDonate"
                    onChange={handleChangeTokensToDonate}
                    placeholder={0}
                    type="number"
                    value={tokensToDonate}
                />
                <span className={styles.subText}>
                    Available letter balance:{" "}
                    <span className={styles.number}>{credits}</span>
                </span>
                <div
                    className={cx(styles.errors, {
                        [styles["errors--active"]]:
                            isInvalidTokensToDonateAmount,
                    })}
                >
                    <span>
                        You only have{" "}
                        <span className={styles.number}>{credits}</span> letters
                        available in your account. Please enter a lower number
                        or purchase more letters below.
                    </span>
                </div>
                <div className={styles.buttons}>
                    <ButtonPrimary
                        classes={styles.button}
                        cypressTestId="donate-tokens-modal-continue-button"
                        isDisabled={!canSubmit}
                        text="Continue"
                        type="submit"
                    />
                    <ButtonInvisible
                        classes={styles.button}
                        cypressTestId="donate-tokens-modal-purchase-button"
                        onClick={handleTriggerPurchaseFlow}
                        text="Buy More Letters"
                        type="button"
                    />
                </div>
            </form>
        </div>
    );
};

SupportSquadDonateTokensModalEnterAmount.propTypes = {
    credits: PropTypes.number,
    handleChangeTokensToDonate: PropTypes.func.isRequired,
    isInvalidTokensToDonateAmount: PropTypes.bool.isRequired,
    setCurrentSection: PropTypes.func.isRequired,
    setHeaderText: PropTypes.func.isRequired,
    setHeaderSubText: PropTypes.func.isRequired,
    tokensToDonate: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.oneOf([""]),
    ]),
};
