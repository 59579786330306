import React from "react";
import cx from "classnames";
import { Skeleton } from "@material-ui/lab";
import PropTypes from "prop-types";

// CSS
import styles from "./wallet-manage-list-card.module.scss";

// Hooks
import { useWalletManageListCard } from "./hooks/useWalletManageListCard";

// Utils
import { handleKeyDown } from "utils/eventUtils";

export const WalletManageListCard = (props) => {
    const { card, onClick } = props;

    /**
     * Custom Hooks
     */

    const { imageSrc, isSelected } = useWalletManageListCard(props);

    /**
     * End Hooks
     */

    function renderImage() {
        if (imageSrc) {
            return (
                <div className={styles.image}>
                    <img alt="Gift Card" src={imageSrc} />
                </div>
            );
        } else {
            return (
                <div className={styles.image}>
                    <Skeleton
                        className={styles.skeleton}
                        variant="rect"
                        width="100%"
                        height="250px"
                    />
                </div>
            );
        }
    }

    function renderText() {
        const amountText =
            card.remainingBalance === null ? "0" : card.remainingBalance;
        return (
            <div
                className={cx(styles.text, {
                    [styles["text--selected"]]: isSelected,
                })}
            >
                <div className={styles.from}>
                    <span className={styles.header}>From</span>
                    <span className={styles.name}>{card.from}</span>
                </div>
                <div className={styles.balance}>
                    <span className={styles.header}>Available Balance</span>
                    <span className={styles.amount}>${amountText}</span>
                </div>
            </div>
        );
    }

    return (
        <div
            className={cx(styles["wallet-manage-list-card"], {
                [styles["wallet-list-card--selected"]]: isSelected,
            })}
            data-cy="wallet-list-card"
            onClick={onClick}
            onKeyDown={
                /* istanbul ignore next */ (e) => handleKeyDown(e, onClick)
            }
            tabIndex="0"
        >
            {renderImage()}
            {renderText()}
        </div>
    );
};

WalletManageListCard.propTypes = {
    card: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
};
