import React from "react";

// Components
import { EditIcon } from "components/Icons/EditIcon";
import { Overlay } from "components/Overlay/Overlay";
import { SandboxxPlusBadge } from "components/Badges/SandboxxPlusBadge/SandboxxPlusBadge";

// CSS
import styles from "./dashboard-header.module.scss";

// Media
import infoIconSrc from "media/icons/info.svg";

// Utils
import { CurrentUser } from "utils/sandboxx";
import { prettifyTimeUntilCutoff } from "./utils/utils";
import { findTimeDifference } from "utils/dateTimeUtils";
import { handleKeyDown } from "utils/eventUtils";

// Constants
const overlayText =
    "Submit your letter by 4:30PM ET, Monday through Thursday, to ensure next-day delivery.";

export const DashboardHeader = ({
    graduation,
    handleGraduationModalToggle,
    letterCutOffTime,
    lettersSent,
}) => {
    function renderHoursLeftForNextDay() {
        const { timeUntilNextCutoff } = letterCutOffTime || {};
        const { hours } = findTimeDifference(timeUntilNextCutoff);
        return (
            timeUntilNextCutoff &&
            hours !== undefined && (
                <div className={styles.item}>
                    <span
                        className={styles.number}
                        data-cy="dashboard-header-stats-item-number"
                    >
                        {prettifyTimeUntilCutoff(timeUntilNextCutoff)}
                    </span>
                    <span className={styles.text}>
                        left to send your letter next business day
                    </span>
                    <div className={styles.icon}>
                        <Overlay overlayText={overlayText} placement="bottom">
                            <img
                                alt="Info icon"
                                src={infoIconSrc}
                                tabIndex="0"
                            />
                        </Overlay>
                    </div>
                </div>
            )
        );
    }

    function renderWeeksLeftUntilGraduation() {
        const graduationFirst = graduation && graduation[0];
        const hasSentLetter = lettersSent?.length > 0;
        return hasSentLetter && graduation?.length ? (
            <div className={styles.item}>
                <span className={styles.number}>
                    {graduationFirst?.currentWeeksUntil}
                </span>
                <span className={styles.text}>
                    weeks until {graduationFirst.contactFname} graduates
                </span>
                <div
                    className={styles.icon}
                    data-cy="dashboard-header-graduation-modal-toggle"
                    onClick={handleGraduationModalToggle}
                    onKeyDown={(e) =>
                        handleKeyDown(e, handleGraduationModalToggle)
                    }
                    tabIndex="0"
                >
                    <EditIcon />
                </div>
            </div>
        ) : (
            <div className={styles.item}>
                <span>Send a letter to get weekly status updates</span>
            </div>
        );
    }

    return (
        <div className={styles.dashboardHeader}>
            <div className={styles.welcome}>
                <span
                    className={styles.text}
                    data-cy="dashboard-header-welcome-text"
                >
                    Welcome back, {CurrentUser.getUser().firstName}
                </span>
                <SandboxxPlusBadge />
            </div>
            <div className={styles.stats}>
                {renderHoursLeftForNextDay()}
                {renderWeeksLeftUntilGraduation()}
            </div>
        </div>
    );
};
