import React from "react";
import PropTypes from "prop-types";

// Components
import { ButtonInvisible, ButtonPrimary } from "components/Buttons/Buttons";
import { ConfirmVerificationCodeInput } from "components/Inputs/ConfirmVerificationCodeInput/ConfirmVerificationCodeInput";
import { Modal } from "components/Modals/Modal";
import { Text } from "components/Text/Text";

// CSS
import styles from "./referrals-redeem-code-modal.module.scss";

// Hooks
import { useReferralsRedeemCodeModal } from "./hooks/useReferralsRedeemCodeModal";

export const ReferralsRedeemCodeModal = ({
    setShouldShowRedeemCodeModal,
    shouldShowRedeemCodeModal,
}) => {
    /**
     * Custom Hooks
     */

    const { handleChange, isSubmittingCode, redeemCode } =
        useReferralsRedeemCodeModal({ setShouldShowRedeemCodeModal });

    /**
     * End Hooks
     */

    return (
        <Modal
            headerSubText="Please enter the code your friend shared with you to earn free Letters."
            headerText="Redeem Referral Code"
            onHide={() => setShouldShowRedeemCodeModal(false)}
            show={shouldShowRedeemCodeModal}
        >
            <form
                className={styles.referralsRedeemCodeModal}
                onSubmit={redeemCode}
            >
                <ConfirmVerificationCodeInput
                    characterType="all"
                    className={styles.input}
                    label=""
                    length={8}
                    onChange={handleChange}
                    size="small"
                />
                <div className={styles.buttons}>
                    <ButtonPrimary
                        classes={styles.button}
                        isLoading={isSubmittingCode}
                        onClick={redeemCode}
                    >
                        <Text>Redeem</Text>
                    </ButtonPrimary>
                    <ButtonInvisible
                        classes={styles.button}
                        onClick={() => setShouldShowRedeemCodeModal(false)}
                    >
                        <Text>Cancel</Text>
                    </ButtonInvisible>
                </div>
            </form>
        </Modal>
    );
};

ReferralsRedeemCodeModal.propTypes = {
    setShouldShowRedeemCodeModal: PropTypes.func.isRequired,
    shouldShowRedeemCodeModal: PropTypes.bool,
};
