import React, { useEffect, useState } from "react";
import { Modal } from "components/Modals/Modal";

// Components
import { ButtonPrimary } from "../Buttons/Buttons";

// CSS
import "../../css/components/graduation/graduation.scss";

// Platforms
import { SandboxxRestAPI } from "../../utils/sandboxx";

export const GraduationModal = (props) => {
    const { fetchDashboard, graduation, onHide, show, showNotification } =
        props;
    const { currentWeeksUntil, gradJointId, maxNumberOfWeeks } =
        graduation && graduation[0] ? graduation[0] : {};

    /************************************************
     * useState
     ************************************************/

    const [loading, setLoading] = useState(false);
    const [requestedWeeksUntil, setRequestedWeeksUntil] =
        useState(currentWeeksUntil);

    /************************************************
     * useEffect
     ************************************************/

    useEffect(() => {
        const { currentWeeksUntil } =
            graduation && graduation[0] ? graduation[0] : {};
        setRequestedWeeksUntil(currentWeeksUntil);
    }, [graduation]);

    /************************************************
     * End Hooks
     ************************************************/

    const isDecreaseWeeksAvailable = requestedWeeksUntil - 1 > -1;
    const isIncreaseWeeksAvailable =
        requestedWeeksUntil + 1 <= maxNumberOfWeeks;

    function handleDecreaseWeeks() {
        const newRequestedWeeksUntil = requestedWeeksUntil - 1;
        if (newRequestedWeeksUntil > -1) {
            setRequestedWeeksUntil(newRequestedWeeksUntil);
        }
    }

    function handleIncreaseWeeks() {
        const newRequestedWeeksUntil = requestedWeeksUntil + 1;
        if (newRequestedWeeksUntil <= maxNumberOfWeeks) {
            setRequestedWeeksUntil(newRequestedWeeksUntil);
        }
    }

    function handleSetGraduationWeek() {
        setLoading(true);
        SandboxxRestAPI.setGraduationWeek(
            { gradJointId, requestedWeeksUntil },
            onSetGraduationWeekSuccess,
            onSetGraduationWeekError,
            onSetGraduationWeekError
        );
    }

    function onSetGraduationWeekError(err) {
        setLoading(false);
        showNotification({
            text: "We are unable to update your graduation date at this time.",
            type: "warning",
        });
    }

    function onSetGraduationWeekSuccess(res) {
        fetchDashboard().then(() => {
            setLoading(false);
            onHide();
        });
    }

    function renderRequestedWeeksUntil() {
        if (requestedWeeksUntil === 0) {
            return "This Week";
        }
        if (requestedWeeksUntil === 1) {
            return "Next Week";
        }
        return `${requestedWeeksUntil} Weeks`;
    }

    const buttonClass = loading ? "disabled" : "";
    const buttonText = loading ? "Saving..." : "Save";
    const leftIconClass = isDecreaseWeeksAvailable
        ? ""
        : "graduation-modal__selector__icon--disabled";
    const rightIconClass = isIncreaseWeeksAvailable
        ? ""
        : "graduation-modal__selector__icon--disabled";

    return (
        <Modal
            className="graduation-modal"
            cypressTestId="graduation-modal"
            headerText="How Many Weeks Until Graduation?"
            onHide={onHide}
            show={show}
        >
            <div className="graduation-modal__content">
                <div className="graduation-modal__selector">
                    <span
                        className={`graduation-modal__selector__icon graduation-modal__selector__icon--left ${leftIconClass}`}
                        onClick={handleDecreaseWeeks}
                    >
                        -
                    </span>
                    <span className="graduation-modal__selector__option">
                        {renderRequestedWeeksUntil()}
                    </span>
                    <span
                        className={`graduation-modal__selector__icon graduation-modal__selector__icon--right ${rightIconClass}`}
                        onClick={handleIncreaseWeeks}
                    >
                        +
                    </span>
                </div>
                <ButtonPrimary
                    classes={buttonClass}
                    onClick={handleSetGraduationWeek}
                    text={buttonText}
                    type="button"
                />
            </div>
        </Modal>
    );
};
