import React from "react";
import { Link } from "react-router-dom";

// Components
import { ButtonPrimary } from "components/Buttons/Buttons";
import { Divider } from "components/Divider/Divider";
import { ListIcon } from "components/ListIcon/ListIcon";
import { PurchaseModalBundleTile } from "../../PurchaseModalBundleTile/PurchaseModalBundleTile";
import { PurchaseModalBundleOptionsSandboxxPlus } from "./components/PurchaseModalBundleOptionsSandboxxPlus/PurchaseModalBundleOptionsSandboxxPlus";
import { Slider } from "components/Slider/Slider";
import { Text } from "components/Text/Text";

// Constants
import { listIconItems, sliderImages, sliderText } from "./constants/constants";

// CSS
import styles from "./purchase-modal-bundle-options.module.scss";

// Loggers
import { AnalyticsLogger } from "loggers/AnalyticsLogger";

// Utils
import { CurrentUser } from "utils/sandboxx";

export const PurchaseModalBundleOptions = ({
    bundles,
    handleSubmit,
    selectedBundle,
    setSelectedBundle,
}) => {
    function renderBundles() {
        if (bundles) {
            const tiles = bundles
                ?.filter(
                    (bundle) =>
                        bundle.credits !== 0 && !bundle.includesSandboxxPlus
                )
                .sort((a, b) => a.credits - b.credits)
                .map((bundle, i) => (
                    <PurchaseModalBundleTile
                        bundle={bundle}
                        index={i}
                        key={i}
                        selectedBundle={selectedBundle}
                        setSelectedBundle={setSelectedBundle}
                    />
                ));
            return <div className={styles.bundles}>{tiles}</div>;
        } else {
            return (
                <>
                    <PurchaseModalBundleTile isLoading />
                    <PurchaseModalBundleTile isLoading />
                    <PurchaseModalBundleTile isLoading />
                </>
            );
        }
    }

    function renderButtons() {
        const buttonText = selectedBundle?.title
            ? `Purchase ${selectedBundle?.title}`
            : "Purchase";
        return (
            <div className={styles.buttons}>
                <ButtonPrimary
                    classes={styles.button}
                    cypressTestId="purchase-modal-bundle-basic-option-purchase-button"
                    id="purchase-modal-bundle-options-purchase-button"
                    isDisabled={!selectedBundle}
                    onClick={(e) => handleSubmit(e, selectedBundle)}
                    text={buttonText}
                />
                <Text classes={styles.note}>
                    Letters & Sandboxx+ expire after 6 months from purchase.
                </Text>
                <Link
                    target="_blank"
                    to={{
                        pathname:
                            "https://help.sandboxx.us/en/articles/6004504-where-does-sandboxx-ship-overnight",
                    }}
                >
                    <Text
                        classes={styles.faqLink}
                        onClick={
                            AnalyticsLogger.logBundleSelectOvernightShipping
                        }
                    >
                        *View Overnight Shipping Locations
                    </Text>
                </Link>
            </div>
        );
    }

    function renderDivider() {
        const { hasSandboxxPlus } = CurrentUser.getUser();
        const text = hasSandboxxPlus ? null : "OR";
        return <Divider className={styles.divider} text={text} />;
    }

    function renderLeftColumn() {
        return (
            <div className={styles.leftColumn}>
                <div className={styles.headline}>
                    <Text type="h2">Every Sandboxx Letter includes</Text>
                </div>
                <ListIcon
                    classNames={{
                        container: styles.listIcon,
                        subtitle: styles.subtitle,
                    }}
                    items={listIconItems}
                />
                <Slider
                    classNames={{
                        container: styles.slider,
                        image: styles.image,
                        arrows: styles.arrows,
                        subtitle: styles.subText,
                    }}
                    images={sliderImages}
                    shouldShowArrows={true}
                    shouldShowMarkers={false}
                    shouldShowText={true}
                    text={sliderText}
                />
            </div>
        );
    }

    function renderRightColumn() {
        return (
            <div className={styles.rightColumn}>
                <div className={styles.headline}>
                    <div className={styles.title}>
                        <Text classes={styles.text} type="h2">
                            Select a Bundle
                        </Text>
                    </div>
                </div>
                {renderBundles()}
                {renderDivider()}
                <PurchaseModalBundleOptionsSandboxxPlus
                    bundles={bundles}
                    selectedBundle={selectedBundle}
                    setSelectedBundle={setSelectedBundle}
                />
            </div>
        );
    }

    return (
        <div className={styles.bundleOptions}>
            <div className={styles.body}>
                {renderLeftColumn()}
                {renderRightColumn()}
            </div>
            {renderButtons()}
        </div>
    );
};
