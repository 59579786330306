import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal } from "components/Modals/Modal";

// Components
import { ButtonPrimary, ButtonInvisible } from "components/Buttons/Buttons";
import { ConfirmVerificationCodeInput } from "components/Inputs/ConfirmVerificationCodeInput/ConfirmVerificationCodeInput";
import { CheckmarkSuccessIcon } from "components/Icons/CheckmarkSuccessIcon";
import { WarningCircleIcon } from "components/Icons/WarningCircleIcon";

// CSS
import styles from "./support-squad-join-modal.module.scss";

// Logging
import { AnalyticsLogger } from "loggers/AnalyticsLogger";

export const SupportSquadJoinModal = (props) => {
    const { joinSupportSquad, shouldShowJoinModal, setShouldShowJoinModal } =
        props;

    /************************************************
     * useState
     ************************************************/
    const [joinResultMessage, setJoinResultMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [currentSection, setCurrentSection] = useState("default");
    const [verificationCode, setVerificationCode] = useState(null);

    function handleChange(value) {
        const formattedValue = value.join("");
        setVerificationCode(formattedValue);
    }

    function handleSubmit() {
        joinSupportSquad(
            verificationCode,
            handleJoinSuccess,
            handleJoinFailure
        );
        setLoading(true);
    }

    /* istanbul ignore next */
    function handleJoinSuccess(message) {
        AnalyticsLogger.logSquadInviteJoined();
        setJoinResultMessage(message);
        setCurrentSection("success");
        setLoading(false);
    }

    function handleJoinFailure(message) {
        setJoinResultMessage(message);
        setCurrentSection("failed");
        setLoading(false);
    }

    function handleModalClose() {
        setShouldShowJoinModal(false);
        setCurrentSection("default");
    }

    function renderOptions() {
        return (
            <div className={styles["container"]}>
                <div className={styles["input-container"]}>
                    <ConfirmVerificationCodeInput
                        characterType="all"
                        label=""
                        length={8}
                        onChange={handleChange}
                        size="small"
                    />
                </div>
                <div className={styles["buttons-container"]}>
                    <ButtonPrimary
                        classes={styles["submit-code"]}
                        cypressTestId="support-squad-join-modal-join-button"
                        onClick={handleSubmit}
                        isDisabled={
                            loading || verificationCode?.length !== 8
                                ? true
                                : false
                        }
                        text={loading ? "Joining..." : "Join Squad"}
                        type="button"
                    />
                    <ButtonInvisible
                        cypressTestId="support-squad-join-modal-cancel-button"
                        onClick={handleModalClose}
                        text="Cancel"
                        type="button"
                    />
                </div>
            </div>
        );
    }

    /* istanbul ignore next */
    function renderSuccess() {
        return (
            <div className={styles["success-confirmation"]}>
                <CheckmarkSuccessIcon
                    className={styles["success-icon"]}
                    height="50px"
                    width="50px"
                />
                <h3>Success!</h3>
                <span>{joinResultMessage}</span>
                <ButtonPrimary
                    classes={styles["success-confirmation-continue-button"]}
                    onClick={handleModalClose}
                    text="Continue"
                    type="button"
                />
            </div>
        );
    }

    function renderFailed() {
        return (
            <div
                className={styles["error-confirmation"]}
                data-cy="support-squad-join-modal-error-confirmation"
            >
                <WarningCircleIcon
                    className={styles["warning-icon"]}
                    height="45px"
                    width="45px"
                />
                <h3>Error</h3>
                <span className={styles["message"]}>{joinResultMessage}</span>
                <ButtonPrimary
                    classes={styles["enter-new-code"]}
                    cypressTestId="support-squad-join-modal-error-confirmation-new-code-button"
                    onClick={() => setCurrentSection("default")}
                    text="Enter New Code"
                    type="button"
                />
                <ButtonInvisible
                    classes={styles["confirm-cancel-button"]}
                    cypressTestId="support-squad-join-modal-error-confirmation-cancel-button"
                    onClick={handleModalClose}
                    text="Cancel"
                    type="button"
                />
            </div>
        );
    }

    function renderModalContent() {
        switch (currentSection) {
            case "default":
                return renderOptions();
            /* istanbul ignore next */
            case "success":
                return renderSuccess();
            case "failed":
                return renderFailed();
            /* istanbul ignore next */
            default:
                break;
        }
    }

    return (
        <Modal
            cypressTestId="support-squad-join-modal"
            headerText="Join Support Squad"
            headerSubText={
                currentSection === "default"
                    ? "Enter the code you received in your inbox below to join the squad!"
                    : ""
            }
            onClose={handleModalClose}
            onHide={handleModalClose}
            show={shouldShowJoinModal}
        >
            {renderModalContent()}
        </Modal>
    );
};

SupportSquadJoinModal.propTypes = {
    joinSupportSquad: PropTypes.func.isRequired,
    shouldShowJoinModal: PropTypes.bool.isRequired,
    setShouldShowJoinModal: PropTypes.func.isRequired,
};
