import React from "react";
import cx from "classnames";
import { Skeleton } from "@material-ui/lab";
import PropTypes from "prop-types";

// Components
import { ButtonSecondary } from "components/Buttons/Buttons";
import { Panel } from "components/Panel/Panel";

// CSS
import styles from "./wallet-manage-detail.module.scss";

// Hooks
import { useWalletDetail } from "./hooks/useWalletManageDetail";

// Utils
import { handleKeyDown } from "utils/eventUtils";

// Constants
import { WalletListTabIds } from "../../../../constants/constants";
const { MY_GIFT_CARDS } = WalletListTabIds;

export const WalletManageDetail = (props) => {
    const { hasSelectedGiftCard, selectedWalletListTabId } = props;

    /**
     * Custom Hooks
     */

    const {
        amountText,
        barcodeImageSrc,
        handlePrintBarcodeImage,
        handleToggleShouldHidePin,
        pinText,
        showPinText,
    } = useWalletDetail(props);

    /**
     * Constants
     */

    const isViewingMyGiftCards = selectedWalletListTabId === MY_GIFT_CARDS;
    const shouldDisplayGiftCardDetails =
        hasSelectedGiftCard && isViewingMyGiftCards;

    /**
     * End Hooks
     */

    function renderBarcode() {
        return (
            <div className={styles.barcode}>
                {barcodeImageSrc ? (
                    <img
                        alt="Gift Card Barcode"
                        className={styles.image}
                        src={barcodeImageSrc}
                    />
                ) : (
                    <Skeleton className={styles.skeleton} variant="rect" />
                )}
                <div className={styles.pin}>
                    <span>Pin Number: </span>
                    <span>{pinText}</span>
                    <span
                        className={cx(styles["show-pin"], "link")}
                        onClick={handleToggleShouldHidePin}
                        onKeyDown={
                            /* istanbul ignore next */ (e) =>
                                handleKeyDown(e, handleToggleShouldHidePin)
                        }
                        tabIndex="0"
                    >
                        {showPinText}
                    </span>
                </div>
            </div>
        );
    }

    function renderInfo() {
        return (
            <div className={styles.info}>
                <ButtonSecondary
                    classes={styles.button}
                    isDisabled={!barcodeImageSrc}
                    onClick={handlePrintBarcodeImage}
                    text="Print Gift Card"
                    type="button"
                />
                <div className={styles.balance}>
                    <div className={styles.description}>
                        <span>Available</span>
                        <span>Balance</span>
                    </div>
                    <div className={styles.divider} />
                    <div className={styles.amount}>
                        <span>${amountText}</span>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div
            className={styles["wallet-manage-detail"]}
            data-cy="wallet-detail-panel"
        >
            {shouldDisplayGiftCardDetails && (
                <Panel classes={styles.panel}>
                    <div className={styles.top}>
                        {renderBarcode()}
                        {renderInfo()}
                    </div>
                </Panel>
            )}
        </div>
    );
};

WalletManageDetail.propTypes = {
    hasSelectedGiftCard: PropTypes.bool.isRequired,
    selectedWalletListTabId: PropTypes.number.isRequired,
};
