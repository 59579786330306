import React from "react";
import PropTypes from "prop-types";

// Components
import { ReferralsList } from "../ReferralsList/ReferralsList";

// CSS
import styles from "./referrals-lists.module.scss";

// Enums
import { ReferralType } from "scenes/Referrals/enums/ReferralType";

export const ReferralsLists = ({
    earnedLetters,
    isLoadingRewardsStatus,
    pendingLetters,
    pendingReferrals,
    referredReferrals,
}) => {
    return (
        <div className={styles.referralsLists}>
            <ReferralsList
                headerText="Earned"
                isLoadingRewardsStatus={isLoadingRewardsStatus}
                letterCount={earnedLetters}
                list={referredReferrals}
                referralType={ReferralType.REFERRED}
            />
            <ReferralsList
                headerText="Pending"
                isLoadingRewardsStatus={isLoadingRewardsStatus}
                letterCount={pendingLetters}
                list={pendingReferrals}
                referralType={ReferralType.PENDING}
            />
        </div>
    );
};

ReferralsLists.propTypes = {
    earnedLetters: PropTypes.number,
    isLoadingRewardsStatus: PropTypes.bool,
    pendingReferrals: PropTypes.array,
    referredReferrals: PropTypes.array,
};
