import { useEffect, useState } from "react";

// Platforms
import { Iterable } from "platforms/iterable/iterable";

export const useDashboardMessages = () => {
    /**
     * useState
     */

    const [isLoading, setIsLoading] = useState(false);
    const [messages, setMessages] = useState(null);
    const [shouldShowComponent, setShouldShowComponent] = useState(true);

    /**
     * useEffect
     */

    useEffect(() => {
        fetchMessages();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * End Hooks
     */

    function fetchMessages() {
        setIsLoading(() => true);
        Iterable.getEmbeddedMessages({
            onFailure: onFetchMessagesFailure,
            onSuccess: onFetchMessagesSuccess,
        });
    }

    function onFetchMessagesFailure(err) {
        setIsLoading(false);
        setShouldShowComponent(false);
    }

    function onFetchMessagesSuccess({ messages }) {
        if (messages?.length === 0) {
            setShouldShowComponent(() => false);
        }
        setIsLoading(() => false);
        setMessages(() => messages);
    }

    return { isLoading, messages, shouldShowComponent };
};
