import { useEffect, useRef } from "react";

export const useSupportSquadSettingsModalCreateName = ({
    setHeaderText,
    setHeaderSubText,
}) => {
    /**
     * useRef
     */

    const fileInputRef = useRef(null);

    /**
     * useEffect
     */

    useEffect(() => {
        setHeaderText("Personalize your Support Squad");
        setHeaderSubText(
            "Pick a name everyone will recognize and add a photo to help your squad stand out"
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * End Hooks
     */

    function handleAddPhotoClick() {
        fileInputRef.current.click();
    }

    return { fileInputRef, handleAddPhotoClick };
};
