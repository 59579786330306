// Media
import amexSrc from "media/icons/credit-cards/amex.svg";
import defaultCardSrc from "media/icons/credit-cards/default.svg";
import dinersClubSrc from "media/icons/credit-cards/dinersclub.svg";
import discoverSrc from "media/icons/credit-cards/discover.svg";
import jcbSrc from "media/icons/credit-cards/jcb.svg";
import maestroSrc from "media/icons/credit-cards/maestro.svg";
import masterCardSrc from "media/icons/credit-cards/mastercard.svg";
import visaSrc from "media/icons/credit-cards/visa.svg";

/* istanbul ignore next */
export function generateCreditCardIconSrc(brand) {
    switch (brand) {
        case "American Express":
            return amexSrc;
        case "Diners Club":
            return dinersClubSrc;
        case "Discover":
            return discoverSrc;
        case "JCB":
            return jcbSrc;
        case "MasterCard":
            return masterCardSrc;
        case "Maestro":
            return maestroSrc;
        case "Visa":
            return visaSrc;
        default:
            return defaultCardSrc;
    }
}
