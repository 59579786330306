import React from "react";
import cx from "classnames";

// Components
import { ButtonInvisible, ButtonPrimary } from "components/Buttons/Buttons";
import { ConfirmVerificationCodeInput } from "components/Inputs/ConfirmVerificationCodeInput/ConfirmVerificationCodeInput";

// CSS
import signInStyles from "../../../sign-in.module.scss";
import signUpConfirmEmailStyles from "./sign-up-confirm-email.module.scss";

// Hooks
import { useSignUpConfirmEmail } from "./hooks/useSignUpConfirmEmail";

export const SignUpConfirmEmail = (props) => {
    const { loading, verificationCode } = props;

    /**
     * Custom Hooks
     */

    const {
        handleChangeConfirmVerificationCodeInput,
        handleSubmit,
        resendVerificationCode,
        shouldShowErrorState,
    } = useSignUpConfirmEmail(props);

    /**
     * End Hooks
     */

    function renderForm() {
        return (
            <form
                className={cx(
                    signInStyles["form--verification-code"],
                    signUpConfirmEmailStyles.form,
                    "form"
                )}
                data-action="authEmailVerify"
                onSubmit={handleSubmit}
            >
                <ConfirmVerificationCodeInput
                    className={signUpConfirmEmailStyles.input}
                    label=""
                    length={6}
                    loading={loading.verify}
                    onChange={handleChangeConfirmVerificationCodeInput}
                    shouldShowErrorState={shouldShowErrorState}
                />
                <div className={signUpConfirmEmailStyles.buttons}>
                    {renderSubmitButton()}
                    <ButtonInvisible
                        classes={signUpConfirmEmailStyles.button}
                        cypressTestId="sign-up-confirm-resend-code-button"
                        onClick={resendVerificationCode}
                        text="Resend Code"
                        type="button"
                    />
                </div>
            </form>
        );
    }

    function renderSubmitButton() {
        return loading.verify ? (
            <ButtonPrimary
                classes={cx(
                    signInStyles.button,
                    signUpConfirmEmailStyles.button
                )}
                color="orange"
                isDisabled={loading.verify}
                text="Verifying..."
                type="submit"
            />
        ) : (
            <ButtonPrimary
                classes={cx(
                    signInStyles.button,
                    signUpConfirmEmailStyles.button
                )}
                color="orange"
                cypressTestId="sign-up-confirm-verify-button"
                dataAttributes={{ "data-action": "authEmailVerify" }}
                isDisabled={verificationCode.length < 6}
                onClick={handleSubmit}
                text="Continue"
                type="submit"
            />
        );
    }

    return (
        <div
            className={cx(
                signInStyles.content,
                signUpConfirmEmailStyles["sign-up-confirm-email"]
            )}
        >
            <div className={signInStyles.top}>
                <div
                    className={cx(
                        signInStyles.header,
                        signUpConfirmEmailStyles.header
                    )}
                >
                    <span
                        className={signInStyles.text}
                        data-cy="sign-up-confirm-header-text"
                    >
                        Please verify your email address
                    </span>
                    <span className={signInStyles["text-secondary"]}>
                        We have sent a verification code to the email you
                        provided. Please enter it below to create your account.
                    </span>
                </div>
                {renderForm()}
            </div>
        </div>
    );
};
