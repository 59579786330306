import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// Components
import { ButtonInvisible, ButtonPrimary } from "components/Buttons/Buttons";
import { PhoneNumberInput } from "components/Inputs/PhoneNumberInput/PhoneNumberInput";

// CSS
import signInStyles from "../../../sign-in.module.scss";
import signUpPhoneStyles from "./sign-up-phone.module.scss";

// Hooks
import { useSignUpPhone } from "./hooks/useSignUpPhone";

export const SignUpPhone = (props) => {
    const {
        handleClearPhoneNumber,
        handleChangePhoneNumber,
        handleLoadingToggle,
        loading,
        setCurrentSection,
        setUserInfo,
        userInfo,
    } = props;
    const { phoneNumber } = userInfo;

    /**
     * Custom Hooks
     */

    const { handleSkip, handleSubmit } = useSignUpPhone({
        handleLoadingToggle,
        setCurrentSection,
        setUserInfo,
        userInfo,
    });

    /**
     * End Hooks
     */

    function renderSubmitButton() {
        return loading.phone ? (
            <ButtonPrimary
                classes={cx(signInStyles.button, signUpPhoneStyles.button)}
                isDisabled={loading.phone}
                text="Sending..."
                type="submit"
            />
        ) : (
            <ButtonPrimary
                classes={cx(signInStyles.button, signUpPhoneStyles.button)}
                onClick={handleSubmit}
                text="Send Confirmation Code"
                type="submit"
            />
        );
    }

    return (
        <div
            className={cx(
                signInStyles.content,
                signUpPhoneStyles["sign-up-phone"]
            )}
        >
            <div className={signInStyles.top}>
                <div
                    className={cx(
                        signInStyles.header,
                        signUpPhoneStyles.header
                    )}
                >
                    <span
                        className={signInStyles.text}
                        data-cy="sign-up-phone-header-text"
                    >
                        What's your phone number?
                    </span>
                    <div className={signInStyles["text-secondary"]}>
                        Enter your phone number to verify your account. If your
                        recruit added you as a contact, we’ll use your phone
                        number to connect you.
                    </div>
                </div>
                <form
                    className={cx(
                        signInStyles.form,
                        signUpPhoneStyles.form,
                        "form"
                    )}
                    onSubmit={handleSubmit}
                >
                    <PhoneNumberInput
                        autoFocus={true}
                        containerClasses={signUpPhoneStyles.input}
                        inputClasses={cx(signInStyles.input)}
                        onChange={handleChangePhoneNumber}
                        onClear={handleClearPhoneNumber}
                        phoneNumber={phoneNumber}
                        shouldHideLabel={true}
                    />
                    {renderSubmitButton()}
                    <ButtonInvisible
                        classes={cx(
                            signInStyles.button,
                            signUpPhoneStyles.button
                        )}
                        cypressTestId="sign-up-phone-skip-button"
                        onClick={handleSkip}
                        text="Skip"
                    />
                    <span className={signUpPhoneStyles.disclaimer}>
                        By clicking “Send Confirmation Code,” Sandbox Inc. will
                        send you an SMS to confirm your phone number. Standard
                        message, call, and data rates may apply.
                    </span>
                </form>
            </div>
        </div>
    );
};

SignUpPhone.propTypes = {
    handleChangePhoneNumber: PropTypes.func.isRequired,
    handleLoadingToggle: PropTypes.func.isRequired,
    loading: PropTypes.object.isRequired,
    setCurrentSection: PropTypes.func.isRequired,
    setUserInfo: PropTypes.func.isRequired,
    userInfo: PropTypes.object.isRequired,
};
