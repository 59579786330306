import React from "react";

// Components
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import CardGiftcardOutlinedIcon from "@mui/icons-material/CardGiftcardOutlined";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import { Text } from "components/Text/Text";

// CSS
import styles from "./support-squad-list-empty.module.scss";

// Media
import supportSquadHeaderSrc from "media/scenes/support-squad/support-squad-header.jpg";

export const SupportSquadListEmpty = () => {
    return (
        <div className={styles.supportSquadListEmpty}>
            <div
                className={styles.header}
                style={{ backgroundImage: `url(${supportSquadHeaderSrc})` }}
            >
                <Text classes={styles.headline} isBold>
                    Create a community
                </Text>
                <Text classes={styles.headline} isBold>
                    of support
                </Text>
            </div>
            <div className={styles.list}>
                <div className={styles.item}>
                    <div className={styles.icon}>
                        <MailOutlineIcon fontSize="large" />
                    </div>
                    <div className={styles.body}>
                        <Text classes={styles.title} isBold>
                            Share your Letters
                        </Text>
                        <Text classes={styles.subtitle}>
                            Share Letters so everyone in the Squad can write to
                            your recruit.
                        </Text>
                    </div>
                </div>
                <div className={styles.item}>
                    <div className={styles.icon}>
                        <PeopleOutlineIcon fontSize="large" />
                    </div>
                    <div className={styles.body}>
                        <Text classes={styles.title} isBold>
                            Watch the support
                        </Text>
                        <Text classes={styles.subtitle}>
                            Scroll through Squad activity and ensure they are
                            receiving support.
                        </Text>
                    </div>
                </div>
                <div className={styles.item}>
                    <div className={styles.icon}>
                        <CalendarMonthOutlinedIcon fontSize="large" />
                    </div>
                    <div className={styles.body}>
                        <Text classes={styles.title} isBold>
                            Get Weekly Updates
                        </Text>
                        <Text classes={styles.subtitle}>
                            Get access to carefully curated articles about your
                            recruit's experience each week.
                        </Text>
                    </div>
                </div>
                <div className={styles.item}>
                    <div className={styles.icon}>
                        <CardGiftcardOutlinedIcon fontSize="large" />
                    </div>
                    <div className={styles.body}>
                        <Text classes={styles.title} isBold>
                            Pay for their basic needs
                        </Text>
                        <Text classes={styles.subtitle}>
                            Send your recruit gift cards that can be used at
                            their basic training location's store.
                        </Text>
                    </div>
                </div>
            </div>
        </div>
    );
};
