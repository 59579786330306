import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import cx from "classnames";

// Components
import { ButtonPrimary } from "components/Buttons/Buttons";
import { ChevronRightIcon } from "components/Icons/ChevronRightIcon";
import { LoadingPage } from "../../../../components/Loading/Loading";

// CSS
import styles from "./weekly-updates-detail.module.scss";

// Hooks
import { Fade } from "../../../../hooks/Fade";

// Loggers
import { AnalyticsLogger } from "loggers/AnalyticsLogger";

// Utils
import { handleKeyDown } from "../../../../utils/eventUtils";

export const WeeklyUpdatesDetail = (props) => {
    const { handleSelectWeeklyUpdate, isDep, weeklyUpdates } = props;
    const { selected } = weeklyUpdates;

    /**
     * useEffect
     */

    useEffect(() => {
        AnalyticsLogger.logWeeklyUpdateOpened();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * End Hooks
     */

    function renderConsumedStatus(hasBeenConsumed, index) {
        const hasSetConsumedStatus = hasBeenConsumed !== null;
        if (hasSetConsumedStatus && !hasBeenConsumed) {
            return (
                <div
                    className={styles.consumedStatus}
                    data-cy={`weekly-updates-detail-body-list-item-${index}-consumed-status`}
                />
            );
        }
    }

    function renderContent() {
        return weeklyUpdates?.selected ? renderWeeklyUpdate() : <LoadingPage />;
    }

    function renderWeeklyUpdatesList() {
        return weeklyUpdates.updates?.map((update, index) => {
            const { hasBeenConsumed, contentNumber, slug, title, week } =
                update;
            const isActive = isDep
                ? selected.contentNumber === contentNumber
                : selected.week === week;
            const header = isDep
                ? `DEP Update #${contentNumber}`
                : `Week ${week}`;
            const onClick = () => handleSelectWeeklyUpdate(update);
            return (
                <div
                    className={cx(styles.item, {
                        [styles["item--active"]]: isActive,
                    })}
                    data-cy={`weekly-updates-detail-body-list-item-${index}`}
                    key={slug}
                    onClick={onClick}
                    tabIndex="0"
                    onKeyDown={(e) => handleKeyDown(e, onClick)}
                >
                    <div className={styles.text}>
                        <div className={styles.headerText}>
                            <span>
                                {header}
                                {renderConsumedStatus(hasBeenConsumed, index)}
                            </span>
                        </div>
                        <div className={styles.description}>
                            <span>{title}</span>
                        </div>
                    </div>
                    <div className={styles.chevron}>
                        <ChevronRightIcon height="20px" width="20px" />
                    </div>
                </div>
            );
        });
    }

    function renderWeeklyUpdate() {
        const { featuredImageUrl, iosLightHtml, title, week } = selected;
        return (
            <Fade show={selected}>
                <div className={styles.weeklyUpdatesDetail}>
                    <div
                        className={styles.header}
                        style={{ backgroundImage: `url(${featuredImageUrl})` }}
                    >
                        {!isDep && (
                            <div className={styles.week}>
                                <span className={styles.description}>WEEK</span>
                                <span className={styles.number}>{week}</span>
                            </div>
                        )}
                    </div>
                    <div className={styles.body}>
                        <div className={styles.left}>
                            <h1 className={styles.title}>{title}</h1>
                            <div
                                className={styles.content}
                                dangerouslySetInnerHTML={{
                                    __html: iosLightHtml,
                                }}
                            />
                            {!isDep && (
                                <div className={styles.button}>
                                    <Link
                                        onClick={
                                            AnalyticsLogger.logWeeklyUpdateCTAClicked
                                        }
                                        to="/letters/compose"
                                    >
                                        <ButtonPrimary cypressTestId="weekly-updates-detail-body-button">
                                            <span>
                                                Send Letter to Your Recruit
                                            </span>
                                        </ButtonPrimary>
                                    </Link>
                                </div>
                            )}
                        </div>
                        <div className={styles.right}>
                            <div className={styles.list}>
                                {renderWeeklyUpdatesList()}
                            </div>
                        </div>
                    </div>
                </div>
            </Fade>
        );
    }

    return <div className="page-body">{renderContent()}</div>;
};
