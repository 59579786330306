import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// Components
import { Banner } from "components/Banner/Banner";
import {
    ButtonPrimary,
    ButtonInvisible,
} from "../../../../../components/Buttons/Buttons";
import { DropdownState } from "components/Dropdowns/DropdownState/DropdownState";
import { Select } from "components/Inputs/Select/Select";
import { Text } from "components/Text/Text";
import { TextField } from "components/Inputs/TextField/TextField";
import { ToggleSwitch } from "components/ToggleSwitch/ToggleSwitch";

// CSS
import styles from "./contact-modal-form.module.scss";

// Hooks
import { useContactModalForm } from "./hooks/useContactModalForm";

// Utils
import { emptyFunction } from "utils/miscUtils";
import { titleCase } from "utils/textUtils";
import { generateFullName } from "utils/userUtils";

export const ContactModalForm = (props) => {
    const {
        contactStaging,
        handleChange,
        handleSubmit,
        loading,
        setCurrentSection,
        setHeaderText,
        shouldReverseFullName,
        shouldShowBackButton,
        titles,
        toggleShouldReverseFullName,
    } = props;
    const { address, firstName, lastName } = contactStaging;
    const { city, line1, line2, state, zipcode } = address || {};
    const buttonPrimaryText = loading.form ? "Confirming..." : "Confirm";
    const hasNameAndAddress =
        firstName && lastName && line1 && city && state && zipcode;
    const canSubmit = !loading.form && hasNameAndAddress;
    const onSubmit = canSubmit ? handleSubmit : emptyFunction;

    /**
     * Custom Hooks
     **/

    const { handleGoBack, handleGoToSelectBase } = useContactModalForm({
        setCurrentSection,
        setHeaderText,
    });

    /**
     * End Hooks
     **/

    function renderPreview() {
        return (
            <div
                className={cx(styles.preview, {
                    [styles["preview--hidden"]]: !firstName || !lastName,
                })}
                data-cy="contact-modal-form-preview"
            >
                <Text>Letters will be addressed to:</Text>
                <Text isBold>
                    {generateFullName(contactStaging, shouldReverseFullName)}
                </Text>
            </div>
        );
    }

    function renderReverseFullNameToggleSwitch() {
        return (
            <ToggleSwitch
                checked={shouldReverseFullName}
                classes={cx(styles.toggle, {
                    [styles["toggle--hidden"]]: !firstName || !lastName,
                })}
                cypressTestId="contact-modal-form-toggle"
                labelSwitchText="Reverse first and last name?"
                name="toggleReverseName"
                onChange={toggleShouldReverseFullName}
            />
        );
    }

    return (
        <div className={styles.contactModalForm}>
            <form
                className={cx(styles.form, "form")}
                id="contact-modal__form"
                onSubmit={onSubmit}
            >
                <TextField
                    className={cx(styles.input, styles["input--firstName"])}
                    cypressTestId="contact-modal-form-first-name"
                    id="firstName"
                    label="First Name"
                    name="firstName"
                    onChange={handleChange}
                    required
                    value={firstName}
                />

                <TextField
                    className={cx(styles.input, styles["input--lastName"])}
                    cypressTestId="contact-modal-form-last-name"
                    id="lastName"
                    label="Last Name"
                    name="lastName"
                    onChange={handleChange}
                    required
                    value={lastName}
                />
                <Select
                    className={cx(styles.input, styles["input--title"])}
                    cypressTestId="contact-modal-form-title-select"
                    id="contact-modal-form-title-select"
                    label="Rank"
                    labelId="contact-modal-form-title-select"
                    name="title"
                    onChange={handleChange}
                >
                    <option value={null} />
                    {titles.map((option) => (
                        <option
                            key={`${option.title} ${option.branchId}`}
                            value={option.title}
                        >
                            {`${option.title} ${titleCase(option.branchId)}`}
                        </option>
                    ))}
                </Select>
                {renderPreview()}
                {renderReverseFullNameToggleSwitch()}
                <TextField
                    className={cx(styles.input, styles["input--line1"])}
                    cypressTestId="contact-modal-form-line-1"
                    id="line1"
                    label="Address Line 1"
                    name="line1"
                    onChange={handleChange}
                    required
                    value={line1}
                />
                <TextField
                    className={cx(styles.input, styles["input--line2"])}
                    cypressTestId="contact-modal-form-line-2"
                    id="line2"
                    label="Address Line 2"
                    name="line2"
                    onChange={handleChange}
                    value={line2}
                />
                <TextField
                    className={cx(styles.input, styles["input--city"])}
                    cypressTestId="contact-modal-form-city"
                    id="city"
                    label="City"
                    name="city"
                    onChange={handleChange}
                    required
                    value={city}
                />
                <DropdownState
                    className={cx(styles.input, styles["input--state"])}
                    cypressTestId="contact-modal-form-state"
                    name="state"
                    onChange={handleChange}
                    value={state}
                />
                <TextField
                    className={cx(styles.input, styles["input--zipcode"])}
                    cypressTestId="contact-modal-form-zipcode"
                    id="zipcode"
                    label="Zip Code"
                    name="zipcode"
                    onChange={handleChange}
                    required
                    value={zipcode}
                />
                <Banner
                    classes={styles.banner}
                    config={{ shouldShowChevron: true }}
                    onClick={handleGoToSelectBase}
                    tabFlow
                >
                    <Text isBold>Need Help?</Text>
                    <Text>
                        If your recipient's address has changed significantly,
                        click here to use our address formatter assistant.
                    </Text>
                </Banner>
                <div className={styles.buttons}>
                    <ButtonPrimary
                        cypressTestId="contact-modal-form-submit-button"
                        isDisabled={!canSubmit}
                        onClick={onSubmit}
                        text={buttonPrimaryText}
                        type="submit"
                    />
                    {shouldShowBackButton && (
                        <ButtonInvisible
                            cypressTestId="contact-modal-form-back-button"
                            onClick={handleGoBack}
                            text="Back"
                            type="reset"
                        />
                    )}
                </div>
            </form>
        </div>
    );
};

ContactModalForm.propTypes = {
    contactStaging: PropTypes.shape({
        address: PropTypes.shape({
            city: PropTypes.string,
            line1: PropTypes.string,
            line2: PropTypes.string,
            state: PropTypes.string,
            zipcode: PropTypes.string,
        }),
        firstName: PropTypes.string,
        lastName: PropTypes.string,
    }).isRequired,
    handleChange: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    loading: PropTypes.shape({
        form: PropTypes.bool,
    }).isRequired,
    setCurrentSection: PropTypes.func.isRequired,
    setHeaderText: PropTypes.func.isRequired,
    shouldReverseFullName: PropTypes.bool.isRequired,
    shouldShowBackButton: PropTypes.bool,
    titles: PropTypes.arrayOf(
        PropTypes.shape({
            branchId: PropTypes.string,
            title: PropTypes.string,
        })
    ).isRequired,
    toggleShouldReverseFullName: PropTypes.func.isRequired,
};
