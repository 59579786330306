import { useEffect, useState } from "react";

export const useSignInWelcomeTypingEffect = ({
    captions,
    delay,
    handleSwitchBackground,
    typingSpeed,
}) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentText, setCurrentText] = useState("");
    const [isDeleting, setIsDeleting] = useState(false);

    useEffect(() => {
        const handleTyping = () => {
            const nextCharIndex = isDeleting
                ? currentText.length - 1
                : currentText.length;
            const nextText = captions[currentIndex].slice(
                0,
                nextCharIndex + (isDeleting ? -1 : 1)
            );
            setCurrentText(nextText);

            if (!isDeleting && nextText === captions[currentIndex]) {
                setTimeout(() => setIsDeleting(true), delay);
            } else if (isDeleting && nextText.length === 1) {
                setIsDeleting(false);
                setCurrentIndex((currentIndex + 1) % captions.length);
                handleSwitchBackground();
            }
        };

        const typingTimeout = setTimeout(
            handleTyping,
            isDeleting ? typingSpeed / 2 : typingSpeed
        );

        return () => clearTimeout(typingTimeout);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentIndex, currentText, delay, isDeleting, captions, typingSpeed]);

    return { currentText };
};
