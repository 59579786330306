import React from "react";
import PropTypes from "prop-types";

// Components
import { ButtonPrimary } from "components/Buttons/Buttons";
import { CartDiscount } from "../CartDiscount/CartDiscount";
import { CartHelp } from "../CartHelp/CartHelp";
import { CartItems } from "../CartItems/CartItems";
import { CartLineItems } from "../CartLineItems/CartLineItems";
import { CartPaymentMethod } from "../CartPaymentMethod/CartPaymentMethod";
import { CartShippingAddress } from "../CartShippingAddress/CartShippingAddress";
import { CartShippingOptions } from "../CartShippingOptions/CartShippingOptions";
import { Text } from "components/Text/Text";

// CSS
import styles from "./cart-main.module.scss";

// Hooks
import { useCartMain } from "./hooks/useCartMain";

// Utils
import { convertWholeNumberToDollars } from "utils/cartUtils";

export const CartMain = ({
    handleCompleteCheckout,
    handleSelectPaymentMethod,
    isSubmittingPaymentMethod,
    selectedPaymentMethod,
    setCartCurrentSection,
    setHeaderText,
}) => {
    /**
     * Custom Hooks
     */

    const { cart, isSubmittingCart, isUpdatingCart } = useCartMain({
        setHeaderText,
    });

    /**
     * End Hooks
     */

    return (
        <div className={styles.cartMain}>
            <div className={styles.content}>
                <CartItems />
                <CartShippingAddress
                    setCartCurrentSection={setCartCurrentSection}
                />
                <CartShippingOptions />
                <CartPaymentMethod
                    handleSelectPaymentMethod={handleSelectPaymentMethod}
                    isSubmittingPaymentMethod={isSubmittingPaymentMethod}
                    selectedPaymentMethod={selectedPaymentMethod}
                    setCartCurrentSection={setCartCurrentSection}
                />
                <CartDiscount />
                <CartLineItems />
            </div>
            <div className={styles.buttons}>
                <ButtonPrimary
                    classes={styles.button}
                    config={{ shouldMaintainDimensions: true }}
                    isDisabled={isUpdatingCart || isSubmittingCart}
                    isLoading={isSubmittingCart}
                    onClick={handleCompleteCheckout}
                >
                    <Text>Place Your Order</Text>
                    <Text>{convertWholeNumberToDollars(cart.total)}</Text>
                </ButtonPrimary>
                <CartHelp />
            </div>
        </div>
    );
};

CartMain.propTypes = {
    handleCompleteCheckout: PropTypes.func.isRequired,
    handleSelectPaymentMethod: PropTypes.func.isRequired,
    isSubmittingPaymentMethod: PropTypes.bool.isRequired,
    selectedPaymentMethod: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
    ]),
    setCartCurrentSection: PropTypes.func.isRequired,
    setHeaderText: PropTypes.func.isRequired,
};
