import React, { useEffect, useState } from "react";
import cx from "classnames";

// Components
import { ButtonInvisible, ButtonPrimary } from "components/Buttons/Buttons";
import { ButtonRounded } from "components/Buttons/ButtonRounded/ButtonRounded";
import { Text } from "components/Text/Text";

// CSS
import signInStyles from "../../../sign-in.module.scss";
import styles from "./sign-up-ship-date.module.scss";

// Enums
import { NavigationEnum } from "scenes/SignIn/constants/constants";

// Loggers
import { AnalyticsLogger } from "loggers/AnalyticsLogger";

// Utils
import { SandboxxRestAPI } from "utils/sandboxx";

export const SignUpShipDate = (props) => {
    const {
        handleLoadingToggle,
        loading,
        setCurrentSection,
        setUserInfo,
        showNotification,
        storeUserAfterUpdate,
        userInfo,
    } = props;
    const { base, role, shipDate } = userInfo;

    /**
     * useState
     */

    const [knowsShipDate, setKnowsShipDate] = useState(null);
    const [selectedTile, setSelectedTile] = useState(null);

    /**
     * useEffect
     */

    useEffect(() => {
        if (knowsShipDate === false) {
            handleSubmit();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [knowsShipDate]);

    /**
     * Constants
     */

    const hasSelectedDate = shipDate.day && shipDate.month && shipDate.year;

    /**
     * End Hooks & Constants
     */

    function handleGoBack() {
        setCurrentSection(NavigationEnum.SIGN_UP_RECRUITING_STATION);
    }

    function handleSelectDate(e) {
        const { name, value } = e.target;
        setUserInfo({
            ...userInfo,
            shipDate: {
                ...userInfo.shipDate,
                [name]: value,
            },
        });
    }

    function handleSelectTile(bool) {
        setKnowsShipDate(bool);
        setSelectedTile(bool);
    }

    function handleSubmit(e) {
        e && e?.preventDefault();
        if (validateBeforeSubmit()) {
            if (hasSelectedDate) {
                handleLoadingToggle("shipDate", true);
                setShipDate();
            } else {
                AnalyticsLogger.logShipDateSelect(knowsShipDate, role);
                setCurrentSection(NavigationEnum.SIGN_UP_SUCCESS);
            }
        } else {
            showFailedValidationNotification();
        }
    }

    function onSetShipDateFailure(res) {
        handleLoadingToggle("shipDate", false);
        showNotification({
            text: res.message,
            type: "warning",
        });
    }

    function onSetShipDateSuccess() {
        handleLoadingToggle("shipDate", false);
        AnalyticsLogger.logShipDateSelect(knowsShipDate, role);
        storeUserAfterUpdate();
        setCurrentSection(NavigationEnum.SIGN_UP_SUCCESS);
    }

    function showFailedValidationNotification() {
        if (knowsShipDate === null) {
            props.showNotification({
                text: "Please select an option.",
                type: "warning",
            });
        } else if (knowsShipDate && !hasSelectedDate) {
            props.showNotification({
                text: "Please provide a ship date.",
                type: "warning",
            });
        }
    }

    function setShipDate() {
        const { day, month, year } = shipDate;
        const formattedShipDate = `${year}-${month}-${day}`;
        SandboxxRestAPI.setUserShipDate(
            { baseId: base.id, shipDate: formattedShipDate },
            onSetShipDateSuccess,
            onSetShipDateFailure,
            onSetShipDateFailure
        );
    }

    function validateBeforeSubmit() {
        // If user knows ship date, make sure they have provided a ship date
        if (knowsShipDate === true) {
            return hasSelectedDate;
        }
        // If user does not know ship date, proceed
        else if (knowsShipDate === false) {
            return true;
        }
        // If user has not indicated whether they know ship date or not, show error message
        else if (knowsShipDate === null) {
            return false;
        }
    }

    function renderButtons() {
        return (
            <div className={signInStyles.buttons}>
                <ButtonInvisible
                    classes={cx(
                        signInStyles.button,
                        signInStyles["button--invisible"]
                    )}
                    onClick={handleGoBack}
                    text="Back"
                    type="button"
                />
                {knowsShipDate && renderSubmitButton()}
            </div>
        );
    }

    function renderDateSelect() {
        return (
            knowsShipDate && (
                <div className={signInStyles["select-date"]}>
                    <div className={signInStyles.text}>
                        <Text type="h4">
                            Great! We’ll get you synced up with your friend or
                            family member. Enter your ship date below.
                        </Text>
                    </div>
                    <div className={signInStyles.inputs}>
                        <select
                            className={cx(
                                signInStyles.form,
                                "select",
                                styles.select
                            )}
                            data-cy="ship-date-month"
                            id="ship-date-month"
                            name="month"
                            onChange={handleSelectDate}
                        >
                            <option value="" disabled selected>
                                Month
                            </option>
                            <option value="01">January</option>
                            <option value="02">February</option>
                            <option value="03">March</option>
                            <option value="04">April</option>
                            <option value="05">May</option>
                            <option value="06">June</option>
                            <option value="07">July</option>
                            <option value="08">August</option>
                            <option value="09">September</option>
                            <option value="10">October</option>
                            <option value="11">November</option>
                            <option value="12">December</option>
                        </select>
                        <select
                            className={cx(
                                signInStyles.form,
                                "select",
                                styles.select
                            )}
                            data-cy="ship-date-day"
                            id="ship-date-day"
                            name="day"
                            onChange={handleSelectDate}
                        >
                            <option value="" disabled selected>
                                Day
                            </option>
                            <option value="01">1</option>
                            <option value="02">2</option>
                            <option value="03">3</option>
                            <option value="04">4</option>
                            <option value="05">5</option>
                            <option value="06">6</option>
                            <option value="07">7</option>
                            <option value="08">8</option>
                            <option value="09">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                            <option value="13">13</option>
                            <option value="14">14</option>
                            <option value="15">15</option>
                            <option value="16">16</option>
                            <option value="17">17</option>
                            <option value="18">18</option>
                            <option value="19">19</option>
                            <option value="20">20</option>
                            <option value="21">21</option>
                            <option value="22">22</option>
                            <option value="23">23</option>
                            <option value="24">24</option>
                            <option value="25">25</option>
                            <option value="26">26</option>
                            <option value="27">27</option>
                            <option value="28">28</option>
                            <option value="29">29</option>
                            <option value="30">30</option>
                            <option value="31">31</option>
                        </select>
                        <select
                            className={cx(
                                signInStyles.form,
                                "select",
                                styles.select
                            )}
                            data-cy="ship-date-year"
                            id="ship-date-year"
                            name="year"
                            onChange={handleSelectDate}
                        >
                            <option value="" disabled selected>
                                Year
                            </option>
                            <option value="2023">2023</option>
                            <option value="2024">2024</option>
                            <option value="2025">2025</option>
                            <option value="2026">2026</option>
                            <option value="2027">2027</option>
                            <option value="2028">2028</option>
                        </select>
                    </div>
                </div>
            )
        );
    }

    function renderOptions() {
        return (
            <div className={cx(signInStyles.options, styles.options)}>
                <ButtonRounded
                    classes={styles["yes-button"]}
                    cypressTestId="sign-up-ship-date-button-yes"
                    isFilled={selectedTile}
                    onClick={() => handleSelectTile(true)}
                    text="Yes"
                    type="button"
                />
                <ButtonRounded
                    cypressTestId="sign-up-ship-date-button-not-yet"
                    onClick={() => handleSelectTile(false)}
                    text="No"
                    type="button"
                />
            </div>
        );
    }

    function renderSubmitButton() {
        return loading.shipDate ? (
            <ButtonPrimary
                classes={signInStyles.button}
                color="orange"
                isDisabled={loading.shipDate}
                text="Updating..."
                type="button"
            />
        ) : (
            <ButtonPrimary
                classes={signInStyles.button}
                color="orange"
                cypressTestId="sign-up-ship-date-next-button"
                isDisabled={!hasSelectedDate}
                onClick={handleSubmit}
                text="Next"
                type="button"
            />
        );
    }

    return (
        <div
            className={cx(
                signInStyles.content,
                signInStyles["ship-date"],
                styles["sign-up-ship-date"]
            )}
        >
            <div className={cx(signInStyles.top, styles.top)}>
                <div className={signInStyles.header}>
                    <Text classes={signInStyles.text}>
                        Do you know your ship date?
                    </Text>
                    <Text classes={signInStyles["text-secondary"]}>
                        Adding in your ship date allows us to sync your friends
                        and family to your schedule for weekly updates and
                        notifications, keeping them involved, and updating them
                        on your progress every step of the way.
                    </Text>
                </div>
                {renderOptions()}
                {renderDateSelect()}
            </div>
            <div
                className={cx(
                    signInStyles.bottom,
                    styles["navigation-buttons"]
                )}
            >
                {renderButtons()}
            </div>
        </div>
    );
};
