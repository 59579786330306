import PropTypes from "prop-types";

// Components
import { ButtonSecondary } from "components/Buttons/Buttons";
import { SaveIcon } from "components/Icons/SaveIcon";

// Loggers
import { AnalyticsLogger } from "loggers/AnalyticsLogger";

// Utils
import { calculateRemainingMessageCharacters } from "utils/lettersUtils";
import { emptyFunction } from "utils/miscUtils";

export const LettersDraftSaveButton = ({
    handleInitializeSaveDraft,
    message,
    isSavingDraft,
    setIsSavingDraft,
}) => {
    const { remainingCharacters } = calculateRemainingMessageCharacters(
        message.content
    );
    const isDisabled = remainingCharacters < 0;
    const saveButtonIcon = !isSavingDraft && (
        <SaveIcon
            className="icon"
            color={isDisabled ? "var(--gray-light)" : "#2B536A"}
        />
    );
    const saveButtonOnClick = isSavingDraft ? emptyFunction : onClick;
    const saveButtonText = isSavingDraft ? "Saving..." : "Save";

    function onClick() {
        handleInitializeSaveDraft(true);
        AnalyticsLogger.logLetterComposeSaveDraft();
        setIsSavingDraft(true);
    }

    return (
        <ButtonSecondary
            classes="letters-draft-save-button"
            cypressTestId="letters-draft-save-button"
            isDisabled={isDisabled}
            onClick={saveButtonOnClick}
            type="button"
        >
            {saveButtonIcon}
            {saveButtonText}
        </ButtonSecondary>
    );
};

LettersDraftSaveButton.defaultProps = {
    handleDraftSave: emptyFunction,
    isSavingDraft: false,
};

LettersDraftSaveButton.propTypes = {
    handleDraftSave: PropTypes.func.isRequired,
    message: PropTypes.object.isRequired,
    isSavingDraft: PropTypes.bool.isRequired,
    setIsSavingDraft: PropTypes.func.isRequired,
};
