import React from "react";
import cx from "classnames";

// Components
import { Panel } from "components/Panel/Panel";
import { Text } from "components/Text/Text";
import { WalletGiftCardsPurchaseBillingDetails } from "./components/WalletGiftCardsPurchaseBillingDetails/WalletGiftCardsPurchaseBillingDetails";
import { WalletGiftCardsPurchaseConfirmation } from "./components/WalletGiftCardsPurchaseConfirmation/WalletGiftCardsPurchaseConfirmation";
import { WalletGiftCardsPurchaseLearnMore } from "./components/WalletGiftCardsPurchaseTermsConditions/WalletGiftCardsPurchaseLearnMore";
import { WalletGiftCardsPurchaseOrderProgress } from "./components/WalletGiftCardsPurchaseOrderProgress/WalletGiftCardsPurchaseOrderProgress";
import { WalletGiftCardsPurchaseRecipient } from "./components/WalletGiftCardsPurchaseRecipient/WalletGiftCardsPurchaseRecipient";
import { WalletGiftCardsPurchaseReview } from "./components/WalletGiftCardsPurchaseReview/WalletGiftCardsPurchaseReview";
import { WalletGiftCardsPurchaseSelectOption } from "./components/WalletGiftCardsPurchaseSelectOption/WalletGiftCardsPurchaseSelectOption";
import { WalletGiftCardsPurchaseSender } from "./components/WalletGiftCardsPurchaseSender/WalletGiftCardsPurchaseSender";

// CSS
import styles from "./wallet-gift-cards-purchase.module.scss";
import "./wallet-gift-cards-purchase-braintree-override.scss";

// Enums
import { NavigationEnum } from "./enums/NavigationEnum";

// Hooks
import { useWalletGiftCardsPurchase } from "./hooks/useWalletGiftCardsPurchase";

export const WalletGiftCardsPurchase = (props) => {
    const { showNotification } = props;

    /**
     * Custom Hooks
     */

    const {
        clearRecipient,
        contacts,
        currentSection,
        giftCardOptions,
        handleSelectRecipient,
        hasAcceptedTermsAndConditions,
        loading,
        orderNumber,
        orderProgress,
        paymentMethod,
        purchaseGiftCard,
        recipient,
        recipientSource,
        resetPurchaseState,
        savedCards,
        selectedGiftCardOption,
        sender,
        setCurrentSection,
        setHasAcceptedTermsAndConditions,
        setLoading,
        setOrderProgress,
        setPaymentMethod,
        setRecipientSource,
        setSelectedGiftCardOption,
        setSender,
        suggestedContacts,
    } = useWalletGiftCardsPurchase({ showNotification });

    /**
     * End Hooks
     */

    function renderCurrentSection() {
        return {
            [NavigationEnum.BILLING_DETAILS]: (
                <WalletGiftCardsPurchaseBillingDetails
                    loading={loading}
                    paymentMethod={paymentMethod}
                    savedCards={savedCards}
                    setCurrentSection={setCurrentSection}
                    setLoading={setLoading}
                    setOrderProgress={setOrderProgress}
                    setPaymentMethod={setPaymentMethod}
                    showNotification={showNotification}
                />
            ),
            [NavigationEnum.CONFIRMATION]: (
                <WalletGiftCardsPurchaseConfirmation
                    orderNumber={orderNumber}
                    orderProgress={orderProgress}
                    paymentMethod={paymentMethod}
                    recipient={recipient}
                    resetPurchaseState={resetPurchaseState}
                    selectedGiftCardOption={selectedGiftCardOption}
                    sender={sender}
                    setCurrentSection={setCurrentSection}
                />
            ),
            [NavigationEnum.RECIPIENT]: (
                <WalletGiftCardsPurchaseRecipient
                    clearRecipient={clearRecipient}
                    contacts={contacts}
                    handleSelectRecipient={handleSelectRecipient}
                    recipient={recipient}
                    recipientSource={recipientSource}
                    setCurrentSection={setCurrentSection}
                    setOrderProgress={setOrderProgress}
                    showNotification={showNotification}
                    setRecipientSource={setRecipientSource}
                    suggestedContacts={suggestedContacts}
                />
            ),
            [NavigationEnum.REVIEW]: (
                <WalletGiftCardsPurchaseReview
                    loading={loading}
                    orderProgress={orderProgress}
                    paymentMethod={paymentMethod}
                    purchaseGiftCard={purchaseGiftCard}
                    setCurrentSection={setCurrentSection}
                    setOrderProgress={setOrderProgress}
                />
            ),
            [NavigationEnum.SELECT_OPTION]: (
                <WalletGiftCardsPurchaseSelectOption
                    giftCardOptions={giftCardOptions}
                    hasAcceptedTermsAndConditions={
                        hasAcceptedTermsAndConditions
                    }
                    selectedGiftCardOption={selectedGiftCardOption}
                    setCurrentSection={setCurrentSection}
                    setHasAcceptedTermsAndConditions={
                        setHasAcceptedTermsAndConditions
                    }
                    setOrderProgress={setOrderProgress}
                    setSelectedGiftCardOption={setSelectedGiftCardOption}
                />
            ),
            [NavigationEnum.SENDER]: (
                <WalletGiftCardsPurchaseSender
                    sender={sender}
                    setCurrentSection={setCurrentSection}
                    setOrderProgress={setOrderProgress}
                    setSender={setSender}
                />
            ),
        }[currentSection];
    }

    function renderRightColumn() {
        if (currentSection === NavigationEnum.SELECT_OPTION) {
            return <WalletGiftCardsPurchaseLearnMore />;
        } else if (
            currentSection === NavigationEnum.CONFIRMATION ||
            currentSection === NavigationEnum.REVIEW
        ) {
            return null;
        } else {
            return (
                <WalletGiftCardsPurchaseOrderProgress
                    orderProgress={orderProgress}
                    paymentMethod={paymentMethod}
                    recipient={recipient}
                    selectedGiftCardOption={selectedGiftCardOption}
                    sender={sender}
                />
            );
        }
    }

    return (
        <div
            className={cx(
                styles["wallet-gift-cards-purchase"],
                "wallet-gift-cards-purchase"
            )}
        >
            <Panel classes={styles.panel}>
                <header
                    className={cx(styles.header, {
                        [styles["header--hidden"]]:
                            currentSection === NavigationEnum.CONFIRMATION,
                    })}
                >
                    <Text type="h1">Sandboxx Gift Card</Text>
                    <Text type="p">
                        Help your recruit cover training expenses, celebrate
                        graduation, or simply show your support.
                    </Text>
                </header>
                <div
                    className={cx(styles.body, {
                        [styles["body--full-width"]]:
                            currentSection === NavigationEnum.CONFIRMATION ||
                            currentSection === NavigationEnum.REVIEW,
                    })}
                >
                    <div className={styles["column-left"]}>
                        {renderCurrentSection()}
                    </div>
                    <div className={styles["column-right"]}>
                        {renderRightColumn()}
                    </div>
                </div>
            </Panel>
        </div>
    );
};
