import React from "react";
import cx from "classnames";
import { Skeleton } from "@material-ui/lab";

// Components
import { Carousel } from "components/Carousel/Carousel";

// CSS
import styles from "./dashboard-promotions.module.scss";

// Hooks
import { useDashboardPromotions } from "./hooks/useDashboardPromotions";

// Media
import promoCarouselFillerSrc from "media/dashboard/promo-carousel-filler.jpg";

// Utils
import { handleKeyDown } from "utils/eventUtils";

export const DashboardPromotions = (props) => {
    const { handlePurchaseModalToggle, promotions, taxIdentity } = props;

    /**
     * Custom Hooks
     */

    const {
        carouselConfig,
        hasPromotions,
        hasPromotionsFormatted,
        promotionItemStyle,
        promotionsFormatted,
        shouldShowPromotions,
    } = useDashboardPromotions({
        promotions,
        taxIdentity,
    });

    /**
     * End Hooks
     */

    if (hasPromotions && !hasPromotionsFormatted) {
        return (
            <div className={styles["dashboard__promotions"]}>
                <Skeleton variant="rect" width="100%" height="167px" />
            </div>
        );
    } else if (hasPromotionsFormatted && shouldShowPromotions) {
        return (
            <div className={styles["dashboard__promotions"]}>
                <Carousel
                    {...props}
                    classNames={{
                        container: styles.carousel,
                        wrapper: styles.wrapper,
                    }}
                    config={carouselConfig}
                    itemStyle={promotionItemStyle}
                    items={promotions}
                    itemsInViewCount={1}
                >
                    {promotionsFormatted
                        .map((promotion, i) => (
                            <img
                                alt="Sandboxx promotion"
                                className={styles.promotion}
                                data-cy={`dashboard-promotions-image-button-${i}`}
                                onClick={() =>
                                    handlePurchaseModalToggle(
                                        promotion.discountCode
                                    )
                                }
                                onKeyDown={
                                    /* istanbul ignore next */ (e) =>
                                        handleKeyDown(e, () =>
                                            handlePurchaseModalToggle(
                                                promotion.discountCode
                                            )
                                        )
                                }
                                key={i}
                                src={promotion.imageUrl}
                                tabIndex="0"
                            />
                        ))
                        .concat([
                            <img
                                alt="Sandboxx promotion"
                                className={cx(
                                    styles.promotion,
                                    "promo-carousel-filler"
                                )}
                                key="Empty carousel item"
                                src={promoCarouselFillerSrc}
                            />,
                        ])}
                </Carousel>
            </div>
        );
    } else {
        return null;
    }
};
