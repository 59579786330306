import { useState } from "react";
import sha256 from "crypto-js/sha256";

// Enums
import { NavigationEnum } from "scenes/SignIn/constants/constants";

// Logging
import { AnalyticsLogger } from "loggers/AnalyticsLogger";

// Platforms
import { Analytics } from "platforms/analytics";
import { Branch } from "platforms/branch";
import { Intercom } from "platforms/intercom";

// Utils
import { emptyFunction, isEmptyObject } from "utils/miscUtils";
import { CurrentUser, SandboxxRestAPI } from "utils/sandboxx";
import { getRecaptchaToken } from "utils/recaptchaUtils";
import { DEV } from "utils/urlUtils";

// Constants
const siteKey = DEV
    ? process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY_DEV
    : process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY_PROD;

export const useSignUp = (props) => {
    const {
        history,
        isUsingEmail,
        isUsingEmailVerify,
        setCurrentSection,
        showNotification,
        userInfo,
    } = props;

    /************************************************
     * useState
     ************************************************/

    const [connections, setConnections] = useState(null);
    const [loading, setLoading] = useState({
        base: false,
        branch: false,
        phone: false,
        rank: false,
        role: false,
        roleMilitary: false,
        signUp: false,
        shipDate: false,
        verificationCode: false,
        verify: false,
    });
    const [shouldShowEmailInUseModal, setShouldShowEmailInUseModal] =
        useState(false);

    /************************************************
     * End Hooks
     ************************************************/

    function handleLoadingToggle(key, bool, callback = emptyFunction) {
        setLoading({
            ...loading,
            [key]: bool,
        });
        callback();
    }

    async function handleSignUp(e) {
        e && e.preventDefault();
        handleLoadingToggle("signUp", true);
        const { email, firstName, lastName, password } = userInfo;
        const branch = Branch.generateSignUpParams();
        const installParams = isEmptyObject(branch) ? {} : { branch };
        const { musterInviteCode } =
            JSON.parse(sessionStorage.getItem("musterInviteCode")) || "";
        if (isUsingEmail) {
            window.grecaptcha.enterprise.ready(function () {
                window.grecaptcha.enterprise
                    .execute(siteKey, {
                        action: "signUpSubmit",
                    })
                    .then(function (recaptchaToken) {
                        if (recaptchaToken) {
                            const userPayload = {
                                email,
                                firstName,
                                installParams,
                                lastName,
                                musterInviteCode,
                                password: sha256(password).toString(),
                                platform: "WEB",
                                recaptchaToken,
                            };
                            SandboxxRestAPI.signUpRecaptcha(
                                userPayload,
                                onSignUpSuccess,
                                onSignUpFailure,
                                onSignUpError
                            );
                        } else {
                            onSignUpError();
                        }
                    });
            });
        } else if (isUsingEmailVerify) {
            getRecaptchaToken(
                "authEmailGenerate",
                (recaptchaToken) => {
                    SandboxxRestAPI.generateEmailVerificationCode(
                        {
                            email,
                            platform: "WEB",
                            recaptchaToken,
                        },
                        onSignUpEmailVerifySuccess,
                        onSignUpEmailVerifyFailure,
                        onSignUpEmailVerifyFailure
                    );
                },
                onSignUpError
            );
        } else {
            const userPayload = {
                email,
                firstName,
                installParams,
                lastName,
                musterInviteCode,
                password: sha256(password).toString(),
                platform: "WEB",
            };
            SandboxxRestAPI.signUp(
                userPayload,
                onSignUpSuccess,
                onSignUpFailure,
                onSignUpError
            );
        }
    }

    function handleUpdateName() {
        const { firstName, lastName } = userInfo;
        SandboxxRestAPI.updateUserFullName(
            { firstName, lastName },
            onUpdateNameSuccess,
            onUpdateNameFailure,
            onUpdateNameFailure
        );
    }

    function onSignUpError(err) {
        console.error("signup error", err);
        handleLoadingToggle("signUp", false);
        showNotification({
            text: "There was an issue signing you up. Please try again in a few moments.",
            type: "warning",
        });
    }

    function onSignUpFailure({ message }) {
        handleLoadingToggle("signUp", false);
        if (message.includes("Email address" && "already exists")) {
            setShouldShowEmailInUseModal(true);
        } else {
            showNotification({
                text: message,
                type: "warning",
            });
        }
    }

    function onSignUpSuccess(res) {
        const { auth, musterConnection, onboarding, user, verification } = res;
        sessionStorage.setItem(
            "hasMusterConnection",
            musterConnection || false
        );
        CurrentUser.storeUserSignIn(user, auth, verification, onboarding);
        Analytics.signUp({
            onIterableIdentifySuccess: AnalyticsLogger.logSignUpEndIterable,
        });
        const referralCode = sessionStorage.getItem("referralCode");
        if (referralCode) {
            SandboxxRestAPI.claimReferralCode(referralCode, () => {
                // Sucessfully redeemed, so clear the referralCode from session storage
                sessionStorage.setItem("referralCode", "");
            });
        }
        AnalyticsLogger.logSignUpEnd(user);
        Intercom.registerUser();
        handleLoadingToggle("signUp", false);
        setCurrentSection(NavigationEnum.SIGN_UP_NAME);
    }

    function onSignUpEmailVerifyFailure(err) {
        console.error({ err });
        handleLoadingToggle("signUp", false);
        showNotification({
            text: err.message,
            type: "warning",
        });
    }

    function onSignUpEmailVerifySuccess(res) {
        setCurrentSection(NavigationEnum.SIGN_UP_CONFIRM_EMAIL);
        showNotification({
            text: res.message,
            type: "success",
        });
        handleLoadingToggle("signUp", false);
    }

    function onUpdateNameFailure(err) {
        showNotification({
            text: "There was an issue updating your name. Please contact customer support.",
            type: "warning",
        });
    }

    function onUpdateNameSuccess(res) {
        history.push("/letters");
    }

    return {
        connections,
        handleLoadingToggle,
        handleSignUp,
        handleUpdateName,
        loading,
        setConnections,
        setShouldShowEmailInUseModal,
        shouldShowEmailInUseModal,
    };
};
