import React from "react";

// Components
import { Banner } from "components/Banner/Banner";
import { Text } from "components/Text/Text";

// CSS
import styles from "./referrals-rewards.module.scss";

export const ReferralsRewards = (props) => {
    return (
        <Banner classes={styles.referralsRewards} theme="secondary">
            <Text classes={styles.text}>
                Refer 1 friend, get 1 free Letter. Refer 2 friends, get 3 free
                Letters. Refer 3 friends, get 5 free Letters.
            </Text>
            <Text classes={styles.text}>
                Each referral after your third gives you 1 free letter.
            </Text>
        </Banner>
    );
};
