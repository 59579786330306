import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// Components
import { ButtonInvisible, ButtonPrimary } from "components/Buttons/Buttons";
import { Checkbox } from "components/Inputs/Checkbox/Checkbox";
import { WarningCircleIcon } from "components/Icons/WarningCircleIcon";

// CSS
import styles from "./support-squad-delete-modal.module.scss";

export const SupportSquadDeleteModalDeleteSquad = (props) => {
    const {
        deleteRequestPending,
        deleteSquad,
        handleChangeCheckbox,
        handleModalClose,
        hasAcceptedDeleteTerms,
        setHasAcceptedDeleteTerms,
        selectedSquad,
    } = props;

    function renderContent() {
        return (
            <>
                <div className={styles.container}>
                    <WarningCircleIcon height="64" width="64" />
                    <span className={styles.headerTextRed}>
                        Delete Support Squad
                    </span>
                    <span className={styles.bodyText}>
                        The support squad will not be able to be recovered once
                        deleted. In additon, the below items will be permanently
                        deleted:
                    </span>
                    <div className={styles.itemsToBeDeleted}>
                        <span className={styles.item}>
                            {selectedSquad.letterCount} Sent Letters
                        </span>
                        <span className={styles.item}>
                            {selectedSquad.tokenCount} Tokens
                        </span>
                        <span className={styles.item}>
                            Support Squad Access
                        </span>
                    </div>
                    <div className={styles.checkbox}>
                        <Checkbox
                            cyId="support-squad-delete-squad-confirm-checkbox"
                            checked={hasAcceptedDeleteTerms}
                            id="support-squad-delete-squad-confirm-checkbox"
                            onChange={handleChangeCheckbox}
                            text="I agree to the above terms"
                        />
                    </div>
                </div>
            </>
        );
    }

    function renderButtons() {
        return (
            <>
                <ButtonPrimary
                    classes={cx(
                        styles["remove-button"],
                        "button--primary-warning"
                    )}
                    cypressTestId="delete-squad-confirm-button"
                    isDisabled={!hasAcceptedDeleteTerms || deleteRequestPending}
                    onClick={() =>
                        deleteSquad(() => setHasAcceptedDeleteTerms(false))
                    }
                    text={
                        deleteRequestPending
                            ? "Deleting Squad..."
                            : "Delete Squad"
                    }
                    type="button"
                />
                <ButtonInvisible
                    classes={styles.button}
                    onClick={handleModalClose}
                    text="Cancel"
                    type="button"
                />
            </>
        );
    }

    return (
        <div className={styles.removeMembersWarning}>
            <div className="modal__content">{renderContent()}</div>
            <div className={styles.buttonContainer}>{renderButtons()}</div>
        </div>
    );
};

SupportSquadDeleteModalDeleteSquad.propTypes = {
    deleteRequestPending: PropTypes.bool.isRequired,
    deleteSquad: PropTypes.func.isRequired,
    handleChangeCheckbox: PropTypes.func.isRequired,
    handleModalClose: PropTypes.func.isRequired,
    hasAcceptedDeleteTerms: PropTypes.bool.isRequired,
    setHasAcceptedDeleteTerms: PropTypes.func.isRequired,
    selectedSquad: PropTypes.object.isRequired,
};
