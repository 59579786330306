import React from "react";

export const LettersComposeAddOnsConfirmation = (props) => {
    const { handleCloseModal, handleLetterSend } = props;

    function handleSubmit() {
        handleCloseModal(true);
        setTimeout(() => {
            handleLetterSend();
        }, 250);
    }

    return (
        <div className="letters-compose-purchase-confirmation-container modal__body">
            <div className="letters-compose-purchase-confirmation-checkmark" />
            <div className="letters-compose-purchase-confirmation-success-text">
                <span>Thanks for your purchase!</span>
            </div>
            <div className="letters-compose-purchase-confirmation-sent-text">
                <span>
                    You’ll be charged for your gift card when you send your
                    letter. Click below to continue.
                </span>
            </div>
            <button
                className="button button--primary button-small"
                onClick={handleSubmit}
            >
                <span>Send Letter</span>
            </button>
        </div>
    );
};
