import React, { useEffect, useState } from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// Components
import { Banner } from "components/Banner/Banner";
import { ButtonSecondary, ButtonPrimary } from "components/Buttons/Buttons";
import { Checkbox } from "components/Inputs/Checkbox/Checkbox";

// CSS
import styles from "./wallet-manage-purchase-card-modal-recipient-details.module.scss";

// Loggers
import { AnalyticsLogger } from "loggers/AnalyticsLogger";

// Validation
import { Validation } from "validation/validation";

// Constants
import { NavigationEnum, ProvidersEnum } from "../../constants/constants";
const { SELECT_DESIGN } = NavigationEnum;
const { AAFES, MCX, NEX } = ProvidersEnum;

export const WalletManagePurchaseCardModalRecipientDetails = (props) => {
    const {
        fetchWalletGiftCardOptions,
        loading,
        purchaseInfo,
        setCurrentSection,
        setPurchaseInfo,
    } = props;
    const { isForCurrentUser, provider } = purchaseInfo;

    /**
     * useState
     */

    const [email, setEmail] = useState("");

    /**
     * useEffect
     */

    useEffect(() => {
        AnalyticsLogger.logDigitalGiftCardRecipientEntry();
    }, []);

    /**
     * End Hooks & Constants
     */

    function handleChangeInput({ target }) {
        setEmail(target.value);
    }

    function handleSelectGiftCardIsForCurrentUser(e) {
        e.preventDefault();
        setPurchaseInfo((prev) => ({
            ...prev,
            isForCurrentUser: !prev.isForCurrentUser,
        }));
        AnalyticsLogger.logDigitalGiftCardForMeSelected();
    }

    function handleSubmitForm(e) {
        e.preventDefault();
        fetchWalletGiftCardOptions(provider);
        setPurchaseInfo((prev) => ({ ...prev, email }));
        setCurrentSection(SELECT_DESIGN);
    }

    function handleSelectProvider(e, provider) {
        e.preventDefault();
        setPurchaseInfo((prev) => ({
            ...prev,
            provider,
        }));
        AnalyticsLogger.logDigitalGiftCardBranchSelected();
    }

    function validateEmail() {
        if (isForCurrentUser) {
            return true;
        } else {
            return Validation.isEmailAddress(email);
        }
    }

    return (
        <div data-cy="purchase-modal-recipient-details">
            <form className={styles.form}>
                <label className={styles.label} htmlFor="email">
                    <span className={styles.text}>Recipient Email</span>
                    <span className={styles["text-secondary"]}>
                        Please enter your recipient's email address below. Gift
                        cards are delivered digitally, not in a letter.
                    </span>
                </label>
                <input
                    // Connects input with react-hook-form
                    {...(isForCurrentUser && { disabled: true })}
                    className={cx(styles.input, "input")}
                    data-cy="recipient-email-input"
                    id="email"
                    onChange={handleChangeInput}
                    type="text"
                />
                <Checkbox
                    checked={isForCurrentUser}
                    cyId="giftcard-for-self-checkbox"
                    id="wallet-purchase-modal-recipient-details-is-for-current-user"
                    onChange={handleSelectGiftCardIsForCurrentUser}
                    text="This gift card is for me"
                />
                {isForCurrentUser && (
                    <Banner classes={styles.banner}>
                        <span>
                            Gift cards may only be redeemed by recipients who
                            have Exchange privileges.
                        </span>
                    </Banner>
                )}
                <label className={styles.label} htmlFor="branchId">
                    <span className={styles.text}>Recipient Branch</span>
                    <span className={styles["text-secondary"]}>
                        Which exchange does your recipient have access to?
                    </span>
                </label>
                <ButtonSecondary
                    classes={cx(styles.button)}
                    isFilled={provider === NEX}
                    onClick={(e) => handleSelectProvider(e, NEX)}
                    text="Navy"
                    type="button"
                />
                <ButtonSecondary
                    classes={cx(styles.button)}
                    cypressTestId="wallet-purchase-modal-marine-button"
                    isFilled={provider === MCX}
                    onClick={(e) => handleSelectProvider(e, MCX)}
                    text="Marine Corps"
                    type="button"
                />
                <ButtonSecondary
                    classes={cx(styles.button)}
                    isFilled={provider === AAFES}
                    onClick={(e) => handleSelectProvider(e, AAFES)}
                    text="Army & Air Force"
                    type="button"
                />
                <ButtonPrimary
                    classes={styles.button}
                    cypressTestId="wallet-purchase-modal-recipient-details-continue-button"
                    isDisabled={
                        loading.purchaseCard || !provider || !validateEmail()
                    }
                    onClick={handleSubmitForm}
                    text="Continue"
                    type="submit"
                />
            </form>
        </div>
    );
};

WalletManagePurchaseCardModalRecipientDetails.propTypes = {
    fetchWalletGiftCardOptions: PropTypes.func.isRequired,
    handleClosePurchaseCardModal: PropTypes.func.isRequired,
    loading: PropTypes.object.isRequired,
    purchaseInfo: PropTypes.object.isRequired,
    setCurrentSection: PropTypes.func.isRequired,
    setPurchaseInfo: PropTypes.func.isRequired,
};
