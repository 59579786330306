import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// Components
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

// CSS
import styles from "./support-squad-settings-modal-manage-options.module.scss";

// Loggers
import { AnalyticsLogger } from "loggers/AnalyticsLogger";

// Utils
import { handleKeyDown } from "utils/eventUtils";

export const SupportSquadSettingsModalManageOptions = (props) => {
    const {
        onDeleteSquadClick,
        openContactModalEdit,
        setCurrentSection,
        setSettingsSection,
    } = props;

    return (
        <div className={styles["support-squad-settings-modal-manage-options"]}>
            <div
                className={styles.squadOptions}
                data-cy="support-squad-settings-view"
            >
                <span className={styles.header}>SUPPORT SQUAD</span>
                <div
                    className={styles.option}
                    data-cy="support-squad-settings-edit-details-button"
                    onClick={() => {
                        setCurrentSection("edit-details");
                        AnalyticsLogger.logSquadUpdateSquadDetailsSelected();
                    }}
                    onKeyDown={(e) =>
                        handleKeyDown(e, () => {
                            setCurrentSection("edit-details");
                            AnalyticsLogger.logSquadUpdateSquadDetailsSelected();
                        })
                    }
                    tabIndex="0"
                >
                    <span>Update Support Squad Details</span>
                    <ChevronRightIcon
                        height="32px"
                        width="32px"
                        color="#9b9b9b"
                    />
                </div>
                <div
                    className={styles.option}
                    data-cy="support-squad-settings-edit-recipient-address-button"
                    onClick={() => {
                        openContactModalEdit();
                        AnalyticsLogger.logSquadUpdateRecipientAddressSelected();
                    }}
                    onKeyDown={(e) =>
                        handleKeyDown(e, () => {
                            openContactModalEdit();
                            AnalyticsLogger.logSquadUpdateRecipientAddressSelected();
                        })
                    }
                    tabIndex="0"
                >
                    <span>Update Recipient Address</span>
                    <ChevronRightIcon
                        height="32px"
                        width="32px"
                        color="#9b9b9b"
                    />
                </div>
            </div>
            <div className={styles.membershipOptions}>
                <span className={styles.header}>MANAGE MEMBERSHIP</span>
                <div
                    className={styles.option}
                    data-cy="support-squad-settings-manage-members-button"
                    onClick={() => {
                        setSettingsSection("manage-members");
                        AnalyticsLogger.logSquadViewMembersSelected();
                    }}
                    onKeyDown={(e) =>
                        handleKeyDown(e, () => {
                            setSettingsSection("manage-members");
                            AnalyticsLogger.logSquadViewMembersSelected();
                        })
                    }
                    tabIndex="0"
                >
                    <span>Manage Members</span>
                    <ChevronRightIcon
                        height="32px"
                        width="32px"
                        color="#9b9b9b"
                    />
                </div>
                <div
                    className={styles.option}
                    data-cy="support-squad-settings-invite-supporters-button"
                    onClick={() => {
                        setCurrentSection("invite");
                        AnalyticsLogger.logSquadInviteSupportersSelected();
                    }}
                    onKeyDown={(e) =>
                        handleKeyDown(e, () => {
                            setCurrentSection("invite");
                            AnalyticsLogger.logSquadInviteSupportersSelected();
                        })
                    }
                    tabIndex="0"
                >
                    <span>Invite Supporters</span>
                    <ChevronRightIcon
                        height="32px"
                        width="32px"
                        color="#9b9b9b"
                    />
                </div>
                <div
                    className={cx(styles.option, styles["option--red"])}
                    data-cy="support-squad-settings-delete-squad-button"
                    onClick={onDeleteSquadClick}
                    onKeyDown={(e) => handleKeyDown(e, onDeleteSquadClick)}
                    tabIndex="0"
                >
                    Delete Support Squad
                </div>
            </div>
        </div>
    );
};

SupportSquadSettingsModalManageOptions.propTypes = {
    onDeleteSquadClick: PropTypes.func.isRequired,
    openContactModalEdit: PropTypes.func.isRequired,
    setCurrentSection: PropTypes.func.isRequired,
    setSettingsSection: PropTypes.func.isRequired,
};
