import { useContext, useEffect, useState } from "react";

// Context
import { HeaderBarContext } from "context/headerBar";
import { NotificationBarContext } from "context/notificationBar";

// Loggers
import { AnalyticsLogger } from "loggers/AnalyticsLogger";

// Utils
import { SandboxxRestAPI } from "utils/sandboxx";

export const useReferrals = () => {
    /**
     * useContext
     */

    const { setHeaderBarContent } = useContext(HeaderBarContext);
    const { showNotification } = useContext(NotificationBarContext);

    /**
     * useState
     */

    const [earnedLetters, setEarnedLetters] = useState(null);
    const [isLoadingRewardsStatus, setIsLoadingRewardsStatus] = useState(true);
    const [pendingLetters, setPendingLetters] = useState(null);
    const [pendingReferrals, setPendingReferrals] = useState(null);
    const [referredReferrals, setReferredReferrals] = useState(null);

    /**
     * useEffect
     */

    useEffect(() => {
        // Initialize
        AnalyticsLogger.logWebReferralStart();
        setHeaderBarContent({
            backPathname: null,
            onBackClick: null,
            text: "Invite",
        });

        // Fetch rewards
        AnalyticsLogger.logReferralFeedViewed();
        fetchRewards();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * End Hooks
     */

    async function fetchRewards() {
        await SandboxxRestAPI.getReferralRewards(
            onFetchRewardsSuccess,
            onFetchRewardsFailure,
            onFetchRewardsFailure
        );
    }

    function onFetchRewardsFailure(err) {
        setIsLoadingRewardsStatus(false);
        showNotification({
            text: "There was an issue retrieving your referral information. Please wait a few minutes and try again.",
            type: "warning",
        });
    }

    function onFetchRewardsSuccess({
        earned_credits,
        pending_credits,
        users_pending,
        users_referred,
    }) {
        setEarnedLetters(earned_credits);
        setIsLoadingRewardsStatus(false);
        setPendingLetters(pending_credits);
        setPendingReferrals(users_pending);
        setReferredReferrals(users_referred);
    }

    return {
        earnedLetters,
        isLoadingRewardsStatus,
        pendingLetters,
        pendingReferrals,
        referredReferrals,
    };
};
