// Components
import { MaterialIcon } from "components/MaterialIcon/MaterialIcon";

// CSS
import styles from "../letters-compose-confirmation.module.scss";

// Enums
import { MaterialIconMode } from "components/MaterialIcon/enums/MaterialIconMode";

export function generateListIconItems() {
    return [
        {
            icon: (
                <MaterialIcon
                    className={styles.icon}
                    mode={MaterialIconMode.MATERIAL_SYMBOLS_OUTLINED}
                    name="drafts"
                />
            ),
            subtitle:
                "Our team is on it. They're working to print your letter, include any add ons, and add stationery for your recruit to write back.",
            title: "Your letter is being prepared to ship",
        },
        {
            icon: (
                <MaterialIcon
                    className={styles.icon}
                    mode={MaterialIconMode.MATERIAL_SYMBOLS_OUTLINED}
                    name="local_shipping"
                />
            ),
            subtitle:
                "With Sandboxx, you can watch your Letter make its way to the base. At supported bases, we ship your Letter overnight.",
            title: "Track the progress each step of the way",
        },
    ];
}
