import React, { useContext, useState } from "react";
import cx from "classnames";

// Components
import { ButtonPrimary } from "components/Buttons/Buttons";

// Context
import { MusterContext } from "context/muster";

// CSS
import styles from "../../sign-in.module.scss";

// Enums
import { NavigationEnum } from "scenes/SignIn/constants/constants";

// Platforms
import { Analytics } from "../../../../platforms/analytics";
import { Intercom } from "../../../../platforms/intercom";

// Utils
import { CurrentUser, SandboxxRestAPI } from "../../../../utils/sandboxx";

export const LoginEmail = (props) => {
    const {
        handleGoToForgotPassword,
        history,
        runShouldShowNavBarCheck,
        setCurrentSection,
        showNotification,
    } = props;

    /**
     * useContext
     */

    const { setShouldShowJoinSuccessModal } = useContext(MusterContext);

    // TODO: Move to SignIn
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState("");

    function handleChangeEmail(e) {
        setEmail(e.target.value);
    }

    function handleChangePassword(e) {
        setPassword(e.target.value);
    }

    function handleGoToLoginMain() {
        setCurrentSection(NavigationEnum.SIGN_IN_MAIN);
    }

    function handleLogin(e) {
        e.preventDefault();
        const { musterInviteCode } =
            JSON.parse(sessionStorage.getItem("musterInviteCode")) || "";
        if (email && password) {
            setLoading(true);
            SandboxxRestAPI.login(
                {
                    email,
                    password,
                    musterInviteCode,
                },
                onLoginSuccess,
                onLoginError,
                onLoginError
            );
        } else {
            showNotification({
                text: "Please enter an email and password.",
                type: "warning",
            });
        }
    }

    function onLoginError(err) {
        setLoading(false);
        showNotification({
            text: "The email and/or password you provided is not valid. Please try again.",
            type: "warning",
        });
    }

    function onLoginSuccess(res) {
        const { auth, onboarding, user, verification } = res;
        CurrentUser.storeUserSignIn(user, auth, verification, onboarding);
        Intercom.registerUser();
        Analytics.login();
        resetState();
        history.push("/letters");
        setShouldShowJoinSuccessModal(true);
        runShouldShowNavBarCheck();
    }

    function resetState() {
        setEmail("");
        setLoading(false);
        setPassword("");
    }

    function renderForm() {
        return (
            <form className={cx(styles.form, "form")} onSubmit={handleLogin}>
                <input type="submit" hidden />
                <span className={styles["input-header"]}>Email</span>
                <input
                    autoFocus
                    className={cx(styles.input, "input")}
                    data-cy="login-email-email-input"
                    name="email"
                    onChange={handleChangeEmail}
                />
                <span className={styles["input-header"]}>Password</span>
                <input
                    className={cx(styles.input, "input")}
                    data-cy="login-email-password-input"
                    name="password"
                    type="password"
                    onChange={handleChangePassword}
                />
            </form>
        );
    }

    function renderSubmitButton() {
        return loading ? (
            <ButtonPrimary
                classes={styles.button}
                isDisabled
                text="Logging in..."
                type="button"
            />
        ) : (
            <ButtonPrimary
                classes={styles.button}
                cypressTestId="login-email-login-button"
                onClick={handleLogin}
                text="Login"
                type="button"
            />
        );
    }

    return (
        <div className={styles.content}>
            <div className={styles.top}>
                <div className={styles.header}>
                    <span className={styles.text}>Welcome back!</span>
                    <span className={styles["text-secondary"]}>
                        Enter your login information below to start writing and
                        receiving letters!
                    </span>
                </div>
                {renderForm()}
                <span
                    className={cx(styles.link, "link")}
                    onClick={handleGoToForgotPassword}
                >
                    Forgot password?
                </span>
            </div>
            <div className={styles.bottom}>
                <div className={cx(styles.buttons, styles["buttons--single"])}>
                    {renderSubmitButton()}
                </div>
                <span className={styles.text}>
                    Don’t have an account?{" "}
                    <span
                        className={cx(styles.link, "link")}
                        onClick={handleGoToLoginMain}
                    >
                        Sign up
                    </span>
                </span>
            </div>
        </div>
    );
};
