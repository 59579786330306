import { useContext, useEffect, useState } from "react";

// Context
import { DepsContext } from "context/deps";
import { NotificationBarContext } from "context/notificationBar";

// Utils
import {
    generateProfileUpdateDiff,
    generateProfileUpdateMethods,
    generateUserStaging,
} from "../utils";
import { isAddressProperty } from "utils/userUtils";

// Validation
import { hasValidName } from "../validate";

export const useProfileFields = (props) => {
    const {
        handleShowPersonaSelectionModal,
        onProfileUpdateSuccess,
        profileInfo,
    } = props;

    /**
     * useContext
     */

    const { showNotification } = useContext(NotificationBarContext);
    const { fetchDepsLocations } = useContext(DepsContext);

    /**
     * useState
     */

    const [isEditing, setIsEditing] = useState(false);
    const [userStaging, setUserStaging] = useState({});

    /**
     * useEffect
     */

    // Set state user to props user when props user changes
    useEffect(() => {
        if (profileInfo) {
            setUserStaging(generateUserStaging(profileInfo));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profileInfo]);

    useEffect(() => {
        if (userStaging.triggerSave) {
            updateUserProperties();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userStaging.triggerSave]);

    // Re-fetch DEP locations when branchId changes
    useEffect(() => {
        if (userStaging.branchId) {
            fetchDepsLocations({ branchId: userStaging.branchId });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userStaging.branchId]);

    /**
     * End Hooks
     */

    function handleChange(e) {
        const { name, value } = e.target;
        isAddressProperty(name)
            ? setUserStaging((prevUserStaging) => ({
                  ...prevUserStaging,
                  address: { ...prevUserStaging.address, [name]: value },
              }))
            : setUserStaging((prevUserStaging) => ({
                  ...prevUserStaging,
                  [name]: value,
              }));
    }

    function handleEditEnd(e, { shouldValidate }) {
        e.preventDefault();
        if (!shouldValidate) {
            // If the user hits cancel when editing...
            setIsEditing(false);
        } else {
            if (!validateProfileAttributes()) {
                return;
            }
            // If the user hits save when editing...
            updateUserProperties();
        }
    }

    function handleOpenPersonaSelectionModal() {
        handleShowPersonaSelectionModal(true);
    }

    function updateUserProperties() {
        const newValues = generateProfileUpdateDiff(userStaging, profileInfo);
        const methods = generateProfileUpdateMethods(userStaging);
        Promise.all(
            Object.keys(newValues).map((property) => {
                return methods[property]();
            })
        )
            .then(onProfileUpdateSuccess)
            .catch(onProfileUpdateError);
    }

    function handleEditStart() {
        setIsEditing(true);
    }

    function onProfileUpdateError(err) {
        showNotification({
            text: "There was an issue updating your profile information. Please try again.",
            type: "warning",
        });
        setIsEditing(false);
    }

    function validateProfileAttributes() {
        if (!hasValidName(userStaging)) {
            showNotification({
                text: "Please make sure you have included a First Name and Last Name.",
                type: "warning",
            });
            return false;
        } else if (
            userStaging.address?.line1?.length > 40 ||
            userStaging.address?.line2?.length > 40
        ) {
            showNotification({
                text: `Your address exceeds our character count limit, please contact our team at happiness@sandboxx.us for assistance.`,
                time: 10000,
                type: "warning",
            });
            return false;
        }
        return true;
    }

    function toggleFormEditState(e, config) {
        e.preventDefault();
        isEditing ? handleEditEnd(e, config) : handleEditStart(config);
    }

    return {
        handleOpenPersonaSelectionModal,
        handleChange,
        isEditing,
        toggleFormEditState,
        userStaging,
    };
};
