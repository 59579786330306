import React, { useEffect } from "react";
import PropTypes from "prop-types";

// Components
import { ButtonPrimary } from "components/Buttons/Buttons";
import { CheckmarkSuccessIcon } from "components/Icons/CheckmarkSuccessIcon";

// CSS
import styles from "./support-squad-donate-tokens-modal-success.module.scss";

/**
 * TODO: Replace this with small modal configuration when available (and adjusting checkmark size according to the designs)
 */
export const SupportSquadDonateTokensModalSuccess = (props) => {
    const {
        handleCloseModal,
        setHeaderText,
        setHeaderSubText,
        selectedSquad,
        tokensToDonate,
    } = props;

    /**
     * useEffect
     */

    useEffect(() => {
        setHeaderText("");
        setHeaderSubText("");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Constants
     */

    /* istanbul ignore next */
    const tokensText = tokensToDonate > 1 ? "letters" : "letter";

    /**
     * End Hooks
     */

    return (
        <div className={styles["support-squad-donate-tokens-modal-success"]}>
            <CheckmarkSuccessIcon
                className={styles.checkmarkSuccess}
                height="64"
                width="64"
            />
            <div className={styles.text}>
                <p>
                    You have successfully donated {tokensToDonate} {tokensText}{" "}
                    to {selectedSquad.supporteeFirstName}'s Support Squad!
                </p>
            </div>
            <div className={styles.buttons}>
                <ButtonPrimary
                    classes={styles.button}
                    onClick={handleCloseModal}
                    text="Continue"
                    type="button"
                />
            </div>
        </div>
    );
};

SupportSquadDonateTokensModalSuccess.propTypes = {
    handleCloseModal: PropTypes.func.isRequired,
    setHeaderText: PropTypes.func.isRequired,
    setHeaderSubText: PropTypes.func.isRequired,
    tokensToDonate: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.oneOf([""]),
    ]),
};
