import React, { useMemo } from "react";
import cx from "classnames";

// Components
import { PurchaseModalBundleHighlightCard } from "./components/PurchaseModalBundleHighlightCard/PurchaseModalBundleHighlightCard";
import { Text } from "components/Text/Text";

// CSS
import styles from "./purchase-modal-bundle-highlight.module.scss";

// Enums
import { PurchaseModalNavigation } from "../../../enums/enums";

// Loggers
import { AnalyticsLogger } from "loggers/AnalyticsLogger";

// Utils
import { generateBundleCardData } from "./utils";
import { handleKeyDown } from "utils/eventUtils";

export const PurchaseModalBundleHighlight = (props) => {
    const {
        bundles,
        changeCurrentSection,
        handleCloseModal,
        handleSelectBundle,
        setPurchaseMode,
        selectedCard,
    } = props;

    /**
     * useMemo
     */

    const sandboxxPlusBundle = useMemo(
        () => bundles?.filter((bundle) => bundle.includesSandboxxPlus)[0],
        [bundles]
    );

    const multiPhotoBundle = useMemo(
        () =>
            bundles?.filter(
                (bundle) => bundle.multi_photo_redemptions === 1
            )[0],
        [bundles]
    );

    /**
     * Constants
     */

    const bundleCardData = generateBundleCardData(
        sandboxxPlusBundle,
        multiPhotoBundle
    );

    /**
     * End Hooks & Constants
     */

    function handleSubmit(bundle) {
        const hasSelectedCreditCard = selectedCard?.card;
        handleSelectBundle(bundle);
        setPurchaseMode("lettersPhotoManageModal");
        AnalyticsLogger.logAddBundleToCart({ bundle });
        hasSelectedCreditCard
            ? changeCurrentSection(PurchaseModalNavigation.REVIEW)
            : changeCurrentSection(PurchaseModalNavigation.PAYMENT);
    }

    return (
        <div
            className={styles["purchase-modal-bundle-highlight"]}
            data-cy="purchase-modal-bundle-highlight"
        >
            <div className={styles.content}>
                <img
                    alt="Bundle"
                    className={styles.image}
                    src={
                        require("media/purchaseModal/bundle-highlight.png")
                            .default
                    }
                />
                <div className={styles.right}>
                    <div className={styles.header}>
                        <div className={styles.text}>
                            <Text
                                classes={styles.headline}
                                isBold={false}
                                type="h2"
                            >
                                Add Multiple Photos
                            </Text>
                            <Text type="p">
                                Choose the best option for you.
                            </Text>
                        </div>

                        <span
                            className={cx(
                                styles.close,
                                "modal__header__close material-icons"
                            )}
                            onClick={handleCloseModal}
                            tabIndex="0"
                            onKeyDown={
                                /* istanbul ignore next */ (e) =>
                                    handleKeyDown(e, handleCloseModal)
                            }
                        >
                            close
                        </span>
                    </div>
                    <div className={styles.cards}>
                        <PurchaseModalBundleHighlightCard
                            data={bundleCardData[0]}
                            handleSubmit={handleSubmit}
                        />
                        <PurchaseModalBundleHighlightCard
                            data={bundleCardData[1]}
                            handleSubmit={handleSubmit}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
