import React from "react";
import cx from "classnames";
import { Skeleton } from "@material-ui/lab";

// Components
import { AvatarEmptyIcon } from "components/Icons/AvatarEmptyIcon";
import { DogTagIcon } from "components/Icons/DogTagIcon";
import { DropdownState } from "components/Dropdowns/DropdownState/DropdownState";
import { IdBadgeIcon } from "components/Icons/IdBadgeIcon";
import { LocationIcon } from "components/Icons/LocationIcon";
import { MilitaryBaseIcon } from "components/Icons/MilitaryBaseIcon";
import { PhoneIcon } from "components/Icons/PhoneIcon";
import { Select } from "components/Inputs/Select/Select";
import { TextField } from "components/Inputs/TextField/TextField";
import { TextFieldMasked } from "components/Inputs/TextFieldMasked/TextFieldMasked";
import { TrashIcon } from "components/Icons/TrashIcon";

// Loggers
import { AnalyticsLogger } from "loggers/AnalyticsLogger";

// Utils
import { generateSelectDefaults } from "../../utils";
import { handleKeyDown } from "utils/eventUtils";
import {
    generateBranchPrettyName,
    generateIntentionPrettyName,
    generateMilitaryStatusPrettyName,
    generateRelationshipPrettyName,
} from "utils/userUtils";

export const ProfileFieldsEdit = ({
    branchPersona,
    depLocation,
    handleChange,
    handleOpenPersonaSelectionModal,
    handleShowProfileDepsModal,
    handleShowShipDateModal,
    isBuildingCareer,
    isLetterWriter,
    isPreparingForBasic,
    isProfileLoaded,
    isRecruiter,
    profileInfo,
    rootPersona,
    setShowProfileDeleteAccountModal,
    toggleFormEditState,
    togglePhoneVerificationModal,
    userStaging,
}) => {
    const defaults = generateSelectDefaults(userStaging);

    return (
        <div className="profile__fields__edit">
            <form
                className="profile__fields__edit__form form"
                onSubmit={(e) =>
                    toggleFormEditState(e, { shouldValidate: true })
                }
            >
                <div className="profile__fields__header__left">
                    <button
                        className="panel__header__button"
                        data-cy="profile-fields-cancel-button"
                        onClick={(e) =>
                            toggleFormEditState(e, {
                                shouldValidate: false,
                            })
                        }
                        onKeyDown={(e) =>
                            handleKeyDown(e, () =>
                                toggleFormEditState(e, {
                                    shouldValidate: false,
                                })
                            )
                        }
                        tabIndex="0"
                        type="reset"
                    >
                        Cancel
                    </button>
                </div>
                <div className="profile__fields__header__right">
                    <button
                        className="panel__header__button"
                        data-cy="profile-fields-save-button"
                        onClick={(e) =>
                            toggleFormEditState(e, {
                                shouldValidate: true,
                            })
                        }
                        onKeyDown={(e) =>
                            handleKeyDown(e, () =>
                                toggleFormEditState(e, {
                                    shouldValidate: true,
                                })
                            )
                        }
                        tabIndex="0"
                        type="submit"
                    >
                        Save
                    </button>
                </div>
                <TextField
                    className={cx("input--firstName")}
                    cypressTestId="profile-fields-edit-first-name"
                    label="First Name"
                    name="firstName"
                    onChange={handleChange}
                    required
                    value={userStaging.firstName}
                />
                <TextField
                    className={cx("input--lastName")}
                    cypressTestId="profile-fields-edit-last-name"
                    label="Last Name"
                    name="lastName"
                    onChange={handleChange}
                    required
                    value={userStaging.lastName}
                />
                <TextField
                    className={cx("input--line1")}
                    cypressTestId="profile-fields-edit-line-1"
                    label="Address Line 1"
                    name="line1"
                    onChange={handleChange}
                    required
                    value={userStaging.address?.line1}
                />
                <TextField
                    className={cx("input--line2")}
                    cypressTestId="profile-fields-edit-line-2"
                    label="Address Line 2"
                    name="line2"
                    onChange={handleChange}
                    value={userStaging.address?.line2}
                />
                <TextField
                    className={cx("input--city")}
                    cypressTestId="profile-fields-edit-city"
                    label="City"
                    name="city"
                    onChange={handleChange}
                    required
                    value={userStaging.address?.city}
                />
                <DropdownState
                    className={cx("input--state")}
                    cypressTestId="profile-fields-edit-state"
                    name="state"
                    onChange={handleChange}
                    required
                    value={userStaging.address?.state}
                />
                <TextField
                    className={cx("input--zipcode")}
                    cypressTestId="profile-fields-edit-zipcode"
                    label="Zip Code"
                    name="zipcode"
                    onChange={handleChange}
                    required
                    value={userStaging.address?.zipcode}
                />
                <Select
                    className={cx("input--gender")}
                    cypressTestId="profile-fields-edit-gender-select"
                    label="Gender"
                    name="gender"
                    onChange={handleChange}
                    value={userStaging.gender}
                >
                    <option disabled value={null} />
                    <option selected={defaults.gender.female} value="Female">
                        Female
                    </option>
                    <option selected={defaults.gender.male} value="Male">
                        Male
                    </option>
                </Select>
                <TextFieldMasked
                    className={cx("input--birthday")}
                    data-cy="profile-fields-edit-birthday"
                    label="Birth Date (MM/DD/YYYY)"
                    name="birthday"
                    onChange={handleChange}
                    value={userStaging.birthday}
                />
                <Select
                    className={cx("input--maritalStatus")}
                    cypressTestId="profile-fields-edit-marital-status-select"
                    label="Marital Status"
                    name="maritalStatus"
                    onChange={handleChange}
                    value={userStaging.maritalStatus}
                >
                    <option
                        disabled
                        selected={defaults.maritalStatus.empty}
                        value={null}
                    ></option>
                    <option
                        selected={defaults.maritalStatus.single}
                        value="Single"
                    >
                        Single
                    </option>
                    <option
                        selected={defaults.maritalStatus.married}
                        value="Married"
                    >
                        Married
                    </option>
                    <option
                        selected={defaults.maritalStatus.separated}
                        value="Separated"
                    >
                        Separated
                    </option>
                    <option
                        selected={defaults.maritalStatus.divorced}
                        value="Divorced"
                    >
                        Divorced
                    </option>
                </Select>
            </form>
            <div className="profile__fields__display__item">
                <div className="profile__fields__display__item__left">
                    <div className="profile__fields__display__item__icon">
                        <PhoneIcon height="20px" width="20px" />
                    </div>
                    <div className="profile__fields__display__item__title">
                        <span>Phone</span>
                    </div>
                </div>
                <div className="profile__fields__display__item__left">
                    <div className="profile__fields__display__item__content">
                        <span
                            className="link"
                            data-cy="profile-fields-display-phone-number-edit"
                            onClick={() =>
                                togglePhoneVerificationModal(true, "profile")
                            }
                            onKeyDown={(e) =>
                                handleKeyDown(e, () =>
                                    togglePhoneVerificationModal(
                                        true,
                                        "profile"
                                    )
                                )
                            }
                            tabIndex="0"
                        >
                            {profileInfo.phoneNumber || "Not Specified"}
                        </span>
                    </div>
                </div>
            </div>
            <div className="profile__fields__display__item">
                <div className="profile__fields__display__item__left">
                    <div className="profile__fields__display__item__icon">
                        <AvatarEmptyIcon />
                    </div>
                    <div className="profile__fields__display__item__title">
                        <span>Use Sandboxx To</span>
                    </div>
                </div>
                <div className="profile__fields__display__item__left">
                    <div className="profile__fields__display__item__content">
                        <span
                            className="link"
                            data-cy="profile-fields-edit-role"
                            onClick={handleOpenPersonaSelectionModal}
                            onKeyDown={(e) =>
                                handleKeyDown(e, () =>
                                    handleOpenPersonaSelectionModal()
                                )
                            }
                            tabIndex="0"
                        >
                            {rootPersona
                                ? generateIntentionPrettyName(rootPersona.id)
                                : "Not Specified"}
                        </span>
                    </div>
                </div>
            </div>
            {isLetterWriter && (
                <div className="profile__fields__display__item">
                    <div className="profile__fields__display__item__left">
                        <div className="profile__fields__display__item__icon">
                            <IdBadgeIcon />
                        </div>
                        <div className="profile__fields__display__item__title">
                            <span>Relationship to recruit</span>
                        </div>
                    </div>
                    <div className="profile__fields__display__item__left">
                        <div className="profile__fields__display__item__content">
                            <span
                                className="link"
                                data-cy="profile-fields-edit-role"
                                onClick={handleOpenPersonaSelectionModal}
                                onKeyDown={(e) =>
                                    handleKeyDown(e, () =>
                                        handleOpenPersonaSelectionModal()
                                    )
                                }
                                tabIndex="0"
                            >
                                {profileInfo?.personas[1]
                                    ? generateRelationshipPrettyName(
                                          profileInfo.personas[1].id
                                      )
                                    : "Not Specified"}
                            </span>
                        </div>
                    </div>
                </div>
            )}
            {(isPreparingForBasic || isBuildingCareer || isRecruiter) && (
                <div className="profile__fields__display__item">
                    <div className="profile__fields__display__item__left">
                        <div className="profile__fields__display__item__icon">
                            <DogTagIcon />
                        </div>
                        <div className="profile__fields__display__item__title">
                            <span>Military status</span>
                        </div>
                    </div>
                    <div className="profile__fields__display__item__left">
                        <div className="profile__fields__display__item__content">
                            <span
                                className="link"
                                data-cy="profile-fields-edit-role"
                                onClick={handleOpenPersonaSelectionModal}
                                onKeyDown={(e) =>
                                    handleKeyDown(e, () =>
                                        handleOpenPersonaSelectionModal()
                                    )
                                }
                                tabIndex="0"
                            >
                                {profileInfo?.personas[1]
                                    ? generateMilitaryStatusPrettyName(
                                          profileInfo.personas[1].id
                                      )
                                    : "Not Specified"}
                            </span>
                        </div>
                    </div>
                </div>
            )}
            {(isPreparingForBasic || isBuildingCareer || isRecruiter) && (
                <div className="profile__fields__display__item">
                    <div className="profile__fields__display__item__left">
                        <div className="profile__fields__display__item__icon">
                            <IdBadgeIcon />
                        </div>
                        <div className="profile__fields__display__item__title">
                            <span>Branch of Service</span>
                        </div>
                    </div>
                    <div className="profile__fields__display__item__left">
                        <div className="profile__fields__display__item__content">
                            <span
                                className="link"
                                data-cy="profile-fields-edit-branch"
                                onClick={handleOpenPersonaSelectionModal}
                                onKeyDown={(e) =>
                                    handleKeyDown(e, () =>
                                        handleOpenPersonaSelectionModal()
                                    )
                                }
                                tabIndex="0"
                            >
                                {branchPersona
                                    ? generateBranchPrettyName(branchPersona.id)
                                    : "Not Specified"}
                            </span>
                        </div>
                    </div>
                </div>
            )}
            {isPreparingForBasic && branchPersona && (
                <div className="profile__fields__display__item">
                    <div className="profile__fields__display__item__left">
                        <div className="profile__fields__display__item__icon">
                            <LocationIcon />
                        </div>
                        <div className="profile__fields__display__item__title">
                            <span>Recruiting Station</span>
                        </div>
                    </div>
                    <div className="profile__fields__display__item__left">
                        <div className="profile__fields__display__item__content">
                            <span
                                className="link"
                                onClick={() => handleShowProfileDepsModal(true)}
                                onKeyDown={(e) =>
                                    handleKeyDown(e, () =>
                                        handleShowProfileDepsModal(true)
                                    )
                                }
                                tabIndex="0"
                            >
                                {depLocation
                                    ? `${depLocation.locale} • ${depLocation.office}`
                                    : "Not Specified"}
                            </span>
                        </div>
                    </div>
                </div>
            )}
            {isPreparingForBasic && (
                <div className="profile__fields__display__item">
                    <div className="profile__fields__display__item__left">
                        <div className="profile__fields__display__item__icon">
                            <MilitaryBaseIcon />
                        </div>
                        <div className="profile__fields__display__item__title">
                            <span>Ship Date</span>
                        </div>
                    </div>
                    <div className="profile__fields__display__item__left">
                        <div className="profile__fields__display__item__content">
                            <span
                                className="link"
                                onClick={() => handleShowShipDateModal(true)}
                                onKeyDown={(e) =>
                                    handleKeyDown(e, () =>
                                        handleShowShipDateModal(true)
                                    )
                                }
                                tabIndex="0"
                            >
                                {isProfileLoaded ? (
                                    <Skeleton height={16} width={150} />
                                ) : profileInfo?.basicTrainingShipDate ? (
                                    profileInfo.basicTrainingShipDate
                                ) : (
                                    "Not Specfied"
                                )}
                            </span>
                        </div>
                    </div>
                </div>
            )}
            <div className="profile__fields__display__item">
                <button
                    className="profile__fields__display__item__left profile__fields__display__item__left--action"
                    data-cy="profile-fields-delete-account"
                    onClick={() => {
                        setShowProfileDeleteAccountModal(true);
                        AnalyticsLogger.logDeleteAccountProfileButtonClick();
                    }}
                >
                    <div className="profile__fields__display__item__icon">
                        <TrashIcon color="#b90202" />
                    </div>
                    <div className="profile__fields__display__item__title profile__fields__display__item__title--delete">
                        <span>Delete Account</span>
                    </div>
                </button>
            </div>
        </div>
    );
};
