import React from "react";
import { Link } from "react-router-dom";

// Components
import { DashboardCompose } from "./DashboardCompose/DashboardCompose";
import { DashboardCredits } from "./components/DashboardCredits/DashboardCredits";
import { DashboardMainLetters } from "./DashboardMainLetters/DashboardMainLetters";
import { DashboardNews } from "./DashboardNews/DashboardNews";
import { DashboardMessages } from "./components/DashboardMessages/DashboardMessages";
import { DashboardPromotions } from "./DashboardPromotions/DashboardPromotions";
import { DashboardStoryly } from "./DashboardStoryly/DashboardStoryly";
import { DashboardWallet } from "./DashboardWallet/DashboardWallet";
import { DashboardWeeklyUpdates } from "./DashboardWeeklyUpdates/DashboardWeeklyUpdates";

// Loggers
import { AnalyticsLogger } from "loggers/AnalyticsLogger";

// Utils
import { emptyFunction } from "../../utils/miscUtils";
import { imagePublicBase } from "../../utils/urlUtils";

export const DashboardMain = (props) => {
    const {
        bundles,
        connections,
        credits,
        handlePurchaseModalToggle,
        history,
        lettersReplied,
        lettersSent,
        moreLettersRepliedCursor,
        moreLettersSentCursor,
    } = props;

    function renderReferralsPanel() {
        const connection = connections && connections[0];
        const bodyText = connection
            ? `Get more letters to ${connection.firstName}`
            : "Give a Letter, Get a Letter";
        const imageLeftUrl = `${imagePublicBase}/web/media/dashboard/referral_left.svg`;
        const imageRightUrl = `${imagePublicBase}/web/media/dashboard/referral_right.svg`;
        const linkToParams = {
            pathname: "/letters/referrals",
            state: {
                referringRecruit: {
                    id: connection && connection.id,
                    name: connection && connection.fullName,
                },
            },
        };
        const onClick = connection
            ? AnalyticsLogger.logReferralWebRecruitShareStartDashboard
            : emptyFunction;
        return (
            <div className="dashboard__referrals panel">
                <img
                    alt="Woman holding letter"
                    className="dashboard__referrals__image dashboard__referrals__image--left"
                    src={imageLeftUrl}
                />
                <div className="dashboard__referrals__body">
                    <div className="dashboard__referrals__body__text">
                        <span>{bodyText}</span>
                    </div>
                    <Link onClick={onClick} to={linkToParams}>
                        <button className="dashboard__referrals__body__button button button--size-short button--secondary">
                            <span>Refer a Friend</span>
                        </button>
                    </Link>
                </div>
                <img
                    alt="Man holding letter"
                    className="dashboard__referrals__image dashboard__referrals__image--right"
                    src={imageRightUrl}
                />
            </div>
        );
    }

    return (
        <div className="page__body">
            <div className="dashboard-container" data-cy="dashboard">
                <div className="dashboard-column-one">
                    <DashboardCompose {...props} />
                    <DashboardCredits
                        bundles={bundles}
                        credits={credits}
                        handlePurchaseModalToggle={handlePurchaseModalToggle}
                    />
                    <DashboardWeeklyUpdates {...props} />
                    <DashboardWallet {...props} />
                    <DashboardMainLetters
                        history={history}
                        lettersReplied={lettersReplied}
                        lettersSent={lettersSent}
                        moreLettersRepliedCursor={moreLettersRepliedCursor}
                        moreLettersSentCursor={moreLettersSentCursor}
                    />
                </div>
                <div className="dashboard-column-two">
                    <DashboardStoryly />
                    {renderReferralsPanel()}
                    <DashboardMessages />
                    <DashboardPromotions {...props} />
                    <DashboardNews />
                </div>
            </div>
        </div>
    );
};
