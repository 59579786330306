// Components
import { MaterialIcon } from "components/MaterialIcon/MaterialIcon";

// CSS
import styles from "../sign-up-success.module.scss";

// Enums
import { MaterialIconMode } from "components/MaterialIcon/enums/MaterialIconMode";
import { PersonaEnum } from "enums/PersonaEnum";

export function generatePersonaContent({ selectedRootPersona }) {
    switch (selectedRootPersona.id) {
        case PersonaEnum.RECRUIT:
            return {
                items: [
                    {
                        icon: (
                            <MaterialIcon
                                className={styles.icon}
                                mode={
                                    MaterialIconMode.MATERIAL_SYMBOLS_OUTLINED
                                }
                                name="inventory"
                            />
                        ),
                        subtitle:
                            "Mentally and physically prepare for the battles that lie ahead at basic training. We have everything from training guides, packing lists, and more.",
                        title: "Prepare for Basic Training",
                    },
                ],
                subtitle:
                    "Not knowing what to expect when you arrive at basic training can be overwhelming. We'll help you prepare physically and mentally for the experience, so you can arrive with confidence.",
                title: "Welcome to Sandboxx",
            };
        case PersonaEnum.RECRUITER_COMMAND:
        case PersonaEnum.RECRUITER:
            return {
                items: [
                    {
                        icon: (
                            <MaterialIcon
                                className={styles.icon}
                                mode={
                                    MaterialIconMode.MATERIAL_SYMBOLS_OUTLINED
                                }
                                name="group"
                            />
                        ),
                        subtitle:
                            "Manage recruits so you can meet your goals. We'll flag recruits who are missing items and may be prone to drop-out.",
                        title: "Manage Prospects",
                    },
                    {
                        icon: (
                            <MaterialIcon
                                className={styles.icon}
                                mode={
                                    MaterialIconMode.MATERIAL_SYMBOLS_OUTLINED
                                }
                                name="newsmode"
                            />
                        ),
                        subtitle:
                            "Tailored content and resources to equip you to be the best recruiter you can be.",
                        title: "Get Content",
                    },
                ],
                subtitle:
                    "Staying in touch with recruits can be difficult. We'll help keep you connected with them to meet your mission and improve retention.",
                title: "Welcome to Sandboxx",
            };
        case PersonaEnum.LETTER_WRITER:
        default:
            return {
                items: [
                    {
                        icon: (
                            <MaterialIcon
                                className={styles.icon}
                                mode={
                                    MaterialIconMode.MATERIAL_SYMBOLS_OUTLINED
                                }
                                name="mail"
                            />
                        ),
                        subtitle:
                            "As easy as sending a text message. Once your letter is en route, track it all the way to your recruit.",
                        title: "Write your Letter",
                    },
                    {
                        icon: (
                            <MaterialIcon
                                className={styles.icon}
                                mode={
                                    MaterialIconMode.MATERIAL_SYMBOLS_OUTLINED
                                }
                                name="imagesmode"
                            />
                        ),
                        subtitle:
                            "No need to print anything, that's our job. When you're done writing your letter, simply add your photos.",
                        title: "Motivate with Photos",
                    },
                    {
                        icon: (
                            <MaterialIcon
                                className={styles.icon}
                                mode={
                                    MaterialIconMode.MATERIAL_SYMBOLS_OUTLINED
                                }
                                name="featured_seasonal_and_gifts"
                            />
                        ),
                        subtitle:
                            "With our Newsletters, gift cards, and more we provide you with everything your recruit needs to succeed.",
                        title: "Send more than just a Letter",
                    },
                    {
                        icon: (
                            <MaterialIcon
                                className={styles.icon}
                                mode={
                                    MaterialIconMode.MATERIAL_SYMBOLS_OUTLINED
                                }
                                name="local_shipping"
                            />
                        ),
                        subtitle:
                            "Once you send your letter, we take care of the rest. Submit your letter by 4:30 p.m. ET Monday through Thursday.",
                        title: "Leave the rest to us",
                    },
                ],
                subtitle:
                    "Send Letters to your recruit from anywhere, anytime. Get Letter tracking, weekly training updates, and build a community of support to help your recruit through training.",
                title: "Welcome to Sandboxx",
            };
    }
}
