import React from "react";

// Components
import { Text } from "components/Text/Text";

// CSS
import styles from "./cart-help.module.scss";

// Platforms
import { Intercom } from "platforms/intercom";

export const CartHelp = () => {
    return (
        <div className={styles.cartHelp}>
            <Text>
                Need help with your purchase? Chat with our{" "}
                <Text
                    classes={styles.link}
                    onClick={() =>
                        Intercom.showNewMessage(
                            "Hello Sandboxx, I need help purchasing a Memory Book."
                        )
                    }
                >
                    Customer Happiness
                </Text>{" "}
                team.
            </Text>
        </div>
    );
};
