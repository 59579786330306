import React, { useEffect, useState } from "react";
import { Modal } from "components/Modals/Modal";

// Components
import { ProfilePersonaModalSelect } from "./components/ProfilePersonaModalSelect/ProfilePersonaModalSelect";

// Hooks
import { usePersonaWizard } from "hooks/wizards/usePersonaWizard/usePersonaWizard";

// Utils
import { generateDisplayText } from "utils/personaUtils";

// Platforms
import { CurrentUser, SandboxxRestAPI } from "utils/sandboxx";

export const ProfilePersonaModal = (props) => {
    const {
        handleShowPersonaSelectionModal,
        onProfileUpdateSuccess,
        shouldShowPersonaModal,
    } = props;

    /**
     * Custom Hooks
     */

    const {
        childPersonas,
        fetchRootPersonas,
        handleSelectProfileChildPersona,
        handleSelectRootPersona,
        isLoadingChildPersonas,
        rootPersonas,
        setIsLoadingChildPersonas,
    } = usePersonaWizard({ storeUserAfterUpdate });

    /**
     * useEffect
     */

    useEffect(() => {
        shouldShowPersonaModal &&
            setDisplayText(generateDisplayText(lastChildPersonasArray));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [childPersonas]);

    useEffect(() => {
        shouldShowPersonaModal && fetchRootPersonas();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldShowPersonaModal]);

    /**
     * useState
     */

    const [displayText, setDisplayText] = useState({
        header: "How do you want to use Sandboxx?",
        subHeader:
            "You will still be able to access all Sandboxx features regardless of your selection.",
    });

    /**
     * Constants
     */

    const lastChildPersonasArray = childPersonas[childPersonas?.length - 1];

    /**
     * End Hooks
     */

    function onComplete() {
        storeUserAfterUpdate();
        setIsLoadingChildPersonas(true);
        onProfileUpdateSuccess();
        localStorage.setItem("shouldShowProfileUpdateSuccess", true);
    }

    function onCloseModal() {
        handleShowPersonaSelectionModal(false);
        setDisplayText({
            header: "How do you want to use Sandboxx?",
            subHeader:
                "You will still be able to access all Sandboxx features regardless of your selection.",
        });
    }

    function storeUserAfterUpdate() {
        return SandboxxRestAPI.getAccount((res) => {
            const { user, onboarding, verification } = res;
            CurrentUser.storeUser(user, verification, onboarding);
        });
    }

    return (
        <Modal
            headerText={displayText.header}
            headerSubText={displayText.subHeader}
            onClose={onCloseModal}
            onHide={onCloseModal}
            show={shouldShowPersonaModal}
        >
            <ProfilePersonaModalSelect
                childPersonas={childPersonas}
                handleSelectProfileChildPersona={
                    handleSelectProfileChildPersona
                }
                handleSelectRootPersona={handleSelectRootPersona}
                isLoadingChildPersonas={isLoadingChildPersonas}
                onComplete={onComplete}
                rootPersonas={rootPersonas}
            />
        </Modal>
    );
};
