// Components
import ArrowCircleUpOutlinedIcon from "@mui/icons-material/ArrowCircleUpOutlined";
import CardGiftcardOutlinedIcon from "@mui/icons-material/CardGiftcardOutlined";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import FeedOutlinedIcon from "@mui/icons-material/FeedOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import LoginOutlinedIcon from "@mui/icons-material/LoginOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import MarkunreadOutlinedIcon from "@mui/icons-material/MarkunreadOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";

// Enums
import { SquadActivity } from "scenes/SupportSquad/enums/SquadActivity";

// Utils
import { findTimeFrom } from "utils/dateTimeUtils";

export function renderSquadActivityIcon(activityType) {
    switch (activityType) {
        case SquadActivity.ADDRESS_UPDATE:
            return (
                <PlaceOutlinedIcon style={{ color: "var(--blue-darkerer)" }} />
            );
        case SquadActivity.IMAGE_UPDATE:
            return (
                <ImageOutlinedIcon style={{ color: "var(--blue-darkerer)" }} />
            );
        case SquadActivity.NAME_UPDATE:
            return (
                <EditOutlinedIcon style={{ color: "var(--blue-darkerer)" }} />
            );
        case SquadActivity.GIFTCARD:
            return (
                <CardGiftcardOutlinedIcon
                    style={{ color: "var(--blue-darkerer)" }}
                />
            );
        case SquadActivity.GRADUATION_UPDATE:
            return (
                <SchoolOutlinedIcon style={{ color: "var(--blue-darkerer)" }} />
            );
        case SquadActivity.INVITE:
            return (
                <ShareOutlinedIcon style={{ color: "var(--blue-darkerer)" }} />
            );
        case SquadActivity.LETTER_SENT:
            return (
                <MarkunreadOutlinedIcon
                    style={{ color: "var(--blue-darkerer)" }}
                />
            );
        case SquadActivity.MEMBER_JOIN:
            return (
                <LoginOutlinedIcon style={{ color: "var(--blue-darkerer)" }} />
            );
        case SquadActivity.MEMBER_LEAVE:
        case SquadActivity.MEMBER_REMOVE:
            return (
                <LogoutOutlinedIcon style={{ color: "var(--blue-darkerer)" }} />
            );
        case SquadActivity.NEWSLETTER_SUBSCRIPTION_CANCEL:
        case SquadActivity.NEWSLETTER_SUBSCRIPTION_PURCHASE:
            return (
                <FeedOutlinedIcon style={{ color: "var(--blue-darkerer)" }} />
            );
        case SquadActivity.ROLE_UPGRADE:
            return (
                <ArrowCircleUpOutlinedIcon
                    style={{ color: "var(--blue-darkerer)" }}
                />
            );
        case SquadActivity.SQUAD_CREATED:
            return (
                <GroupsOutlinedIcon style={{ color: "var(--blue-darkerer)" }} />
            );
        case SquadActivity.TOKEN_DONATED:
            return (
                <MonetizationOnOutlinedIcon
                    style={{ color: "var(--blue-darkerer)" }}
                />
            );
        default:
            break;
    }
}

export function generateSquadActivityTimeString(time) {
    const { days, hours } = findTimeFrom(time);
    if (days) {
        return `${days} days ago`;
    } else if (hours) {
        return `${hours} hours ago`;
    } else {
        return "less than 1 hour ago";
    }
}
