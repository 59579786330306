import React from "react";

// Components
import { HeaderBar } from "../../components/HeaderBar/HeaderBar";
import { ReferralsInstructions } from "./components/ReferralsInstructions/ReferralsInstructions";
import { ReferralsLists } from "./components/ReferralsLists/ReferralsLists";
import { ReferralsRedeemCodeModal } from "./components/ReferralsRedeemCodeModal/ReferralsRedeemCodeModal";
import { ReferralsShareLink } from "./components/ReferralsShareLink/ReferralsShareLink";

// CSS
import styles from "./referrals.module.scss";

// Hooks
import { useDocumentTitle } from "@uidotdev/usehooks";
import { useReferrals } from "./hooks/useReferrals";
import { useReferralsNavigation } from "./hooks/useReferralsNavigation";

export const Referrals = () => {
    /**
     * Custom Hooks
     **/

    useDocumentTitle("Sandboxx - Invite");

    const {
        earnedLetters,
        isLoadingRewardsStatus,
        pendingLetters,
        pendingReferrals,
        referredReferrals,
    } = useReferrals();

    const { setShouldShowRedeemCodeModal, shouldShowRedeemCodeModal } =
        useReferralsNavigation();

    /**
     * End Hooks
     **/

    return (
        <div className="page">
            <HeaderBar />
            <div className={styles.referrals}>
                <ReferralsShareLink
                    earnedLetters={earnedLetters}
                    setShouldShowRedeemCodeModal={setShouldShowRedeemCodeModal}
                />
                <ReferralsInstructions
                    setShouldShowRedeemCodeModal={setShouldShowRedeemCodeModal}
                    shouldShowRedeemCodeModal={shouldShowRedeemCodeModal}
                />
                <ReferralsLists
                    earnedLetters={earnedLetters}
                    isLoadingRewardsStatus={isLoadingRewardsStatus}
                    pendingLetters={pendingLetters}
                    pendingReferrals={pendingReferrals}
                    referredReferrals={referredReferrals}
                />
            </div>
            <ReferralsRedeemCodeModal
                setShouldShowRedeemCodeModal={setShouldShowRedeemCodeModal}
                shouldShowRedeemCodeModal={shouldShowRedeemCodeModal}
            />
        </div>
    );
};
