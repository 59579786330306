import React, { useEffect, useState } from "react";

// Components
import { ButtonInvisible, ButtonPrimary } from "components/Buttons/Buttons";
import { SavedPaymentOptionsOption } from "../PaymentOption/SavedPaymentOptions";
import { Text } from "components/Text/Text";

// Loggers
import { AnalyticsLogger } from "../../loggers/AnalyticsLogger";

// Enums
import { PurchaseModalNavigation } from "./enums/enums";

// Utils
import { handleKeyDown } from "utils/eventUtils";

// Constants
const checkIconSrc = require("../../media/general/icons/check.png").default;
const closeIconSrc = require("../../media/general/icons/close.png").default;
const loadingIconSrc = require("../../media/shared/loader.gif").default;
const warningIconSrc = require("../../media/general/icons/warning.png").default;

export const PurchaseModalReview = (props) => {
    const {
        changeCurrentSection,
        discount,
        loading,
        handleCancelDiscount,
        handlePurchase,
        handleRecordDiscountInput,
        handleSubmitDiscountCode,
        purchase,
        selectedCard,
        setLoading,
        setSelectedCard,
    } = props;

    /************************************************
     * useState
     ************************************************/

    const [isDiscountFieldActive, setIsDiscountFieldActive] = useState(false);

    /************************************************
     * useEffect
     ************************************************/

    useEffect(() => {
        AnalyticsLogger.logBeginCheckoutBundle({ bundle: purchase.bundle });

        /**
         * If user has entered the purchase modal by clicking on a promotion, automatically
         * start applying the discount code
         */
        if (discount.isPreloaded) {
            handleShowDiscountCodeField();
            handleSubmitDiscountCode();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /************************************************
     * End Hooks
     ************************************************/

    function handleGoBack() {
        setSelectedCard(null);
        handleCancelDiscount();
        changeCurrentSection(PurchaseModalNavigation.PAYMENT);
    }

    function handleShowDiscountCodeField() {
        AnalyticsLogger.logPromoCodeStart();
        setIsDiscountFieldActive(true);
    }

    function handleSubmit() {
        setLoading({ ...loading, purchase: true });
        handlePurchase();
    }

    function renderBundle() {
        const { originalPrice, title } = purchase.bundle;
        return (
            <div className="letters-compose-purchase-review-list-item">
                <span className="letters-compose-purchase-review-list-item-name">
                    {title}
                </span>
                <span className="letters-compose-purchase-review-list-item-name">
                    {originalPrice}
                </span>
            </div>
        );
    }

    function renderDiscountCodeField() {
        const promptErrorClass =
            discount.status === "error"
                ? "letters-compose-purchase-review__discount__prompt--error"
                : "";
        return isDiscountFieldActive ? (
            <div className="letters-compose-purchase-review__discount">
                <div
                    className={`letters-compose-purchase-review__discount__prompt ${promptErrorClass}`}
                    data-cy="purchase-modal-review-discount-message"
                >
                    <span>{discount.message}</span>
                </div>
                {renderDiscountCodeInput()}
            </div>
        ) : (
            <div className="letters-compose-purchase-review__discount">
                <div className="letters-compose-purchase-review__discount__prompt">
                    <span
                        className="link"
                        data-cy="add-discount-code-button"
                        onClick={handleShowDiscountCodeField}
                        onKeyDown={
                            /* istanbul ignore next */ (e) => {
                                handleKeyDown(e, handleShowDiscountCodeField);
                            }
                        }
                        tabIndex="0"
                    >
                        <span>{discount.message}</span>
                    </span>
                </div>
            </div>
        );
    }

    function renderDiscountCodeInput() {
        if (discount.status === "inactive" || discount.status === "loading") {
            return (
                <form
                    className="letters-compose-purchase-review__discount__form form"
                    data-cy="discount-code-form"
                    onSubmit={handleSubmitDiscountCode}
                >
                    <input
                        className="letters-compose-purchase-review__discount__form__input input"
                        data-cy="purchase-modal-review-discount-code-input"
                        name="code"
                        type="text"
                        value={discount.code}
                        onChange={handleRecordDiscountInput}
                    />
                    <input
                        className="letters-compose-purchase-review__discount__form__submit"
                        data-cy="purchase-modal-review-discount-code-submit"
                        type="submit"
                        value="Apply Code"
                    />
                    {renderLoadingAnimation()}
                </form>
            );
        } else {
            const isActive = discount.status === "active";
            const leftIconClass = isActive ? "check" : "warning";
            const leftIconSrc = isActive ? checkIconSrc : warningIconSrc;
            const statusClass = isActive
                ? "letters-compose-purchase-review__discount__display--active"
                : "letters-compose-purchase-review__discount__display--error";
            return (
                <div
                    className={`letters-compose-purchase-review__discount__display ${statusClass}`}
                >
                    <img
                        alt="Check icon"
                        className={`letters-compose-purchase-review__discount__display__${leftIconClass}`}
                        src={leftIconSrc}
                    />
                    <div className="letters-compose-purchase-review__discount__display__code">
                        <span>{discount.code}</span>
                    </div>
                    <img
                        alt="Close icon"
                        className="letters-compose-purchase-review__discount__display__close"
                        data-cy="purchase-modal-review-discount-code-display-close"
                        src={closeIconSrc}
                        onClick={handleCancelDiscount}
                        onKeyDown={
                            /* istanbul ignore next */ (e) => {
                                handleKeyDown(e, handleCancelDiscount);
                            }
                        }
                        tabIndex="0"
                    />
                </div>
            );
        }
    }

    function renderLoadingAnimation() {
        if (discount.status === "loading") {
            return (
                <div className="letters-compose-purchase-review__discount__form__loading">
                    <img
                        alt="Loading animation"
                        className="loading-section-image"
                        src={loadingIconSrc}
                    />
                </div>
            );
        }
    }

    function renderPaymentMethod() {
        return (
            selectedCard?.card && (
                <div className="letters-compose-purchase-review__payment-method">
                    <div className="letters-compose-purchase-review__payment-method__header">
                        <span>Payment Method</span>
                    </div>
                    <SavedPaymentOptionsOption
                        card={selectedCard}
                        changeCurrentSection={changeCurrentSection}
                        context="recentPaymentCredits"
                        endItem={{ type: "edit" }}
                        index={1}
                        isSelectedCard={true}
                        setSelectedCard={setSelectedCard}
                    />
                </div>
            )
        );
    }

    function renderSavings() {
        const { promotion, savings } = purchase.bundle;
        return (
            <>
                {savings && (
                    <div className="letters-compose-purchase-review-list-item savings">
                        <span className="letters-compose-purchase-review-list-item-name savings">
                            Savings
                        </span>
                        <span className="letters-compose-purchase-review-list-item-name savings">
                            -{savings}
                        </span>
                    </div>
                )}
                {promotion && (
                    <div className="letters-compose-purchase-review-list-item savings">
                        <span className="letters-compose-purchase-review-list-item-name">
                            Promotion
                        </span>
                        <span
                            className="letters-compose-purchase-review-list-item-name"
                            data-cy="purchase-modal-review-purchase-review-promotion-total"
                        >
                            -{promotion.discount}
                        </span>
                    </div>
                )}
            </>
        );
    }

    function renderSubmitButton() {
        return loading.purchase ? (
            <ButtonPrimary isDisabled>
                <Text>Submitting...</Text>
            </ButtonPrimary>
        ) : (
            <ButtonPrimary
                cypressTestId="review-confirm-purchase"
                onClick={handleSubmit}
            >
                <Text>Purchase</Text>
            </ButtonPrimary>
        );
    }

    function renderTax() {
        const { tax } = purchase.bundle;
        const { taxAmount, taxRate } = tax;
        const hasTax = taxAmount !== "$0.00";
        return (
            hasTax && (
                <div className="letters-compose-purchase-review-list-item">
                    <span className="letters-compose-purchase-review-list-item-name">
                        Tax ({taxRate})
                    </span>
                    <span className="letters-compose-purchase-review-list-item-name">
                        {taxAmount}
                    </span>
                </div>
            )
        );
    }

    function renderTotal() {
        const { total } = purchase.bundle;
        return (
            <div className="letters-compose-purchase-review-list-item letters-compose-purchase-review-list-total">
                <span className="letters-compose-purchase-review-list-item-name">
                    Total
                </span>
                <span className="letters-compose-purchase-review-list-item-name">
                    {total}
                </span>
            </div>
        );
    }

    return (
        <>
            {renderPaymentMethod()}
            <div className="letters-compose-purchase-review-list-container">
                {renderBundle()}
                {renderSavings()}
                {renderTax()}
                {renderTotal()}
            </div>
            {renderDiscountCodeField()}
            <div className="button__container force-right">
                <ButtonInvisible
                    cypressTestId="review-back-button"
                    onClick={handleGoBack}
                >
                    <Text>Back</Text>
                </ButtonInvisible>
                {renderSubmitButton()}
            </div>
        </>
    );
};
