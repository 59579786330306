import React from "react";
import IntlTelInput from "react-intl-tel-input";
import cx from "classnames";

// Components
import { ButtonPrimary } from "components/Buttons/Buttons";
import { Text } from "components/Text/Text";

// CSS
import styles from "../phone-verification-modal.module.scss";

export const PhoneVerificationModalPhoneNumber = (props) => {
    const {
        handleChangePhoneNumber,
        handleClearPhoneNumber,
        handleSubmitPhoneNumber,
        loading,
        phoneNumber,
    } = props;

    function renderSubmitButton() {
        return loading.phoneNumber ? (
            <ButtonPrimary
                color="orange"
                isDisabled={loading.phoneNumber}
                text="Sending..."
                type="submit"
            />
        ) : (
            <ButtonPrimary
                color="orange"
                cypressTestId="phone-verification-modal-phone-number-submit"
                onClick={handleSubmitPhoneNumber}
                text="Send Confirmation Code"
                type="submit"
            />
        );
    }

    return (
        <div className={styles["phone-number"]}>
            <Text classes={styles.header} type="h2">
                What’s your phone number?
            </Text>
            <Text classes={styles.subheader} type="p">
                Enter your phone number to verify your account. If your recruit
                added you as a contact, we'll use your phone number to connect
                you.
            </Text>
            <form
                className={styles.form}
                data-cy="phone-verification-modal-form"
                onSubmit={handleSubmitPhoneNumber}
            >
                <div className={styles["input-wrapper"]}>
                    <IntlTelInput
                        autoFocus={true}
                        autoPlaceholder={true}
                        defaultCountry="us"
                        excludeCountries={["kp"]}
                        fieldName="phoneNumber"
                        format={true}
                        inputClassName={cx(
                            styles.input,
                            styles["input--phone-intl"]
                        )}
                        preferredCountries={["us"]}
                        value={phoneNumber.baseNumber}
                        onPhoneNumberChange={handleChangePhoneNumber}
                        onSelectFlag={handleClearPhoneNumber}
                    />
                </div>
                {renderSubmitButton()}
                <Text classes={styles.description} type="small">
                    By clicking “Send Confirmation Code,” Sandboxx, Inc. will
                    send you an SMS to confirm your phone number. Standard
                    message, call, and data rates may apply.
                </Text>
            </form>
        </div>
    );
};
