import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// Components
import { ButtonInvisible, ButtonPrimary } from "components/Buttons/Buttons";
import { WarningCircleIcon } from "components/Icons/WarningCircleIcon";

// CSS
import styles from "./support-squad-delete-modal.module.scss";

export const SupportSquadDeleteModalRemoveMemberWarning = (props) => {
    const { handleModalClose, navigateToManageSquad, selectedSquad } = props;

    function renderContent() {
        return (
            <div className={styles.container}>
                <WarningCircleIcon
                    className={styles.checkmarkSuccess}
                    height="64"
                    width="64"
                />
                <span className={styles.headerText}>
                    Members Must Be Removed
                </span>
                <span className={styles.bodyText}>
                    All members must be removed before you can delete{" "}
                    {selectedSquad.squadName}
                </span>
            </div>
        );
    }

    function renderButtons() {
        return (
            <>
                <ButtonPrimary
                    classes={cx(
                        styles["remove-button"],
                        "button--primary-warning"
                    )}
                    cypressTestId="support-squad-delete-modal-remove-member-warning-continue-button"
                    onClick={navigateToManageSquad}
                    text="Remove Members"
                    type="button"
                />
                <ButtonInvisible
                    classes={styles.button}
                    cypressTestId="support-squad-delete-modal-remove-member-warning-cancel-button"
                    onClick={handleModalClose}
                    text="Cancel"
                    type="button"
                />
            </>
        );
    }

    return (
        <div
            className={styles.removeMembersWarning}
            data-cy="support-squad-delete-modal-remove-member-warning"
        >
            <div className="modal__content">{renderContent()}</div>
            <div className={styles.buttonContainer}>{renderButtons()}</div>
        </div>
    );
};

SupportSquadDeleteModalRemoveMemberWarning.propTypes = {
    handleModalClose: PropTypes.func.isRequired,
    navigateToManageSquad: PropTypes.func.isRequired,
    selectedSquad: PropTypes.object.isRequired,
};
