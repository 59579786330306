import { useEffect } from "react";

export const useSupportSquadSettingsModalMembers = ({
    setHeaderSubText,
    setHeaderText,
}) => {
    /**
     * useEffect
     */

    useEffect(() => {
        setHeaderSubText("");
        setHeaderText("Support Squad Members");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * End Hooks
     */
};
