import React, { useEffect } from "react";

// Components
import { ArrowForwardIcon } from "components/Icons/ArrowForwardIcon";
import { ButtonPrimary } from "components/Buttons/Buttons";
import { LettersAddOnsCarousel } from "../../../components/LettersAddOnsCarousel/LettersAddOnsCarousel";
import { LettersComposeMessageContent } from "../../../components/LettersComposeMessageContent/LettersComposeMessageContent";
import { LettersComposeRecipientSearchContainer } from "../Recipient/Search/LettersComposeRecipientSearchContainer";
import { LettersDraftSaveButton } from "../../../components/LettersDraftSaveButton/LettersDraftSaveButton";
import { Text } from "components/Text/Text";

// CSS
import "../../../../../css/letters/scenes/Compose/letters-compose-message.scss";

// Utils
import { calculateRemainingMessageCharacters } from "utils/lettersUtils";

export const LettersComposeMessage = (props) => {
    const {
        changeComposeCurrentSection,
        contacts,
        handleInitializeSaveDraft,
        handleRecipientSelect,
        handleSubmitMessageForReview,
        hasSandboxxPlus,
        history,
        isSavingDraft,
        loading,
        message,
        recentContacts,
        recipient,
        setHeaderBarContent,
        setIsSavingDraft,
        showNotification,
        setRecipient,
        setShouldShowDeleteModal,
        setShouldShowPhotoManageModal,
        toggleContactModal,
        togglePhoneVerificationModal,
        triggerGoToReview,
    } = props;

    /**
     * Constants
     */

    const hasRecipient = Object.keys(recipient).length !== 0;
    const { isSubmittingForReview } = loading;
    const nextButtonText = isSubmittingForReview ? "Loading..." : "Next";

    /**
     * useEffect
     */

    useEffect(() => {
        if (recipient.isSquadRecipient) {
            setHeaderBarContent({
                backPathname: "/support-squad",
                text: "Compose Your Letter",
                textSecondary: "Start writing your letter below!",
            });
        } else if (hasRecipient) {
            setHeaderBarContent({
                onBackClick: handleGoBack,
                text: "Compose Your Letter",
                textSecondary: "Start writing your letter below!",
            });
        } else {
            setHeaderBarContent({
                onBackClick: handleGoBack,
                text: "Select Recipient",
                textSecondary: "Search for a recipient or create one",
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recipient]);

    useEffect(() => {
        triggerGoToReview && handleSubmitMessageForReview();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [triggerGoToReview]);

    /**
     * End Hooks & Constants
     */

    function handleGoBack() {
        if (hasRecipient) {
            setRecipient({});
            setHeaderBarContent({
                text: "Choose Your Recipient",
                textSecondary: "Search for your recipient or create a contact",
            });
        } else {
            history.push("/letters");
        }
    }

    const { remainingCharacters } = calculateRemainingMessageCharacters(
        message.content
    );
    return (
        <div className="letters-compose__message">
            <div className="letters-compose__message__search">
                <LettersComposeRecipientSearchContainer
                    {...props}
                    changeComposeCurrentSection={changeComposeCurrentSection}
                    contacts={contacts}
                    handleComposeRecipientSelect={handleRecipientSelect}
                    recentContacts={recentContacts}
                    recipient={recipient}
                    setHeaderBarContent={setHeaderBarContent}
                    setRecipient={setRecipient}
                    showNotification={showNotification}
                    togglePhoneVerificationModal={togglePhoneVerificationModal}
                />
            </div>

            {hasRecipient && (
                <div className="letters-compose__message__detail panel">
                    <LettersComposeMessageContent
                        {...props}
                        handleSubmit={handleSubmitMessageForReview}
                        hasSandboxxPlus={hasSandboxxPlus}
                        setShouldShowDeleteModal={setShouldShowDeleteModal}
                        setShouldShowPhotoManageModal={
                            setShouldShowPhotoManageModal
                        }
                        shouldRenderPhotosInline={true}
                        toggleContactModal={toggleContactModal}
                    />
                    <div className="buttons">
                        <LettersDraftSaveButton
                            handleInitializeSaveDraft={
                                handleInitializeSaveDraft
                            }
                            isSavingDraft={isSavingDraft}
                            message={message}
                            setIsSavingDraft={setIsSavingDraft}
                        />
                        <ButtonPrimary
                            classes="next-button"
                            cypressTestId="letters-compose-message-content-next-button"
                            isDisabled={
                                isSubmittingForReview || remainingCharacters < 0
                            }
                            onClick={handleSubmitMessageForReview}
                            text={nextButtonText}
                            type="button"
                        >
                            <Text>{nextButtonText}</Text>
                            <ArrowForwardIcon className="forward-arrow-icon" />
                        </ButtonPrimary>
                    </div>
                    <LettersAddOnsCarousel {...props} />
                </div>
            )}
        </div>
    );
};
