import { useEffect } from "react";

export const useSupportSquadSettingsModalCreateInvite = ({
    setHeaderText,
    setHeaderSubText,
    squadName,
    selectedSquad,
}) => {
    /**
     * useEffect
     */

    useEffect(() => {
        setHeaderText(
            `Invite friends and family to join ${
                selectedSquad.squadName || squadName
            }`
        );
        setHeaderSubText("Enter the emails of people you would like to invite");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSquad]);

    /**
     * End Hooks
     */
};
