import React, { useState } from "react";
import { Modal } from "components/Modals/Modal";

// Constants
const year = new Date().getFullYear();

export const Footer = (props) => {
    /************************************************
     * useState
     ************************************************/

    const [showPhotoCreditsModal, setShowPhotoCreditsModal] = useState(false);

    /************************************************
     * End Hooks
     ************************************************/

    function togglePhotoCreditsModal() {
        setShowPhotoCreditsModal((prev) => !prev);
    }

    return (
        <footer className="footer-container">
            <PhotoCreditsModal
                showPhotoCreditsModal={showPhotoCreditsModal}
                togglePhotoCreditsModal={togglePhotoCreditsModal}
            />
            <div className="footer-links">
                <span>
                    <span>&copy; {year} Sandboxx</span>
                    <span className="footer-divider">|</span>
                    <a
                        className="footer-links-link"
                        href="https://www.sandboxx.us/privacy"
                    >
                        Privacy Policy
                    </a>
                    <span className="footer-divider">|</span>
                    <a
                        className="footer-links-link"
                        href="https://www.sandboxx.us/terms"
                    >
                        Terms Of Service
                    </a>
                    <span className="footer-divider">|</span>
                    <span
                        className="footer-links-link"
                        onClick={togglePhotoCreditsModal}
                    >
                        Photo Credits
                    </span>
                </span>
            </div>
            <div className="footer-dod">
                <span>
                    The appearance of U.S. Department of Defense (DoD) visual
                    information does not imply or constitute DoD endorsement.
                </span>
            </div>
        </footer>
    );
};

const PhotoCreditsModal = (props) => {
    const { showPhotoCreditsModal, togglePhotoCreditsModal } = props;

    return (
        <Modal
            cypressTestId="photo-credits-modal"
            headerText="Photo Credits"
            onHide={togglePhotoCreditsModal}
            show={showPhotoCreditsModal}
        >
            <ul>
                <li>
                    <a
                        className="link"
                        href="https://www.flickr.com/photos/bz3rk/10566720926/in/photolist-ivhPEU-h6KbGJ-ig3pfk-6HpBSH-dy4KFZ-h6K8i8-h6Kbds-h6LudF-cGRK9E-h6Kp1Q-9sxD9X-8Cd7UV-cFVnod-9bmYYv-KjwoDa-h6KpNS-h6Kozj-h6Kckh-h6K6wx-h6K7tc-h6KqgW-h6K7pe-h6KcQL-h6KcZy-h6KcEW-9Nvf3f-h6Kd9S-9UottZ"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        Wilmington, NC - James Willamor
                    </a>{" "}
                    <a
                        className="link"
                        href="https://creativecommons.org/licenses/by-sa/2.0/"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        License
                    </a>
                </li>
            </ul>
        </Modal>
    );
};
