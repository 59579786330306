export function generateDisplayText(personas) {
    if (personas) {
        if (personas.find((persona) => persona.id === "NATIONAL_GUARD")) {
            return {
                header: "What best describes your military status?",
                subHeader: "",
            };
        } else if (
            personas.find(
                (persona) =>
                    persona.id === "MARINES" || persona.id === "ARMY_GUARD"
            )
        ) {
            return {
                header: "What branch of service are you?",
                subHeader: "",
            };
        } else if (personas.find((persona) => persona.id === "MOM")) {
            return {
                header: "What best describes you?",
                subHeader: "Select your relationship to the recruit",
            };
        } else {
            return {
                header: "What best describes you?",
                subHeader: "",
            };
        }
    }
}
