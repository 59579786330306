import React from "react";
import { Link } from "react-router-dom";
import cx from "classnames";
import { DateTime } from "luxon";

// CSS
import styles from "./letters-sent-letter.module.scss";

// Components
import { Banner } from "components/Banner/Banner";
import { ButtonPrimary } from "components/Buttons/Buttons";
import { HeaderBar } from "components/HeaderBar/HeaderBar";
import { LettersSentLetterResolutionModal } from "./components/LettersSentLetterResolutionModal/LettersSentLetterResolutionModal";
import { LoadingPage } from "components/Loading/Loading";
import { MaterialIcon } from "components/MaterialIcon/MaterialIcon";
import { Overlay } from "components/Overlay/Overlay";
import { Panel } from "components/Panel/Panel";
import { Text } from "components/Text/Text";
import { TrackingMap } from "scenes/Letters/components/TrackingMap";
import { ReplyIcon } from "components/Icons/ReplyIcon";
import { Slider } from "components/Slider/Slider";

// Enums
import { LetterType } from "enums/LetterType";
import { MaterialIconMode } from "components/MaterialIcon/enums/MaterialIconMode";

// Hooks
import { useDocumentTitle } from "@uidotdev/usehooks";
import { useLettersSentLetter } from "../hooks/useLettersSentLetter";

// Media
import emptyImageSrc from "media/icons/image-empty.svg";
import infoIconSrc from "media/icons/info.svg";

// Utils
import { formatSentLetterData, generateStatusIcon } from "utils/lettersUtils";
import { titleCase } from "utils/textUtils";

export const LettersSentLetter = (props) => {
    const { location } = props;
    const { letterType } = location.state;
    const letter = formatSentLetterData(location.state.letter, letterType);

    /**
     * Custom Hooks
     */

    useDocumentTitle("Sandboxx - Letters");

    const {
        checkpoints,
        hasCheckpoints,
        imageSrc,
        imageStyle,
        loading,
        replyLetter,
        resolutionModalSize,
        setShouldShowResolutionModal,
        setResolutionModalSize,
        shouldShowResolutionModal,
    } = useLettersSentLetter({ location });

    /**
     * End Hooks
     */

    function renderAddress() {
        const { address } = letter.recipient;
        const { line1, line2, city, state, zipcode } = address;
        return (
            <div className={styles.address}>
                <div className={styles.iconWrapper}>
                    <MaterialIcon
                        className={styles.icon}
                        mode={MaterialIconMode.MATERIAL_SYMBOLS_OUTLINED}
                        name="home"
                    />
                </div>
                <div className={styles.text}>
                    {line1 && <span>{line1}</span>}
                    {line2 && <span>{line2}</span>}
                    {city && <span>{`${city}, ${state} ${zipcode}`}</span>}
                </div>
            </div>
        );
    }

    function renderBanner() {
        if (letter.status === "ONHOLD") {
            return (
                <Banner
                    classes={styles.banner}
                    config={{ shouldExpandText: true, shouldShowChevron: true }}
                    onClick={() => {
                        setShouldShowResolutionModal(true);
                    }}
                    theme="orange"
                >
                    <div className={styles.content}>
                        <Text classes={styles.text} isBold>
                            Please update your Letter address
                        </Text>
                        <Text classes={styles.text}>
                            {letter.status_description}
                        </Text>
                    </div>
                </Banner>
            );
        }
    }

    function renderCheckpoints() {
        const cps = checkpoints.map((checkpoint, i) => {
            const { city, scanDate, state, status, statusDescription } =
                checkpoint;
            const scanDateWithOffset = scanDate + "+00:00";
            const formattedScanDate = DateTime.fromISO(
                scanDateWithOffset
            ).toLocaleString(DateTime.DATETIME_FULL);
            return (
                <div className={styles.checkpoint} key={i}>
                    <div className={styles.left}>
                        <div className={styles.wrapper}>
                            {generateStatusIcon({ status })}
                        </div>
                    </div>
                    <div className={styles.right}>
                        <span className={styles.status}>
                            {statusDescription}
                        </span>
                        <span className={styles.info}>
                            {city}, {state}, {formattedScanDate}
                        </span>
                    </div>
                </div>
            );
        });
        return <div className={styles.checkpoints}>{cps}</div>;
    }

    function renderGiftCard() {
        const { giftcard } = letter;
        return (
            giftcard && (
                <div className={styles.giftCard}>
                    <div className={styles.iconWrapper}>
                        <MaterialIcon
                            className={styles.icon}
                            mode={MaterialIconMode.MATERIAL_SYMBOLS_OUTLINED}
                            name="redeem"
                        />
                    </div>
                    <span className={styles.text}>
                        This letter includes a ${giftcard.value} gift card
                    </span>
                </div>
            )
        );
    }

    function renderImage() {
        if (Array.isArray(imageSrc) && imageSrc.length > 1) {
            return (
                <Slider
                    classNames={{
                        arrows: styles.arrows,
                        container: styles.slider,
                        image: styles.image,
                    }}
                    images={imageSrc}
                    shouldShowArrows={true}
                />
            );
        } else {
            return (
                <div
                    className={cx(styles.image, {
                        [styles["image--empty"]]: imageSrc === emptyImageSrc,
                    })}
                    data-cy="letters-sent-letter-image"
                    style={imageStyle}
                />
            );
        }
    }

    function renderResolutionButton() {
        if (letter.status === "ONHOLD") {
            return (
                <div className={styles.resolutionButton}>
                    <ButtonPrimary
                        classes={cx(styles.button, "button--size-short")}
                        onClick={() => {
                            setShouldShowResolutionModal(true);
                        }}
                    >
                        <Text>Fix Issue</Text>
                    </ButtonPrimary>
                </div>
            );
        }
    }

    function renderInfo() {
        return (
            letterType === LetterType.SENT && (
                <>
                    {renderReplyHeader()}
                    <Panel classes={styles.info}>
                        <div className={styles.left}>
                            <div className="panel__header">
                                <span className="panel__header__text">
                                    LETTER DETAILS
                                </span>
                            </div>
                            {renderLetterStatus()}
                            {renderAddress()}
                            {renderGiftCard()}
                            {renderPostagePaid()}
                            {renderImage()}
                            {renderResolutionButton()}
                        </div>
                        <div className={styles.right}>{renderMessage()}</div>
                    </Panel>
                </>
            )
        );
    }

    function renderMap() {
        return (
            !!checkpoints.length && (
                <div className={styles.map}>
                    <div className={styles.inner}>
                        <TrackingMap
                            checkpoints={checkpoints}
                            loading={loading}
                            selectedLetter={letter}
                        />
                    </div>
                </div>
            )
        );
    }

    function renderMessage() {
        const { custom_message } = letter;
        return <div className={styles.message}>{custom_message}</div>;
    }

    function renderPostagePaid() {
        const { postagePaid } = letter;
        const overlayText =
            "Sandboxx includes a postage paid envelope for your recruit, so replying is easier than ever";
        return (
            postagePaid && (
                <div className={styles.postagePaid}>
                    <div className={styles.iconWrapper}>
                        <MaterialIcon
                            className={styles.icon}
                            mode={MaterialIconMode.MATERIAL_SYMBOLS_OUTLINED}
                            name="markunread_mailbox"
                        />
                    </div>

                    <span className={styles.text}>Includes reply postage </span>
                    <Overlay overlayText={overlayText} placement="bottom">
                        <img
                            alt="Info icon"
                            className={styles.infoIcon}
                            src={infoIconSrc}
                        />
                    </Overlay>
                </div>
            )
        );
    }

    function renderLetterStatus() {
        const { pretty_status, status } = letter;
        return (
            <div className={styles.letterStatus}>
                <div className={styles.iconWrapper}>
                    {generateStatusIcon({ status })}
                </div>
                <span className={styles.text}>{titleCase(pretty_status)}</span>
            </div>
        );
    }

    function renderReplyHeader() {
        const { name } = letter.recipient;
        const { checkpoints, originalLetter, replyMailId } = replyLetter || {};
        const linkParams = {
            pathname: `/letters/sent/${replyMailId}`,
            state: {
                letter: originalLetter,
                letterType: LetterType.REPLIED,
                replyMailId,
            },
        };
        const scanDatePretty =
            checkpoints &&
            DateTime.fromISO(checkpoints[0].scanDate).toLocaleString(
                DateTime.DATE_FULL
            );
        return (
            replyLetter && (
                <div className={styles.replyHeader}>
                    <div className={styles.left}>
                        <ReplyIcon className={styles.icon} />
                        <span>
                            {name} replied on {scanDatePretty}
                        </span>
                    </div>
                    <div className={styles.right}>
                        <Link to={linkParams}>More Info</Link>
                    </div>
                </div>
            )
        );
    }

    function renderTracking() {
        return (
            hasCheckpoints && (
                <Panel classes={styles.tracking}>
                    <div className={styles.left}>
                        <div className="panel__header panel__header--space-between">
                            <span className="panel__header__text">
                                TRACKING
                            </span>
                            <a
                                className={cx(styles.link, "link")}
                                href="https://www.sandboxx.us/help"
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                FAQ
                            </a>
                        </div>
                        {renderCheckpoints()}
                    </div>
                    <div className={styles.right}>{renderMap()}</div>
                </Panel>
            )
        );
    }

    function renderContent() {
        const isLoaded = Object.keys(loading).every((key) => !loading[key]);
        if (isLoaded) {
            return (
                <>
                    {renderBanner()}
                    {renderTracking()}
                    {renderInfo()}
                </>
            );
        } else {
            return <LoadingPage />;
        }
    }

    return (
        <div className="page">
            <LettersSentLetterResolutionModal
                letter={letter}
                onClose={() => setShouldShowResolutionModal(false)}
                onHide={() => setShouldShowResolutionModal(false)}
                resolutionModalSize={resolutionModalSize}
                setResolutionModalSize={setResolutionModalSize}
                shouldShowResolutionModal={shouldShowResolutionModal}
            />
            <HeaderBar />
            <div className={cx(styles.lettersSentLetter, "page__body")}>
                {renderContent()}
            </div>
        </div>
    );
};
