import React, { useContext, useEffect, useState } from "react";
import { Modal } from "components/Modals/Modal";
import cx from "classnames";

// Components
import { PurchaseModalBundle } from "./components/PurchaseModalBundle/PurchaseModalBundle";
import { PurchaseModalBundleHighlight } from "./components/PurchaseModalBundle/PurchaseModalBundleHighlight/PurchaseModalBundleHighlight";
import { PurchaseModalConfirmation } from "./components/PurchaseModalConfirmation/PurchaseModalConfirmation";
import { PurchaseModalPayment } from "./PurchaseModalPayment";
import { PurchaseModalReview } from "./PurchaseModalReview";

// Context
import { BundlesContext } from "../../context/bundles";
import { CreditsContext } from "../../context/credits";
import { NotificationBarContext } from "../../context/notificationBar";
import { PurchaseContext } from "../../context/purchase";

// CSS
import "../../css/letters/scenes/Compose/letters-compose-purchase.scss";

// Enums
import { PurchaseModalNavigation } from "./enums/enums";

// Logging
import { AnalyticsLogger } from "../../loggers/AnalyticsLogger";

// Utils
import { generateHeaderText } from "./utils/utils";
import { CurrentUser, SandboxxRestAPI } from "../../utils/sandboxx";

/**
 * TODO: Remove "letters" prefix from purchase modal code
 */
export const PurchaseModal = (props) => {
    const { sectionOverride } = props;

    /************************************************
     * useContext
     ************************************************/

    const { bundles, fetchBundles } = useContext(BundlesContext);
    const { fetchCredits } = useContext(CreditsContext);
    const { showNotification } = useContext(NotificationBarContext);
    const {
        currentSection,
        discount,
        handleApplyDiscount,
        handleCancelDiscount,
        handleClearPurchase,
        handleResetDiscount,
        handleSelectBundle,
        onCompletePurchaseFlow,
        purchase,
        purchaseMode,
        savedCards,
        selectedCard,
        setCurrentSection,
        setDiscount,
        setPurchaseMode,
        setSelectedCard,
        setShowPurchaseModal,
        showPurchaseModal,
    } = useContext(PurchaseContext);
    const { bundle } = purchase;

    /************************************************
     * useState
     ************************************************/

    const [loading, setLoading] = useState({
        purchase: false,
        braintreeNewCard: false,
        braintreeSavedCard: false,
    });

    const [selectedBundle, setSelectedBundle] = useState(
        purchase?.bundle || null
    );

    /************************************************
     * useEffect
     ************************************************/

    useEffect(() => {
        if (sectionOverride) {
            setCurrentSection(sectionOverride);
        }
    }, [sectionOverride, setCurrentSection]);

    /************************************************
     * Constants
     ************************************************/

    const isOnBundleScreen = currentSection === PurchaseModalNavigation.BUNDLES;
    const isOnHighlightBundleScreen =
        currentSection === PurchaseModalNavigation.BUNDLE_HIGHLIGHT;
    const { headerText, headerSubText } = generateHeaderText(
        currentSection,
        purchaseMode
    );
    const shouldHideHeader =
        currentSection === PurchaseModalNavigation.CONFIRMATION ||
        currentSection === PurchaseModalNavigation.BUNDLE_HIGHLIGHT;

    /************************************************
     * End Hooks & Constants
     ************************************************/

    function changeCurrentSection(key) {
        setCurrentSection(key);
    }

    function fetchAccount() {
        SandboxxRestAPI.getAccount(onFetchAccountSuccess);
    }

    function getModalSize() {
        switch (currentSection) {
            case PurchaseModalNavigation.BUNDLE_HIGHLIGHT:
                return "xl";
            case PurchaseModalNavigation.BUNDLES:
                return "xl";
            case PurchaseModalNavigation.PAYMENT:
                return "";
            case PurchaseModalNavigation.REVIEW:
                return "";
            case PurchaseModalNavigation.CONFIRMATION:
                return "md";
            default:
                return "";
        }
    }

    /**
     * Stores card nonce and whether the card has been previously used in state
     *
     * @param   {string}  nonce        [nonce description]
     * @param   {boolean}  isSavedCard  [isSavedCard description]
     */
    function handleCardSelect(card, isSavedCard = false) {
        setSelectedCard((prevSelectedCard) => ({
            ...prevSelectedCard,
            card,
            isSavedCard,
        }));
    }

    function handleCloseModal() {
        setShowPurchaseModal(false);
        onCompletePurchaseFlow();
        setTimeout(() => {
            changeCurrentSection(PurchaseModalNavigation.BUNDLES);
            handleClearPurchase();
        }, 1000);
    }

    function handlePurchase() {
        setLoading({ ...loading, purchase: true });
        const payload = {
            bundleId: bundle.id,
            discount,
            token: selectedCard.card.token,
            paymentMethod: "braintree",
        };
        SandboxxRestAPI.purchaseBundleV3(
            payload,
            onPurchaseBundleSuccess,
            onPurchaseBundleError,
            onPurchaseBundleError
        );
    }

    function handleRecordDiscountInput(e) {
        e.preventDefault();
        const code = e.target.value;
        if (discount.status === "inactive") {
            setDiscount((prevDiscount) => ({
                ...prevDiscount,
                code,
            }));
        }
    }

    function handleSubmitDiscountCode(e) {
        e && e.preventDefault();
        handleApplyDiscount();
    }

    function onFetchAccountSuccess(res) {
        const { onboarding, user, verification } = res;
        CurrentUser.storeUser(user, verification, onboarding);
    }

    function onPurchaseBundleError(err) {
        setLoading({ ...loading, purchase: false });
        showNotification({
            text: "Your bundle purchase was not successful. Please use the chat widget in the lower-right corner of the screen to get in touch with a Sandboxx representative.",
            type: "warning",
        });
    }

    function onPurchaseBundleSuccess() {
        AnalyticsLogger.logPurchaseBundle(bundle, discount);
        setTimeout(fetchAccount, 3000);
        fetchBundles();
        fetchCredits();
        handleResetDiscount();
        setLoading({ ...loading, purchase: false });
        changeCurrentSection(PurchaseModalNavigation.CONFIRMATION);
    }

    function renderCurrentSection() {
        const sections = {
            [PurchaseModalNavigation.BUNDLE_HIGHLIGHT]: (
                <PurchaseModalBundleHighlight
                    bundles={bundles}
                    changeCurrentSection={changeCurrentSection}
                    handleCloseModal={handleCloseModal}
                    handleSelectBundle={handleSelectBundle}
                    selectedCard={selectedCard}
                    setPurchaseMode={setPurchaseMode}
                />
            ),
            [PurchaseModalNavigation.BUNDLES]: (
                <PurchaseModalBundle
                    bundles={bundles}
                    changeCurrentSection={changeCurrentSection}
                    handleCloseModal={handleCloseModal}
                    handleSelectBundle={handleSelectBundle}
                    selectedBundle={selectedBundle}
                    selectedCard={selectedCard}
                    setPurchaseMode={setPurchaseMode}
                />
            ),
            [PurchaseModalNavigation.PAYMENT]: (
                <PurchaseModalPayment
                    changeCurrentSection={changeCurrentSection}
                    handleCardSelect={handleCardSelect}
                    handleCloseModal={handleCloseModal}
                    loading={loading}
                    purchaseMode={purchaseMode}
                    savedCards={savedCards}
                    selectedCard={selectedCard}
                    setLoading={setLoading}
                    setSelectedBundle={setSelectedBundle}
                    setSelectedCard={setSelectedCard}
                    showNotification={showNotification}
                />
            ),
            [PurchaseModalNavigation.REVIEW]: (
                <PurchaseModalReview
                    changeCurrentSection={changeCurrentSection}
                    discount={discount}
                    handleSubmitDiscountCode={handleSubmitDiscountCode}
                    handleCancelDiscount={handleCancelDiscount}
                    handleCardSelect={handleCardSelect}
                    handleCloseModal={handleCloseModal}
                    handlePurchase={handlePurchase}
                    handleRecordDiscountInput={handleRecordDiscountInput}
                    loading={loading}
                    purchase={purchase}
                    selectedCard={selectedCard}
                    setSelectedCard={setSelectedCard}
                    setLoading={setLoading}
                />
            ),
            [PurchaseModalNavigation.CONFIRMATION]: (
                <PurchaseModalConfirmation
                    handleCloseModal={handleCloseModal}
                    purchase={purchase}
                />
            ),
        };
        return sections[currentSection];
    }

    return (
        <Modal
            centered="true"
            className="letters-purchase-modal"
            cypressTestId="purchase-modal"
            dialogClassName={cx({
                "letters-purchase-modal--no-padding": isOnHighlightBundleScreen,
                "letters-purchase-modal--wide": isOnBundleScreen,
            })}
            headerSubText={headerSubText}
            headerText={headerText}
            isHeaderTextCentered={isOnBundleScreen}
            onHide={handleCloseModal}
            shouldHideHeaderBorder={isOnBundleScreen}
            shouldHideHeader={shouldHideHeader}
            show={showPurchaseModal}
            size={getModalSize()}
        >
            {renderCurrentSection()}
        </Modal>
    );
};
