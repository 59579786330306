import React, { useState } from "react";
import cx from "classnames";

// Components
import { ButtonPrimary } from "components/Buttons/Buttons";

// CSS
import styles from "../../sign-in.module.scss";

// Enums
import { NavigationEnum } from "scenes/SignIn/constants/constants";

// Utils
import { SandboxxRestAPI } from "../../../../utils/sandboxx";

export const SignInForgotPassword = (props) => {
    const { setCurrentSection, showNotification } = props;

    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);

    function handleChangeEmail({ target }) {
        const { value } = target;
        setEmail(value);
    }

    function handleGoBack() {
        setCurrentSection(NavigationEnum.SIGN_IN_MAIN);
    }

    function handleSubmit(e) {
        e.preventDefault();
        if (email) {
            setLoading(true);
            SandboxxRestAPI.requestPasswordResetEmail(
                email,
                onResetPasswordSuccess,
                onResetPasswordFailure,
                onResetPasswordError
            );
        } else {
            showNotification({
                text: "Please enter an email address.",
                type: "warning",
            });
        }
    }

    function onResetPasswordError(err) {
        showNotification({
            text: "There was an issue resetting your email, please try again soon.",
            type: "warning",
        });
    }

    function onResetPasswordFailure(err) {
        showNotification({
            text: "There is no account associated with that email.",
            type: "warning",
        });
    }

    function onResetPasswordSuccess(res) {
        setLoading(false);
        showNotification(
            {
                text: "Check your email for further instructions on how to reset your password.",
                type: "success",
            },
            () => {
                setCurrentSection(NavigationEnum.SIGN_IN_MAIN);
            }
        );
    }

    function renderSubmitButton() {
        return loading ? (
            <ButtonPrimary
                classes={styles.button}
                isDisabled
                text="Sending..."
                type="button"
            />
        ) : (
            <ButtonPrimary
                classes={styles.button}
                onClick={handleSubmit}
                text="Send"
                type="button"
            />
        );
    }

    return (
        <div className={styles.content}>
            <div className={styles.top}>
                <div className={styles.header}>
                    <span className={styles.text}>
                        Forgot your password? Don't worry about it!
                    </span>
                    <span className={styles["text-secondary"]}>
                        Enter your email below and we will send you a link to
                        reset it.
                    </span>
                </div>
                <form
                    className={cx(styles.form, "form")}
                    onSubmit={handleSubmit}
                >
                    <span className={styles["input-header"]}>Email</span>
                    <input
                        className={cx(styles.input, "input")}
                        name="email"
                        type="text"
                        value={email}
                        onChange={handleChangeEmail}
                    />
                </form>
            </div>
            <div className={styles.bottom}>
                <div className={cx(styles.buttons, styles["buttons--single"])}>
                    {renderSubmitButton()}
                </div>
                <span className={styles.text}>
                    <span
                        className={cx(styles.link, "link")}
                        onClick={handleGoBack}
                    >
                        Go Back
                    </span>
                </span>
            </div>
        </div>
    );
};
