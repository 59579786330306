import React, { useEffect } from "react";
import { Skeleton } from "@material-ui/lab";

// Components
import { LettersAddOnsCarouselItem } from "./components/LettersAddOnsCarouselItem/LettersAddOnsCarouselItem";
import { Text } from "components/Text/Text";

// Hooks
import { useAddOnsCarousel } from "./hooks/useAddOnsCarousel";

// Loggers
import { AnalyticsLogger } from "loggers/AnalyticsLogger";

export const LettersAddOnsCarousel = (props) => {
    const { addOns, loading } = props;

    /************************************************
     * Custom Hooks
     ************************************************/

    const { canGoLeft, canGoRight, handleShiftCarousel, hasAddOns, position } =
        useAddOnsCarousel(addOns);

    /************************************************
     * useEffect
     ************************************************/

    useEffect(() => {
        AnalyticsLogger.logLetterComposeAddOnViewed();
    }, []);

    /************************************************
     * End Hooks
     ************************************************/

    function renderAddOns() {
        return (
            <div className="letters-compose__add-ons__carousel__wrapper">
                {addOns.map((addOn) => (
                    <LettersAddOnsCarouselItem
                        {...props}
                        addOn={addOn}
                        addOns={addOns}
                        key={addOn.id}
                        position={position}
                    />
                ))}
            </div>
        );
    }

    function renderHeader() {
        const arrowLeftClass = canGoLeft
            ? ""
            : "letters-compose__add-ons__carousel__arrows__arrow--disabled";
        const arrowRightClass = canGoRight
            ? ""
            : "letters-compose__add-ons__carousel__arrows__arrow--disabled";

        return (
            <div className="panel__header panel__header--space-between">
                <Text isBold={true} type="h2">
                    Add-Ons
                </Text>
                <div className="letters-compose__add-ons__carousel__arrows">
                    <button
                        className={`letters-compose__add-ons__carousel__arrows__arrow ${arrowLeftClass} material-icons`}
                        data-cy="letters-add-ons-carousel-shift-left-button"
                        disabled={!canGoLeft}
                        onClick={() => handleShiftCarousel("left")}
                    >
                        arrow_back
                    </button>
                    <button
                        className={`letters-compose__add-ons__carousel__arrows__arrow ${arrowRightClass} material-icons`}
                        data-cy="letters-add-ons-carousel-shift-right-button"
                        disabled={!canGoRight}
                        onClick={() => handleShiftCarousel("right")}
                    >
                        arrow_forward
                    </button>
                </div>
            </div>
        );
    }

    function renderLoadingSection() {
        return (
            <div className="letters-compose__add-ons__carousel__loader-wrapper">
                <Skeleton
                    height={28}
                    width="100%"
                    style={{ marginBottom: "12px" }}
                    variant="rect"
                />
                <div style={{ display: "flex", marginBottom: "12px" }}>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            paddingRight: "39px",
                            width: "50%",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flex: "1",
                                marginBottom: "12px",
                            }}
                        >
                            <Skeleton
                                height={74}
                                width={74}
                                style={{
                                    marginRight: "12px",
                                }}
                                variant="rect"
                            />
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    flex: "1",
                                }}
                            >
                                <Skeleton
                                    height={26}
                                    style={{ marginBottom: "4px" }}
                                    variant="rect"
                                    width="100%"
                                />
                                <Skeleton
                                    height={26}
                                    variant="rect"
                                    width="100%"
                                />
                            </div>
                        </div>
                        <Skeleton
                            height={49}
                            style={{ paddingRight: "39px" }}
                            variant="rect"
                        />
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "50%",
                        }}
                    >
                        <div style={{ display: "flex", flex: "1" }}>
                            <Skeleton
                                height={74}
                                width={74}
                                style={{
                                    marginRight: "12px",
                                }}
                                variant="rect"
                            />
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    flex: "1",
                                }}
                            >
                                <Skeleton
                                    height={26}
                                    style={{ marginBottom: "4px" }}
                                    variant="rect"
                                    width="100%"
                                />
                                <Skeleton
                                    height={26}
                                    variant="rect"
                                    width="100%"
                                />
                            </div>
                        </div>
                        <Skeleton
                            height={49}
                            style={{ paddingRight: "39px" }}
                            variant="rect"
                        />
                    </div>
                </div>
            </div>
        );
    }

    return (
        (loading.addOns || hasAddOns) && (
            <div className="letters-compose__add-ons__carousel">
                {renderHeader()}
                {hasAddOns ? renderAddOns() : renderLoadingSection()}
            </div>
        )
    );
};
