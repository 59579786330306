import React from "react";

// Components
import { FeatureFlagBoundary } from "components/Boundaries/FeatureFlagBoundary";
import { Panel } from "components/Panel/Panel";
import { StorylyGroup } from "components/StorylyGroup/StorylyGroup";

// Enums
import { StorylyCategory } from "enums/StorylyCategory";

// Globals
import { featureFlags } from "globals/featureFlags";

export const DashboardStoryly = () => {
    return (
        <FeatureFlagBoundary
            featureFlags={[featureFlags.global.isStorylyContentEnabled]}
        >
            <Panel>
                <div className="panel__header">
                    <span className="panel__header__text">LEARN</span>
                </div>
                <StorylyGroup
                    classNames={{
                        container: "",
                        storylyContainer: "dashboard",
                    }}
                    category={StorylyCategory.DASHBOARD}
                />
            </Panel>
        </FeatureFlagBoundary>
    );
};
