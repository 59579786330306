import React from "react";

// Components
import { ButtonPrimary, ButtonInvisible } from "components/Buttons/Buttons";
import { Checkbox } from "components/Inputs/Checkbox/Checkbox";
import { Text } from "components/Text/Text";

// CSS
import styles from "../profile-delete-account-modal.module.scss";

export const ProfileDeleteAccountModalConfirm = (props) => {
    const {
        handleConfirmationToggle,
        handleDeleteAccount,
        hasConfirmedDeleteIntent,
        loading,
        profileInfo,
        setCurrentSection,
        setHasConfirmedDeleteIntent,
        setShowProfileDeleteAccountModal,
    } = props;
    const { deleteInfo } = profileInfo || {};
    const {
        connections = 0,
        contacts = 0,
        letters = 0,
        tokens = 0,
    } = deleteInfo || {};

    return (
        <div className={styles.confirm}>
            <div className={styles.body}>
                <Text classes={styles["warning-text"]} isBold={true} type="h3">
                    Delete Your Account?
                </Text>
                <p className={styles["paragraph-info"]}>
                    This action is <span>irreversible</span> and your account
                    will not be able to be recovered once deleted.
                    <br></br>
                    <br></br>
                    In addition to your personal information, the below items
                    will be permanently deleted:
                </p>
                <div className={styles["deleted-items"]}>
                    <span>{letters} Sent Letters</span>
                    <span>{tokens} Letters</span>
                    <span>{contacts} Contacts</span>
                    <span>{connections} Connections</span>
                </div>
                <div className={styles["checkbox-container"]}>
                    <Checkbox
                        cyId="profile-delete-account-modal-confirm-checkbox"
                        checked={hasConfirmedDeleteIntent}
                        id="confirm-checkbox"
                        onChange={handleConfirmationToggle}
                        shouldDisplayLabelInline={true}
                        text="I have read the above information and would like to
                            permanently delete my account"
                    />
                </div>
                <div className={styles.buttons}>
                    <ButtonPrimary
                        classes={styles.button}
                        color="warning"
                        cypressTestId="profile-delete-account-modal-confirm-delete-button"
                        isDisabled={!hasConfirmedDeleteIntent || loading}
                        onClick={handleDeleteAccount}
                        text="Delete My Account"
                        type="button"
                    />
                    <ButtonInvisible
                        classes={styles.button}
                        text="Cancel"
                        onClick={() => {
                            setShowProfileDeleteAccountModal(false);
                            setCurrentSection("verify");
                            setHasConfirmedDeleteIntent(false);
                        }}
                        type="button"
                    />
                </div>
            </div>
        </div>
    );
};
