import React from "react";
import { Skeleton } from "@material-ui/lab";

// Components
import { AvatarEmptyIcon } from "components/Icons/AvatarEmptyIcon";
import { CalendarBirthdayIcon } from "components/Icons/CalendarBirthdayIcon";
import { DogTagIcon } from "components/Icons/DogTagIcon";
import { GenderIcon } from "components/Icons/GenderIcon";
import { HeartIcon } from "components/Icons/HeartIcon";
import { IdBadgeIcon } from "components/Icons/IdBadgeIcon";
import { LocationIcon } from "components/Icons/LocationIcon";
import { MilitaryBaseIcon } from "components/Icons/MilitaryBaseIcon";
import { Panel } from "components/Panel/Panel";
import { PhoneIcon } from "components/Icons/PhoneIcon";
import { ProfileFieldsEdit } from "./components/ProfileFieldsEdit/ProfileFieldsEdit";
import { Text } from "components/Text/Text";
import { TrashIcon } from "components/Icons/TrashIcon";

// CSS
import "./profile-fields.scss";

// Hooks
import { useProfileFields } from "./hooks/useProfileFields";

// Enums
import { PersonaEnum } from "enums/PersonaEnum";

// Loggers
import { AnalyticsLogger } from "loggers/AnalyticsLogger";

// Utils
import { handleKeyDown } from "utils/eventUtils";
import { isEmptyObject } from "utils/miscUtils";
import {
    generateBranchPrettyName,
    generateIntentionPrettyName,
    generateMilitaryStatusPrettyName,
    generateRelationshipPrettyName,
} from "utils/userUtils";

export const ProfileFields = (props) => {
    const {
        depLocation,
        handleShowPersonaSelectionModal,
        handleShowProfileDepsModal,
        handleShowShipDateModal,
        onProfileUpdateSuccess,
        profileInfo,
        setShowProfileDeleteAccountModal,
        togglePhoneVerificationModal,
    } = props;

    /**
     * Custom Hooks
     */

    const {
        handleOpenPersonaSelectionModal,
        handleChange,
        isEditing,
        toggleFormEditState,
        userStaging,
    } = useProfileFields({
        handleShowPersonaSelectionModal,
        onProfileUpdateSuccess,
        profileInfo,
    });

    /**
     * Constants
     */

    const isProfileLoaded = isEmptyObject(profileInfo);
    const arePersonasLoaded = !!profileInfo.personas;
    const branchPersona = arePersonasLoaded && profileInfo.personas[2];
    const rootPersona = arePersonasLoaded && profileInfo.personas[0];
    const isLetterWriter = rootPersona.id === PersonaEnum.LETTER_WRITER;
    const isPreparingForBasic = rootPersona.id === PersonaEnum.RECRUIT;
    const isBuildingCareer = rootPersona.id === PersonaEnum.CAREER;
    const isRecruiter = rootPersona.id === PersonaEnum.RECRUITER;

    /**
     * End Hooks
     */

    function renderProfileFields() {
        return isEditing
            ? renderProfileFieldsEdit()
            : renderProfileFieldsDisplay();
    }

    function renderProfileFieldsDisplay() {
        const linkDisabledClass = isProfileLoaded ? "" : "disabled";
        return (
            <div className="profile__fields__display">
                <div className="profile__fields__header">
                    <div className="profile__fields__header__left">
                        <Text data-cy="profile-fields-h2" type="h2">
                            Personal Details
                        </Text>
                    </div>
                    <div className="profile__fields__header__right">
                        <span
                            className={`panel__header__link link ${linkDisabledClass}`}
                            data-cy="profile-fields-edit-profile"
                            onClick={toggleFormEditState}
                            onKeyDown={
                                /* istanbul ignore next */ (e) =>
                                    handleKeyDown(e, toggleFormEditState)
                            }
                            tabIndex="0"
                        >
                            Edit profile
                        </span>
                    </div>
                </div>
                <div className="profile__fields__display__item">
                    <div className="profile__fields__display__item__left">
                        <div className="profile__fields__display__item__icon">
                            <LocationIcon />
                        </div>
                        <div className="profile__fields__display__item__title">
                            <span>Address</span>
                        </div>
                    </div>
                    <div className="profile__fields__display__item__left">
                        <div
                            className="profile__fields__display__item__content"
                            data-cy="profile-fields-address"
                        >
                            {isProfileLoaded ? (
                                <>
                                    <Skeleton height={16} width={150} />
                                    <Skeleton height={16} width={100} />
                                    <Skeleton height={16} width={140} />
                                </>
                            ) : profileInfo.address?.line1 ? (
                                <>
                                    <span>{profileInfo.address?.line1}</span>
                                    <span>{profileInfo.address?.line2}</span>
                                    <span>
                                        {profileInfo.address?.city},{" "}
                                        {profileInfo.address?.state}{" "}
                                        {profileInfo.address?.zipcode}
                                    </span>
                                </>
                            ) : (
                                <span>Not Specified</span>
                            )}
                        </div>
                    </div>
                </div>
                <div className="profile__fields__display__item">
                    <div className="profile__fields__display__item__left">
                        <div className="profile__fields__display__item__icon">
                            <PhoneIcon height="20px" width="20px" />
                        </div>
                        <div className="profile__fields__display__item__title">
                            <span>Phone</span>
                        </div>
                    </div>
                    <div className="profile__fields__display__item__left">
                        <div
                            className="profile__fields__display__item__content"
                            data-cy="profile-fields-phone"
                        >
                            {isProfileLoaded ? (
                                <Skeleton height={16} width={150} />
                            ) : (
                                profileInfo.phoneNumber || "Not Specified"
                            )}
                        </div>
                    </div>
                </div>
                <div className="profile__fields__display__item">
                    <div className="profile__fields__display__item__left">
                        <div className="profile__fields__display__item__icon">
                            <GenderIcon />
                        </div>
                        <div className="profile__fields__display__item__title">
                            <span>Gender</span>
                        </div>
                    </div>
                    <div className="profile__fields__display__item__left">
                        <div
                            className="profile__fields__display__item__content"
                            data-cy="profile-fields-gender"
                        >
                            {isProfileLoaded ? (
                                <Skeleton height={16} width={150} />
                            ) : (
                                profileInfo.gender || "Not Specified"
                            )}
                        </div>
                    </div>
                </div>
                <div className="profile__fields__display__item">
                    <div className="profile__fields__display__item__left">
                        <div className="profile__fields__display__item__icon">
                            <CalendarBirthdayIcon />
                        </div>
                        <div className="profile__fields__display__item__title">
                            <span>Date of Birth</span>
                        </div>
                    </div>
                    <div className="profile__fields__display__item__left">
                        <div
                            className="profile__fields__display__item__content"
                            data-cy="profile-fields-birthday"
                        >
                            {isProfileLoaded ? (
                                <Skeleton height={16} width={150} />
                            ) : (
                                profileInfo.birthday || "Not Specified"
                            )}
                        </div>
                    </div>
                </div>
                <div className="profile__fields__display__item">
                    <div className="profile__fields__display__item__left">
                        <div className="profile__fields__display__item__icon">
                            <HeartIcon />
                        </div>
                        <div className="profile__fields__display__item__title">
                            <span>Marital Status</span>
                        </div>
                    </div>
                    <div className="profile__fields__display__item__left">
                        <div
                            className="profile__fields__display__item__content"
                            data-cy="profile-fields-marital-status"
                        >
                            {isProfileLoaded ? (
                                <Skeleton height={16} width={150} />
                            ) : (
                                profileInfo.maritalStatus || "Not Specified"
                            )}
                        </div>
                    </div>
                </div>
                <div className="profile__fields__display__item">
                    <div className="profile__fields__display__item__left">
                        <div className="profile__fields__display__item__icon">
                            <AvatarEmptyIcon />
                        </div>
                        <div className="profile__fields__display__item__title">
                            <span>Use Sandboxx To</span>
                        </div>
                    </div>
                    <div className="profile__fields__display__item__left">
                        <div
                            className="profile__fields__display__item__content"
                            data-cy="profile-fields-role"
                        >
                            {isProfileLoaded ? (
                                <Skeleton height={16} width={150} />
                            ) : rootPersona ? (
                                generateIntentionPrettyName(rootPersona.id)
                            ) : (
                                "Not Specified"
                            )}
                        </div>
                    </div>
                </div>
                {arePersonasLoaded && isLetterWriter && (
                    <div className="profile__fields__display__item">
                        <div className="profile__fields__display__item__left">
                            <div className="profile__fields__display__item__icon">
                                <AvatarEmptyIcon />
                            </div>
                            <div className="profile__fields__display__item__title">
                                <span>Relationship to recruit</span>
                            </div>
                        </div>
                        <div className="profile__fields__display__item__left">
                            <div
                                className="profile__fields__display__item__content"
                                data-cy="profile-fields-branch"
                            >
                                {isProfileLoaded ? (
                                    <Skeleton height={16} width={150} />
                                ) : profileInfo?.personas[1] ? (
                                    generateRelationshipPrettyName(
                                        profileInfo.personas[1].id
                                    )
                                ) : (
                                    "Not Specified"
                                )}
                            </div>
                        </div>
                    </div>
                )}
                {arePersonasLoaded &&
                    (isPreparingForBasic ||
                        isBuildingCareer ||
                        isRecruiter) && (
                        <div className="profile__fields__display__item">
                            <div className="profile__fields__display__item__left">
                                <div className="profile__fields__display__item__icon">
                                    <DogTagIcon />
                                </div>
                                <div className="profile__fields__display__item__title">
                                    <span>Military status</span>
                                </div>
                            </div>
                            <div className="profile__fields__display__item__left">
                                <div
                                    className="profile__fields__display__item__content"
                                    data-cy="profile-fields-branch"
                                >
                                    {isProfileLoaded ? (
                                        <Skeleton height={16} width={150} />
                                    ) : profileInfo?.personas[1] ? (
                                        generateMilitaryStatusPrettyName(
                                            profileInfo.personas[1].id
                                        )
                                    ) : (
                                        "Not Specified"
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                {arePersonasLoaded &&
                    (isPreparingForBasic ||
                        isBuildingCareer ||
                        isRecruiter) && (
                        <div className="profile__fields__display__item">
                            <div className="profile__fields__display__item__left">
                                <div className="profile__fields__display__item__icon">
                                    <IdBadgeIcon />
                                </div>
                                <div className="profile__fields__display__item__title">
                                    <span>Branch of Service</span>
                                </div>
                            </div>
                            <div className="profile__fields__display__item__left">
                                <div
                                    className="profile__fields__display__item__content"
                                    data-cy="profile-fields-branch"
                                >
                                    {isProfileLoaded ? (
                                        <Skeleton height={16} width={150} />
                                    ) : branchPersona ? (
                                        generateBranchPrettyName(
                                            branchPersona.id
                                        )
                                    ) : (
                                        "Not Specified"
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                {arePersonasLoaded && isPreparingForBasic && branchPersona && (
                    <div className="profile__fields__display__item">
                        <div className="profile__fields__display__item__left">
                            <div className="profile__fields__display__item__icon">
                                <LocationIcon />
                            </div>
                            <div className="profile__fields__display__item__title">
                                <span>Recruiting station</span>
                            </div>
                        </div>
                        <div className="profile__fields__display__item__left">
                            <div
                                className="profile__fields__display__item__content"
                                data-cy="profile-fields-branch"
                            >
                                {depLocation
                                    ? `${depLocation.locale} • ${depLocation.office}`
                                    : "Not Specified"}
                            </div>
                        </div>
                    </div>
                )}
                {arePersonasLoaded && isPreparingForBasic && (
                    <div className="profile__fields__display__item">
                        <div className="profile__fields__display__item__left">
                            <div className="profile__fields__display__item__icon">
                                <MilitaryBaseIcon />
                            </div>
                            <div className="profile__fields__display__item__title">
                                <span>Ship Date</span>
                            </div>
                        </div>
                        <div className="profile__fields__display__item__left">
                            <div
                                className="profile__fields__display__item__content"
                                data-cy="profile-fields-branch"
                            >
                                {isProfileLoaded ? (
                                    <Skeleton height={16} width={150} />
                                ) : profileInfo?.basicTrainingShipDate ? (
                                    profileInfo.basicTrainingShipDate
                                ) : (
                                    "Not Specfied"
                                )}
                            </div>
                        </div>
                    </div>
                )}
                <div className="profile__fields__display__item profile__fields__display__item--delete">
                    <button
                        className="profile__fields__display__item__left profile__fields__display__item__left--action"
                        data-cy="profile-fields-delete-account"
                        onClick={() => {
                            setShowProfileDeleteAccountModal(true);
                            AnalyticsLogger.logDeleteAccountProfileButtonClick();
                        }}
                    >
                        <div className="profile__fields__display__item__icon">
                            <TrashIcon color="#b90202" />
                        </div>
                        <div className="profile__fields__display__item__title profile__fields__display__item__title--delete">
                            <span>Delete Account</span>
                        </div>
                    </button>
                </div>
            </div>
        );
    }

    function renderProfileFieldsEdit() {
        return (
            <ProfileFieldsEdit
                branchPersona={branchPersona}
                handleChange={handleChange}
                handleOpenPersonaSelectionModal={
                    handleOpenPersonaSelectionModal
                }
                handleShowProfileDepsModal={handleShowProfileDepsModal}
                handleShowShipDateModal={handleShowShipDateModal}
                isBuildingCareer={isBuildingCareer}
                isLetterWriter={isLetterWriter}
                isPreparingForBasic={isPreparingForBasic}
                isProfileLoaded={isProfileLoaded}
                isRecruiter={isRecruiter}
                profileInfo={profileInfo}
                rootPersona={rootPersona}
                setShowProfileDeleteAccountModal={
                    setShowProfileDeleteAccountModal
                }
                toggleFormEditState={toggleFormEditState}
                togglePhoneVerificationModal={togglePhoneVerificationModal}
                userStaging={userStaging}
            />
        );
    }

    return <Panel classes="profile__fields">{renderProfileFields()}</Panel>;
};
