import React, { useState } from "react";

// Components
import { AddPaymentOption } from "../PaymentOption/AddPaymentOption";
import { SavedPaymentOptions } from "../PaymentOption/SavedPaymentOptions";

// Enums
import { PurchaseModalNavigation } from "./enums/enums";

export const PurchaseModalPayment = (props) => {
    const {
        changeCurrentSection,
        handleCardSelect,
        handleCloseModal,
        loading,
        purchaseMode,
        savedCards,
        selectedCard,
        setLoading,
        setSelectedBundle,
        setSelectedCard,
        showNotification,
    } = props;

    /************************************************
     * useState
     ************************************************/

    const [newCard, setNewCard] = useState({
        id: null,
        name: null,
        saveCard: true,
        token: null,
    });

    /************************************************
     * End Hooks
     ************************************************/

    function handleCardInputChange(e) {
        const { name, value } = e.target;
        setNewCard({
            ...newCard,
            [name]: value,
        });
    }

    function handleCardSetToken(token) {
        setNewCard({ ...newCard, token });
    }

    function handleGoBack() {
        setSelectedBundle(null);
        purchaseMode === "lettersPhotoManageModal"
            ? changeCurrentSection(PurchaseModalNavigation.BUNDLE_HIGHLIGHT)
            : changeCurrentSection(PurchaseModalNavigation.BUNDLES);
    }

    function handleSavedCardSelect(card, index, callback) {
        setSelectedCard((prevSelectedCard) => ({
            ...prevSelectedCard,
            card,
            index,
        }));
        callback(card);
    }

    function renderButtons(stripeCardType, submitCallback) {
        const submitButton = loading[stripeCardType] ? (
            <button className="button button--primary button-small disabled">
                <span>Loading...</span>
            </button>
        ) : (
            <button
                className="button button--primary button-small"
                onClick={submitCallback}
            >
                <span>Next</span>
            </button>
        );
        return (
            <div className="button__container force-right">
                <button
                    className="button button--invisible button-small"
                    onClick={handleGoBack}
                >
                    <span>Back</span>
                </button>
                {submitButton}
            </div>
        );
    }

    const savedCardEndItem = { type: "select" };
    return (
        <div className="letters-compose-purchase-payment-container">
            <AddPaymentOption
                changeCurrentSection={changeCurrentSection}
                context="credits"
                handleCardInputChange={handleCardInputChange}
                handleCardSelect={handleCardSelect}
                handleCardSetToken={handleCardSetToken}
                handleCloseModal={handleCloseModal}
                handleGoBack={handleGoBack}
                loading={loading}
                newCard={newCard}
                setLoading={setLoading}
                showNotification={showNotification}
            />
            <div className="new-modal-text-divider horizontal">OR</div>
            <SavedPaymentOptions
                changeCurrentSection={changeCurrentSection}
                context="credits"
                endItem={savedCardEndItem}
                handleCardSelect={handleCardSelect} // Updates LetterCompose
                handleCloseModal={handleCloseModal}
                handleSavedCardSelect={handleSavedCardSelect} // Updates LettersComposePurchasePayment
                renderButtons={renderButtons}
                savedCards={savedCards}
                selectedCard={selectedCard}
            />
        </div>
    );
};
