import { useContext, useState } from "react";
import { useHistory } from "react-router";

// Context
import { BundlesContext } from "context/bundles";
import { CartContext } from "context/Cart/cart";
import { ContactsContext } from "context/contacts";
import { CreditsContext } from "context/credits";
import { MusterContext } from "context/muster";
import { NavBarContext } from "context/navbar";
import { PurchaseContext } from "context/purchase";

// Platforms
import { Analytics } from "platforms/analytics";
import { Facebook } from "platforms/facebook";
import { Google } from "platforms/google";
import { Intercom } from "platforms/intercom";

// Utils
import { CurrentUser, SandboxxRestAPI } from "utils/sandboxx";

export const useAuthModalLogout = ({ setShouldShowLogOutModal }) => {
    /**
     * Router Hooks
     */

    const history = useHistory();

    /**
     * useContext
     **/

    const { resetBundlesContext } = useContext(BundlesContext);
    const { resetCartContext, resetCartShippingOptionsContext } =
        useContext(CartContext);
    const { resetContactsContext } = useContext(ContactsContext);
    const { resetCreditsContext } = useContext(CreditsContext);
    const { resetMusterContext } = useContext(MusterContext);
    const { setShouldShowNavBar } = useContext(NavBarContext);
    const { resetPurchaseContext } = useContext(PurchaseContext);

    /**
     * useState
     */

    const [buttonText, setButtonText] = useState("Yes, I want to logout");
    const [isLoading, setIsLoading] = useState(false);

    /**
     * End Hooks & Constants
     */

    function closeLogOutModal() {
        setShouldShowLogOutModal(false);
    }

    function handleLogout() {
        setIsLoading(true);
        setButtonText("Logging out...");
        onLogoutSuccess();
        SandboxxRestAPI.logout();
    }

    function handleLogoutSocial() {
        const authProvider = localStorage.getItem("authProvider");
        switch (authProvider) {
            case "facebook":
                Facebook.logout();
                localStorage.removeItem("authProvider");
                return;
            case "google":
                Google.logout();
                localStorage.removeItem("authProvider");
                return;
            default:
                localStorage.removeItem("authProvider");
                return;
        }
    }

    function onLogoutSuccess() {
        // Update localStorage values
        CurrentUser.clearUser();
        localStorage.removeItem("sandboxxMessageRecipient");
        localStorage.removeItem("hasCompletedSignUpName");

        // Reset context
        resetBundlesContext();
        resetCartContext();
        resetCartShippingOptionsContext();
        resetCreditsContext();
        resetContactsContext();
        resetMusterContext();
        resetPurchaseContext();

        // Logout of social platform
        handleLogoutSocial();

        // Update Analytics platform
        Analytics.logout();

        // Update Intercom
        Intercom.shutdown();
        Intercom.boot();

        // Update UI
        closeLogOutModal();
        setIsLoading(false);
        setButtonText("Yes, I want to logout");

        // Change route
        history.push({ pathname: "/" });

        // Close NavBar
        setShouldShowNavBar(false);
    }

    return { buttonText, closeLogOutModal, handleLogout, isLoading };
};
