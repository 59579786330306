import React, { useState, useEffect } from "react";
import cx from "classnames";
import { Skeleton } from "@material-ui/lab";

// Components
import { ButtonInvisible } from "components/Buttons/Buttons";
import { ButtonRounded } from "components/Buttons/ButtonRounded/ButtonRounded";
import { Text } from "components/Text/Text";

// CSS
import signInStyles from "../../../sign-in.module.scss";
import styles from "./sign-up-persona-child.module.scss";

// Enums
import { NavigationEnum } from "scenes/SignIn/constants/constants";
import { PersonaEnum } from "enums/PersonaEnum";

// Utils
import { generateDisplayText } from "utils/personaUtils";

export const SignUpPersonaChild = (props) => {
    const {
        childPersonas,
        handleGoBackToPersonaSelection,
        handleSelectChildPersona,
        isLoadingChildPersonas,
        selectedRootPersona,
        setCurrentSection,
        setUserInfo,
    } = props;

    const lastChildPersonasArray = childPersonas[childPersonas?.length - 1];
    const shouldDisplayPersonasGrid = lastChildPersonasArray?.length > 3;

    /************************************************
     * useState
     ************************************************/

    const [displayText, setDisplayText] = useState({
        header: "",
        subHeader: "",
    });

    /************************************************
     * useEffect
     ************************************************/

    useEffect(() => {
        setDisplayText(generateDisplayText(lastChildPersonasArray));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [childPersonas]);

    /************************************************
     * End Hooks
     ************************************************/

    /**
     * The `handleSubmit` method sends the user to the next screen in
     * the onboarding flow once the usePersonaWizard process is complete.
     *
     * The next screen depends on which root persona the user
     * selected on the `SignUpPersonaRoot` screen.
     */
    function handleSubmit() {
        switch (selectedRootPersona.id) {
            case PersonaEnum.CAREER:
            case PersonaEnum.LETTER_WRITER:
            case PersonaEnum.RECRUITER:
                setCurrentSection(NavigationEnum.SIGN_UP_SUCCESS);
                return;
            case PersonaEnum.RECRUIT:
                setCurrentSection(NavigationEnum.SIGN_UP_RECRUITING_STATION);
                return;
            default:
                return;
        }
    }

    /**
     * The `handleSelectPersona` will either (a) trigger the fetching
     * of subsequent child personas if the selected persona has any children;
     * or (b) trigger the `onComplete` callback if there are no more
     * child personas to fetch
     *
     * @param {Persona} persona
     */
    function handleSelectPersona(persona) {
        handleSelectChildPersona(persona, { onComplete: handleSubmit });
        setUserInfo((prev) => ({
            ...prev,
            personas: [...prev.personas, persona.id],
        }));
        if (!persona.hasChildren()) {
            if (selectedRootPersona.id === PersonaEnum.RECRUIT) {
                setUserInfo((prev) => ({
                    ...prev,
                    branchId: persona.id,
                }));
            }
        }
    }

    function renderPersonas() {
        if (isLoadingChildPersonas) {
            return (
                <>
                    <ButtonRounded classes={styles.persona} isLoading />
                    <ButtonRounded classes={styles.persona} isLoading />
                    <ButtonRounded classes={styles.persona} isLoading />
                    <ButtonRounded classes={styles.persona} isLoading />
                    <ButtonRounded classes={styles.persona} isLoading />
                    <ButtonRounded classes={styles.persona} isLoading />
                </>
            );
        } else {
            return lastChildPersonasArray.map((persona) => {
                return (
                    <ButtonRounded
                        classes={styles.persona}
                        cypressTestId={`sign-up-persona-child-${persona.id}`}
                        key={persona.id}
                        onClick={() => handleSelectPersona(persona)}
                        text={persona.displayName}
                    />
                );
            });
        }
    }

    return (
        <div className={cx(signInStyles.content, styles.signUpPersonaChild)}>
            <div className={signInStyles.top}>
                <div className={signInStyles.header}>
                    <Text classes={signInStyles.text}>
                        {isLoadingChildPersonas ? (
                            <Skeleton
                                variant="rect"
                                width="100%"
                                height="30px"
                            />
                        ) : (
                            displayText.header
                        )}
                    </Text>
                    <Text classes={signInStyles["text-secondary"]}>
                        {isLoadingChildPersonas ? (
                            <Skeleton
                                variant="rect"
                                width="40%"
                                height="10px"
                            />
                        ) : (
                            displayText.subHeader
                        )}
                    </Text>
                </div>
                <div
                    className={cx(styles.personas, {
                        [styles["personas--grid"]]:
                            isLoadingChildPersonas || shouldDisplayPersonasGrid,
                    })}
                >
                    {renderPersonas()}
                </div>
            </div>
            <div className={styles.navigation}>
                <ButtonInvisible
                    classes={styles.backButton}
                    onClick={handleGoBackToPersonaSelection}
                    text="Back"
                />
            </div>
        </div>
    );
};
