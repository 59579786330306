import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// Components
import { ButtonSecondary } from "components/Buttons/Buttons";
import { ChevronRightIcon } from "components/Icons/ChevronRightIcon";
import { LetterPreview } from "../../../components/LetterPreview/LetterPreview";
import { Panel } from "components/Panel/Panel";
import { Tabs } from "components/Tabs/Tabs";

// CSS
import styles from "./dashboard-main-letters.module.scss";

// Enums
import { LetterType } from "enums/LetterType";

// Media
import uspsLogoSrc from "media/logos/usps.svg";

export const DashboardMainLetters = (props) => {
    const {
        history,
        lettersReplied,
        lettersSent,
        moreLettersRepliedCursor,
        moreLettersSentCursor,
    } = props;

    /************************************************
     * useState
     ************************************************/

    const [letters, setLetters] = useState({
        list: lettersSent,
        type: LetterType.SENT,
    });

    /************************************************
     * useEffect
     ************************************************/

    useEffect(() => {
        if (letters.list === null) {
            setLetters((prevLetters) => ({
                ...prevLetters,
                list: lettersSent,
                type: LetterType.SENT,
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lettersSent]);

    /************************************************
     * End Hooks
     ************************************************/

    function renderContent() {
        if (letters.list === null) {
            return renderLoading();
        }
        if (letters.list && letters.list.length) {
            return renderLetters();
        }
        if (letters.list && !letters.list.length) {
            return renderEmptyState();
        }
    }

    function renderEmptyState() {
        if (letters.type === LetterType.REPLIED) {
            return (
                <div className={styles.replied}>
                    <div className={styles.content}>
                        <div className={styles.logo}>
                            <img alt="USPS logo" src={uspsLogoSrc} />
                        </div>
                        <div className={styles.text}>
                            Receive a free digital preview of letters before
                            they arrive from basic training with the{" "}
                            <b>USPS Informed Delivery®</b> feature.
                        </div>
                    </div>
                    <div
                        className={styles.button}
                        data-cy="dashboard-main-letters-empty-replied-button"
                    >
                        <a
                            href="https://informeddelivery.usps.com/box/pages/intro/start.action?utm_medium=digital&utm_source=ID&utm_campaign=sandboxx&utm_content=dashboard"
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            <ButtonSecondary text="Get Started" />
                        </a>
                    </div>
                </div>
            );
        } else {
            return (
                <div className={styles.empty}>
                    <span>
                        It looks like you have not sent a letter yet. Click the
                        button above to get started.
                    </span>
                </div>
            );
        }
    }

    function renderLoading() {
        return (
            <div className={styles.list}>
                <LetterPreview isLoading={true} history={history} key={0} />
                <LetterPreview isLoading={true} history={history} key={1} />
                <LetterPreview isLoading={true} history={history} key={2} />
                {renderShowAll()}
            </div>
        );
    }

    function renderLetters() {
        const items = letters.list.slice(0, 3).map((letter, key) => {
            const { id, mailboxxOrderId } = letter;
            const urlId =
                letters.type === LetterType.REPLIED ? mailboxxOrderId : id;
            const linkParams = {
                pathname: `/letters/sent/${urlId}`,
                state: { letter, letterType: letters.type },
            };
            return (
                <LetterPreview
                    isLoading={false}
                    key={urlId}
                    letter={letter}
                    letterType={letters.type}
                    linkParams={linkParams}
                />
            );
        });
        return (
            <div className={styles.list}>
                {items}
                {renderShowAll()}
            </div>
        );
    }

    function renderShowAll() {
        return (
            <Link
                data-cy="dashboard-main-letters-show-all"
                to={{
                    pathname: "/letters/sent",
                    state: {
                        cursorReplied: moreLettersRepliedCursor,
                        cursorSent: moreLettersSentCursor,
                        lettersReplied,
                        lettersSent,
                    },
                }}
            >
                <div className={styles.showAll}>
                    <span>Show All</span>
                    <ChevronRightIcon
                        className={styles.icon}
                        height="20px"
                        width="20px"
                    />
                </div>
            </Link>
        );
    }

    function renderTabs() {
        const tabsData = [
            {
                cyId: "dashboard-main-letters-sent-button",
                id: LetterType.SENT,
                index: 0,
                isActive: letters.type === LetterType.SENT,
                onClick: () =>
                    setLetters((prev) => ({
                        ...prev,
                        list: lettersSent,
                        type: LetterType.SENT,
                    })),
                text: "Sent",
            },
            {
                cyId: "dashboard-main-letters-replied-button",
                id: LetterType.REPLIED,
                index: 1,
                isActive: letters.type === LetterType.REPLIED,
                onClick: () =>
                    setLetters((prev) => ({
                        ...prev,
                        list: lettersReplied,
                        type: LetterType.REPLIED,
                    })),
                text: "Replied",
            },
        ];
        return <Tabs data={tabsData} />;
    }

    return (
        <Panel classes={styles.dashboardMainLetters}>
            <div className="panel__header">
                <span className="panel__header__text">HISTORY</span>
            </div>
            {renderTabs()}
            {renderContent()}
        </Panel>
    );
};
