import React, { useState } from "react";

// Utils
import { emptyFunction } from "../../../../../utils/miscUtils";
import { handleKeyDown } from "utils/eventUtils";

// Enums
import { AddOnsNavigation } from "../enums/enums";

export const LettersComposeAddOnsGiftCardSelectAmount = (props) => {
    const {
        giftCard,
        changeCurrentSection,
        handleCloseModal,
        handleSelectGiftCardAmount,
    } = props;
    const { selected } = giftCard;

    /************************************************
     * useState
     ************************************************/

    const [selectedAmount, setSelectAmount] = useState(null);

    /************************************************
     * End Hooks
     ************************************************/

    const buttonDisabledClass = selectedAmount ? "" : "disabled";
    const buttonOnClick = selectedAmount ? handleSubmit : emptyFunction;

    function handleAmountOnClick(denomination, fee) {
        setSelectAmount({ base: denomination, fee });
    }

    function handleGoBack() {
        changeCurrentSection(AddOnsNavigation.SELECT_DESIGN);
    }

    function handleSubmit() {
        handleSelectGiftCardAmount(selectedAmount);
        handleCloseModal(false, { shouldNotClear: true });
    }

    function renderAmounts() {
        return selected.denominations.map((denomination, i) => {
            const amountSelectedClass =
                selectedAmount?.base === denomination
                    ? "button--secondary--filled"
                    : "";
            return (
                <div
                    className={`letters-compose-add-ons__select-amount__amounts__amount ${amountSelectedClass} button button--secondary button-small`}
                    data-cy={`letters-compose-add-ons-gift-card-amount-${i}`}
                    key={i}
                    onClick={() =>
                        handleAmountOnClick(
                            denomination,
                            selected.fee_totals[i]
                        )
                    }
                    onKeyDown={
                        /* istanbul ignore next */ (e) =>
                            handleKeyDown(e, () =>
                                handleAmountOnClick(
                                    denomination,
                                    selected.fee_totals[i]
                                )
                            )
                    }
                    tabIndex={i + 1}
                >
                    <span>${denomination}</span>
                </div>
            );
        });
    }

    function renderDesign() {
        const { id, photoUrlFull } = selected;
        const style = { backgroundImage: `url(${photoUrlFull})` };
        return <div className="gift-card" key={id} style={style} />;
    }

    return (
        <div className="letters-compose-add-ons__select-amount">
            <div className="letters-compose-add-ons__select-amount__design">
                {renderDesign()}
            </div>
            <div className="letters-compose-add-ons__select-amount__amounts">
                {renderAmounts()}
            </div>
            <div
                className="button__container"
                style={{ justifyContent: "space-between" }}
            >
                <button
                    className={`button button--invisible button-small`}
                    onClick={handleGoBack}
                >
                    <span>Back</span>
                </button>
                <button
                    className={`button button--primary button-small ${buttonDisabledClass}`}
                    data-cy="letters-compose-add-ons-gift-card-amount-confirm-button"
                    onClick={buttonOnClick}
                >
                    <span>Confirm</span>
                </button>
            </div>
        </div>
    );
};
