import React, { useContext } from "react";
import cx from "classnames";

// Components
import { Skeleton } from "@mui/material";
import { Text } from "components/Text/Text";

// Context
import { CartContext } from "context/Cart/cart";

// CSS
import styles from "./cart-line-items.module.scss";

// Utils
import { convertWholeNumberToDollars } from "utils/cartUtils";

export const CartLineItems = () => {
    /**
     * useContext
     */

    const { cart, isUpdatingCart } = useContext(CartContext);

    /**
     * End Hooks
     */

    if (isUpdatingCart) {
        return (
            <div className={styles.cartLineItems}>
                <div className={styles.item}>
                    <Text>Subtotal</Text>
                    <Skeleton variant="text" width={50} />
                </div>
                <div className={styles.item}>
                    <Text>Shipping</Text>
                    <Skeleton variant="text" width={50} />
                </div>
                <div className={styles.item}>
                    <Text>Tax</Text>
                    <Skeleton variant="text" width={50} />
                </div>
                <div className={styles.item}>
                    <Text isBold>Total</Text>
                    <Skeleton variant="text" width={50} />
                </div>
            </div>
        );
    } else {
        return (
            <div className={styles.cartLineItems}>
                <div className={styles.item}>
                    <Text classes={styles.label}>Subtotal</Text>
                    <Text classes={styles.value}>
                        {convertWholeNumberToDollars(cart?.subtotal)}
                    </Text>
                </div>
                {cart?.discount_total > 0 && (
                    <div className={cx(styles.item, styles["item--discount"])}>
                        <Text classes={styles.label}>Discount</Text>
                        <Text classes={styles.value}>
                            -{convertWholeNumberToDollars(cart?.discount_total)}
                        </Text>
                    </div>
                )}
                <div className={styles.item}>
                    <Text classes={styles.label}>Shipping</Text>
                    <Text classes={styles.value}>
                        {cart?.shipping_methods[0]
                            ? convertWholeNumberToDollars(cart?.shipping_total)
                            : "Calculated after selecting shipping method"}
                    </Text>
                </div>
                <div className={styles.item}>
                    <Text classes={styles.label}>Tax</Text>
                    <Text classes={styles.value}>
                        {convertWholeNumberToDollars(cart?.tax_total)}
                    </Text>
                </div>
                <div className={styles.item}>
                    <Text classes={styles.label} isBold>
                        Total
                    </Text>
                    <Text classes={styles.value} isBold>
                        {convertWholeNumberToDollars(cart?.total)}
                    </Text>
                </div>
            </div>
        );
    }
};
