// Components
import { MaterialIcon } from "components/MaterialIcon/MaterialIcon";

// Constants
import {
    LETTER_CHARACTER_LIMIT,
    LETTER_LINE_BREAK_CHARACTER_WEIGHT,
} from "globals/constants";

// Enums
import { LetterType } from "enums/LetterType";

// Utils
import { titleCase } from "./textUtils";

/**
 * This method returns the number of characters left in the `message` input string
 * according to Sandboxx letter length specifications.
 *
 * Each line break takes up the number of characters stored in the `lineBreakWeight`
 * constant.
 *
 * For the time being, this value is set to 44 to align with the backend value of 45.
 * It is lower by one because the client is adding another characters to that value
 * after length calculations are made. This should be revisited.
 *
 * @param {String} message
 * @returns {Object} An object containing information about the remaining characters
 * @returns {Number} returns.remainingCharacters the number of remaining characters
 */
export function calculateRemainingMessageCharacters(message) {
    // Clone message string
    const tempMessage = message.slice();

    // Count the number of line breaks in the message string
    const lineBreakMatch = message.match(/\r|\n|\r\n/gi);
    const lineBreakCount = lineBreakMatch ? lineBreakMatch.length : 0;

    // Remove line breaks from message string
    tempMessage.replace(/\r\n/, "");
    tempMessage.replace(/\r/, "");
    tempMessage.replace(/\n/, "");

    // Calculate how many characters are left in user's letter, accounting
    // for `lineBreakWeight`
    const lineBreakCharacters =
        LETTER_LINE_BREAK_CHARACTER_WEIGHT * lineBreakCount;
    const weightedLength = lineBreakCharacters + tempMessage.length;
    const remainingCharacters = LETTER_CHARACTER_LIMIT - weightedLength;

    // Return the number of characters left
    return { remainingCharacters };
}

export function formatSentLetterData(letter, type) {
    if (type === LetterType.SENT) {
        return letter;
    } else if (type === LetterType.REPLIED) {
        const {
            firstScanDate,
            mailboxxOrderId,
            recipientName,
            pretty_status,
            prettyScanStatus,
            recipient,
            scanCity,
            scanState,
        } = letter;
        return {
            ...letter,
            created_at: firstScanDate,
            id: mailboxxOrderId,
            pretty_status: prettyScanStatus || pretty_status,
            status: prettyScanStatus || pretty_status,
            recipient: {
                address: {
                    city: titleCase(scanCity) || recipient.address.city,
                    state: scanState || recipient.address.state,
                },
                name: recipientName,
            },
        };
    }
}

export function generateLetterSendPayload(state) {
    const { message, sender } = state;
    const recipientPayload = generateRecipientIdPayloadLetterSend(state);
    const letterPayload = {
        letter: {
            customPhotoArray:
                message.customPhotoArray.map((photo) => photo.url) || [],
            message: message.content,
            recipient: recipientPayload,
            sender,
        },
    };
    return letterPayload;
}

export function generateRecipientIdPayload({ recipient }) {
    if (recipient.isSandboxxUser) {
        return { recipientUserId: recipient.id };
    } else if (recipient.isSquadRecipient) {
        return { squadId: recipient.id };
    } else {
        return { abContactId: recipient.id };
    }
}

export function generateRecipientIdPayloadLetterSend({ recipient }) {
    if (recipient.isSandboxxUser) {
        return { userId: recipient.id };
    } else if (recipient.isSquadRecipient) {
        return { squadId: recipient.id };
    } else {
        return { abContactId: recipient.id };
    }
}

export function generateStatusColor(status) {
    const statusFormatted = status
        .toUpperCase()
        .replace(/\s/g, "")
        .replace(/_/g, "");
    const statusColors = {
        DELIVERED: "var(--teal-neutral)",
        INREVIEW: "var(--blue-darkerer)",
        INTRANSIT: "var(--blue-darkerer)",
        NEW: "var(--blue-darkerer)",
        ONHOLD: "var(--mission-orange-100)",
        OUTFORDELIVERY: "var(--blue-darkerer)",
        PENDINGSHIPMENT: "var(--blue-darkerer)",
        PICKEDUP: "var(--blue-darkerer)",
        PRINTED: "var(--blue-darkerer)",
        PROCESSING: "var(--blue-darkerer)",
        SENT: "var(--teal-neutral)",
    };
    return statusColors[statusFormatted];
}

export function generateStatusIcon({ className = "", status } = {}) {
    const statusFormatted = status
        .toUpperCase()
        .replace(/\s/g, "")
        .replace(/_/g, "");
    const statusIcons = {
        DELIVERED: (
            <MaterialIcon
                className={className}
                color="var(--teal-neutral)"
                name="check_circle"
            />
        ),
        EXCEPTION: (
            <MaterialIcon
                className={className}
                color="var(--mission-orange-100)"
                name="warning"
            />
        ),
        INFORECEIVED: (
            <MaterialIcon
                className={className}
                color="var(--blue-darkerer)"
                name="autorenew"
            />
        ),
        INREVIEW: (
            <MaterialIcon
                className={className}
                color="var(--blue-darkerer)"
                name="quick_reference_all"
            />
        ),
        INTRANSIT: (
            <MaterialIcon
                className={className}
                color="var(--blue-darkerer)"
                name="send"
            />
        ),
        NEW: (
            <MaterialIcon
                className={className}
                color="var(--blue-darkerer)"
                name="description"
            />
        ),
        ONHOLD: (
            <MaterialIcon
                className={className}
                color="var(--mission-orange-100)"
                name="warning"
            />
        ),
        OUTFORDELIVERY: (
            <MaterialIcon
                className={className}
                color="var(--blue-darkerer)"
                name="send"
            />
        ),
        PENDINGSHIPMENT: (
            <MaterialIcon
                className={className}
                color="var(--blue-darkerer)"
                name="inventory_2"
            />
        ),
        PHASE1: (
            <MaterialIcon
                className={className}
                color="var(--blue-darkerer)"
                name="send"
            />
        ),
        PHASE2: (
            <MaterialIcon
                className={className}
                color="var(--blue-darkerer)"
                name="send"
            />
        ),
        PHASE3: (
            <MaterialIcon
                className={className}
                color="var(--blue-darkerer)"
                name="send"
            />
        ),
        PHASE4: (
            <MaterialIcon
                className={className}
                color="var(--teal-neutral)"
                name="check_circle"
            />
        ),
        PICKEDUP: (
            <MaterialIcon
                className={className}
                color="var(--blue-darkerer)"
                name="send"
            />
        ),
        PRINTED: (
            <MaterialIcon
                className={className}
                color="var(--blue-darkerer)"
                name="description"
            />
        ),
        PROCESSING: (
            <MaterialIcon
                className={className}
                color="var(--blue-darkerer)"
                name="autorenew"
            />
        ),
        SENT: (
            <MaterialIcon
                className={className}
                color="var(--teal-neutral)"
                name="check_circle"
            />
        ),
    };
    return statusIcons[statusFormatted];
}

export function generateStatusWidth(status) {
    const statusFormatted = status
        .toUpperCase()
        .replace(/\s/g, "")
        .replace(/_/g, "");
    const statusWidths = {
        DELIVERED: "100%",
        INREVIEW: "20%",
        INTRANSIT: "60%",
        NEW: "20%",
        ONHOLD: "20%",
        OUTFORDELIVERY: "60%",
        PENDINGSHIPMENT: "60%",
        PICKEDUP: "20%",
        PRINTED: "20%",
        PROCESSING: "20%",
        SENT: "20%",
    };
    return statusWidths[statusFormatted];
}
