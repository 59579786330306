import React from "react";
import cx from "classnames";

// Components
import { ButtonPrimary } from "../../../components/Buttons/Buttons";
import { Panel } from "../../../components/Panel/Panel";

// CSS
import styles from "./dashboard-wallet.module.scss";

// Loggers
import { AnalyticsLogger } from "loggers/AnalyticsLogger";

// Constants
import giftcardImageSrc from "media/dashboard/giftcard.svg";

export const DashboardWallet = (props) => {
    const { history } = props;

    function handleGoToWallet() {
        AnalyticsLogger.logGiftCardViewed();
        history.push("/sandboxx-gift-card");
    }

    return (
        <Panel>
            <div className={styles["dashboard__wallet"]}>
                <img
                    alt="Giftcard wrapped in packaging"
                    className={styles.image}
                    src={giftcardImageSrc}
                />
                <span className={styles.text}>
                    Send a Gift Card to your recruit or service member
                </span>
                <ButtonPrimary
                    classes={cx(styles.button, "button--size-short")}
                    cypressTestId="dashboard-wallet-my-wallet-button"
                    color="orange"
                    onClick={handleGoToWallet}
                    text="Gift Cards"
                    type="button"
                />
            </div>
        </Panel>
    );
};
