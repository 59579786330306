// Platforms
import { Auth } from "./auth";

// Utils
import { DEV } from "../utils/urlUtils";
import { emptyFunction } from "../utils/miscUtils";

export class Google {
    static initClient(callbacks) {
        const GAPI = window.gapi;
        try {
            GAPI.load("client:auth2", () => {
                // Initialize Google Auth
                const clientId = DEV
                    ? process.env.REACT_APP_GOOGLE_CLIENT_ID_DEV
                    : process.env.REACT_APP_GOOGLE_CLIENT_ID_PROD;
                const discoveryDocs = [
                    "https://www.googleapis.com/discovery/v1/apis/people/v1/rest",
                ];
                const scope =
                    "https://www.googleapis.com/auth/contacts.readonly";

                GAPI.client
                    .init({
                        clientId,
                        discoveryDocs,
                        scope,
                    })
                    .then(callbacks.onInitClient);
            });
        } catch (err) {
            // eslint-disable-next-line
            const didNotLoad = err.message.includes(
                "Cannot read property 'load'"
            );
            // If GAPI.load fails, try reloading in two seconds
            if (didNotLoad) {
                setTimeout(() => Google.initClient(callbacks), 2000);
            }
        }
    }

    /**
     * TODO: Update args so that `shouldSave` is part of config object w/ `mode`
     */
    static initLogin(callbacksArg, shouldSave = true, { mode = "login" }) {
        const { onSignIn, showNotification, toggleButtonLoading } =
            callbacksArg;
        const callbacks = {
            ...callbacksArg,
            onInitClient: () => {
                const GoogleAuth = window.gapi.auth2.getAuthInstance();
                if (shouldSave) {
                    const onSuccess =
                        mode === "login"
                            ? callbacks.onGoogleLoginSuccess
                            : callbacks.onGoogleVerifySuccess;
                    GoogleAuth.attachClickHandler(
                        "google-login-button",
                        {},
                        onSuccess,
                        callbacks.onGoogleLoginFailure
                    );
                } else {
                    GoogleAuth.signIn()
                        .then(onSignIn)
                        .catch(callbacks.onGoogleLoginFailure);
                }
            },
            onGoogleLoginFailure: ({ error }) => {
                if (
                    error !==
                    "Could not attach click handler to the element. Reason: element not found."
                ) {
                    showNotification({
                        text: "Google Login failed. You may need to disable any ad or pop-up blocker you have installed.",
                        type: "warning",
                    });
                    toggleButtonLoading(false);
                }
            },
            onGoogleLoginSuccess: (user) => Google.login(user, callbacks),
            onGoogleVerifySuccess: (user) => Google.verify(user, callbacks),
        };
        Google.initClient(callbacks);
    }

    static login(user, callbacks) {
        if (user) {
            const { showNotification, toggleButtonLoading } = callbacks;

            // Get access token
            const authResponse = user.getAuthResponse();
            const accessToken = authResponse.id_token;

            // Get user profile information
            const profile = user.getBasicProfile();
            const email = profile.getEmail();
            const firstName = profile.getGivenName();
            const lastName = profile.getFamilyName();

            if (accessToken) {
                const userData = {
                    accessToken,
                    email,
                    first_name: firstName,
                    last_name: lastName,
                    userID: "",
                };
                Google.socialSignOn(userData, callbacks);
            } else {
                showNotification({
                    text: "Google Login failed. You may need to disable any ad or pop-up blocker you have installed.",
                    type: "warning",
                });
                toggleButtonLoading(false);
            }
        }
    }

    static logout() {
        const callbacks = {
            onInitClient: () => {
                const GoogleAuth = window.gapi.auth2.getAuthInstance();
                GoogleAuth.signOut().then(emptyFunction);
            },
        };
        Google.initClient(callbacks);
    }

    static socialSignOn(googleUserData, callbacks) {
        const { onLoginSuccess } = callbacks;
        Auth.socialSignOn("google", googleUserData, (res) => {
            localStorage.setItem("authProvider", "google");
            onLoginSuccess(res, googleUserData);
        });
    }

    static verify(user, callbacks) {
        if (user) {
            const { onVerifySuccess, showNotification, toggleButtonLoading } =
                callbacks;

            // Get access token
            const authResponse = user.getAuthResponse();
            const accessToken = authResponse.id_token;

            if (accessToken) {
                const googleUserData = {
                    accessToken,
                    userID: "",
                };
                onVerifySuccess(googleUserData);
            } else {
                showNotification({
                    text: "Google Login failed. You may need to disable any ad or pop-up blocker you have installed.",
                    type: "warning",
                });
                toggleButtonLoading(false);
            }
        }
    }
}
