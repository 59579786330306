import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

// Components
import { ButtonSecondary } from "components/Buttons/Buttons";
import { HeaderBar } from "../../../../components/HeaderBar/HeaderBar";
import { LetterPreview } from "../../../../components/LetterPreview/LetterPreview";
import { LettersSentMenuUSPSNotification } from "./LettersSentMenuUSPSNotification";
import { LoadingSection } from "../../../../components/Loading/Loading";

// Context
import { HeaderBarContext } from "context/headerBar";
import { NotificationBarContext } from "../../../../context/notificationBar";

// CSS
import "../../../../css/letters/scenes/Sent/letters-sent.scss";

// Enums
import { LetterType } from "enums/LetterType";

// Hooks
import { Fade } from "../../../../hooks/Fade";
import { useDocumentTitle } from "@uidotdev/usehooks";

// Platforms
import { SandboxxRestAPI } from "../../../../utils/sandboxx";

// Utils
import { emptyFunction } from "../../../../utils/miscUtils";
import { scrollToTop } from "../../../../utils/scrollUtils";

// Constants
const showUSPSModalLocal = localStorage.getItem("sandboxxHideUSPSNotification");
const showUSPSModalSession = sessionStorage.getItem(
    "sandboxxHideUSPSNotification"
);

export const LettersSentMenu = (props) => {
    const { history, location } = props;

    /**
     * Custom Hooks
     **/

    useDocumentTitle("Sandboxx - Letters");

    /**
     * useContext
     **/

    const { setHeaderBarContent } = useContext(HeaderBarContext);
    const { showNotification } = useContext(NotificationBarContext);

    /**
     * useState
     **/

    const [cursorReplied, setCursorReplied] = useState("");
    const [cursorSent, setCursorSent] = useState("");
    const [lettersReplied, setLettersReplied] = useState(null);
    const [lettersSent, setLettersSent] = useState(null);
    const [loading, setLoading] = useState({
        lettersReplied: false,
        lettersSent: false,
        showMoreReplied: false,
        showMoreSent: false,
    });
    const [showUSPSNotification, setShowUSPSNotification] = useState(false);
    const [showUSPSPrompt, setShowUSPSPrompt] = useState(false);

    /**
     * useEffect
     **/

    useEffect(() => {
        setHeaderBarContent({ backPathname: "/letters", text: "History" });
        scrollToTop();
        if (!showUSPSModalLocal && !showUSPSModalSession) {
            setShowUSPSNotification(true);
        }
        if (!lettersSent) {
            if (location.state && location.state.lettersSent) {
                setCursorSent(location.state.cursorSent);
                setLettersSent(location.state.lettersSent);
            } else {
                fetchLettersSent();
            }
        }
        if (!lettersReplied) {
            if (location.state && location.state.lettersReplied) {
                setCursorReplied(location.state.cursorReplied);
                setLettersReplied(location.state.lettersReplied);
            } else {
                fetchLettersReplied();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Constants
     **/

    const lettersMenuListClass =
        lettersSent && lettersSent.length
            ? ""
            : "letters-sent__menu__list--empty";

    /**
     * End Hooks
     **/

    function fetchLettersReplied() {
        setLoading((prevLoading) => ({
            ...prevLoading,
            lettersReplied: true,
            showMoreReplied: true,
        }));
        return SandboxxRestAPI.getLettersReplied(
            cursorReplied,
            onFetchLettersRepliedSuccess,
            onFetchLettersRepliedError,
            onFetchLettersRepliedError
        );
    }

    function fetchLettersSent() {
        setLoading((prevLoading) => ({
            ...prevLoading,
            lettersSent: true,
            showMoreSent: true,
        }));
        return SandboxxRestAPI.getLettersSent(
            cursorSent,
            onFetchLettersSentSuccess,
            onFetchLettersSentError,
            onFetchLettersSentError
        );
    }

    function handleHideUSPSNotification({ isTemporary = true }) {
        if (isTemporary) {
            localStorage.removeItem("sandboxxHideUSPSNotification");
            sessionStorage.setItem("sandboxxHideUSPSNotification", true);
        } else {
            localStorage.setItem("sandboxxHideUSPSNotification", true);
            sessionStorage.removeItem("sandboxxHideUSPSNotification");
        }
        setShowUSPSNotification(false);
    }

    function onFetchLettersRepliedError(err) {
        showNotification({
            notificationBarText:
                "We are unable to fetch your replied letters at this time.",
            notificationBarType: "warning",
        });
    }

    function onFetchLettersSentError(err) {
        showNotification({
            text: "We are unable to fetch your sent letters at this time.",
            type: "warning",
        });
    }

    function onFetchLettersRepliedSuccess(res) {
        const { cursor, response } = res;
        const lettersUpdate =
            lettersReplied && cursor
                ? lettersReplied.concat(response)
                : response;
        setCursorReplied(cursor);
        setLettersReplied(lettersUpdate);
        setLoading((prevLoading) => ({
            ...prevLoading,
            lettersReplied: false,
            showMoreReplied: false,
        }));
    }

    function onFetchLettersSentSuccess(res) {
        const { cursor, response } = res;
        const lettersUpdate =
            lettersSent && cursor ? lettersSent.concat(response) : response;
        setCursorSent(cursor);
        setLettersSent(lettersUpdate);
        setLoading((prevLoading) => ({
            ...prevLoading,
            lettersSent: false,
            showMoreSent: false,
        }));
    }

    function renderLettersReplied() {
        if (lettersReplied && !lettersReplied.length) {
            return (
                <Fade show={lettersReplied}>
                    <div className="letters-sent__menu__list__no-letters">
                        <span className="letters-sent__menu__list__no-letters__text">
                            You haven't gotten any replied letters yet
                        </span>
                        <Link to="/letters/compose">
                            <button className="button button--primary">
                                <span>Start Writing</span>
                            </button>
                        </Link>
                    </div>
                </Fade>
            );
        }
        if (lettersReplied) {
            return (
                <Fade show={lettersReplied}>
                    {lettersReplied.map((letter) => {
                        const { mailboxxOrderId } = letter;
                        const linkParams = {
                            pathname: `/letters/sent/${mailboxxOrderId}`,
                            state: { letter, letterType: LetterType.REPLIED },
                        };
                        return (
                            <LetterPreview
                                key={letter.mailboxxOrderId}
                                history={history}
                                letter={letter}
                                letterType={LetterType.REPLIED}
                                linkParams={linkParams}
                            />
                        );
                    })}
                </Fade>
            );
        }
        return <LoadingSection />;
    }

    function renderLettersSent() {
        if (lettersSent && !lettersSent.length) {
            return (
                <Fade show={lettersSent}>
                    <div className="letters-sent__menu__list__no-letters">
                        <span className="letters-sent__menu__list__no-letters__text">
                            It looks like you have not sent a letter yet. Click
                            the button below to get started.
                        </span>
                        <Link to="/letters/compose">
                            <button className="button button--primary">
                                <span>Start Writing</span>
                            </button>
                        </Link>
                    </div>
                </Fade>
            );
        }
        if (lettersSent) {
            return (
                <Fade show={lettersSent}>
                    {lettersSent.map((letter) => {
                        const linkParams = {
                            pathname: `/letters/sent/${letter.id}`,
                            state: { letter, letterType: LetterType.SENT },
                        };
                        return (
                            <LetterPreview
                                key={letter.id}
                                history={history}
                                letter={letter}
                                letterType={LetterType.SENT}
                                linkParams={linkParams}
                            />
                        );
                    })}
                </Fade>
            );
        }
        return <LoadingSection />;
    }

    function renderShowMoreLettersReplied() {
        const onClick =
            cursorSent && !loading.showMoreReplied
                ? fetchLettersReplied
                : emptyFunction;
        return (
            lettersReplied &&
            cursorReplied && (
                <div
                    className="letters-sent__menu__list__show-more"
                    onClick={onClick}
                >
                    <span className="letters-sent__menu__list__show-more__text">
                        Show More
                        {loading.showMoreReplied && <LoadingSection />}
                    </span>
                </div>
            )
        );
    }

    function renderShowMoreLettersSent() {
        const onClick =
            cursorSent && !loading.showMoreSent
                ? fetchLettersSent
                : emptyFunction;
        return (
            lettersSent &&
            cursorSent && (
                <div
                    className="letters-sent__menu__list__show-more"
                    onClick={onClick}
                >
                    <span className="letters-sent__menu__list__show-more__text">
                        Show More
                        {loading.showMoreSent && <LoadingSection />}
                    </span>
                </div>
            )
        );
    }

    return (
        <div className="page">
            <HeaderBar text="Letters Feed" />
            <div className="letters-sent__menu">
                <div className="letters-sent__menu__grid">
                    <div
                        className={`letters-sent__menu__list panel ${lettersMenuListClass}`}
                    >
                        <div className="panel__header">
                            <span className="panel__header__text">SENT</span>
                        </div>
                        {renderLettersSent()}
                        {renderShowMoreLettersSent()}
                    </div>
                    <div
                        className={`letters-sent__menu__list panel ${lettersMenuListClass}`}
                    >
                        <div className="panel__header">
                            <span className="panel__header__text">REPLIED</span>
                        </div>
                        {renderLettersReplied()}
                        {renderShowMoreLettersReplied()}
                    </div>
                </div>
                <div className="button__container">
                    <Link to="/letters">
                        <ButtonSecondary text="Back" type="button" />
                    </Link>
                </div>
                <LettersSentMenuUSPSNotification
                    handleHideUSPSNotification={handleHideUSPSNotification}
                    setShowUSPSPrompt={setShowUSPSPrompt}
                    showUSPSNotification={showUSPSNotification}
                    showUSPSPrompt={showUSPSPrompt}
                />
            </div>
        </div>
    );
};
