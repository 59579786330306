export function areObjectsEqual(object1, object2) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (const key of keys1) {
        const val1 = object1[key];
        const val2 = object2[key];
        const areObjects = isObject(val1) && isObject(val2);
        if (
            (areObjects && !areObjectsEqual(val1, val2)) ||
            (!areObjects && val1 !== val2)
        ) {
            return false;
        }
    }

    return true;
}

export function areObjectsEqualStringified(object1, object2) {
    return JSON.stringify(object1) === JSON.stringify(object2);
}

export function checkAndConvertToArray(item) {
    return Array.isArray(item) ? item : [item];
}

/* istanbul ignore next */
export function convertToISODate(string) {
    const date = new Date(string);
    return date.toISOString();
}

export function convertNumberedObjectToArray(object) {
    return Object.keys(object)
        .filter((key) => {
            return Number.isInteger(parseInt(key));
        })
        .map((key) => {
            return object[key];
        });
}

// TODO: Needs to be updated to support conversion of hyphen and space to underscore
export function convertObjectToSnakeCase(object) {
    return Object.keys(object).reduce((acc, key) => {
        return {
            ...acc,
            [key.replace(/([A-Z])/g, function ($1) {
                return "_" + $1.toLowerCase();
            })]: object[key],
        };
    }, {});
}

/* istanbul ignore next */
export function emptyFunction() {
    return null;
}

export function isEmptyObject(object) {
    for (let key in object) {
        if (object.hasOwnProperty(key)) return false;
    }
    return true;
}

// TODO: Replace use of this with identically-named method currently in accessibilityUtils.js
/* istanbul ignore next */
export function isObject(param) {
    return typeof param === "object" && param !== null;
}

/* istanbul ignore next */
export function preventDefault(e) {
    e.preventDefault();
}
