import React, { useEffect } from "react";

// CSS
import styles from "./inbox-detail.module.scss";

// Interfaces
import IterableInboxManager from "interfaces/inbox/IterableInboxManager";

export const InboxDetail = (props) => {
    const { refresh, selectedMessage } = props;

    /**
     * useEffect
     */

    useEffect(() => {
        if (selectedMessage && !selectedMessage.isRead) {
            IterableInboxManager.markMessageAsRead(selectedMessage, {
                onSuccess: refresh,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedMessage]);

    /**
     * End Hooks
     */

    function renderEmpty() {
        return (
            <div className={styles.empty}>
                <span>No messages have been selected</span>
            </div>
        );
    }

    function renderSelectedMessage() {
        return (
            <div className={styles.detail}>
                {selectedMessage?.html && (
                    <div
                        dangerouslySetInnerHTML={{
                            __html: selectedMessage.html,
                        }}
                    />
                )}
            </div>
        );
    }

    return (
        <div className={styles.detail}>
            {selectedMessage ? renderSelectedMessage() : renderEmpty()}
        </div>
    );
};
