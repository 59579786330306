import { useContext } from "react";

// Context
import { ContactsContext } from "context/contacts";

// Enums
import { ContactModalNavigation } from "scenes/Contacts/ContactModal/enums/ContactModalNavigation";

// Models
import { Contact } from "models/contacts/Contact";

export const useLettersComposeReviewRecipientSender = ({
    parentKey,
    recipient,
    sender,
    setRecipient,
}) => {
    /**
     * useContext
     */

    const {
        fetchContacts,
        fetchRecentContacts,
        toggleContactModal,
        setOnSubmitContactSuccessFinalCallback,
    } = useContext(ContactsContext);

    /**
     * Constants
     */

    const hasSenderAddress =
        sender &&
        sender.address.line1 &&
        sender.address.city &&
        sender.address.state &&
        sender.address.zipcode;
    const isRecipient = parentKey === "recipient";
    const isSender = parentKey === "sender";

    /**
     * End Hooks
     */

    function handleToggleContactModal() {
        toggleContactModal({
            contactToEdit: recipient,
            isWritingLetter: true,
            shouldShow: true,
            targetScreen: ContactModalNavigation.FORM,
            type: "edit",
        });
        setOnSubmitContactSuccessFinalCallback(() => onEditContactSuccess);
    }

    function onEditContactSuccess(res, { contactStaging }) {
        // Fetch latest contacts and recent contacts lists
        fetchContacts();
        fetchRecentContacts();

        // Save new contact locally
        const contact = new Contact({
            ...contactStaging,
            abContactId: res.abContactId,
        });
        localStorage.setItem(
            "sandboxxMessageRecipient",
            JSON.stringify(contact.generateContactObject())
        );
        setRecipient(contact);
    }

    return {
        handleToggleContactModal,
        hasSenderAddress,
        isRecipient,
        isSender,
    };
};
