import React, { useState } from "react";

// Components
import {
    ButtonInvisible,
    ButtonPrimary,
    ButtonSecondary,
} from "components/Buttons/Buttons";
import { DepModal } from "components/DepModal/DepModal";

// CSS
import styles from "../../sign-in.module.scss";

// Enums
import { NavigationEnum } from "scenes/SignIn/constants/constants";

// Platforms
import { CurrentUser } from "utils/sandboxx";

// Utils
import { generateDepLocationsTitle } from "utils/depUtils";
import { isEmptyObject } from "utils/miscUtils";

export const SignUpDepLocation = (props) => {
    const {
        depsLocations,
        setCurrentSection,
        setUserInfo,
        showNotification,
        userInfo,
    } = props;
    const { depLocation } = userInfo;

    /************************************************
     * useState
     ************************************************/

    const [shouldShowDepModal, setShouldShowDepModal] = useState(false);

    /************************************************
     * Constants
     ************************************************/

    const { branchId } = CurrentUser.getUser() || {};
    const depLocationsTitle = generateDepLocationsTitle(branchId);

    /************************************************
     * End Hooks
     ************************************************/

    function handleGoBack() {
        setUserInfo((prev) => ({
            ...prev,
            branchId: "",
            role: "",
        }));
        setCurrentSection(NavigationEnum.SIGN_UP_PERSONA_ROOT);
    }

    function handleSubmit() {
        if (!validateBeforeSubmit()) {
            showNotification({
                text: "Please select a DEP location.",
                type: "warning",
            });
        } else {
            setCurrentSection(NavigationEnum.SIGN_UP_BASE);
        }
    }

    function onSetDepLocation(res, depLocation) {
        setUserInfo((prevUserInfo) => ({
            ...prevUserInfo,
            depLocation,
        }));
        setShouldShowDepModal(false);
    }

    function validateBeforeSubmit() {
        return !!depLocation;
    }

    function renderButtons() {
        const isDisabled = isEmptyObject(depLocation);
        return (
            <div className={styles.buttons}>
                <ButtonInvisible
                    classes={(styles.button, styles["button--invisible"])}
                    onClick={handleGoBack}
                    text="Back"
                    type="button"
                />
                <ButtonPrimary
                    classes={styles.button}
                    cypressTestId="sign-up-dep-location-next-button"
                    isDisabled={isDisabled}
                    onClick={handleSubmit}
                    text="Next"
                    type="button"
                />
            </div>
        );
    }

    function renderDepDisplay() {
        const { locale, office } = depLocation;
        const buttonText = isEmptyObject(depLocation)
            ? "Select A Location"
            : "Change Location";
        return (
            <div className={styles["dep-location"]}>
                {!isEmptyObject(depLocation) && (
                    <div className={styles.selection}>
                        <span className={styles.description}>
                            You Have Selected
                        </span>
                        <span className={styles.title}>
                            {locale} • {office}
                        </span>
                    </div>
                )}
                <ButtonSecondary
                    classes={styles.button}
                    cypressTestId="sign-up-dep-location-button"
                    onClick={() => setShouldShowDepModal(true)}
                    text={buttonText}
                    type="button"
                />
            </div>
        );
    }

    return (
        <div className={styles.content}>
            <DepModal
                depsLocations={depsLocations}
                handleShowDepModal={setShouldShowDepModal}
                onSetDepLocation={onSetDepLocation}
                shouldShow={shouldShowDepModal}
            />
            <div className={styles.top}>
                <div className={styles.header}>
                    <span className={styles.text}>{depLocationsTitle}</span>
                    <span className={styles["text-secondary"]}>
                        Click the button below to select the location you are
                        signing up from.
                    </span>
                </div>
                {renderDepDisplay()}
            </div>
            <div className={styles.bottom}>{renderButtons()}</div>
        </div>
    );
};
