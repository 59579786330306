export function generateButtonText(addOn, hasGiftCard, isGiftCard) {
    if (isGiftCard) {
        if (hasGiftCard) {
            return "Remove";
        } else {
            return "Add";
        }
    } else {
        if (addOn.isSelected) {
            return "Remove";
        } else {
            return "Add";
        }
    }
}
