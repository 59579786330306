import React, { useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// CSS
import styles from "./support-squad-settings-modal-manage-members.module.scss";

// Components
import { ButtonPrimary, ButtonInvisible } from "components/Buttons/Buttons";
import { SupportSquadSettingsModalManageUserListItem } from "../SupportSquadSettingsModalManageUserListItem/SupportSquadSettingsModalManageUserListItem";
import { WarningCircleIcon } from "components/Icons/WarningCircleIcon";

// Utils
import { handleKeyDown } from "utils/eventUtils";

export const SupportSquadSettingsModalManageMembers = (props) => {
    const {
        isUpdatingMembers,
        removeSquadMembers,
        setCurrentManageSection,
        squadMembers,
        selectedSquad,
    } = props;

    /**
     * useState
     **/

    const [showConfirmation, setShowConfirmation] = useState(false);
    const [selectedMembers, setSelectedMembers] = useState([]);
    const [shouldShowRemoveMemberLoading, setShouldShowRemoveMemberLoading] =
        useState(false);

    /**
     * End Hooks
     **/

    function handleChangeCheckbox(member) {
        const result = selectedMembers.filter(
            (item) => item.memberId === member.memberId
        );
        result.length ? unselectMember(member) : selectMember(member);
    }

    function handleRemoveMember() {
        setShouldShowRemoveMemberLoading(true);
        removeSquadMembers(
            selectedMembers,
            selectedSquad.squadId,
            removeMemberSuccess
        );
    }

    function removeMemberSuccess() {
        setShouldShowRemoveMemberLoading(false);
        setShowConfirmation(false);
        setCurrentManageSection("default");
        setSelectedMembers([]);
    }

    function unselectMember(memberId) {
        setSelectedMembers((prev) =>
            prev.filter((item) => item.memberId === memberId)
        );
    }

    function selectMember(member) {
        setSelectedMembers((prev) => [...prev, member]);
    }

    function getIsChecked(member) {
        const result = selectedMembers.filter(
            (item) => item.memberId === member.memberId
        );
        return result.length ? true : false;
    }

    function renderOptions() {
        return (
            <div
                className={styles.supportSquadSettingsModalManageMembers}
                data-cy="support-squad-settings-modal-manage-members"
            >
                <div className={styles.header}>
                    <span className={styles.label}>Members</span>
                    <span
                        className={styles.editOption}
                        data-cy="manange-modal-edit-members-cancel-button"
                        onClick={() => setCurrentManageSection("default")}
                        onKeyDown={(e) =>
                            handleKeyDown(e, () =>
                                setCurrentManageSection("default")
                            )
                        }
                        tabIndex="0"
                    >
                        Cancel
                    </span>
                </div>
                <div className={styles["members-list"]}>
                    {squadMembers.map((member, i) => {
                        return (
                            <SupportSquadSettingsModalManageUserListItem
                                checkbox={{
                                    checked: getIsChecked(member),
                                    onChange: () =>
                                        handleChangeCheckbox(member),
                                    shouldShow: true,
                                    type: "warning",
                                }}
                                index={i}
                                isLoading={isUpdatingMembers}
                                key={member.memberId}
                                user={member}
                            />
                        );
                    })}
                </div>
                <ButtonPrimary
                    classes={cx(
                        styles["remove-button"],
                        "button--primary-warning"
                    )}
                    cypressTestId="manage-modal-edit-members-confirm-button"
                    isDisabled={!selectedMembers.length}
                    onClick={() => setShowConfirmation(true)}
                    text="Remove Members"
                    type="button"
                />
            </div>
        );
    }

    function renderConfirmation() {
        return (
            <div className={styles.confirmation}>
                <WarningCircleIcon
                    className={styles["warning-icon"]}
                    height="45px"
                    width="45px"
                />
                <div className={styles.text}>
                    <span className={styles.header}>Remove from group?</span>
                    <span className={styles.description}>
                        Are you sure you want to remove{" "}
                        {selectedMembers.map(
                            (item) => item.memberFullName + ", "
                        )}{" "}
                        from {selectedSquad.supporteeFirstName}'s Support Group?
                    </span>
                </div>
                <div className={styles["confirmation-buttons"]}>
                    <ButtonPrimary
                        classes={cx(
                            styles["confirm-remove-button"],
                            "button--primary-warning"
                        )}
                        isDisabled={shouldShowRemoveMemberLoading}
                        onClick={handleRemoveMember}
                        text={
                            shouldShowRemoveMemberLoading
                                ? "Removing..."
                                : "Remove"
                        }
                        type="button"
                    />
                    <ButtonInvisible
                        classes={styles["confirm-cancel-button"]}
                        onClick={() => setShowConfirmation(false)}
                        text="Cancel"
                        type="button"
                    />
                </div>
            </div>
        );
    }

    return showConfirmation ? renderConfirmation() : renderOptions();
};

SupportSquadSettingsModalManageMembers.propTypes = {
    isUpdatingMembers: PropTypes.bool.isRequired,
    removeSquadMembers: PropTypes.func.isRequired,
    setCurrentManageSection: PropTypes.func.isRequired,
    squadMembers: PropTypes.array.isRequired,
    selectedSquad: PropTypes.object.isRequired,
};
