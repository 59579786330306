import React from "react";

// Components
import { ButtonPrimary } from "components/Buttons/Buttons";

// CSS
import styles from "../phone-verification-modal.module.scss";

export const PhoneVerificationModalCompleteLettersConnection = (props) => {
    const { connection, handleGoToLetterCompose } = props;

    return (
        <div className={styles["complete-letters-connection"]}>
            <h3 className={styles.header}>
                Congratulations, we've connected you to your recruit
            </h3>
            <span className={styles.description}>
                You can start writing letters to
            </span>
            <span className={styles.recruit}>{connection?.fullName}</span>
            <ButtonPrimary
                color="orange"
                onClick={handleGoToLetterCompose}
                text="Continue Writing"
                type="button"
            />
        </div>
    );
};
