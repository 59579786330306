import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router";

// Components
import { HeaderBar } from "components/HeaderBar/HeaderBar";
import { ImageCropModal } from "components/ImageCropModal/ImageCropModal";
import { SupportSquadDeleteModal } from "./components/SupportSquadDeleteModal/SupportSquadDeleteModal";
import { SupportSquadDetail } from "./components/SupportSquadDetail/SupportSquadDetail";
import { SupportSquadDonateTokensModal } from "./components/SupportSquadDonateTokensModal/SupportSquadDonateTokensModal";
import { SupportSquadJoinModal } from "./components/SupportSquadJoinModal/SupportSquadJoinModal";
import { SupportSquadLeaveModal } from "./components/SupportSquadLeaveModal/SupportSquadLeaveModal";
import { SupportSquadList } from "./components/SupportSquadList/SupportSquadList";
import { SupportSquadNoTokensModal } from "./components/SupportSquadNoTokensModal/SupportSquadNoTokensModal";
import { SupportSquadSettingsModal } from "./components/SupportSquadSettingsModal/SupportSquadSettingsModal";

// Context
import { BundlesContext } from "context/bundles";
import { ContactsContext } from "../../context/contacts";
import { CreditsContext } from "context/credits";
import { HeaderBarContext } from "../../context/headerBar";
import { PurchaseContext } from "context/purchase";

// CSS
import styles from "./support-squad.module.scss";

// Enums
import { ImageAspectRatio } from "components/ImageCropModal/enums/ImageAspectRatio";

// Hooks
import { useDocumentTitle } from "@uidotdev/usehooks";
import { useSupportSquad } from "./hooks/useSupportSquad";
import { useVisibleDimensions } from "hooks/viewport/useVisibleDimensions";

// Loggers
import { AnalyticsLogger } from "loggers/AnalyticsLogger";

// Models
import { Contact } from "models/contacts/Contact";

// Utils
import { emptyFunction } from "utils/miscUtils";

export const SupportSquad = (props) => {
    /**
     * Router Hooks
     **/

    const history = useHistory();

    /**
     * useContext
     **/

    const { fetchBundles } = useContext(BundlesContext);
    const {
        contacts,
        fetchContacts,
        loading: loadingContacts,
    } = useContext(ContactsContext);
    const { credits, fetchCredits } = useContext(CreditsContext);
    const { setHeaderBarContent } = useContext(HeaderBarContext);
    const {
        fetchRecentPaymentMethod,
        fetchSavedCards,
        setOnCompletePurchaseFlow,
        setPurchaseMode,
        setShowPurchaseModal,
    } = useContext(PurchaseContext);

    /**
     * Custom Hooks
     */

    const [pageBodyRef, pageBodyVisibleDimensions] = useVisibleDimensions();

    useDocumentTitle("Sandboxx - Support Squad");

    const {
        changeSupportSquadRole,
        currentSection,
        deleteRequestPending,
        deleteSquad,
        fetchSquads,
        fetchSquadMembers,
        handleImageUpload,
        handleSelectSquad,
        isCreatingSquad,
        isFetchingSquad,
        isFetchingSquads,
        isUpdatingMembers,
        isUpdatingSquad,
        isUploadingImage,
        joinSupportSquad,
        leaveSquad,
        removeSquadMembers,
        selectedUserListItem,
        setCurrentSection,
        setSelectedSquad,
        setSelectedUserListItem,
        setShouldShowCreateModal,
        setShouldShowDeleteModal,
        setShouldShowDonateTokensModal,
        setShouldShowImageCropModal,
        setShouldShowJoinModal,
        setShouldShowLeaveModal,
        setShouldShowNoTokensModal,
        setSquadName,
        setSquadRecipientId,
        setSupportSquadImage,
        shouldShowCreateModal,
        shouldShowDeleteModal,
        shouldShowDonateTokensModal,
        shouldShowImageCropModal,
        shouldShowJoinModal,
        shouldShowLeaveModal,
        shouldShowNoTokensModal,
        squadAdmins,
        squadList,
        squadMembers,
        squadRecipientId,
        squadName,
        selectedSquad,
        supportSquadImage,
        validateAndSubmit,
    } = useSupportSquad(props);

    /**
     * useEffect
     **/

    useEffect(() => {
        // Analytics
        AnalyticsLogger.logSquadGroupsViewed();

        // Fetch calls
        fetchBundles();
        fetchContacts();
        fetchCredits();
        fetchRecentPaymentMethod();
        fetchSavedCards();
        fetchSquads();

        // State calls
        setHeaderBarContent({
            textSecondary: "Create and manage your Support Squads",
            text: "Support Squads",
        });

        setOnCompletePurchaseFlow(
            () => () => setShouldShowDonateTokensModal(true)
        );
        setPurchaseMode("supportSquad");

        // Clean-up
        return () => {
            setOnCompletePurchaseFlow(() => emptyFunction);
            setPurchaseMode("letters");
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        contacts.sort(({ abContactId }) => (abContactId ? -1 : 1));
    }, [contacts]);

    /**
     * End Hooks & Constants
     */

    function handleClickSendLetter() {
        if (selectedSquad.tokenCount <= 0) {
            setShouldShowNoTokensModal(true);
        } else {
            handleGoToLetterCompose();
        }
    }

    function handleGoToLetterCompose() {
        AnalyticsLogger.logSquadSendLetter();
        const contactPayload = {
            address: selectedSquad.supporteeAddress,
            firstName: selectedSquad.supporteeFirstName,
            fullName: selectedSquad.supporteeFullName,
            lastName: selectedSquad.supporteeLastName,
            squadId: selectedSquad.squadId,
            title: selectedSquad.supporteeTitle,
        };
        const contact = new Contact(contactPayload);
        localStorage.setItem(
            "sandboxxMessageRecipient",
            JSON.stringify(contact.generateContactObject())
        );
        history.push("/letters/compose");
    }

    function handleImageCancel() {
        setSupportSquadImage({
            imageFile: null,
            imageName: null,
            imagePreviewUrl: null,
        });
        setShouldShowImageCropModal(false);
    }

    function handleSaveCroppedImage(croppedImage) {
        const { imageFile, imagePreviewUrl } = croppedImage;
        setSupportSquadImage((prev) => ({
            ...prev,
            imageFile,
            imagePreviewUrl,
        }));
        setShouldShowImageCropModal(false);
        setShouldShowCreateModal(true);
    }

    function handleTriggerPurchaseFlow(e) {
        e.preventDefault();
        setShouldShowDonateTokensModal(false);
        setShowPurchaseModal(true);
    }

    return (
        <div className="page">
            <ImageCropModal
                aspect={ImageAspectRatio.SUPPORT_SQUAD}
                finalWidth={1200}
                handleImageCancel={handleImageCancel}
                handleSaveCroppedImage={handleSaveCroppedImage}
                imagePreview={supportSquadImage}
                mode="supportSquad"
                showImageCropModal={shouldShowImageCropModal}
            />
            <SupportSquadSettingsModal
                changeSupportSquadRole={changeSupportSquadRole}
                currentSection={currentSection}
                fetchSquadMembers={fetchSquadMembers}
                handleImageUpload={handleImageUpload}
                isCreatingSquad={isCreatingSquad}
                isFetchingSquads={isFetchingSquads}
                isUpdatingMembers={isUpdatingMembers}
                isUpdatingSquad={isUpdatingSquad}
                isUploadingImage={isUploadingImage}
                loadingContacts={loadingContacts}
                removeSquadMembers={removeSquadMembers}
                selectedUserListItem={selectedUserListItem}
                setCurrentSection={setCurrentSection}
                setSelectedUserListItem={setSelectedUserListItem}
                setShouldShowDeleteModal={setShouldShowDeleteModal}
                setShouldShowImageCropModal={setShouldShowImageCropModal}
                setShouldShowCreateModal={setShouldShowCreateModal}
                setSquadName={setSquadName}
                setSquadRecipientId={setSquadRecipientId}
                setSupportSquadImage={setSupportSquadImage}
                shouldShowCreateModal={shouldShowCreateModal}
                squadAdmins={squadAdmins}
                squadMembers={squadMembers}
                squadName={squadName}
                squadRecipientId={squadRecipientId}
                selectedSquad={selectedSquad}
                supportSquadImage={supportSquadImage}
                validateAndSubmit={validateAndSubmit}
            />
            <SupportSquadDeleteModal
                currentSection={currentSection}
                deleteRequestPending={deleteRequestPending}
                deleteSquad={deleteSquad}
                removeSquadMembers={removeSquadMembers}
                setCurrentSection={setCurrentSection}
                setShouldShowDeleteModal={setShouldShowDeleteModal}
                shouldShowDeleteModal={shouldShowDeleteModal}
                squadMembers={squadMembers}
                selectedSquad={selectedSquad}
            />
            <SupportSquadDonateTokensModal
                {...props}
                credits={credits}
                fetchCredits={fetchCredits}
                handleSelectSquad={handleSelectSquad}
                handleTriggerPurchaseFlow={handleTriggerPurchaseFlow}
                setShouldShowDonateTokensModal={setShouldShowDonateTokensModal}
                shouldShowDonateTokensModal={shouldShowDonateTokensModal}
                squadList={squadList}
                selectedSquad={selectedSquad}
            />
            <SupportSquadJoinModal
                joinSupportSquad={joinSupportSquad}
                setShouldShowJoinModal={setShouldShowJoinModal}
                shouldShowJoinModal={shouldShowJoinModal}
            />
            <SupportSquadLeaveModal
                leaveSquad={leaveSquad}
                setShouldShowLeaveModal={setShouldShowLeaveModal}
                shouldShowLeaveModal={shouldShowLeaveModal}
                selectedSquad={selectedSquad}
            />
            <SupportSquadNoTokensModal
                setShouldShowDonateTokensModal={setShouldShowDonateTokensModal}
                setShouldShowNoTokensModal={setShouldShowNoTokensModal}
                shouldShowNoTokensModal={shouldShowNoTokensModal}
            />
            <HeaderBar />
            <div className="page__body" ref={pageBodyRef}>
                <div className={styles["support-squad"]}>
                    <SupportSquadList
                        handleSelectSquad={handleSelectSquad}
                        isFetchingSquads={isFetchingSquads}
                        pageBodyVisibleDimensions={pageBodyVisibleDimensions}
                        setCurrentSection={setCurrentSection}
                        setShouldShowJoinModal={setShouldShowJoinModal}
                        setShouldShowCreateModal={setShouldShowCreateModal}
                        setSquadName={setSquadName}
                        setSelectedSquad={setSelectedSquad}
                        setSupportSquadImage={setSupportSquadImage}
                        squadList={squadList}
                        selectedSquad={selectedSquad}
                    />

                    <SupportSquadDetail
                        handleClickSendLetter={handleClickSendLetter}
                        handleGoToLetterCompose={handleGoToLetterCompose}
                        isFetchingSquad={isFetchingSquad}
                        setCurrentSection={setCurrentSection}
                        setShouldShowCreateModal={setShouldShowCreateModal}
                        setShouldShowDonateTokensModal={
                            setShouldShowDonateTokensModal
                        }
                        setShouldShowLeaveModal={setShouldShowLeaveModal}
                        setSquadName={setSquadName}
                        selectedSquad={selectedSquad}
                    />
                </div>
            </div>
        </div>
    );
};
