import React, { useContext } from "react";
import PropTypes from "prop-types";

// Components
import { ButtonSecondary } from "components/Buttons/Buttons";
import { Text } from "components/Text/Text";

// Context
import { CartContext } from "context/Cart/cart";

// CSS
import styles from "./cart-shipping-address-display.module.scss";

// Enums
import { CartNavigation } from "components/Cart/enums/CartNavigation";

export const CartShippingAddressDisplay = ({ setCartCurrentSection }) => {
    /**
     * useContext
     */

    const { cart, isUpdatingCart, isSubmittingCart } = useContext(CartContext);

    /**
     * End Hooks
     */
    return (
        <div className={styles.cartShippingAddressDisplay}>
            <div className={styles.address}>
                <Text isBold>
                    {cart.shipping_address?.first_name}{" "}
                    {cart.shipping_address?.last_name}
                </Text>
                <Text>{cart.shipping_address?.address_1}</Text>
                {cart.shipping_address?.address_2 && (
                    <Text>{cart.shipping_address?.address_2}</Text>
                )}
                <Text>
                    {cart.shipping_address?.city},{" "}
                    {cart.shipping_address?.province}{" "}
                    {cart.shipping_address?.postal_code}
                </Text>
                <Text>{cart.shipping_address?.phone}</Text>
            </div>
            <div className={styles.buttons}>
                <ButtonSecondary
                    isDisabled={isSubmittingCart || isUpdatingCart}
                    onClick={() =>
                        setCartCurrentSection(
                            CartNavigation.SHIPPING_ADDRESS_FORM
                        )
                    }
                >
                    <Text>Edit</Text>
                </ButtonSecondary>
            </div>
        </div>
    );
};

CartShippingAddressDisplay.propTypes = {
    setCartCurrentSection: PropTypes.func.isRequired,
};
