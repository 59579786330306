import React, { useEffect } from "react";
import cx from "classnames";

// Components
import { ButtonPrimary } from "components/Buttons/Buttons";
import { PasswordInput } from "components/Inputs/PasswordInput/PasswordInput";
import { TextFieldValidated } from "components/Inputs/TextFieldValidated/TextFieldValidated";

// CSS
import signInStyles from "../../../sign-in.module.scss";
import signUpNameStyles from "./sign-up-name.module.scss";

// Hooks
import { useSignUpName } from "./hooks/useSignUpName";

// Utils
import { CurrentUser } from "utils/sandboxx";

export const SignUpName = (props) => {
    const {
        isUsingEmail,
        isUsingEmailVerify,
        setHasCompletedSignUpName,
        setUserInfo,
        userInfo,
    } = props;
    const { email, firstName, lastName, password } = userInfo;

    /**
     * Custom Hooks
     */

    const {
        handleSubmit,
        hasValidEmail,
        hasValidFirstName,
        hasValidForm,
        hasValidLastName,
        hasValidPassword,
        isLoadingDetailsUpdate,
        passwordType,
    } = useSignUpName(props, setHasCompletedSignUpName);

    /**
     * useEffect
     */

    useEffect(() => {
        // Check local storage for value, set state appropriately
        const { email } = CurrentUser.getUser();
        if (email) {
            setUserInfo((prev) => ({ ...prev, email }));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * End Hooks
     */

    function handleChange({ target }) {
        const { name, value } = target;
        setUserInfo({
            ...userInfo,
            [name]: value,
        });
    }

    function renderForm() {
        return (
            <form
                className={cx(signInStyles.form, signUpNameStyles.form, "form")}
                onSubmit={handleSubmit}
            >
                <input type="submit" hidden />
                <TextFieldValidated
                    autoFocus={true}
                    className={signUpNameStyles.input}
                    cypressTestId="sign-up-name-first-name-input"
                    id="firstName"
                    isValid={hasValidFirstName}
                    label="First Name"
                    name="firstName"
                    onChange={handleChange}
                    value={firstName}
                />
                <TextFieldValidated
                    className={signUpNameStyles.input}
                    cypressTestId="sign-up-name-last-name-input"
                    id="lastName"
                    isValid={hasValidLastName}
                    label="Last Name"
                    name="lastName"
                    onChange={handleChange}
                    value={lastName}
                />
                {!isUsingEmail && (
                    <TextFieldValidated
                        className={signUpNameStyles.input}
                        cypressTestId="sign-up-name-email-input"
                        disabled={isUsingEmailVerify}
                        id="email"
                        isValid={hasValidEmail}
                        label="Email"
                        name="email"
                        onChange={handleChange}
                        value={email}
                    />
                )}
                {!isUsingEmail && email && (
                    <PasswordInput
                        className={signUpNameStyles.input}
                        cypressTestId="sign-up-name-password-input"
                        id="password"
                        isValid={hasValidPassword}
                        label="Password (8+ Characters)"
                        name="password"
                        onChange={handleChange}
                        type={passwordType}
                        value={password}
                    />
                )}
                {renderSubmitButton()}
            </form>
        );
    }

    function renderSubmitButton() {
        const isDisabled = isLoadingDetailsUpdate || !hasValidForm;
        return isLoadingDetailsUpdate ? (
            <ButtonPrimary
                classes={signInStyles.button}
                isDisabled={isDisabled}
                text="Updating..."
                type="submit"
            />
        ) : (
            <ButtonPrimary
                classes={signInStyles.button}
                cypressTestId="sign-up-name-next-button"
                isDisabled={isDisabled}
                onClick={(e) => handleSubmit(e)}
                text="Sign Up"
                type="submit"
            />
        );
    }

    return (
        <div
            className={cx(
                signInStyles.content,
                signUpNameStyles["sign-up-name"]
            )}
        >
            <div className={signInStyles.top}>
                <div className={signInStyles.header}>
                    <span
                        className={signInStyles.text}
                        data-cy="sign-in-content-top-header-text"
                    >
                        Finish Signing Up
                    </span>
                    <span className={signInStyles["text-secondary"]}>
                        Whether you are heading off to basic training, an active
                        duty member of the military, or a supporter, Sandboxx is
                        here to support you through your military journey.
                    </span>
                </div>
                {renderForm()}
            </div>
        </div>
    );
};
