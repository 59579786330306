// Components
import { CountDownTimer } from "components/CountDownTimer/CountDownTimer";

// Utils
import { findTimeDifference } from "utils/dateTimeUtils";

export function prettifyTimeUntilCutoff(cutoffTime) {
    const { hours, minutes } = findTimeDifference(cutoffTime);
    const hoursPretty = Math.floor(hours);
    const minutesPretty = Math.floor(minutes);

    if (hours === 0) {
        return <CountDownTimer endTime={cutoffTime} />;
    }
    if (hours >= 1 && hours < 2) {
        return `${hoursPretty} hour and ${minutesPretty} minutes`;
    }
    if (hours >= 2) {
        return `${hoursPretty} hours`;
    }
}
