import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// Component
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import { Skeleton } from "@mui/material";
import { Text } from "components/Text/Text";

// CSS
import styles from "./support-squad-list-item.module.scss";

// Enums
import { SquadRole } from "scenes/SupportSquad/enums/SquadRole";

// Hooks
import { useSupportSquadListItem } from "./hooks/useSupportSquadListItem";

// Utils
import { handleKeyDown } from "utils/eventUtils";

export const SupportSquadListItem = ({
    handleSelectSquad,
    index,
    isLoading,
    isSelected,
    squad,
}) => {
    /**
     * Custom Hooks
     */

    const { isFetchingImage, membersText, resolvedImage } =
        useSupportSquadListItem({ squad });

    /**
     * End Hooks
     */

    function renderImage() {
        if (isFetchingImage || isLoading) {
            return <Skeleton className={styles.image} />;
        } else {
            return (
                <div
                    className={styles.image}
                    style={{ backgroundImage: `url(${resolvedImage})` }}
                />
            );
        }
    }

    function renderItem() {
        return (
            <div
                className={cx(styles.supportSquadListItem, {
                    [styles["supportSquadListItem--selected"]]: isSelected,
                })}
                data-cy={`support-squad-list-item-${index}`}
                id={`support-squad-list-item-${squad.squadId}`}
                onKeyDown={(e) =>
                    handleKeyDown(e, () => handleSelectSquad(squad))
                }
                onClick={() => {
                    handleSelectSquad(squad);
                }}
                tabIndex="0"
            >
                {renderImage()}
                <div className={styles.body}>
                    <div className={styles.header}>
                        <div className={styles.description}>
                            <Text classes={styles.name} isBold>
                                {squad.squadName}
                            </Text>
                            <Text classes={styles.memberCount}>
                                {squad.memberCount} {membersText}
                            </Text>
                        </div>
                        {squad.role === SquadRole.ADMIN && (
                            <div className={styles.icon}>
                                <AdminPanelSettingsOutlinedIcon />
                                <Text classes={styles.role}>Squad Admin</Text>
                            </div>
                        )}
                    </div>
                    <div className={styles.createdBy}>
                        <Text classes={styles.label}>Created by</Text>
                        <Text classes={styles.value}>{squad.createdBy}</Text>
                    </div>
                </div>
            </div>
        );
    }

    function renderLoading() {
        return (
            <div
                className={cx(
                    styles.supportSquadListItem,
                    styles["supportSquadListItem--loading"]
                )}
            >
                <Skeleton className={styles.image} />
                <div className={styles.body}>
                    <div className={styles.header}>
                        <div className={styles.description}>
                            <Skeleton width="75%" />
                            <Skeleton width="30%" />
                        </div>
                    </div>
                    <div className={styles.createdBy}>
                        <Skeleton width="20%" />
                        <Skeleton width="30%" />
                    </div>
                </div>
            </div>
        );
    }

    return isLoading ? renderLoading() : renderItem();
};

SupportSquadListItem.propTypes = {
    handleSelectSquad: PropTypes.func,
    index: PropTypes.number,
    isLoading: PropTypes.bool,
    isSelected: PropTypes.bool,
    squad: PropTypes.object,
};
