import React, { useEffect, useRef, useState } from "react";
import cx from "classnames";

// Components
import { Card } from "components/Card/Card";
import { ChevronLeftIcon } from "components/Icons/ChevronLeftIcon";
import { ChevronRightIcon } from "components/Icons/ChevronRightIcon";
import { MusterOverviewDocumentCarouselCard } from "./components/MusterOverviewDocumentCarouselCard";
import { RightArrowInCircleIcon } from "components/Icons/RightArrowInCircleIcon";
import { Text } from "components/Text/Text";

// CSS
import styles from "./muster-overview-document-carousel.module.scss";

export const MusterOverviewDocumentCarousel = ({
    history,
    musterDocuments,
}) => {
    /**
     * useState
     */
    const [canScrollLeft, setCanScrollLeft] = useState(true);
    const [canScrollRight, setCanScrollRight] = useState(true);
    const [scrollDistance, setScrollDistance] = useState(0);

    /**
     * useRef
     */

    const carouselRef = useRef([]);

    /**
     * useEffect
     */

    useEffect(() => {
        const box = carouselRef.current;
        const width = box.offsetWidth;

        setCanScrollLeft(-scrollDistance <= 0);
        setCanScrollRight(
            -scrollDistance >= (musterDocuments.length + 1) * 210 - width
        );
    }, [musterDocuments, scrollDistance]);

    /**
     * End Hooks
     */

    function handleNavigate(activeAccordionId = "") {
        history.push({
            pathname: "/waypoints/checklist",
            state: { activeAccordionId },
        });
    }

    return (
        <div className={styles.musterOverviewDocumentCarousel}>
            <div className={styles.header}>
                <Text type="h2">Document Checklist</Text>
                <Text
                    classes={styles.button}
                    onClick={() => history.push("/waypoints/checklist")}
                    type="h4"
                >
                    {"See All >"}
                </Text>
            </div>
            <div
                className={styles.carousel}
                data-cy="muster-overview-document-carousel"
                ref={carouselRef}
            >
                <button
                    className={cx(styles.arrow, styles["arrow--left"], {
                        [styles["arrow--hidden"]]: canScrollLeft,
                    })}
                    data-cy="letters-add-ons-carousel-shift-left-button"
                    onClick={() => setScrollDistance(scrollDistance + 210)}
                >
                    <ChevronLeftIcon height="24px" width="24px" />
                </button>
                <div className={styles.cardHolder}>
                    <div
                        className={styles.scrollContainer}
                        style={{ transform: `translateX(${scrollDistance}px)` }}
                    >
                        <div className={styles.cardContainer}>
                            <Card
                                cypressTestId={`muster-document-card`}
                                onClick={() =>
                                    history.push("/waypoints/checklist")
                                }
                            >
                                <RightArrowInCircleIcon />
                                <div>
                                    <div>
                                        <Text type="h4">Get Prepared</Text>
                                    </div>
                                    <div className={styles.footerText}>
                                        Be a better recruit before you ship to
                                        training
                                    </div>
                                </div>
                            </Card>
                        </div>
                        {musterDocuments.map((collection, i) => {
                            return (
                                <MusterOverviewDocumentCarouselCard
                                    collection={collection}
                                    handleNavigate={handleNavigate}
                                    history={history}
                                    id={i}
                                    key={i}
                                />
                            );
                        })}
                    </div>
                </div>
                <button
                    className={cx(styles.arrow, styles["arrow--right"], {
                        [styles["arrow--hidden"]]: canScrollRight,
                    })}
                    data-cy="letters-add-ons-carousel-shift-right-button"
                    onClick={() => setScrollDistance(scrollDistance - 210)}
                >
                    <ChevronRightIcon height="24px" width="24px" />
                </button>
            </div>
        </div>
    );
};
