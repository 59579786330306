import React, { useRef } from "react";
import { Image } from "react-bootstrap";
import cx from "classnames";

// CSS
import "../../css/components/stripe/stripe.scss";

// Enums
import { LayoutEnum } from "./constants/constants";
import { PurchaseModalNavigation } from "../Purchase/enums/enums";
import {
    AddOnsNavigation,
    LettersComposeNavigation,
} from "../../scenes/Letters/scenes/Compose/enums/enums";
// Hooks
import { useIsOverflow } from "hooks/scroll/useIsOverflow";

// Utils
import { emptyFunction } from "../../utils/miscUtils";
import { handleKeyDown } from "utils/eventUtils";
import { generateCreditCardIconSrc } from "../../utils/purchaseUtils";

// Constants
const loadingIconSrc = require("media/shared/loader.gif").default;

export const SavedPaymentOptions = (props) => {
    const {
        config,
        context,
        changeCurrentSection,
        endItem,
        handleCardSelect,
        handleCloseModal,
        layout,
        handleSavedCardSelect,
        selectedCard,
        savedCards,
    } = props;

    /************************************************
     * useRef
     ************************************************/

    const containerRef = useRef(null);

    /************************************************
     * Custom Hooks
     ************************************************/

    const isContainerOverflow = useIsOverflow(containerRef, {
        isHorizontal: true,
    });

    /************************************************
     * End Hooks
     ************************************************/

    function renderLoadingContent() {
        return (
            <div className="new-modal-loading">
                <Image responsive src={loadingIconSrc} />
            </div>
        );
    }

    function renderSavedCards() {
        const noCardsDisplay = (
            <span className="stripe__saved-cards__no-cards">
                You do not have any saved cards.
            </span>
        );
        const savedCardsDisplay =
            savedCards &&
            savedCards.map((card, i) => {
                const selected =
                    selectedCard &&
                    selectedCard.card &&
                    selectedCard.index === i;
                return (
                    <SavedPaymentOptionsOption
                        card={card}
                        changeCurrentSection={changeCurrentSection}
                        context={context}
                        endItem={endItem}
                        handleCardSelect={handleCardSelect}
                        handleCloseModal={handleCloseModal}
                        handleSavedCardSelect={handleSavedCardSelect}
                        index={i}
                        key={i}
                        layout={layout}
                        selected={selected}
                        selectedCard={selectedCard}
                    />
                );
            });
        return savedCards && savedCards.length
            ? savedCardsDisplay
            : noCardsDisplay;
    }

    return (
        <div>
            <div
                className={cx("stripe__saved-cards", {
                    "stripe__saved-cards--layout-card":
                        layout === LayoutEnum.CARD,
                    "stripe__saved-cards--layout-card--overflow":
                        isContainerOverflow,
                })}
                data-cy="saved-cards-container"
                ref={containerRef}
            >
                {!config.shouldHideHeader && (
                    <span className="form-header">Use a Saved Credit Card</span>
                )}
                {savedCards ? renderSavedCards() : renderLoadingContent()}
            </div>
        </div>
    );
};

SavedPaymentOptions.defaultProps = {
    config: {},
    format: null,
    handleCloseModal: emptyFunction,
};

export const SavedPaymentOptionsOption = (props) => {
    const {
        card,
        changeCurrentSection,
        classNames = {},
        config = {},
        context,
        endItem = {},
        handleCardSelect = emptyFunction,
        handleCloseModal,
        handleSavedCardSelect = emptyFunction,
        index,
        isSelectedCard,
        layout,
        selected,
        setSelectedCard = emptyFunction,
    } = props;

    /************************************************
     * Constants
     ************************************************/

    const { brand, expMonth, expYear, last4 } = isSelectedCard
        ? card.card
        : card;
    const creditCardIconSrc = generateCreditCardIconSrc(brand);
    const expirationDate = `${expMonth}/${expYear.toString().substring(2, 4)}`;
    const isContextCredits = context === "credits";
    const isContextWallet = context === "wallet";
    const maskedCardNumber =
        layout === LayoutEnum.CARD || config.shouldShowLongNumber
            ? `•••• •••• •••• ${last4}`
            : `x-${last4}`;

    /************************************************
     * End Hooks & Constants
     ************************************************/

    function handleGoToPaymentMethodSelect() {
        const nextSection = {
            recentPaymentCredits: PurchaseModalNavigation.PAYMENT,
            recentPaymentGiftCard: AddOnsNavigation.SELECT_PAYMENT,
            wallet: "SELECT_PAYMENT",
        }[context];
        changeCurrentSection(nextSection);
        setSelectedCard(null);
    }

    function handleSubmit() {
        const savedCardSelectCallback = (card) => {
            const nextSections = {
                credits: PurchaseModalNavigation.REVIEW,
                giftCard: LettersComposeNavigation.REVIEW,
                recentPaymentCredits: PurchaseModalNavigation.PAYMENT,
                recentPaymentGiftCard: AddOnsNavigation.SELECT_PAYMENT,
                wallet: "REVIEW",
            };
            handleCardSelect(card, true);
            if (isContextCredits || isContextWallet) {
                changeCurrentSection(nextSections[context]);
            } else {
                handleCloseModal();
            }
        };
        handleSavedCardSelect(card, index, savedCardSelectCallback);
    }

    function renderEndItem(index) {
        const { type } = endItem;
        if (type === "edit") {
            return (
                <div className="stripe__saved-cards__card__end-item">
                    <span
                        className="stripe__saved-cards__card__end-item__text link"
                        data-cy="change-payment-method"
                        onClick={handleGoToPaymentMethodSelect}
                        onKeyDown={(e) => {
                            handleKeyDown(e, handleGoToPaymentMethodSelect);
                        }}
                        tabIndex="0"
                    >
                        Change payment method
                    </span>
                </div>
            );
        }
        if (type === "select") {
            return (
                <div className="stripe__saved-cards__card__end-item">
                    <span
                        className="stripe__saved-cards__card__end-item__text link"
                        data-cy={`card-select-${index}`}
                        onClick={handleSubmit}
                        onKeyDown={(e) => {
                            handleKeyDown(e, handleSubmit);
                        }}
                        tabIndex="0"
                    >
                        Select
                    </span>
                </div>
            );
        }
        return "";
    }

    return (
        <div
            className={cx("stripe__saved-cards__card", classNames.container, {
                "stripe__saved-cards__card--layout-card":
                    layout === LayoutEnum.CARD,
                selected,
            })}
            data-cy={`saved-payment-options-option-${index}`}
            onClick={handleSubmit}
        >
            <div
                className={cx(
                    "stripe__saved-cards__card__icon",
                    classNames.icon
                )}
            >
                <img
                    alt={`${brand} icon`}
                    className="stripe__saved-cards__card__icon__image"
                    src={creditCardIconSrc}
                />
            </div>
            <div className="stripe__saved-cards__card__info">
                <span
                    className={cx(
                        "stripe__saved-cards__card__info__number",
                        classNames.cardNumber
                    )}
                >
                    {maskedCardNumber}
                </span>
                <span
                    className={cx(
                        "stripe__saved-cards__card__info__expiration",
                        classNames.expirationDate
                    )}
                >
                    {expirationDate}
                </span>
            </div>
            {renderEndItem(index)}
        </div>
    );
};
