import React from "react";

// Components
import { Panel } from "components/Panel/Panel";
import { ReferralsRewards } from "../ReferralsRewards/ReferralsRewards";
import { Text } from "components/Text/Text";

// CSS
import styles from "./referrals-instructions.module.scss";

export const ReferralsInstructions = () => {
    return (
        <Panel classes={styles.referralsInstructions}>
            <div className={styles.header}>
                <Text type="h1">How it works</Text>
            </div>
            <div className={styles.steps}>
                <div className={styles.step}>
                    <div className={styles.number}>
                        <span>1</span>
                    </div>
                    <div className={styles.text}>
                        <Text type="h3">🤝 Share your code</Text>
                        <Text type="body">
                            Invite friends and family to create an account
                        </Text>
                    </div>
                </div>
                <div className={styles.step}>
                    <div className={styles.number}>
                        <span>2</span>
                    </div>
                    <div className={styles.text}>
                        <Text type="h3">📫 They make a Letter purchase</Text>
                        <Text type="body">Whichever bundle works best</Text>
                    </div>
                </div>
                <div className={styles.step}>
                    <div className={styles.number}>
                        <span>3</span>
                    </div>
                    <div className={styles.text}>
                        <Text type="h3">🎉 Both of you earn rewards</Text>
                        <Text type="body">
                            One Letter for them and free letters for you
                        </Text>
                    </div>
                </div>
            </div>
            <ReferralsRewards />
        </Panel>
    );
};
