import React from "react";
import PropTypes from "prop-types";

// Components
import { Banner } from "components/Banner/Banner";
import { Checkbox } from "components/Inputs/Checkbox/Checkbox";
import { Text } from "components/Text/Text";

// Hooks
import { useLettersComposeReviewRecipientSender } from "./hooks/useLettersComposeReviewRecipientSender";

// Utils
import { handleKeyDown } from "utils/eventUtils";

export const LettersComposeReviewRecipientSender = (props) => {
    const {
        connected,
        editStatuses,
        hasAddedPostagePaidInSession,
        isEditing,
        isEligibleForPostagePaid,
        generateEditInputs,
        handleNameReverseToggle,
        loading,
        message,
        parentKey,
        recipient,
        sender,
        setRecipient,
        titles,
        toggleEditPanel,
    } = props;

    /**
     * Custom Hooks
     */

    const { handleToggleContactModal, hasSenderAddress, isSender } =
        useLettersComposeReviewRecipientSender({
            parentKey,
            recipient,
            sender,
            setRecipient,
        });

    /**
     * End Hooks
     */

    function renderEditLink(isEditingThis) {
        const dataSource = props[parentKey];
        const { connected, hasInitialAddress } = dataSource;
        const isRecipient = parentKey === "recipient";
        const editAction = isRecipient
            ? handleToggleContactModal
            : () => toggleEditPanel(parentKey);

        // Disable edit link if...
        // (a) User is editing a different panel
        // (b) User is not connected to recipient but does have an address
        // (c) Recipient is a Support Squad recipient
        if (
            (!isEditingThis && isEditing) ||
            (isRecipient && !connected && hasInitialAddress) ||
            (isRecipient && recipient.isSandboxxUser) ||
            (isRecipient && recipient.isSquadRecipient)
        ) {
            return (
                <span className="letters-compose__review__section__header__link link link-disabled">
                    Edit
                </span>
            );
        }

        // Display edit link if...
        // (a) User is not editing current panel
        // (b) Is recipient panel and does not have address, regardless of connected status
        else if (
            (!isEditingThis && !isEditing) ||
            (!isEditingThis && isRecipient && !connected && !hasInitialAddress)
        ) {
            return (
                <span
                    className="letters-compose__review__section__header__link link"
                    data-cy={`letters-compose-review-edit-${parentKey}`}
                    onClick={editAction}
                    onKeyDown={(e) => handleKeyDown(e, editAction)}
                    tabIndex="0"
                >
                    Edit
                </span>
            );
        }

        // Display "Done Editing" option if user is editing current panel
        else {
            return (
                <span
                    className="letters-compose__review__section__header__link link"
                    data-cy="letters-compose-review-done-editing"
                    onClick={editAction}
                    onKeyDown={(e) => handleKeyDown(e, editAction)}
                    tabIndex="0"
                >
                    Done Editing
                </span>
            );
        }
    }

    function renderMaskedAddressText() {
        const { address, hasInitialAddress } = recipient;
        const hasAddress = !!address.line1;
        return (
            !connected &&
            hasAddress &&
            hasInitialAddress && (
                <div className="letters-compose__review__recipient-sender-masked-address-text">
                    <span className="letters-compose__review__recipient-sender-masked-address-text-text">
                        Some parts of the recipient's address are intentionally
                        masked to protect their personally identifiable
                        information.
                    </span>
                </div>
            )
        );
    }

    function renderRecipientCheckbox() {
        return (
            <div className="letters-compose__review-checkbox-container checkbox-container">
                <Checkbox
                    cyId="letters-compose-review-reverse-name-checkbox"
                    checked={message.isNameReversed}
                    id="letters-compose-review-reverse-name-checkbox"
                    onChange={handleNameReverseToggle}
                    text="Switch first and last name"
                />
            </div>
        );
    }

    function renderRecipientSenderAddress(parentKey, dataSource) {
        const { address, connected, hasInitialAddress } = dataSource;
        const { line1, line2 } = address;
        const hasAddress = !!line1;
        const isRecipient = parentKey === "recipient";
        // Logic for masking address
        if (isRecipient && hasAddress && hasInitialAddress && !connected) {
            return (
                <>
                    <span>*****</span>
                    <span>*****</span>
                </>
            );
        }
        // Display address if it exists
        else if (hasAddress) {
            return (
                <>
                    <span>{line1}</span>
                    {line2 && <span>{line2}</span>}
                </>
            );
        }
        // Nothing returned if a user has no address, masked or otherwise
    }

    function renderRecipientSender() {
        const dataSource = props[parentKey];
        const addressDisplay = renderRecipientSenderAddress(
            parentKey,
            dataSource
        );
        const { address, connected, hasInitialAddress } = dataSource;
        const { city, state, zipcode } = address;
        const isEditing = editStatuses[parentKey];
        const isRecipient = parentKey === "recipient";
        const header = isRecipient ? "To" : "From";
        const name = renderRecipientSenderName(parentKey, dataSource);
        const zipcodeDisplay =
            connected || !isRecipient || !hasInitialAddress ? zipcode : "*****";
        const inputData = [
            { name: "firstName", placeholder: "First Name", type: "text" },
            { name: "lastName", placeholder: "Last Name", type: "text" },
            {
                name: "title",
                options: titles,
                placeholder: "Title (Optional)",
                type: "select",
            },
            { name: "line1", placeholder: "Address (Line 1)", type: "text" },
            { name: "line2", placeholder: "Address (Line 2)", type: "text" },
            { name: "city", placeholder: "City", type: "text" },
            { name: "state", placeholder: "State", type: "text" },
            { name: "zipcode", placeholder: "Zip Code", type: "text" },
        ];
        return isEditing ? (
            <div className="letters-compose__review__recipient-sender--editing letters-compose__review__section">
                <div className="letters-compose__review__section__header">
                    <span className="letters-compose__review__section__header__text">
                        {header}
                    </span>
                    {renderEditLink(isEditing, parentKey)}
                </div>
                <form className="letters-compose__review__recipient-sender__form form">
                    {generateEditInputs(inputData, parentKey)}
                    {isRecipient && renderRecipientCheckbox()}
                </form>
            </div>
        ) : (
            <div
                className="letters-compose__review__recipient-sender letters-compose__review__section"
                data-cy={`letters-compose-review-address-${isRecipient}`}
            >
                <div className="letters-compose__review__section__header">
                    <span className="letters-compose__review__section__header__text">
                        {header}
                    </span>
                    {renderEditLink(isEditing, parentKey)}
                </div>
                {!isRecipient && renderPostagePaidInvalidAddressBanner()}
                {name}
                {addressDisplay}
                {city && <span>{`${city}, ${state} ${zipcodeDisplay}`}</span>}
                {isRecipient && renderMaskedAddressText()}
                {renderNoAddressBanner()}
            </div>
        );
    }

    function renderNoAddressBanner() {
        return (
            isSender &&
            !hasSenderAddress &&
            !editStatuses["sender"] && (
                <Banner
                    classes="letters-compose__review__recipient-sender__banner-no-address"
                    config={{ shouldShowChevron: true, shouldShowIcon: true }}
                    onClick={() => toggleEditPanel("sender")}
                    theme="yellow"
                >
                    <Text isBold>What's your address?</Text>
                    <Text>
                        A return address is required to send a Letter. Please
                        add your address by clicking here.
                    </Text>
                </Banner>
            )
        );
    }

    function renderRecipientSenderName(parentKey, dataSource) {
        const { firstName, lastName, title } = dataSource;
        const { isNameReversed } = message;
        const isRecipient = parentKey === "recipient";
        const titleDisplay = title || "";

        if (isRecipient) {
            // If generating recipient name...
            const name = isNameReversed
                ? `${titleDisplay} ${lastName}, ${firstName}`
                : `${titleDisplay} ${firstName} ${lastName}`;
            return <span>{name}</span>;
        } else {
            // If generating sender name...
            return <span>{`${firstName} ${lastName}`}</span>;
        }
    }

    function renderPostagePaidInvalidAddressBanner() {
        const isBannerLoading = loading.addOns || loading.updateUserAddress;
        return (
            hasAddedPostagePaidInSession &&
            !isEligibleForPostagePaid && (
                <Banner
                    config={{ shouldShowClose: true }}
                    classes="letters-compose__review__recipient-sender__banner"
                    loading={isBannerLoading}
                    theme="yellow"
                >
                    <span>
                        Your sender address is not eligible for Reply Postage.
                        Please ensure you have a valid U.S. address.
                    </span>
                </Banner>
            )
        );
    }

    return renderRecipientSender();
};

LettersComposeReviewRecipientSender.propTypes = {
    connected: PropTypes.bool.isRequired,
    editStatuses: PropTypes.object.isRequired,
    hasAddedPostagePaidInSession: PropTypes.bool.isRequired,
    isEditing: PropTypes.bool,
    isEligibleForPostagePaid: PropTypes.bool,
    generateEditInputs: PropTypes.func.isRequired,
    handleNameReverseToggle: PropTypes.func.isRequired,
    loading: PropTypes.object.isRequired,
    message: PropTypes.object.isRequired,
    parentKey: PropTypes.string.isRequired,
    recipient: PropTypes.object.isRequired,
    sender: PropTypes.object.isRequired,
    titles: PropTypes.array.isRequired,
    toggleEditPanel: PropTypes.func.isRequired,
};
