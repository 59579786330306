import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// Components
import { Banner } from "components/Banner/Banner";
import SearchIcon from "@mui/icons-material/Search";
import { Text } from "components/Text/Text";
import { TextField } from "components/Inputs/TextField/TextField";
import { UserList } from "../UserList/UserList";

// CSS
import "../../css/components/user-search/user-search.scss";

// Hooks
import { useUserSearch } from "./hooks/useUserSearch";

// Utils
import { preventDefault } from "../../utils/miscUtils";
import { handleKeyDown } from "utils/eventUtils";
import { CurrentUser } from "utils/sandboxx";

export const UserSearch = (props) => {
    const { handleSelectSearchResult, isSearching, setIsSearching } = props;

    /**
     * Custom Hooks
     */

    const {
        handleSearchInputChange,
        handleToggleContactModal,
        isLoading,
        search,
        togglePhoneVerificationModal,
    } = useUserSearch({ setIsSearching });

    /**
     * Constants
     */

    const { term, users, validations } = search;
    const { hasResults } = validations;
    const searchResultsContainerClass =
        !hasResults || isLoading ? "user-list__message" : "user-list";

    /**
     * End Hooks & Constants
     */

    function renderLoading() {
        return (
            <>
                <img
                    alt="Binoculars"
                    className="loading-image"
                    src={require("../../media/loading/binoculars.png").default}
                />
                <Text type="h4">Searching for your contacts</Text>
            </>
        );
    }

    function renderNewRecipientOption() {
        return (
            <span
                className="user-search__new"
                data-cy="user-search-add-contact"
                onClick={handleToggleContactModal}
                onKeyDown={
                    /* istanbul ignore next */ (e) =>
                        handleKeyDown(e, handleToggleContactModal)
                }
                tabIndex="0"
            >
                <span className="material-icons">add</span>
                Add New Recipient
            </span>
        );
    }

    function renderMessage() {
        if (!isLoading && !hasResults) {
            return (
                <>
                    <img
                        alt="Drawing of soldier hand opening letter"
                        className="user-search__results--none-image"
                        data-cy="user-search-no-results-image"
                        src={
                            require("../../media/letters/artwork/letter-tutorial-tip-04.svg")
                                .default
                        }
                    />
                    <Text classes="user-list-message-text" type="p">
                        Can't find who you're looking for? Create a recipient by
                        clicking above.
                    </Text>
                </>
            );
        }
    }

    function renderPhoneVerificationBanner() {
        return (
            !CurrentUser.isVerified() && (
                <Banner
                    classes="user-search__banner"
                    config={{ isActive: true, shouldShowChevron: true }}
                    onClick={() =>
                        togglePhoneVerificationModal(true, "profile")
                    }
                    tabFlow={true}
                    theme="blue"
                >
                    <Text isBold>Connect with your recruit</Text>
                    <Text>Verify your phone number</Text>
                </Banner>
            )
        );
    }

    function renderResults() {
        return hasResults ? (
            <UserList
                config={{ hideItemsWithoutAddress: true }}
                isClickable={true}
                isLoading={isLoading}
                userList={users}
                handleUserSelect={handleSelectSearchResult}
            />
        ) : (
            renderMessage()
        );
    }

    return (
        <div className="user-search--letters">
            <div className="panel">
                <form
                    className="user-search__form form"
                    onSubmit={preventDefault}
                >
                    <TextField
                        className="searchField"
                        cypressTestId="user-search-input"
                        label="Search using email or phone number"
                        onChange={handleSearchInputChange}
                        startAdornment={<SearchIcon />}
                        value={term}
                    />
                </form>
                {renderNewRecipientOption()}
                {renderPhoneVerificationBanner()}
            </div>

            {isSearching && (
                <div
                    className={cx("user-search__results", "panel", {
                        "user-search__results--loading": isLoading,
                    })}
                >
                    <div className={searchResultsContainerClass}>
                        {!isLoading && renderResults()}
                        {isLoading && renderLoading()}
                    </div>
                </div>
            )}
        </div>
    );
};

UserSearch.propTypes = {
    handleSelectSearchResult: PropTypes.func.isRequired,
    isSearching: PropTypes.bool,
    setIsSearching: PropTypes.func,
};
