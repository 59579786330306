import React from "react";

// Components
import { Banner } from "components/Banner/Banner";
import { ButtonInvisible, ButtonPrimary } from "components/Buttons/Buttons";
import { DropdownState } from "components/Dropdowns/DropdownState/DropdownState";
import { Text } from "components/Text/Text";
import { TextField } from "components/Inputs/TextField/TextField";

// CSS
import styles from "./contact-modal-address-manual.module.scss";

// Enums
import { ContactModalNavigation } from "../../enums/ContactModalNavigation";

export const ContactModalAddressManual = ({
    contactStaging,
    handleChange,
    handleSubmit,
    setCurrentSection,
}) => {
    const { city, line1, line2, state, zipcode } = contactStaging.address;

    return (
        <div
            className={styles.contactModalAddressManual}
            onSubmit={handleSubmit}
        >
            <form className={styles.form}>
                <TextField
                    className={styles.input}
                    cypressTestId="contact-modal-address-manual-line-1"
                    label="Address Line 1"
                    name="line1"
                    onChange={handleChange}
                    required
                    type="text"
                    value={line1}
                />
                <TextField
                    className={styles.input}
                    cypressTestId="contact-modal-address-manual-line-2"
                    label="Address Line 2"
                    name="line2"
                    onChange={handleChange}
                    required
                    type="text"
                    value={line2}
                />
                <TextField
                    className={styles.input}
                    cypressTestId="contact-modal-address-manual-city"
                    label="City"
                    name="city"
                    onChange={handleChange}
                    required
                    type="text"
                    value={city}
                />
                <DropdownState
                    className={styles.input}
                    cypressTestId="contact-modal-address-manual-state"
                    name="state"
                    onChange={handleChange}
                    required
                    value={state}
                />
                <TextField
                    className={styles.input}
                    cypressTestId="contact-modal-address-manual-zipcode"
                    label="Zip Code"
                    name="zipcode"
                    onChange={handleChange}
                    required
                    type="text"
                    value={zipcode}
                />
                <Banner classes={styles.banner}>
                    <Text>
                        The recipient address must be complete or your letter
                        will be placed on hold. We recommend using our address
                        formatter assistant on the previous screen.{" "}
                        <a
                            className={styles.link}
                            href="https://www.sandboxx.us/blog/how-to-get-the-address-of-someone-in-basic-training/"
                            target="_blank"
                            rel="noreferrer"
                            style={{ textDecoration: "underline" }}
                        >
                            Click here
                        </a>{" "}
                        to learn more about getting the correct recipient
                        address.
                    </Text>
                </Banner>
                <div className={styles.buttons}>
                    <ButtonPrimary
                        cypressTestId="contact-modal-address-manual-submit-button"
                        isDisabled={!city || !line1 || !state || !zipcode}
                        onClick={handleSubmit}
                        text="Confirm Address"
                    />
                    <ButtonInvisible
                        onClick={() =>
                            setCurrentSection(
                                ContactModalNavigation.ADDRESS_FORMATTER
                            )
                        }
                        text="Back"
                    />
                </div>
            </form>
        </div>
    );
};
