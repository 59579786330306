import React, { useEffect } from "react";

// Components
import { AddressCard } from "components/AddressCard/AddressCard";
import {
    ButtonInvisible,
    ButtonPrimary,
} from "../../../../../components/Buttons/Buttons";
import { Text } from "components/Text/Text";

// CSS
import styles from "./contact-modal-confirm.module.scss";

// Enums
import { ContactModalNavigation } from "../../enums/ContactModalNavigation";

export const ContactModalConfirm = (props) => {
    const {
        contactStaging,
        handleSubmit,
        setCurrentSection,
        setHeaderText,
        shouldReverseFullName,
    } = props;

    /**
     * useEffect
     */

    useEffect(() => {
        setHeaderText("Congratulations, we've found your recruit's address");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * End Hooks
     */

    return (
        <div className={styles.contactModalConfirm}>
            <div className={styles.text}>
                <Text>
                    Their mailing address may look different, but it will still
                    reach them. If their training information changes, you can
                    always update this later.
                </Text>
            </div>
            <AddressCard
                contact={contactStaging}
                shouldReverseFullName={shouldReverseFullName}
            />
            <div className={styles.buttons}>
                <ButtonPrimary
                    cypressTestId="contact-modal-confirm-submit-button"
                    onClick={handleSubmit}
                    text="Confirm address"
                    type="button"
                />
                <ButtonInvisible
                    onClick={() =>
                        setCurrentSection(
                            ContactModalNavigation.ADDRESS_FORMATTER
                        )
                    }
                    text="Edit address"
                    type="button"
                />
            </div>
        </div>
    );
};
