import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// Components
import { CartShippingAddressDisplay } from "./components/CartShippingAddressDisplay/CartShippingAddressDisplay";
import { CartShippingAddressEmpty } from "./components/CartShippingAddressEmpty/CartShippingAddressEmpty";
import { CartShippingAddressLoading } from "./components/CartShippingAddressLoading/CartShippingAddressLoading";
import { Text } from "components/Text/Text";

// CSS
import styles from "./cart-shipping-address.module.scss";

// Enums
import { CartShippingAddressNavigation } from "./enums/CartShippingAddressNavigation";

// Hooks
import { useCartShippingAddress } from "./hooks/useCartShippingAddress";

export const CartShippingAddress = ({ setCartCurrentSection }) => {
    /**
     * Custom Hooks
     */

    const { cart, currentSection, hasShippingAddress, setCurrentSection } =
        useCartShippingAddress();

    /**
     * End Hooks
     */

    function renderCurrentSection() {
        return {
            [CartShippingAddressNavigation.DISPLAY]: (
                <CartShippingAddressDisplay
                    setCartCurrentSection={setCartCurrentSection}
                />
            ),
            [CartShippingAddressNavigation.EMPTY]: (
                <CartShippingAddressEmpty
                    setCartCurrentSection={setCartCurrentSection}
                    setCurrentSection={setCurrentSection}
                />
            ),
            [CartShippingAddressNavigation.LOADING]: (
                <CartShippingAddressLoading />
            ),
        }[currentSection];
    }

    return (
        <div className={styles.cartShippingAddress}>
            <div className={styles.header}>
                <Text>Shipping Address</Text>
            </div>
            <div
                className={cx(styles.panel, {
                    [styles["panel--empty"]]: cart && !hasShippingAddress,
                })}
            >
                {renderCurrentSection()}
            </div>
        </div>
    );
};

CartShippingAddress.propTypes = {
    setCartCurrentSection: PropTypes.func,
};
