import React from "react";
import { Modal } from "components/Modals/Modal";

// Components
import {
    ButtonInvisible,
    ButtonPrimary,
} from "../../../components/Buttons/Buttons";

export const LettersComposeModalNoCredits = (props) => {
    const { handlePurchaseModalToggle, setDisplay, showNoCreditsModal } = props;
    const buttonPrimaryStyle = { marginBottom: 0 };

    function onClick() {
        onHide();
        handlePurchaseModalToggle();
    }

    function onHide() {
        setDisplay((prev) => ({
            ...prev,
            showNoCreditsModal: false,
        }));
    }

    return (
        <Modal
            className="letters__modal__no-credits"
            cypressTestId="letters-compose-no-credits-modal"
            onHide={onHide}
            shouldHideHeader={true}
            show={showNoCreditsModal}
        >
            <div className="letters__modal__no-credits__body">
                <div className="letters__modal__no-credits__header">
                    <span>Oops!</span>
                </div>
                <div className="letters__modal__no-credits__text">
                    <span>You don't have enough credits to send a letter</span>
                </div>
                <ButtonPrimary
                    onClick={onClick}
                    style={buttonPrimaryStyle}
                    text="Buy Credits"
                    type="button"
                />
                <ButtonInvisible
                    onClick={onHide}
                    text="No Thanks"
                    type="button"
                />
            </div>
        </Modal>
    );
};
