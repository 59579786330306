import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// Components
import { ChevronRightIcon } from "components/Icons/ChevronRightIcon";
import { Text } from "components/Text/Text";

// CSS
import styles from "./line-item.module.scss";

// Utils
import { emptyFunction } from "utils/miscUtils";
import { handleKeyDown } from "utils/eventUtils";

export const LineItem = (props) => {
    const { className, cypressTestId, icon, onClick, style, text } = props;
    return (
        <div
            className={cx(styles["line-item"], className)}
            data-cy={cypressTestId}
            onClick={onClick}
            style={style}
            onKeyDown={(e) => handleKeyDown(e, onClick)}
            tabIndex="0"
        >
            <div className={styles.left}>
                {icon && <div className={styles.icon}>{icon}</div>}
                <Text className={styles.text}>{text}</Text>
            </div>
            <ChevronRightIcon
                className={styles.chevron}
                height="20px"
                width="20px"
            />
        </div>
    );
};

LineItem.defaultProps = {
    className: "",
    cypressTestId: "",
    onClick: emptyFunction,
    style: {},
};

LineItem.propTypes = {
    className: PropTypes.string,
    cypressTestId: PropTypes.string,
    icon: PropTypes.node,
    onClick: PropTypes.func,
    style: PropTypes.object,
    text: PropTypes.string.isRequired,
};
