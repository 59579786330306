import React from "react";

// Utils
import { handleKeyDown } from "utils/eventUtils";

// Enums
import { AddOnsNavigation } from "../enums/enums";

export const LettersComposeAddOnsGiftCardSelectDesign = (props) => {
    const { giftCard, changeCurrentSection, handleGiftCardSelectOption } =
        props;
    const { options } = giftCard;

    function handleGiftCardClick(option) {
        handleGiftCardSelectOption(option);
        changeCurrentSection(AddOnsNavigation.SELECT_AMOUNT);
    }

    function renderOptions() {
        return options
            .sort((a, b) => a.priority - b.priority)
            .map((option, index) => {
                const { id, photoUrlFull } = option;
                const style = { backgroundImage: `url(${photoUrlFull})` };
                return (
                    <div
                        className="gift-card"
                        data-cy={`letters-compose-addons-gift-card-${id}`}
                        key={id}
                        style={style}
                        onClick={() => handleGiftCardClick(option)}
                        onKeyDown={
                            /* istanbul ignore next */ (e) =>
                                handleKeyDown(e, () =>
                                    handleGiftCardClick(option)
                                )
                        }
                        tabIndex={index + 1}
                    />
                );
            });
    }

    return (
        <div className="letters-compose-add-ons__select-design">
            <div className="letters-compose-add-ons__select-design__designs">
                {renderOptions()}
            </div>
        </div>
    );
};
