import React from "react";
import { Link } from "react-router-dom";
import cx from "classnames";
import { DateTime } from "luxon";
import { Skeleton } from "@material-ui/lab";
import PropTypes from "prop-types";

// Components
import { MultipleImagesIcon } from "components/Icons/MultipleImagesIcon";

// CSS
import styles from "./letter-preview.module.scss";

// Hooks
import { useLetterPreview } from "./hooks/useLetterPreview";

// Media
import imageEmptySrc from "media/icons/image-empty.svg";

// Utils
import {
    generateStatusColor,
    generateStatusIcon,
    generateStatusWidth,
} from "../../utils/lettersUtils";
import { titleCase } from "../../utils/textUtils";

export const LetterPreview = ({
    isLoading,
    letter,
    letterType,
    linkParams,
}) => {
    /**
     * Custom Hooks
     */

    const { imageSrc, letterFormatted } = useLetterPreview({
        letter,
        letterType,
    });

    /**
     * End Hooks
     */

    function renderLetterPreview() {
        const { created_at, newsletterType, pretty_status, recipient, status } =
            letterFormatted;
        const { address, name } = recipient;
        const sentDate = DateTime.fromISO(created_at).toLocaleString({
            day: "numeric",
            month: "short",
        });
        const tagText = newsletterType
            ? newsletterType.replace(/_/g, " ")
            : "LETTER";
        return (
            <Link
                data-cy={`letter-preview-link-container-${name}`}
                to={linkParams}
            >
                <div className={styles.letterPreview}>
                    <div className={styles.row}>
                        <div className={styles.date}>
                            <span>{sentDate}</span>
                        </div>
                        <div
                            className={cx(styles.image, {
                                [styles["image--empty"]]:
                                    imageSrc === imageEmptySrc,
                            })}
                            style={{ backgroundImage: `url(${imageSrc})` }}
                        >
                            {letter.customPhotoArray?.length > 1 && (
                                <MultipleImagesIcon
                                    className={styles.icon}
                                    color="white"
                                    height="16px"
                                    width="16px"
                                />
                            )}
                        </div>
                        <div className={styles.info}>
                            <span className={styles.tag}>{tagText}</span>
                            <span className={styles.name}>To {name}</span>
                            <div className={styles.status}>
                                {generateStatusIcon({
                                    className: styles.icon,
                                    status,
                                })}
                                <span className={styles.text}>
                                    {titleCase(pretty_status)} /{" "}
                                    {titleCase(address.city)}, {address.state}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.statusBar}>
                            <div
                                className={styles.bar}
                                style={{
                                    backgroundColor:
                                        generateStatusColor(status),
                                    width: generateStatusWidth(status),
                                }}
                            />
                        </div>
                    </div>
                </div>
            </Link>
        );
    }

    function renderLoading() {
        return (
            <div className={cx(styles.letterPreview)}>
                <div className={styles.row}>
                    <div className={styles.date}>
                        <Skeleton
                            className={styles.skeleton}
                            height="14px"
                            width="30px"
                        />
                    </div>
                    <Skeleton
                        className={cx(styles.skeleton, styles.skeletonImage)}
                        height="80px"
                        width="80px"
                    />
                    <div className={styles.info}>
                        <Skeleton
                            className={styles.skeleton}
                            height="20px"
                            width="52px"
                        />
                        <Skeleton
                            className={styles.skeleton}
                            height="16px"
                            width="154px"
                        />
                        <Skeleton
                            className={styles.skeleton}
                            height="24px"
                            width="154px"
                        />
                    </div>
                </div>
                <div className={styles.row}>
                    <Skeleton
                        className={styles.skeleton}
                        height="6px"
                        width="100%"
                    />
                </div>
            </div>
        );
    }

    return isLoading ? renderLoading() : renderLetterPreview();
};

LetterPreview.propTypes = {
    isLoading: PropTypes.bool,
    letter: PropTypes.object,
    letterType: PropTypes.string,
    linkParams: PropTypes.object,
};
