import { useContext, useEffect, useState } from "react";

// Enums
import { ContactModalNavigation } from "scenes/Contacts/ContactModal/enums/ContactModalNavigation";

// Loggers
import { AnalyticsLogger } from "loggers/AnalyticsLogger";

// Models
import { Contact } from "../../../../../../models/contacts/Contact";

// Utils
import { titleCase } from "utils/textUtils";
import { generateCreditCardIconSrc } from "utils/purchaseUtils";

// Context
import { ContactsContext } from "context/contacts";

// Validation
import { LettersValidation } from "validation/lettersValidation";

// Enums
import { LettersComposeNavigation } from "../../enums/enums";

export const useLettersComposeReview = (props) => {
    const {
        changeComposeCurrentSection,
        credits,
        handleInitializeSaveDraft,
        message,
        recipient,
        selectedCard,
        setHeaderBarContent,
        setMessage,
        setRecipient,
        setSender,
        showNotification,
        supportSquad,
        toggleContactModal,
        updateUserAddress,
    } = props;

    /**
     * useContext
     */

    const { setOnSubmitContactSuccessFinalCallback } =
        useContext(ContactsContext);

    /**
     * useState
     */

    const [editStatuses, setEditStatuses] = useState({
        message: false,
        photo: false,
        recipient: false,
        sender: false,
    });
    const [isEditing, setIsEditing] = useState(false);
    const [recipientStaging, setRecipientStaging] = useState(
        recipient.generateContactObject()
    );
    const [selectedTitle, setSelectedTitle] = useState("");

    /**
     * Constants
     */

    const { connected } = recipient;
    const { brand, last4 } = selectedCard?.card || {};
    const creditCardIconSrc = generateCreditCardIconSrc(brand);
    const hasCredits = LettersValidation.validateCredits({
        credits,
        recipient,
        supportSquad,
    });

    /**
     * useEffect
     */

    useEffect(() => {
        // Auto-open edit recipient panel if the recipient does not have an address
        const recipientHasAddress = recipient.address.line1;
        if (!recipientHasAddress) {
            setIsEditing(true);
            setEditStatuses((prevEditStatuses) => ({
                ...prevEditStatuses,
                recipient: true,
            }));
            showNotification({
                text: "Please provide an address for your recipient.",
                type: "warning",
            });
        }
        setHeaderBarContent({
            onBackClick: handleGoBack,
            text: "Review Your Order",
            textSecondary: "Safe & Secure Checkout",
        });
        AnalyticsLogger.logLetterComposeReviewViewed();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * End Hooks & Constants
     */

    /**
     * * If the user is modifying the recipient, onChange should update the `recipientStaging`
     * * state value, not the recipient being passed through props
     *
     * TODO: Update <select> logic so that it is not specific to the `title` property
     */
    function generateEditInputs(inputData, parentKey) {
        const dataSource =
            parentKey === "recipient" ? recipientStaging : props[parentKey];
        const shouldAutoComplete = parentKey === "sender" ? "on" : "off";

        return inputData.map((input, i) => {
            const isAddressProperty =
                input.name === "city" ||
                input.name === "line1" ||
                input.name === "line2" ||
                input.name === "state" ||
                input.name === "zipcode";
            const onChange = (e) => {
                e.persist();
                if (parentKey === "message") {
                    setMessage((prevMessage) => ({
                        ...prevMessage,
                        [input.name]: e.target.value,
                    }));
                } else if (parentKey === "recipient") {
                    setRecipientStaging((prevRecipientStaging) => {
                        const updatedValue = isAddressProperty
                            ? {
                                  address: {
                                      ...prevRecipientStaging.address,
                                      [input.name]: e.target.value,
                                  },
                              }
                            : { [input.name]: e.target.value };
                        return {
                            ...prevRecipientStaging,
                            ...updatedValue,
                        };
                    });
                } else if (parentKey === "sender") {
                    setSender((prevSender) => {
                        const updatedValue = isAddressProperty
                            ? {
                                  address: {
                                      ...prevSender.address,
                                      [input.name]: e.target.value,
                                  },
                              }
                            : { [input.name]: e.target.value };
                        return {
                            ...prevSender,
                            ...updatedValue,
                        };
                    });
                }
            };
            switch (input.type) {
                case "select":
                    // TODO: Make code not specific to the title property
                    if (input.name === "title" && parentKey === "sender") {
                        return null;
                    }
                    const options = input.options.map((option, i) => {
                        const content = `${option.title} (${titleCase(
                            option.branchId
                        )})`;
                        const key = `${option.title} ${option.branchId}`;
                        return (
                            <option key={key} value={option.title}>
                                {content}
                            </option>
                        );
                    });
                    return (
                        <select
                            className="select"
                            data-cy={`letters-compose-review-container-${input.name}`}
                            key={i}
                            name={input.name}
                            onChange={(e) => {
                                onChange(e);
                                setSelectedTitle(e.target.key);
                            }}
                            value={selectedTitle}
                        >
                            <option key="default" defaultValue="" value="">
                                {input.placeholder}
                            </option>
                            {options}
                        </select>
                    );
                case "text":
                    const value = isAddressProperty
                        ? dataSource.address[input.name]
                        : dataSource[input.name];
                    return (
                        <input
                            autoComplete={shouldAutoComplete}
                            data-cy={`letters-compose-review-container-${input.name}`}
                            className="input"
                            key={i}
                            name={input.name}
                            placeholder={input.placeholder}
                            type="text"
                            value={value}
                            onChange={onChange}
                        />
                    );
                case "textarea":
                    return (
                        <textarea
                            className="textarea"
                            key={i}
                            name={input.name}
                            placeholder={input.placeholder}
                            value={dataSource[input.name]}
                            onChange={onChange}
                        />
                    );
                default:
                    break;
            }
            return null;
        });
    }

    function handleGoBack() {
        changeComposeCurrentSection(LettersComposeNavigation.COMPOSE);
    }

    function handleNameReverseToggle() {
        const recipientTitle = recipient.title ? `${recipient.title} ` : "";
        const fullName = !message.isNameReversed
            ? `${recipientTitle}${recipient.lastName}, ${recipient.firstName}`
            : `${recipientTitle}${recipient.firstName} ${recipient.lastName}`;
        setMessage((prevMessage) => ({
            ...prevMessage,
            isNameReversed: !prevMessage.isNameReversed,
        }));
        setRecipientStaging((prevRecipientStaging) => ({
            ...prevRecipientStaging,
            fullName,
        }));
    }

    function handleSaveMessage() {
        handleInitializeSaveDraft(true);
        toggleEditPanel("message");
    }

    function toggleEditPanel(key) {
        const isValidInput = validateInput(key);
        const isEditingMessage =
            key === "message" && isEditing && editStatuses.message;
        const willEditMessage =
            key === "message" && !isEditing && !editStatuses.message;
        const isEditingSender =
            key === "sender" && isEditing && editStatuses.sender;
        if (isValidInput) {
            if (isEditingMessage) {
                AnalyticsLogger.logEditLetterSave();
            }
            if (isEditingSender) {
                // Update user's address to match sender data
                updateUserAddress();
            }
            if (willEditMessage) {
                AnalyticsLogger.logEditLetter();
            }
            setEditStatuses((prevEditStatuses) => ({
                ...prevEditStatuses,
                [key]: !prevEditStatuses[key],
            }));
            setIsEditing((prevIsEditing) => !prevIsEditing);
        }
    }

    function handleToggleContactModal() {
        toggleContactModal({
            contactToEdit: recipient,
            isWritingLetter: true,
            shouldShow: true,
            targetScreen: ContactModalNavigation.FORM,
            type: "edit",
        });
        setOnSubmitContactSuccessFinalCallback(() => onEditContactSuccess);
    }

    function onEditContactSuccess(res) {
        const updatedRecipient = new Contact(recipientStaging);
        setRecipient(updatedRecipient);
    }

    function validateInput(key) {
        if (key === "message") {
            if (message.content) {
                return true;
            } else {
                showNotification({
                    text: "Please include a message with your letter.",
                    type: "warning",
                });
                return false;
            }
        }
        return true;
    }

    return {
        brand,
        connected,
        creditCardIconSrc,
        editStatuses,
        generateEditInputs,
        handleNameReverseToggle,
        handleSaveMessage,
        handleToggleContactModal,
        hasCredits,
        last4,
        toggleEditPanel,
    };
};
