import React from "react";
import PropTypes from "prop-types";

// Components
import { Divider } from "components/Divider/Divider";
import { Skeleton } from "@mui/material";
import { Text } from "components/Text/Text";

// CSS
import styles from "./memory-book-description.module.scss";

export const MemoryBookDescription = ({ cart }) => {
    const memoryBookItem = cart?.items.find(
        (item) => item?.variant?.product?.handle === "memory-book"
    );

    return (
        <div className={styles.memoryBookDescription}>
            <div className={styles.title}>
                <Text classes={styles.title} type="h1">
                    Memory Book
                </Text>
            </div>
            <div className={styles.description}>
                {memoryBookItem ? (
                    <Text>{memoryBookItem?.variant?.product?.description}</Text>
                ) : (
                    <>
                        <Skeleton width="100%" />
                        <Skeleton width="100%" />
                        <Skeleton width="80%" />
                    </>
                )}
            </div>
            <Divider className={styles.divider} />
        </div>
    );
};

MemoryBookDescription.propTypes = {
    cart: PropTypes.object,
};
