import React, { useEffect } from "react";
import cx from "classnames";

// Components
import { ButtonPrimary } from "components/Buttons/Buttons";
import { TextFieldValidated } from "components/Inputs/TextFieldValidated/TextFieldValidated";

// CSS
import signInStyles from "../../../../../sign-in.module.scss";
import signInMainStyles from "../../sign-in-main.module.scss";

// Enums
import { SignInPlatform } from "../../constants/constants";

export const SignInMainSignUpForm = (props) => {
    const {
        handleChangeUserInfo,
        handleSignUp,
        hasValidEmail,
        loading,
        setSignInPlatform,
        userInfo,
    } = props;
    const { email } = userInfo;

    useEffect(() => {
        setSignInPlatform(SignInPlatform.EMAIL_VERIFY);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <form className={cx(signInStyles.form, signInMainStyles.form)}>
            <TextFieldValidated
                className={signInStyles["input-validatable"]}
                cypressTestId="sign-in-main-email-input"
                id="email"
                isValid={hasValidEmail}
                label="Email"
                name="email"
                onChange={handleChangeUserInfo}
                value={email}
            />
            <ButtonPrimary
                classes={signInMainStyles.submit}
                cypressTestId="sign-in-main-form-email-submit-button"
                isDisabled={!hasValidEmail || loading.signUp}
                onClick={handleSignUp}
                text={!loading.signUp ? "Continue" : "Signing Up..."}
                type="submit"
            />
        </form>
    );
};
