import React, { useState } from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// Components
import { ChevronRightIcon } from "components/Icons/ChevronRightIcon";
import { LoadingOverlay } from "components/Loading/Loading";

// CSS
import styles from "./banner.module.scss";

// Utils
import { generateBannerIcon } from "./utils";
import { emptyFunction } from "utils/miscUtils";
import { handleKeyDown } from "utils/eventUtils";

export const Banner = ({
    children,
    classes,
    config,
    cypressTestId,
    loading,
    onClick,
    tabFlow,
    theme,
}) => {
    const {
        isActive,
        shouldExpandText,
        shouldShowChevron,
        shouldShowClose,
        shouldShowIcon,
    } = config;

    /**
     * useState
     */

    const [shouldHide, setShouldHide] = useState(false);

    /**
     * End Hooks
     */

    function renderChevron() {
        return (
            shouldShowChevron &&
            !shouldShowClose && (
                <div className={styles.chevron}>
                    <ChevronRightIcon height="24px" width="18px" />
                </div>
            )
        );
    }

    function renderClose() {
        return (
            shouldShowClose &&
            !shouldShowChevron && (
                <div
                    className={styles.close}
                    onClick={() => setShouldHide(true)}
                >
                    <span className="material-icons">close</span>
                </div>
            )
        );
    }

    function renderIcon() {
        return shouldShowIcon && generateBannerIcon(theme);
    }

    function renderLoading() {
        return loading && <LoadingOverlay />;
    }

    return (
        <div
            className={cx(styles.banner, classes, {
                [styles["banner--active"]]: isActive,
                [styles["banner--expand-text"]]: shouldExpandText,
                [styles["banner--hidden"]]: shouldHide,
                [styles["banner--loading"]]: loading,
                [styles["banner--orange"]]: theme === "orange",
                [styles["banner--secondary"]]: theme === "secondary",
                [styles["banner--yellow"]]: theme === "yellow",
            })}
            data-cy={cypressTestId}
            onClick={onClick}
            tabIndex={tabFlow ? "0" : null}
            onKeyDown={(e) => handleKeyDown(e, onClick)}
        >
            {renderLoading()}
            {renderIcon()}
            <div className={styles.text}>{children}</div>
            {renderChevron()}
            {renderClose()}
        </div>
    );
};

Banner.defaultProps = {
    classes: "",
    config: {
        isActive: false,
        shouldShowChevron: false,
        shouldShowClose: false,
        shouldShowIcon: false,
    },
    loading: false,
    onClick: emptyFunction,
    onHide: emptyFunction,
    theme: "blue",
};

Banner.propTypes = {
    classes: PropTypes.string,
    config: PropTypes.object,
    onClick: PropTypes.func,
    text: PropTypes.string,
    theme: PropTypes.string,
};
