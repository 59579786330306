import { useContext, useEffect, useState } from "react";

// Context
import { NotificationBarContext } from "context/notificationBar";

// Loggers
import { AnalyticsLogger } from "loggers/AnalyticsLogger";

// Utils
import { SandboxxRestAPI } from "utils/sandboxx";
import { copyTextToClipboard } from "utils/textUtils";

export const useReferralsShareLink = () => {
    /**
     * useContext
     */

    const { showNotification } = useContext(NotificationBarContext);

    /**
     * useState
     */

    const [referralLink, setReferralLink] = useState("");

    /**
     * useEffect
     */

    useEffect(() => {
        generateReferralLink();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * End Hooks
     */

    function generateReferralLink() {
        SandboxxRestAPI.getReferralCode(
            onGenerateReferralLinkSuccess,
            onGenerateReferralLinkFailure,
            onGenerateReferralLinkFailure
        );
    }

    function handleCopyReferralLinkToClipboard() {
        AnalyticsLogger.logWebReferralCopyLink();
        copyTextToClipboard(referralLink);
        showNotification({
            text: "Your referral link has been copied to your clipboard.",
            type: "success",
        });
    }

    function onGenerateReferralLinkFailure() {
        showNotification({
            text: "There was an issue generating your referral link. Please reach out to our Customer Happiness team by clicking the chat widget in the bottom-right corner of the page.",
            type: "warning",
        });
    }

    function onGenerateReferralLinkSuccess({ referralMagicLink }) {
        setReferralLink(referralMagicLink);
    }

    return {
        handleCopyReferralLinkToClipboard,
        referralLink,
    };
};
