import React from "react";
import cx from "classnames";

// Components
import { ButtonPrimary } from "../../../components/Buttons/Buttons";
import { Panel } from "../../../components/Panel/Panel";

// CSS
import styles from "./dashboard-compose.module.scss";

// Utils
import { isEmptyObject } from "utils/miscUtils";

// Logging
import { AnalyticsLogger } from "../../../loggers/AnalyticsLogger";

// Constants
const planeImageSrc = require("media/letters/artwork/plane.svg").default;

export const DashboardCompose = (props) => {
    const { activeDraft, currentActiveWriters, history } = props;
    const { draftRecipient } = activeDraft || {};

    const hasActiveDraft =
        activeDraft && !isEmptyObject(activeDraft) && draftRecipient;
    const buttonText = hasActiveDraft ? "Finish Letter" : "Start Writing";
    const peopleText = currentActiveWriters === 1 ? "person is" : "people are";
    const subButtonText =
        currentActiveWriters < 10
            ? "Every day is a chance to show your support"
            : `${currentActiveWriters} ${peopleText} writing a letter now`;

    function handleComposeClick() {
        AnalyticsLogger.logLetterComposeStart();
        history.push("/letters/compose");
        draftRecipient &&
            localStorage.setItem(
                "sandboxxMessageRecipient",
                JSON.stringify(draftRecipient)
            );
    }

    function renderDraftNotification() {
        return (
            hasActiveDraft && (
                <span className={styles.text}>
                    Finish your letter to {draftRecipient.firstName}
                </span>
            )
        );
    }

    return (
        <Panel>
            <div className={styles["dashboard__compose"]}>
                <img
                    alt="Paper airplane"
                    className={styles.image}
                    src={planeImageSrc}
                />
                {renderDraftNotification()}
                <ButtonPrimary
                    classes={cx(styles.button, "button--size-short")}
                    color="orange"
                    cypressTestId="dashboard-compose-button"
                    onClick={handleComposeClick}
                    text={buttonText}
                    type="button"
                />
                <div className={styles.text}>
                    <span>{subButtonText}</span>
                </div>
            </div>
        </Panel>
    );
};
