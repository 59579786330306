import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import cx from "classnames";

// Components
import { AppleLoginButton } from "components/Auth/AppleLoginButton/AppleLoginButton";
import { ButtonPrimary, ButtonSignIn } from "components/Buttons/Buttons";
import { FacebookLoginButton } from "components/Auth/FacebookLoginButton/FacebookLoginButton";
import { GoogleLoginButton } from "components/Auth/GoogleLoginButton/GoogleLoginButton";
import { LetterIcon } from "components/Icons/LetterIcon";

// CSS
import styles from "../profile-delete-account-modal.module.scss";
import signInMainStyles from "scenes/SignIn/scenes/SignIn/SignInMain/sign-in-main.module.scss";

export const ProfileDeleteAccountModalVerify = (props) => {
    const {
        callbacks,
        handleEmailLogin,
        loading,
        isEmail,
        isEmailCode,
        isSocial,
        profileInfo,
        setVerificationMethod,
        verificationMethod,
    } = props;

    const { authIds } = profileInfo || {};

    /**
     * useForm
     */

    const { formState, register, handleSubmit } = useForm({ mode: "onChange" });
    const { errors } = formState;

    /**
     * useEffect
     */

    useEffect(() => {
        if (isEmail || isEmailCode) {
            setVerificationMethod("EMAIL");
        } else if (!isEmail && !isEmailCode) {
            setVerificationMethod("SOCIAL");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * End Hooks
     */

    return (
        <div className={styles["verify-method-selection"]}>
            <div className={styles.body}>
                {verificationMethod === "EMAIL" && (
                    <form className={styles.form}>
                        <label className={styles.label} htmlFor="email">
                            Email
                        </label>
                        <input
                            className={cx("input", styles.input)}
                            data-cy="profile-delete-account-modal-verify-email-input"
                            id="email"
                            type="email"
                            {...register("email", {
                                required: true,
                            })}
                            aria-invalid={errors.email ? "true" : "false"}
                        />
                        <label className={styles.label} htmlFor="password">
                            Password
                        </label>
                        <input
                            className={cx("input", styles.input)}
                            data-cy="profile-delete-account-modal-verify-password-input"
                            id="password"
                            type="password"
                            {...register("password", {
                                required: true,
                            })}
                        />
                        <ButtonPrimary
                            classes={styles.button}
                            cypressTestId="profile-delete-account-modal-verify-continue-button"
                            isDisabled={
                                errors.email || errors.password || loading
                            }
                            onClick={handleSubmit(handleEmailLogin)}
                            text={!loading ? "Continue" : "Continuing..."}
                            type="submit"
                        />
                    </form>
                )}
                {isSocial && (isEmail || isEmailCode) ? (
                    <div className="modal__separator">
                        <span>OR</span>
                    </div>
                ) : null}
                {isEmail || isEmailCode || isSocial ? (
                    <div className={styles.sso}>
                        <div className={styles.options}>
                            {(isEmail || isEmailCode) &&
                                verificationMethod !== "EMAIL" && (
                                    <ButtonSignIn
                                        classes={cx(
                                            signInMainStyles.button,
                                            styles.button
                                        )}
                                        cypressTestId="profile-delete-email-button"
                                        icon={<LetterIcon />}
                                        id="email-signup-button"
                                        onClick={() =>
                                            setVerificationMethod("EMAIL")
                                        }
                                        text={"Continue With Email"}
                                        type="button"
                                    />
                                )}
                            {authIds?.includes("FACEBOOK") && (
                                <div className={styles.button}>
                                    <FacebookLoginButton
                                        callbacks={callbacks}
                                        classes={cx(signInMainStyles.button)}
                                        mode="verify"
                                        size="lg"
                                        text="Continue With Facebook"
                                    />
                                </div>
                            )}
                            {authIds?.includes("GOOGLE") && (
                                <div className={styles.button}>
                                    <GoogleLoginButton
                                        callbacks={callbacks}
                                        classes={cx(signInMainStyles.button)}
                                        mode="verify"
                                        size="lg"
                                        text="Continue With Google"
                                    />
                                </div>
                            )}
                            {authIds?.includes("APPLE") && (
                                <div className={styles.button}>
                                    <AppleLoginButton
                                        callbacks={callbacks}
                                        mode="verify"
                                        text="Continue With Apple"
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    );
};
