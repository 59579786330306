export function ChevronUpIcon({ height, width, color }) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M18 15L12 9L6 15"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

ChevronUpIcon.defaultProps = {
    color: "#212121",
    height: "24px",
    width: "24px",
};
