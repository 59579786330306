import React from "react";

// Components
import { ButtonPrimary } from "components/Buttons/Buttons";
import { NotificationCorner } from "components/Notification/NotificationCorner/NotificationCorner";

// CSS
import styles from "./profile-onboarding-notification-corner.module.scss";

export const ProfileOnboardingNotificationCorner = (props) => {
    const {
        handleGoToOnboarding,
        handleShowCompleteProfileNotification,
        shouldShowOnboardingNotification,
    } = props;

    function handleHideProfileNotificationCorner() {
        handleShowCompleteProfileNotification({
            overrideBool: false,
            shouldOverride: true,
        });
    }

    return (
        <NotificationCorner
            classes={styles["profile-notification-corner"]}
            cyId="profile-onboarding-notification-corner"
            cypressCloseButtonTestId="profile-onboarding-notification-corner-close-button"
            onClose={handleHideProfileNotificationCorner}
            shouldShow={shouldShowOnboardingNotification}
        >
            <span className={styles.text}>Finish setting up your profile</span>
            <ButtonPrimary
                classes={styles.button}
                cypressTestId="profile-onboarding-notification-corner-continue-button"
                onClick={handleGoToOnboarding}
                text="Continue"
                type="button"
            />
        </NotificationCorner>
    );
};
