import React from "react";
import { useForm } from "react-hook-form";
import cx from "classnames";
import PropTypes from "prop-types";

// Components
import { ButtonPrimary } from "components/Buttons/Buttons";

// CSS
import styles from "./wallet-manage-add-card-modal-manual-input.module.scss";

export const WalletManageAddCardModalManualInput = (props) => {
    const { addGiftCardToWalletWithBarcode, loading } = props;

    /**
     * useForm
     */

    const {
        formState: { errors, isValid },
        register,
        handleSubmit,
    } = useForm({ mode: "onChange" });

    /**
     * Constants
     */

    const buttonText = loading.addCard
        ? "Adding Gift Card..."
        : "Add My Gift Card";

    /**
     * End Hooks
     */

    return (
        <div
            className={styles["wallet-manage-add-card-modal-manual-input"]}
            data-cy="wallet-add-card-modal-manual"
        >
            <div className="modal__body">
                <form
                    className={styles.form}
                    onSubmit={handleSubmit(addGiftCardToWalletWithBarcode)}
                >
                    <label className={styles.label} htmlFor="giftCardNumber">
                        <span className={styles.text}>
                            Enter your gift card’s 19 digit code
                        </span>
                        <span className={styles["text-secondary"]}>
                            This code can be found below your card’s bar code
                        </span>
                    </label>
                    <input
                        {...register("giftCardNumber", {
                            maxLength: 19,
                            minLength: 19,
                            required: true,
                        })}
                        aria-invalid={errors.giftCardNumber ? "true" : "false"}
                        className={cx(styles.input, "input")}
                        data-cy="wallet-add-card-code-input"
                        id="giftCardNumber"
                        maxLength="19"
                    />
                    <label className={styles.label} htmlFor="pin">
                        <span className={styles.text}>
                            Enter your gift card’s pin number
                        </span>
                        <span className={styles["text-secondary"]}>
                            Your pin is located below your card’s bar code
                        </span>
                    </label>
                    <input
                        {...register("pin", {
                            minLength: 4,
                            maxLength: 4,
                            required: true,
                        })}
                        aria-invalid={errors.pin ? "true" : "false"}
                        className={cx(styles.input, "input")}
                        data-cy="wallet-add-card-code-pin"
                        id="pin"
                        maxLength="4"
                    />
                    <ButtonPrimary
                        classes={styles.button}
                        cypressTestId="wallet-add-card-submit"
                        isDisabled={!isValid || loading.addCard}
                        onClick={handleSubmit(addGiftCardToWalletWithBarcode)}
                        text={buttonText}
                        type="submit"
                    />
                </form>
            </div>
        </div>
    );
};

WalletManageAddCardModalManualInput.propTypes = {
    addGiftCardToWalletWithBarcode: PropTypes.func.isRequired,
    loading: PropTypes.object.isRequired,
};
