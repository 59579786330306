import React from "react";

// Components
import { ButtonPrimary } from "components/Buttons/Buttons";

// CSS
import styles from "../phone-verification-modal.module.scss";

export const PhoneVerificationModalCompleteLetters = (props) => {
    const { togglePhoneVerificationModal } = props;

    return (
        <div className={styles["complete-letters"]}>
            <h3 className={styles.header}>
                Would you link to continue sending a letter?
            </h3>
            <p className={styles.description}>
                You can manually enter your recruit's mailing address and begin
                writing.
            </p>
            <ButtonPrimary
                color="orange"
                onClick={() => togglePhoneVerificationModal(false)}
                text="Select A Recipient"
                type="button"
            />
        </div>
    );
};
