import React from "react";
import PropTypes from "prop-types";

// Components
import { Banner } from "components/Banner/Banner";
import { Text } from "components/Text/Text";

// Enums
import { CartNavigation } from "components/Cart/enums/CartNavigation";

export const CartShippingAddressEmpty = ({ setCartCurrentSection }) => {
    return (
        <Banner
            config={{
                isActive: true,
                shouldShowChevron: true,
                shouldShowIcon: true,
            }}
            onClick={() =>
                setCartCurrentSection(
                    () => CartNavigation.SHIPPING_ADDRESS_FORM
                )
            }
        >
            <Text isBold>No Shipping Address</Text>
            <Text>Please let us know where to deliver your Memory Book</Text>
        </Banner>
    );
};

CartShippingAddressEmpty.propTypes = {
    setCartCurrentSection: PropTypes.func,
};
