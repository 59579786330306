import React, { useEffect, useState } from "react";

// Components
import { PurchaseModalBundleOptions } from "./components/PurchaseModalBundleOptions/PurchaseModalBundleOptions";

// CSS
import styles from "./purchase-modal-bundle.module.scss";

// Loggers
import { AnalyticsLogger } from "loggers/AnalyticsLogger";

// Enums
import { PurchaseModalNavigation } from "../../enums/enums";

export const PurchaseModalBundle = (props) => {
    const {
        bundles,
        changeCurrentSection,
        handleSelectBundle,
        selectedCard,
        setPurchaseMode,
    } = props;

    /**
     * useEffect
     */

    useEffect(() => {
        AnalyticsLogger.logBundleView();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * useState
     */

    const [selectedBundle, setSelectedBundle] = useState(null);

    /**
     * End Hooks
     */

    function handleSubmit(e, bundle) {
        e && e.preventDefault();
        setPurchaseMode("letters");
        const hasSelectedCreditCard = selectedCard?.card;
        handleSelectBundle(bundle);
        hasSelectedCreditCard
            ? changeCurrentSection(PurchaseModalNavigation.REVIEW)
            : changeCurrentSection(PurchaseModalNavigation.PAYMENT);
        if (bundle.includesSandboxxPlus) {
            AnalyticsLogger.logBundleViewPlusPurchase();
        } else {
            AnalyticsLogger.logBundleViewBasicPurchase();
        }
        AnalyticsLogger.logAddBundleToCart({ bundle });
    }

    return (
        <div className={styles["purchase-modal-bundle"]}>
            <PurchaseModalBundleOptions
                bundles={bundles}
                handleSubmit={handleSubmit}
                selectedBundle={selectedBundle}
                setSelectedBundle={setSelectedBundle}
            />
        </div>
    );
};
