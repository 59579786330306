import React from "react";
import cx from "classnames";
import { styled } from "@mui/system";

// Components
import { ButtonSecondary } from "components/Buttons/Buttons";
import CheckIcon from "@mui/icons-material/Check";
import { Text } from "components/Text/Text";
import { TextField } from "components/Inputs/TextField/TextField";

// CSS
import styles from "./cart-discount.module.scss";

// Hooks
import { useCartDiscount } from "./hooks/useCartDiscount";

// Constants
const TextFieldStyled = styled(TextField)({
    "& .MuiInputLabel-root": {
        fontSize: "14px",
        lineHeight: "24px",
    },
});

export const CartDiscount = () => {
    /**
     * Custom Hooks
     */

    const {
        cart,
        discountCode,
        handleChange,
        handleSubmit,
        isSubmitting,
        isSubmittingCart,
        isUpdatingCart,
    } = useCartDiscount();

    /**
     * End Hooks
     */
    return (
        <div className={styles.cartDiscount}>
            <div className={styles.header}>
                <Text>Discount Code</Text>
            </div>
            <form className={styles.form} onSubmit={handleSubmit}>
                <TextFieldStyled
                    className={styles.input}
                    cypressTestId="cart-discount-input"
                    disabled={isUpdatingCart}
                    label="Enter discount code"
                    name="discountCode"
                    onChange={handleChange}
                    size="small"
                    type="text"
                    value={discountCode}
                />
                <ButtonSecondary
                    classes={cx(styles.button, {
                        [styles["button--disabled"]]: isUpdatingCart,
                    })}
                    config={{ shouldMaintainDimensions: true }}
                    isDisabled={
                        isSubmitting || isSubmittingCart || isUpdatingCart
                    }
                    isLoading={isSubmitting}
                    onClick={handleSubmit}
                    type="submit"
                >
                    <Text>Apply</Text>
                </ButtonSecondary>
            </form>
            <div className={styles.activeCodes}>
                {cart.discounts.map((discount) => {
                    return (
                        <div className={styles.code} key={discount.id}>
                            <CheckIcon className={styles.icon} />
                            <Text>{discount.code}</Text>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
