import React from "react";
import PropTypes from "prop-types";
import { Skeleton } from "@mui/material";

// Components
import { QuantitySelector } from "components/QuantitySelector/QuantitySelector";
import { Text } from "components/Text/Text";

// CSS
import styles from "../../memory-book.module.scss";

export const MemoryBookQuantity = ({
    cart,
    isUpdatingCart,
    updateCartBookQuantity,
}) => {
    if (isUpdatingCart) {
        return (
            <div className={styles.quantity}>
                <div className={styles.item}>
                    <div className={styles.label}>
                        <Skeleton variant="text" width={200} />
                    </div>
                    <div className={styles.value}>
                        <Skeleton variant="text" width={50} />
                    </div>
                </div>
            </div>
        );
    } else {
        const item = cart?.items.find((item) => item.title === "Memory Book");
        return (
            <div className={styles.quantity}>
                <div className={styles.item}>
                    <div className={styles.label}>
                        <Text classes={styles.title} isBold>
                            Quantity
                        </Text>
                    </div>
                    <div className={styles.value}>
                        <QuantitySelector
                            initialQuantity={item?.quantity}
                            maximumQuantity={10}
                            minimumQuantity={1}
                            onChange={updateCartBookQuantity}
                        />
                    </div>
                </div>
            </div>
        );
    }
};

MemoryBookQuantity.propTypes = {
    cart: PropTypes.object,
    isUpdatingCart: PropTypes.bool,
    updateCartBookQuantity: PropTypes.func,
};
