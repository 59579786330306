import React from "react";

// Components
import { Text } from "components/Text/Text";
import { WalletGiftCardsLandingEasyToSendIcon } from "./components/Icons/WalletGiftCardsLandingEasyToSendIcon";
import { WalletGiftCardsLandingFinancialSupportIcon } from "./components/Icons/WalletGiftCardsLandingFinancialSupportIcon";
import { WalletGiftCardsLandingFundsNeverExpireIcon } from "./components/Icons/WalletGiftCardsLandingFundsNeverExpireIcon";
import { WalletGiftCardsLandingPhysicalCardIcon } from "./components/Icons/WalletGiftCardsLandingPhysicalCardIcon";

// CSS
import styles from "./wallet-gift-cards-landing-features.module.scss";

export const WalletGiftCardsLandingFeatures = (props) => {
    return (
        <section className={styles["wallet-gift-cards-landing-features"]}>
            <div className={styles.header}>
                <Text isBold type="h2">
                    Gift Card Features
                </Text>
                <span className={styles.description}>
                    Gift Cards are the easiest way to send extra support to your
                    recruit at basic training and beyond.
                </span>
            </div>
            <div className={styles.features}>
                <div className={styles.feature}>
                    <div className={styles.image}>
                        <WalletGiftCardsLandingFinancialSupportIcon />
                    </div>
                    <div className={styles.text}>
                        <Text isBold type="h3">
                            Financial Support
                        </Text>
                        <Text type="p">
                            Help your recruit focus on their training, not their
                            finances. Gift cards can be used for essentials from
                            toiletries and cleaning supplies, to stamps and
                            chap-stick.
                        </Text>
                    </div>
                </div>
                <div className={styles.feature}>
                    <div className={styles.image}>
                        <WalletGiftCardsLandingPhysicalCardIcon />
                    </div>
                    <div className={styles.text}>
                        <Text isBold type="h3">
                            Gift Card Via Mail
                        </Text>
                        <Text type="p">
                            Gift Cards arrive on base via mail as a part of the
                            letter. With no activation required, your recruit
                            can use it right away.
                        </Text>
                    </div>
                </div>
                <div className={styles.feature}>
                    <div className={styles.image}>
                        <WalletGiftCardsLandingFundsNeverExpireIcon />
                    </div>
                    <div className={styles.text}>
                        <Text isBold type="h3">
                            Funds Never Expire
                        </Text>
                        <Text type="p">
                            Gift Card funds do not expire. They can be used
                            during training at Exchange stores, or after
                            graduation anywhere Exchange gift cards are
                            accepted.
                        </Text>
                    </div>
                </div>
                <div className={styles.feature}>
                    <div className={styles.image}>
                        <WalletGiftCardsLandingEasyToSendIcon />
                    </div>
                    <div className={styles.text}>
                        <Text isBold type="h3">
                            Easy To Send
                        </Text>
                        <Text type="p">
                            Gift Cards are sent as a part of the letter, with
                            your message. This means you don't have to send a
                            separate gift card to send support to your recruit
                            at basic training.
                        </Text>
                    </div>
                </div>
            </div>
        </section>
    );
};
