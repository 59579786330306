import React from "react";

// Components
import { ButtonInvisible, ButtonPrimary } from "components/Buttons/Buttons";
import { ConfirmVerificationCodeInput } from "../../Inputs/ConfirmVerificationCodeInput/ConfirmVerificationCodeInput";
import { Text } from "components/Text/Text";

// CSS
import styles from "../phone-verification-modal.module.scss";

export const PhoneVerificationModalVerificationCode = (props) => {
    const {
        handleChangeVerificationCode,
        handleSubmitVerificationCode,
        loading,
        resendVerificationCode,
    } = props;

    function renderSubmitButton() {
        return loading.verificationCode ? (
            <ButtonPrimary
                color="orange"
                classes={styles.submit}
                isDisabled={loading.verificationCode}
                text="Verifying..."
                type="submit"
            />
        ) : (
            <ButtonPrimary
                color="orange"
                classes={styles.submit}
                cypressTestId="phone-verification-modal-verification-code-submit-button"
                onClick={handleSubmitVerificationCode}
                text="Verify"
                type="submit"
            />
        );
    }

    return (
        <div className={styles["verification-code"]}>
            <Text classes={styles.header} type="h2">
                Please verify with the code we just sent
            </Text>
            <form
                className={styles.form}
                onSubmit={handleSubmitVerificationCode}
            >
                <ConfirmVerificationCodeInput
                    label=""
                    loading={loading.verify}
                    length={6}
                    onChange={handleChangeVerificationCode}
                />
                {renderSubmitButton()}
            </form>
            <ButtonInvisible
                onClick={resendVerificationCode}
                text="Resend Code"
                type="button"
            />
        </div>
    );
};
