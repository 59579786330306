import React, { useState } from "react";
import cx from "classnames";

// Components
import { BookIcon } from "components/Icons/BookIcon";
import { InboxEmptyIcon } from "components/Icons/InboxEmptyIcon";
import { Tabs } from "components/Tabs/Tabs";
import { Text } from "components/Text/Text";
import { UserList } from "components/UserList/UserList";
import { UserSearch } from "components/UserSearch/UserSearch";

// CSS
import "../../../../../../css/letters/scenes/Compose/letters-compose-recipient.scss";

// Enums
import { LettersListTabIds } from "./constants/constants";

// Utils
import { emptyFunction } from "utils/miscUtils";

// Constants
const chainIconSrc = require("../../../../../../media/icons/chain.svg").default;
const icon = {
    shouldShow: (contact) => contact.isSandboxxUser,
    src: chainIconSrc,
};

export const LettersComposeRecipientSearch = (props) => {
    const {
        contacts,
        handleSelectSearchResult,
        isSearching,
        recentContacts,
        recipient,
        setIsSearching,
    } = props;

    /************************************************
     * Constants
     ************************************************/

    const hasContacts = !!contacts?.length;
    const hasRecentContacts = !!recentContacts?.length;

    /************************************************
     * useState
     ************************************************/

    const [selectedSearchListTabId, setSelectedSearchListTabId] = useState(
        hasRecentContacts
            ? LettersListTabIds.RECENTS_TAB_ID
            : LettersListTabIds.CONTACTS_TAB_ID
    );

    /************************************************
     * End Hooks & Constants
     ************************************************/

    function handleSelectSearchListTab(id) {
        setSelectedSearchListTabId(id);
    }

    function renderTabs() {
        const tabsData = hasRecentContacts
            ? [
                  {
                      id: LettersListTabIds.RECENTS_TAB_ID,
                      index: 0,
                      isActive:
                          selectedSearchListTabId ===
                          LettersListTabIds.RECENTS_TAB_ID,
                      onClick: () => {
                          handleSelectSearchListTab(
                              LettersListTabIds.RECENTS_TAB_ID
                          );
                      },
                      text: "Recents",
                      cyId: "recents-tab",
                  },
                  {
                      id: LettersListTabIds.CONTACTS_TAB_ID,
                      index: 1,
                      isActive:
                          selectedSearchListTabId ===
                          LettersListTabIds.CONTACTS_TAB_ID,
                      onClick: () => {
                          handleSelectSearchListTab(
                              LettersListTabIds.CONTACTS_TAB_ID
                          );
                      },
                      text: "Address Book",
                      cyId: "contacts-tab",
                  },
              ]
            : [
                  {
                      id: 0,
                      index: 0,
                      isActive: true,
                      onClick: emptyFunction,
                      text: "Address Book",
                      cyId: "contacts-tab",
                  },
              ];

        return (
            <div className="letters-compose__recipient__search__lists__tabs">
                <Tabs data={tabsData} />
            </div>
        );
    }

    function renderRecentContactList() {
        if (hasRecentContacts) {
            return (
                <UserList
                    isClickable={true}
                    handleUserSelect={handleSelectSearchResult}
                    icon={icon}
                    selectedUserListItem={recipient}
                    userList={recentContacts}
                />
            );
        } else {
            return (
                <div className="empty">
                    <InboxEmptyIcon className="icon" />
                    <Text classes="text" type="p">
                        You haven't sent any letters yet. Create a contact or
                        search for your recipient above.
                    </Text>
                </div>
            );
        }
    }

    function renderContactList() {
        if (hasContacts) {
            return (
                <UserList
                    isClickable={true}
                    handleUserSelect={handleSelectSearchResult}
                    icon={icon}
                    selectedUserListItem={recipient}
                    userList={contacts}
                />
            );
        } else {
            return (
                <div className="empty">
                    <BookIcon classes="icon" />
                    <Text classes="text" type="p">
                        You don't have any recipients yet. Get started by
                        creating a new one or searching using their email or
                        phone number.
                    </Text>
                </div>
            );
        }
    }

    function renderUserLists() {
        return (
            <div className="letters-compose__recipient__search__lists">
                {selectedSearchListTabId === LettersListTabIds.RECENTS_TAB_ID &&
                    renderRecentContactList()}
                {selectedSearchListTabId ===
                    LettersListTabIds.CONTACTS_TAB_ID && renderContactList()}
            </div>
        );
    }

    return (
        <div className="letters-compose__recipient__search__grid">
            <UserSearch
                {...props}
                handleSelectSearchResult={handleSelectSearchResult}
                isSearching={isSearching}
                setIsSearching={setIsSearching}
            />

            <div
                className={cx(
                    "letters-compose__recipient__search__grid__user-lists panel",
                    {
                        "letters-compose__recipient__search__grid__user-lists--hidden":
                            isSearching,
                    }
                )}
            >
                {renderTabs()}
                {renderUserLists()}
            </div>
        </div>
    );
};
