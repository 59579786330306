import React, { useEffect } from "react";
import cx from "classnames";

// Components
import { ButtonBlock } from "components/Buttons/ButtonBlock/ButtonBlock";
import { Text } from "components/Text/Text";

// CSS
import signInStyles from "../../../sign-in.module.scss";
import styles from "./sign-up-persona-root.module.scss";

// Enums
import { NavigationEnum } from "scenes/SignIn/constants/constants";
import { RightColumnMode } from "scenes/SignIn/enums/RightColumnMode";

// Loggers
import { AnalyticsLogger } from "loggers/AnalyticsLogger";

// Platforms
import { SandboxxRestAPI } from "utils/sandboxx";

export const SignUpPersonaRoot = (props) => {
    const {
        connections,
        fetchRootPersonas,
        handleSelectRootPersona,
        isLoadingRootPersonaSelection,
        rootPersonas,
        setConnections,
        setCurrentSection,
        setIsLoadingRootPersonaSelection,
        setRightColumnMode,
        setUserInfo,
    } = props;

    /**
     * useEffect
     */

    useEffect(() => {
        setRightColumnMode(RightColumnMode.SLIDESHOW);

        if (!rootPersonas) {
            fetchRootPersonas();
        }

        /**
         * This analytics event may need to be moved to
         * SignUpVerificationSuccess.js depending on how the
         * analytics for this feature update play out.
         */
        AnalyticsLogger.logOnboardingStart();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * End Hooks
     */

    function onUpdateRootPersonaSuccess() {
        if (connections === null) {
            SandboxxRestAPI.checkForOnboardingSkip(
                onCheckForOnboardingSkipSuccess,
                onCheckForOnboardingSkipFailure,
                onCheckForOnboardingSkipFailure
            );
        } else if (connections?.length) {
            setCurrentSection(NavigationEnum.SIGN_UP_CONNECTED);
        } else {
            setCurrentSection(NavigationEnum.SIGN_UP_PERSONA_CHILD);
        }
    }

    function handleSelectPersona(persona) {
        handleSelectRootPersona(persona, {
            onSuccess: onUpdateRootPersonaSuccess,
        });
        setUserInfo((prev) => ({
            ...prev,
            personas: [...prev.personas, persona.id],
        }));
    }

    function onCheckForOnboardingSkipFailure(err) {
        setCurrentSection(NavigationEnum.SIGN_UP_PERSONA_CHILD);
    }

    function onCheckForOnboardingSkipSuccess(connections) {
        const hasConnection = !!connections?.length;
        setIsLoadingRootPersonaSelection(false);
        if (hasConnection) {
            setConnections(connections);
            setCurrentSection(NavigationEnum.SIGN_UP_CONNECTED);
        } else {
            setCurrentSection(NavigationEnum.SIGN_UP_PERSONA_CHILD);
        }
    }

    function renderPersonas() {
        if (rootPersonas && !isLoadingRootPersonaSelection) {
            return rootPersonas.map((persona) => {
                return (
                    <ButtonBlock
                        bodyText={
                            persona.description ||
                            "This is where a user will see the persona description text."
                        }
                        classes={styles.persona}
                        cypressTestId={`sign-up-persona-root-${persona.id}`}
                        headerText={persona.displayName || persona.name}
                        key={persona.id}
                        onClick={() => handleSelectPersona(persona)}
                    />
                );
            });
        } else {
            return (
                <>
                    <ButtonBlock classes={styles.persona} isLoading={true} />
                    <ButtonBlock classes={styles.persona} isLoading={true} />
                    <ButtonBlock classes={styles.persona} isLoading={true} />
                </>
            );
        }
    }

    return (
        <div className={cx(signInStyles.content, styles.signUpPersonaRoot)}>
            <div className={signInStyles.top}>
                <div className={signInStyles.header}>
                    <Text classes={signInStyles.text}>
                        How do you want to use Sandboxx?
                    </Text>
                    <Text classes={signInStyles["text-secondary"]}>
                        You will still be able to access all Sandboxx features
                        regardless of your selection.
                    </Text>
                </div>
                <div className={styles.personas}>{renderPersonas()}</div>
            </div>
        </div>
    );
};
