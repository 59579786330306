import React from "react";
import PropTypes from "prop-types";

// Components
import { AddPaymentOption } from "components/PaymentOption/AddPaymentOption";
import { SavedPaymentOptions } from "components/PaymentOption/SavedPaymentOptions";

export const WalletManagePurchaseCardModalSelectPayment = (props) => {
    const {
        loading,
        newCard,
        savedCards,
        selectedCard,
        setCurrentSection,
        setLoading,
        setNewCard,
        setSelectedCard,
        showNotification,
    } = props;

    /************************************************
     * Constants
     ************************************************/

    const savedCardEndItem = { type: "select" };

    /************************************************
     * End Hooks
     ************************************************/

    function handleCardInputChange(e) {
        const { name, value } = e.target;
        setNewCard({
            ...newCard,
            [name]: value,
        });
    }

    function handleCardSelect(selectedCard, isSavedCard) {
        setSelectedCard((prevSelectedCard) => ({
            ...prevSelectedCard,
            card: selectedCard,
            isSavedCard,
        }));
    }

    function handleCardSetToken(token) {
        setNewCard({ ...newCard, token });
    }

    function handleSavedCardSelect(card, index, callback) {
        setSelectedCard((prevSelectedCard) => ({
            ...prevSelectedCard,
            card,
            index,
        }));
        callback(card);
    }

    function renderButtons(cardType, submitCallback) {
        const submitButton = loading[cardType] ? (
            <button className="button button--primary button-small disabled">
                <span>Loading...</span>
            </button>
        ) : (
            <button
                className="button button--primary button-small"
                onClick={submitCallback}
            >
                <span>Next</span>
            </button>
        );
        return (
            <div className="button__container force-right">{submitButton}</div>
        );
    }

    return (
        <div
            className="letters-compose-add-ons__select-payment"
            data-cy="wallet-purchase-card-modal-select-payment"
        >
            <div className="letters-compose-add-ons-payment-container">
                <AddPaymentOption
                    changeCurrentSection={setCurrentSection}
                    context="wallet"
                    handleCardInputChange={handleCardInputChange}
                    handleCardSelect={handleCardSelect}
                    handleCardSetToken={handleCardSetToken}
                    hideBackButton={true}
                    loading={loading}
                    newCard={newCard}
                    renderButtons={renderButtons}
                    setLoading={setLoading}
                    showNotification={showNotification}
                />
                <div className="new-modal-text-divider horizontal">OR</div>
                <SavedPaymentOptions
                    changeCurrentSection={setCurrentSection}
                    context="wallet"
                    endItem={savedCardEndItem}
                    handleCardSelect={handleCardSelect}
                    handleSavedCardSelect={handleSavedCardSelect}
                    renderButtons={renderButtons}
                    savedCards={savedCards}
                    selectedCard={selectedCard}
                />
            </div>
        </div>
    );
};

WalletManagePurchaseCardModalSelectPayment.propTypes = {
    handleClosePurchaseCardModal: PropTypes.func.isRequired,
    loading: PropTypes.object.isRequired,
    newCard: PropTypes.object.isRequired,
    savedCards: PropTypes.array,
    selectedCard: PropTypes.object,
    setCurrentSection: PropTypes.func.isRequired,
    setLoading: PropTypes.func.isRequired,
    setNewCard: PropTypes.func.isRequired,
    setSelectedCard: PropTypes.func.isRequired,
    showNotification: PropTypes.func.isRequired,
};
