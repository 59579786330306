import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";

// Context
import { PhoneVerificationContext } from "context/phoneVerification";
import { NotificationBarContext } from "context/notificationBar";

// Loggers
import { AnalyticsLogger } from "loggers/AnalyticsLogger";

// Models
import { Contact } from "models/contacts/Contact";

// Platforms
import { CurrentUser, SandboxxRestAPI } from "utils/sandboxx";

export const usePhoneVerificationModal = () => {
    /**
     * Router Hooks
     */

    const history = useHistory();

    /**
     * useContext
     */

    const {
        phoneVerificationModalSetting,
        showPhoneVerificationModal,
        togglePhoneVerificationModal,
    } = useContext(PhoneVerificationContext);
    const { showNotification } = useContext(NotificationBarContext);

    /**
     * useState
     */

    const [connection, setConnection] = useState([]);
    const [currentSection, setCurrentSection] = useState("phoneNumber");
    const [loading, setLoading] = useState({
        phoneNumber: false,
        verificationCode: false,
    });
    const [phoneNumber, setPhoneNumber] = useState({
        baseNumber: "",
        countryCode: "",
        isValid: false,
    });
    const [verificationCode, setVerificationCode] = useState("");
    const [verificationType] = useState("sms");

    /**
     * End Hooks & Constants
     */

    function fetchVerificationCode() {
        const payload = { phoneNumber, via: verificationType };
        return SandboxxRestAPI.generatePhoneVerificationCode(
            payload,
            onGenerateCodeSuccess,
            onGenerateCodeError,
            onGenerateCodeError
        );
    }

    function handleChangePhoneNumber(isValid, value, countryData) {
        const { dialCode } = countryData;
        setPhoneNumber((prev) => ({
            ...prev,
            baseNumber: value,
            countryCode: dialCode,
            isValid,
        }));
    }

    function handleChangeVerificationCode(value) {
        const formattedValue = value.join("");
        setVerificationCode(formattedValue);
    }

    function handleClearPhoneNumber() {
        const { dialCode } = arguments[1];
        setPhoneNumber((prev) => ({
            ...prev,
            baseNumber: "",
            countryCode: dialCode,
            isValid: false,
        }));
    }

    function handleCloseModal() {
        togglePhoneVerificationModal(false);
        setTimeout(() => {
            setCurrentSection("phoneNumber");
        }, 1000);
    }

    function handleGoToLetterCompose() {
        const contact = new Contact(connection);
        const isContactInstance = contact instanceof Contact;
        if (isContactInstance) {
            localStorage.setItem(
                "sandboxxMessageRecipient",
                JSON.stringify(contact.generateContactObject())
            );
        }
        history.go(0);
    }

    function handleSubmitPhoneNumber(e) {
        e && e.preventDefault();
        setLoading((prev) => ({ ...prev, phoneNumber: true }));
        if (phoneNumber.isValid) {
            fetchVerificationCode();
        } else {
            setLoading((prev) => ({ ...prev, phoneNumber: false }));
            showFailedValidationNotification();
        }
    }

    function handleSubmitVerificationCode(e) {
        e && e.preventDefault();
        setLoading((prev) => ({ ...prev, verificationCode: true }));
        SandboxxRestAPI.checkPhoneVerificationCode(
            { verificationCode },
            onCheckVerificationCodeSuccess,
            onCheckVerificationCodeError,
            onCheckVerificationCodeError
        );
    }

    function onCheckVerificationCodeError(err) {
        console.error("onCheckVerificationCodeError", err);
        setLoading((prev) => ({ ...prev, verificationCode: false }));
        showNotification({
            text: err.message,
            type: "warning",
        });
    }

    function onCheckVerificationCodeSuccess(res) {
        const { success } = res;
        if (success) {
            storeUserAfterUpdate();
            SandboxxRestAPI.checkForOnboardingSkip(
                onCheckForOnboardingSkipSuccess,
                onCheckForOnboardingSkipFailure,
                onCheckForOnboardingSkipFailure
            );
            AnalyticsLogger.logVerificationConfirmPhone();
        } else {
            showNotification({
                text: "That verification code is not correct.",
                type: "warning",
            });
            setLoading((prev) => ({ ...prev, verificationCode: false }));
        }
    }

    function onCheckForOnboardingSkipFailure(err) {
        console.error("onCheckForOnboardingSkip err", err);
        setCurrentSection("complete");
        setLoading((prev) => ({ ...prev, verificationCode: false }));
    }

    function onCheckForOnboardingSkipSuccess(connections) {
        const hasConnection = !!(connections && connections.length);
        if (hasConnection) {
            setConnection(connections[0]);
            setCurrentSection("completeConnection");
        } else {
            setCurrentSection("complete");
        }
        setLoading((prev) => ({ ...prev, verificationCode: false }));
    }

    function onGenerateCodeError(err) {
        setLoading((prev) => ({ ...prev, phoneNumber: false }));
        showNotification({
            text: err.message,
            type: "warning",
        });
    }

    function onGenerateCodeSuccess(res) {
        setLoading((prev) => ({ ...prev, phoneNumber: false }));
        if (res.success) {
            AnalyticsLogger.logVerificationEnterPhone();
            setCurrentSection("verificationCode");
        } else {
            showNotification({
                text: "There was an issue generating your code. Please try again.",
                type: "warning",
            });
        }
    }

    function resendVerificationCode() {
        const payload = { phoneNumber, verificationType };
        return SandboxxRestAPI.resendPhoneVerificationCode(
            payload,
            onResendVerificationCodeSuccess,
            onResendVerificationCodeError,
            onResendVerificationCodeError
        );
    }

    function onResendVerificationCodeError() {
        showNotification({
            text: "There was an issue resending your code, please try again.",
            type: "warning",
        });
    }

    function onResendVerificationCodeSuccess() {
        showNotification({
            text: `A new verification code has been sent to ${phoneNumber.baseNumber}`,
            type: "success",
        });
    }

    function showFailedValidationNotification() {
        showNotification({
            text: "Please enter a valid phone number.",
            type: "warning",
        });
    }

    function storeUserAfterUpdate() {
        return SandboxxRestAPI.getAccount((res) => {
            const { user, onboarding, verification } = res;
            CurrentUser.storeUser(user, verification, onboarding);
        });
    }

    return {
        connection,
        currentSection,
        handleChangePhoneNumber,
        handleChangeVerificationCode,
        handleClearPhoneNumber,
        handleCloseModal,
        handleGoToLetterCompose,
        handleSubmitPhoneNumber,
        handleSubmitVerificationCode,
        loading,
        phoneNumber,
        phoneVerificationModalSetting,
        resendVerificationCode,
        showPhoneVerificationModal,
        togglePhoneVerificationModal,
    };
};
