import { useEffect, useRef, useState } from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// Components
import { ChevronDownIcon } from "components/Icons/ChevronDownIcon";
import { ChevronUpIcon } from "components/Icons/ChevronUpIcon";
import { MinusIcon } from "components/Icons/MinusIcon";
import { PlusIcon } from "components/Icons/PlusIcon";
import { Text } from "components/Text/Text";

// CSS
import styles from "./accordion.module.scss";

// Utils
import { handleKeyDown } from "utils/eventUtils";
import { emptyFunction } from "utils/miscUtils";

export const Accordion = (props) => {
    const {
        children,
        classNames,
        headerIcon,
        indicatorType,
        onSetToActive,
        onSetToInactive,
        shouldForceActive,
        shouldForceInactive,
        subtext,
        title,
        type,
    } = props;

    /**
     * useRef
     */

    const contentRef = useRef(null);

    /**
     * useState
     */

    const [isActive, setIsActive] = useState(false);
    const [height, setHeight] = useState("0px");

    /**
     * useEffect
     */

    useEffect(() => {
        handleSetAccordionHeight();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [children, isActive]);

    useEffect(() => {
        shouldForceActive && handleSetToActive();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldForceActive]);

    useEffect(() => {
        shouldForceInactive && handleSetToInactive();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldForceInactive]);

    /**
     * End Hooks
     */

    function handleSetAccordionHeight() {
        setHeight(isActive ? `${contentRef.current.scrollHeight}px` : "0px");
    }

    function handleIsActiveToggle() {
        // Optional prop callbacks based on pre-setState isActive value
        if (isActive) onSetToInactive();
        if (!isActive) onSetToActive();

        // Set state
        setIsActive((prev) => !prev);
    }

    function handleSetToActive() {
        setIsActive(true);
        onSetToActive();
    }

    function handleSetToInactive() {
        setIsActive(false);
        onSetToInactive();
    }

    function renderClosedIcon() {
        return indicatorType === "chevron" ? (
            <div className={styles.chevronHolder}>
                <ChevronDownIcon height="20px" width="20px" color="#2B536A" />
            </div>
        ) : (
            <PlusIcon />
        );
    }

    function renderOpenIcon() {
        return indicatorType === "chevron" ? (
            <div className={styles.chevronHolder}>
                <ChevronUpIcon height="20px" width="20px" color="#2B536A" />
            </div>
        ) : (
            <MinusIcon />
        );
    }

    return (
        <div
            className={cx(styles.accordion, classNames.container, {
                [classNames["container--active"]]: isActive,
            })}
        >
            <div className={styles.item}>
                <div
                    className={styles.header}
                    onClick={handleIsActiveToggle}
                    onKeyDown={(e) => {
                        handleKeyDown(e, handleIsActiveToggle);
                    }}
                    tabIndex="0"
                >
                    <div className={styles.leftHandContent}>
                        {headerIcon && (
                            <div className={styles.iconHolder}>
                                {headerIcon}
                            </div>
                        )}
                        <div>
                            <Text classes={styles.title} isBold type={type}>
                                {title}
                            </Text>
                            {subtext && <Text type="p">{subtext}</Text>}
                        </div>
                    </div>
                    {isActive ? renderOpenIcon() : renderClosedIcon()}
                </div>
                <div
                    className={cx(styles.content, classNames.content)}
                    ref={contentRef}
                    style={{ maxHeight: height }}
                >
                    {children}
                </div>
            </div>
        </div>
    );
};

Accordion.defaultProps = {
    classNames: { container: "", content: "" },
    indicatorType: "plus",
    onSetToActive: emptyFunction,
    onSetToInactive: emptyFunction,
    shouldForceActive: false,
};

Accordion.propTypes = {
    children: PropTypes.node.isRequired,
    classNames: PropTypes.object,
    indicatorType: PropTypes.string,
    onSetToActive: PropTypes.func,
    onSetToInactive: PropTypes.func,
    shouldForceActive: PropTypes.bool,
    shouldForceInactive: PropTypes.bool,
    title: PropTypes.string.isRequired,
};
