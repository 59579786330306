import React from "react";
import cx from "classnames";

// Components
import { ChevronRightIcon } from "components/Icons/ChevronRightIcon";
import { Text } from "components/Text/Text";
import { WalletGiftCardsPurchaseRecipientCard } from "../WalletGiftCardsPurchaseRecipientCard/WalletGiftCardsPurchaseRecipientCard";

// CSS
import styles from "./wallet-gift-cards-purchase-recipient-contacts.module.scss";

// Enums
import { RecipientSourceEnum } from "../../../../enums/RecipientSourceEnum";

// Utils
import { handleKeyDown } from "utils/eventUtils";

export const WalletGiftCardsPurchaseRecipientContacts = (props) => {
    const { recipient, recipientSource, setShouldShowContactsModal } = props;

    /**
     * Constants
     */

    const isActive = recipientSource === RecipientSourceEnum.CONTACTS;

    /**
     * End Constants
     */

    return (
        <div
            className={cx(
                styles["wallet-gift-cards-purchase-recipient-contacts"],
                {
                    [styles[
                        "wallet-gift-cards-purchase-recipient-contacts--active"
                    ]]: isActive,
                }
            )}
        >
            <div
                className={styles.header}
                onClick={() => setShouldShowContactsModal(true)}
                onKeyDown={(e) => {
                    handleKeyDown(e, () => setShouldShowContactsModal(true));
                }}
                tabIndex="0"
            >
                <Text isBold type="h4">
                    Choose From Contacts
                </Text>
                <div className={styles.chevron}>
                    <ChevronRightIcon height="20px" width="20px" />
                </div>
            </div>
            {isActive && (
                <div className={styles.cards}>
                    <WalletGiftCardsPurchaseRecipientCard
                        {...props}
                        classes={styles.card}
                        isActive
                        recipient={recipient}
                    />
                </div>
            )}
        </div>
    );
};
