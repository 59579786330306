import { useEffect, useState } from "react";

// Enums
import { NavigationEnum } from "scenes/SignIn/constants/constants";
import { RightColumnMode } from "../enums/RightColumnMode";
import { SignInModeEnum } from "../scenes/SignIn/SignInMain/constants/constants";
import { SignInPlatform } from "../scenes/SignIn/SignInMain/constants/constants";

// Loggers
import { AnalyticsLogger } from "loggers/AnalyticsLogger";

// Platforms
import { Analytics } from "../../../platforms/analytics";
import { Intercom } from "../../../platforms/intercom";

// Utils
import { CurrentUser, SandboxxRestAPI } from "utils/sandboxx";
import { emptyFunction } from "utils/miscUtils";
import { generateLandingPath } from "utils/navigationUtils";
import { getRecaptchaToken } from "utils/recaptchaUtils";

export const useSignIn = (props) => {
    const {
        history,
        runShouldShowNavBarCheck,
        setCurrentSection,
        showNotification,
        setUserInfo,
        userInfo,
    } = props;

    /**
     * useState
     */
    const [rightColumnMode, setRightColumnMode] = useState(
        RightColumnMode.WELCOME
    );
    const [signInLoading, setSignInLoading] = useState(false);
    const [signInMode, setSignInMode] = useState(SignInModeEnum.SIGN_UP);
    const [signInPlatform, setSignInPlatform] = useState(
        SignInPlatform.EMAIL_VERIFY
    );
    const [verificationCode, setVerificationCode] = useState("");

    /**
     * useEffect
     */

    useEffect(() => {
        runShouldShowNavBarCheck();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Constants
     */

    const isUsingEmail = signInPlatform === SignInPlatform.EMAIL;
    const isUsingEmailVerify = signInPlatform === SignInPlatform.EMAIL_VERIFY;
    const isUsingPhone = signInPlatform === SignInPlatform.PHONE;

    /**
     * End Hooks
     */

    function handleChangeEmail(e) {
        setUserInfo((prev) => ({ ...prev, email: e.target.value }));
    }

    function handleChangePassword(e) {
        setUserInfo((prev) => ({ ...prev, password: e.target.value }));
    }

    function handleChangeVerificationCode(value) {
        const formattedValue = value.join("");
        setVerificationCode(formattedValue);
    }

    function handleLogin(e) {
        e.preventDefault();
        const { musterInviteCode } =
            JSON.parse(sessionStorage.getItem("musterInviteCode")) || "";
        if (isUsingEmail) {
            if (userInfo.email && userInfo.password) {
                setSignInLoading(true);
                SandboxxRestAPI.login(
                    {
                        email: userInfo.email,
                        password: userInfo.password,
                        musterInviteCode,
                    },
                    onLoginSuccess,
                    onLoginError,
                    onLoginError
                );
            } else {
                showNotification({
                    text: "Please enter an email and password.",
                    type: "warning",
                });
            }
        } else if (isUsingEmailVerify) {
            setSignInLoading(true);
            getRecaptchaToken(
                "authEmailGenerate",
                (recaptchaToken) => {
                    SandboxxRestAPI.generateEmailVerificationCode(
                        {
                            email: userInfo.email,
                            platform: "WEB",
                            recaptchaToken,
                        },
                        onGenerateEmailVerificationSuccess,
                        onGenerateEmailVerificationFailure,
                        onGenerateEmailVerificationFailure
                    );
                },
                onLoginError
            );
        }
    }

    function handleSelectSignInPlatform(platform) {
        if (platform === SignInPlatform.EMAIL) {
            setSignInPlatform(SignInPlatform.EMAIL);
            AnalyticsLogger.logSignUpStart("email");
            return;
        }
        if (platform === SignInPlatform.EMAIL_VERIFY) {
            setSignInPlatform(SignInPlatform.EMAIL_VERIFY);
            setUserInfo((prev) => ({ ...prev, verificationType: "email" }));
            AnalyticsLogger.logSignUpStart("email");
            return;
        }
        if (platform === SignInPlatform.PHONE) {
            setSignInPlatform(SignInPlatform.PHONE);
            AnalyticsLogger.logSignUpStart("phone");
            return;
        }
    }

    function onGenerateEmailVerificationFailure(err) {
        console.error({ err });
        setSignInLoading(false);
        showNotification({
            text: err.message,
            type: "warning",
        });
    }

    function onGenerateEmailVerificationSuccess(res) {
        setCurrentSection(NavigationEnum.SIGN_UP_CONFIRM_EMAIL);
        showNotification({
            text: res.message,
            type: "success",
        });
        setSignInLoading(false);
    }

    function onLoginError(err) {
        setSignInLoading(false);
        showNotification({
            text: "The email and/or password you provided is not valid. Please try again.",
            type: "warning",
        });
    }

    function onLoginSuccess(res) {
        const { auth, musterConnection, onboarding, user, verification } = res;
        sessionStorage.setItem(
            "hasMusterConnection",
            musterConnection || false
        );
        CurrentUser.storeUserSignIn(user, auth, verification, onboarding);
        SandboxxRestAPI.getAccount(
            (res) => {
                CurrentUser.storeUserSignIn(
                    res.user,
                    auth,
                    verification,
                    onboarding
                );
                setSignInLoading(false);
                Intercom.registerUser();
                Analytics.login();
                resetEmailPassword();
                const rootPersona = res.user.personas.filter((persona) => {
                    return persona.type === "RootPersona";
                });
                const landingUrl = generateLandingPath(rootPersona[0], {
                    forcePasswordReset: auth.force_password_reset,
                });
                history.push(landingUrl.path);
                runShouldShowNavBarCheck();
            },
            emptyFunction,
            emptyFunction
        );
    }

    function resetEmailPassword() {
        setUserInfo((prev) => ({ ...prev, email: "", password: "" }));
        setSignInLoading(false);
    }

    function toggleSignInMode() {
        if (signInMode === SignInModeEnum.LOG_IN) {
            setSignInMode(SignInModeEnum.SIGN_UP);
        } else if (signInMode === SignInModeEnum.SIGN_UP) {
            setSignInMode(SignInModeEnum.LOG_IN);
        }
    }

    function storeUserAfterUpdate() {
        return SandboxxRestAPI.getAccount(
            (res) => {
                const { user, onboarding, verification } = res;
                CurrentUser.storeUser(user, verification, onboarding);
            },
            (err) => console.log(err),
            (err) => console.log(err)
        );
    }

    return {
        handleChangeVerificationCode,
        handleChangeEmail,
        handleChangePassword,
        handleLogin,
        handleSelectSignInPlatform,
        isUsingEmail,
        isUsingEmailVerify,
        isUsingPhone,
        rightColumnMode,
        signInLoading,
        signInMode,
        signInPlatform,
        setRightColumnMode,
        setSignInMode,
        setSignInPlatform,
        storeUserAfterUpdate,
        toggleSignInMode,
        verificationCode,
    };
};
