import { useContext, useEffect } from "react";

// Context
import { CartContext } from "context/Cart/cart";

export const useCartMain = ({ setHeaderText }) => {
    /**
     * useContext
     */

    const { cart, isSubmittingCart, isUpdatingCart } = useContext(CartContext);

    /**
     * useEffect
     */

    useEffect(() => {
        setHeaderText("Checkout");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * End Hooks
     */
    return { cart, isSubmittingCart, isUpdatingCart };
};
