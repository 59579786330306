import React from "react";
import cx from "classnames";

// Components
import { Banner } from "components/Banner/Banner";
import { ButtonSecondary } from "components/Buttons/Buttons";
import { LettersComposeReviewAddOnsPanelItem } from "./LettersComposeReviewAddOnsPanelItem";
import { Text } from "components/Text/Text";

// CSS
import styles from "../../../../../css/letters/components/letters-add-ons-panel.module.scss";

// Utils
import { isEmptyObject } from "../../../../../utils/miscUtils";

// Enums
import { AddOnsNavigation } from "../enums/enums";

export const LettersComposeReviewAddOnsPanel = (props) => {
    const {
        addOns,
        selectPostagePaid,
        giftCard,
        handleAddOnsModalToggle,
        hasPostagePaidInCart,
        isEligibleForPostagePaid,
    } = props;

    const hasAddOnsOptions = addOns?.length;
    const addOnsSelected =
        hasAddOnsOptions && addOns.filter(({ isSelected }) => isSelected);
    const hasAddOnsSelected = !!addOnsSelected?.length;
    const hasAddOnsSelectedAll = addOns?.length === addOnsSelected?.length;
    const hasGiftCard = !isEmptyObject(giftCard.selected);

    function renderContentItems() {
        if (hasAddOnsSelected || hasGiftCard) {
            return (
                <div className={styles.list}>
                    {renderItemsAddOns()}
                    {renderContentItemsGiftCard()}
                </div>
            );
        }
    }

    function renderContentItemsGiftCard() {
        return (
            hasGiftCard && (
                <LettersComposeReviewAddOnsPanelItem
                    {...props}
                    isGiftCard={true}
                />
            )
        );
    }

    function renderHeader() {
        return (
            <div className="letters-compose__review__section__header">
                <div>
                    <span className="letters-compose__review__section__header__text">
                        Add-Ons
                    </span>
                </div>
            </div>
        );
    }

    function renderItemsAddOns() {
        return (
            hasAddOnsSelected &&
            addOnsSelected.map((addOn, i) => {
                return (
                    <LettersComposeReviewAddOnsPanelItem
                        {...props}
                        addOn={addOn}
                        key={addOn.id}
                    />
                );
            })
        );
    }

    function renderPostagePaidBanner() {
        const text = (
            <Text classes={styles.text} type="p">
                Your letter is eligible for Reply Postage. Would you like to add
                it?{" "}
                <span className={styles.link} type="p">
                    Add Reply Postage
                </span>
            </Text>
        );
        return (
            isEligibleForPostagePaid &&
            !hasPostagePaidInCart && (
                <Banner
                    classes={styles.banner}
                    config={{ isActive: true }}
                    cypressTestId="letters-compose-review-add-ons-panel-postage-banner"
                    onClick={selectPostagePaid}
                    theme="blue"
                >
                    {text}
                </Banner>
            )
        );
    }

    return (
        <div
            className={cx(
                styles["letters-add-ons-panel"],
                "letters-compose__review__section",
                "add-ons-section"
            )}
        >
            {renderHeader()}

            {renderPostagePaidBanner()}
            {renderContentItems()}
            {!hasAddOnsSelectedAll && (
                <ButtonSecondary
                    classes="letters-compose__review__purchase-add-ons-button"
                    cypressTestId="letters-compose-review-add-ons-panel-add-on-button"
                    onClick={() =>
                        handleAddOnsModalToggle(true, {
                            section: AddOnsNavigation.SELECT_ITEMS,
                        })
                    }
                    text="View All Add-Ons"
                />
            )}
        </div>
    );
};
