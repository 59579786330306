import { useEffect, useState } from "react";

// Enums
import { LetterType } from "enums/LetterType";

// Media
import imageEmptySrc from "media/icons/image-empty.svg";

// Utils
import { formatSentLetterData } from "utils/lettersUtils";
import { SandboxxRestAPI } from "utils/sandboxx";

export const useLetterPreview = ({ letter, letterType }) => {
    /**
     * useState
     */

    const [imageSrc, setImageSrc] = useState(imageEmptySrc);

    /**
     * useEffect
     */

    useEffect(() => {
        fetchLetterImage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Constants
     */

    const letterFormatted = formatSentLetterData(letter, letterType);

    /**
     * End Hooks & Constants
     */

    function fetchLetterImage() {
        if (letterType === LetterType.SENT) {
            SandboxxRestAPI.getImageCloudfront(
                letterFormatted.photo_url,
                (res) => setImageSrc(res)
            );
        }
    }

    return { imageSrc, letterFormatted };
};
