import React from "react";
import cx from "classnames";

// Components
import { ButtonInvisible, ButtonPrimary } from "components/Buttons/Buttons";
import { ConfirmVerificationCodeInput } from "components/Inputs/ConfirmVerificationCodeInput/ConfirmVerificationCodeInput";

// CSS
import signInStyles from "../../../sign-in.module.scss";
import signUpConfirmPhoneStyles from "./sign-up-confirm-phone.module.scss";

// Hooks
import { useSignUpConfirmPhone } from "./hooks/useSignUpConfirmPhone";

export const SignUpConfirmPhone = ({
    loading,
    handleChangeVerificationCode,
    handleLoadingToggle,
    setConnections,
    setCurrentSection,
    storeUserAfterUpdate,
    userInfo,
    verificationCode,
}) => {
    /**
     * Custom Hooks
     */

    const {
        handleChangeConfirmVerificationCodeInput,
        handleSubmit,
        resendVerificationCode,
        shouldShowErrorState,
    } = useSignUpConfirmPhone({
        handleChangeVerificationCode,
        handleLoadingToggle,
        setConnections,
        setCurrentSection,
        storeUserAfterUpdate,
        userInfo,
        verificationCode,
    });

    /**
     * End Hooks
     */

    function renderForm() {
        return (
            <form
                className={cx(
                    signInStyles["form--verification-code"],
                    signUpConfirmPhoneStyles.form,
                    "form"
                )}
                data-action="authPhoneVerify"
                onSubmit={handleSubmit}
            >
                <ConfirmVerificationCodeInput
                    className={signUpConfirmPhoneStyles.input}
                    label=""
                    length={6}
                    loading={loading.verify}
                    onChange={handleChangeConfirmVerificationCodeInput}
                    shouldShowErrorState={shouldShowErrorState}
                />
                <div className={signUpConfirmPhoneStyles.buttons}>
                    {renderSubmitButton()}
                    <ButtonInvisible
                        classes={signUpConfirmPhoneStyles.button}
                        cypressTestId="sign-up-confirm-resend-code-button"
                        onClick={resendVerificationCode}
                        text="Resend Code"
                        type="button"
                    />
                </div>
            </form>
        );
    }

    function renderSubmitButton() {
        return loading.verify ? (
            <ButtonPrimary
                classes={cx(
                    signInStyles.button,
                    signUpConfirmPhoneStyles.button
                )}
                isDisabled={loading.verify}
                text="Verifying..."
                type="submit"
            />
        ) : (
            <ButtonPrimary
                classes={cx(
                    signInStyles.button,
                    signUpConfirmPhoneStyles.button
                )}
                cypressTestId="sign-up-confirm-verify-button"
                dataAttributes={{ "data-action": "authPhoneVerify" }}
                isDisabled={verificationCode.length < 6}
                onClick={handleSubmit}
                text="Continue"
                type="submit"
            />
        );
    }

    return (
        <div
            className={cx(
                signInStyles.content,
                signUpConfirmPhoneStyles["sign-up-confirm-phone"]
            )}
        >
            <div className={signInStyles.top}>
                <div
                    className={cx(
                        signInStyles.header,
                        signUpConfirmPhoneStyles.header
                    )}
                >
                    <span
                        className={signInStyles.text}
                        data-cy="sign-up-confirm-header-text"
                    >
                        Please verify your phone number
                    </span>
                    <span className={signInStyles["text-secondary"]}>
                        We have sent a verification code to the phone number you
                        provided. Please enter it below to create your account.
                    </span>
                </div>
                {renderForm()}
            </div>
        </div>
    );
};
