import React from "react";

// Components
import { NotificationCorner } from "../../../../components/Notification/NotificationCorner/NotificationCorner";

// Constants
const uspsLogoSrc = require("../../../../media/logos/usps.svg").default;

export const LettersSentMenuUSPSNotification = (props) => {
    const {
        handleHideUSPSNotification,
        setShowUSPSPrompt,
        showUSPSNotification,
        showUSPSPrompt,
    } = props;

    function renderOffer() {
        return (
            <>
                <div className="letters-sent__menu__usps__offer__content">
                    <div className="letters-sent__menu__usps__offer__content__logo">
                        <img alt="USPS logo" src={uspsLogoSrc} />
                    </div>
                    <div className="letters-sent__menu__usps__offer__content__text">
                        <span>
                            Receive a free digital preview of letters before
                            they arrive from basic training with the{" "}
                            <b>USPS Informed Delivery®</b> feature.
                        </span>
                    </div>
                </div>
                <div className="letters-sent__menu__usps__offer__button">
                    <a
                        className="button button--size-short-wide button--secondary"
                        href="https://informeddelivery.usps.com/box/pages/intro/start.action?utm_medium=digital&utm_source=ID&utm_campaign=sandboxx&utm_content=history"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        GET STARTED
                    </a>
                </div>
            </>
        );
    }

    function renderPrompt() {
        const onClickPermanent = () =>
            handleHideUSPSNotification({ isTemporary: false });
        const onClickTemporary = () =>
            handleHideUSPSNotification({ isTemporary: true });
        return (
            <div className="letters-sent__menu__usps__prompt">
                <div className="letters-sent__menu__usps__prompt__button">
                    <button
                        className="button button--size-short-wide button--secondary"
                        onClick={onClickTemporary}
                    >
                        REMIND ME LATER
                    </button>
                </div>
                <div
                    className="letters-sent__menu__usps__prompt__text"
                    onClick={onClickPermanent}
                >
                    <span>Don't remind me again</span>
                </div>
            </div>
        );
    }

    const onClose = showUSPSPrompt
        ? () => handleHideUSPSNotification({ isTemporary: true })
        : () => setShowUSPSPrompt(true);

    return (
        <NotificationCorner
            cypressCloseButtonTestId="letters-sent-usps-notification-corner-close-button"
            onClose={onClose}
            shouldShow={showUSPSNotification}
        >
            {showUSPSPrompt ? renderPrompt() : renderOffer()}
        </NotificationCorner>
    );
};
