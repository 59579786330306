import { useContext, useEffect } from "react";

// Context
import { NotificationBarContext } from "context/notificationBar";

// Enums
import { NavigationEnum } from "scenes/SignIn/constants/constants";

// Loggers
import { AnalyticsLogger } from "loggers/AnalyticsLogger";

// Utils
import { SandboxxRestAPI } from "utils/sandboxx";

export const useSignUpPhone = ({
    handleLoadingToggle,
    setCurrentSection,
    setUserInfo,
    userInfo,
}) => {
    const { phoneNumber, verificationType } = userInfo;
    const { isValid } = phoneNumber;

    /**
     * useContext
     */

    const { showNotification } = useContext(NotificationBarContext);

    /**
     * useEffect
     */

    useEffect(() => {
        setUserInfo((prev) => ({
            ...prev,
            verificationType: "sms",
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * End Hooks
     */

    function generateCode() {
        const payload = { phoneNumber, via: verificationType };
        return SandboxxRestAPI.generatePhoneVerificationCode(
            payload,
            onGenerateCodeSuccess,
            onGenerateCodeFailure,
            onGenerateCodeError
        );
    }

    function handleSkip() {
        setCurrentSection(NavigationEnum.SIGN_UP_PERSONA_ROOT);
    }

    function handleSubmit(e) {
        e && e.preventDefault();
        handleLoadingToggle("phone", true);
        if (isValid) {
            generateCode();
        } else {
            handleLoadingToggle("phone", false);
            showFailedValidationNotification();
        }
    }

    function onGenerateCodeError(err) {
        handleLoadingToggle("phone", false);
        showNotification({
            text: "There was an issue sending you a code, please wait a moment and try again.",
            type: "warning",
        });
    }

    function onGenerateCodeFailure(err) {
        handleLoadingToggle("phone", false);
        showNotification({
            text: err.message,
            type: "warning",
        });
    }

    function onGenerateCodeSuccess(res) {
        handleLoadingToggle("phone");
        if (res.success) {
            AnalyticsLogger.logVerificationEnterPhone();
            setCurrentSection(NavigationEnum.SIGN_UP_CONFIRM_PHONE);
        } else {
            showNotification({
                text: "There was an issue generating your code. Please try again.",
                type: "warning",
            });
        }
    }

    function showFailedValidationNotification() {
        showNotification({
            text: "Please enter a valid phone number.",
            type: "warning",
        });
    }

    return { handleSkip, handleSubmit };
};
