import { useState } from "react";

// Validation
import { Validation } from "validation/validation";

// Utils
import { SandboxxRestAPI } from "utils/sandboxx";
import { generateAttributes } from "../utils/attributeUtils";

// Constants
import { NavigationEnum } from "../../../../constants/constants";

export const useSignUpName = (props, setHasCompletedSignUpName) => {
    const {
        handleUpdateName,
        isUsingEmail,
        isUsingEmailVerify,
        isUsingPhone,
        needsNameForSSO,
        setCurrentSection,
        setShouldShowEmailInUseModal,
        showNotification,
        storeUserAfterUpdate,
        userInfo,
    } = props;
    const { email, firstName, lastName, password } = userInfo;

    /**
     * useState
     */

    const [isLoadingDetailsUpdate, setIsLoadingDetailsUpdate] = useState(false);

    /**
     * Constants
     */

    const hasValidEmail = Validation.isEmailAddress(email);
    const hasValidFirstName = Validation.isPresent(firstName);
    const hasValidLastName = Validation.isPresent(lastName);
    const hasValidPassword = Validation.isAtLeastOfLength(password, 6);
    const hasValidForm =
        hasValidFirstName &&
        hasValidLastName &&
        (needsNameForSSO ||
            (hasValidEmail && hasValidPassword) ||
            (isUsingPhone && email.length === 0));
    const isUsingOnlyNameFields =
        (isUsingEmail && !isUsingEmailVerify) ||
        needsNameForSSO ||
        (isUsingPhone && email.length === 0);

    /**
     * End Hooks & Constants
     */

    function handleInfoUpdate() {
        setIsLoadingDetailsUpdate(true);
        const attributesPayload = generateAttributes(userInfo, {
            isUsingEmailVerify,
            isUsingOnlyNameFields,
        });
        SandboxxRestAPI.phoneSignUpUpdateAttributes(
            attributesPayload,
            updateAttributeSuccess,
            updateAttributesFailure,
            updateAttributesFailure
        );
    }

    function handleSubmit(e) {
        e && e.preventDefault();
        if (hasValidForm) {
            if (isUsingEmailVerify) {
                handleInfoUpdate();
            } else {
                needsNameForSSO ? handleUpdateName() : handleInfoUpdate();
            }
            localStorage.setItem("hasCompletedSignUpName", true);
            setHasCompletedSignUpName(true);
        } else {
            showFailedValidationNotification();
        }
    }

    function showFailedValidationNotification() {
        if (!hasValidFirstName || !hasValidLastName) {
            showNotification({
                text: "Please enter a first and last name.",
                type: "warning",
            });
        } else if (!hasValidEmail) {
            showNotification({
                text: "Please provide a valid email address.",
                type: "warning",
            });
        } else if (!hasValidPassword) {
            showNotification({
                text: "Please enter a password that is at least 6 characters long.",
                type: "warning",
            });
        }
    }

    function updateAttributesFailure(err) {
        const { message } = err;
        setIsLoadingDetailsUpdate(false);
        if (message.includes("E-mail is not unique")) {
            setShouldShowEmailInUseModal(true);
        } else {
            showNotification({
                text: message,
                type: "warning",
            });
        }
    }

    function updateAttributeSuccess() {
        setCurrentSection(NavigationEnum.SIGN_UP_PHONE);
        storeUserAfterUpdate();
        setIsLoadingDetailsUpdate(false);
    }

    return {
        handleSubmit,
        handleInfoUpdate,
        hasValidEmail,
        hasValidFirstName,
        hasValidForm,
        hasValidLastName,
        hasValidPassword,
        isLoadingDetailsUpdate,
    };
};
