import React from "react";
import PropTypes from "prop-types";

// Components
import { ButtonInvisible, ButtonPrimary } from "components/Buttons/Buttons";
import { CheckmarkSuccessIcon } from "components/Icons/CheckmarkSuccessIcon";
import { Text } from "components/Text/Text";

// CSS
import styles from "./support-squad-settings-modal-create-invite-success.module.scss";

// Hooks
import { useSupportSquadSettingsModalCreateInviteSuccess } from "./hooks/useSupportSquadSettingsModalCreateInviteSuccess";

export const SupportSquadSettingsModalCreateInviteSuccess = ({
    handleModalClose,
    setCurrentSection,
    setHeaderSubText,
    setHeaderText,
    setInviteEmails,
}) => {
    /**
     * useEffect
     */

    const { handleGoToInviteMore } =
        useSupportSquadSettingsModalCreateInviteSuccess({
            setCurrentSection,
            setHeaderSubText,
            setHeaderText,
            setInviteEmails,
        });

    /**
     * End Hooks
     */

    function renderBody() {
        return (
            <div className={styles.body} data-cy="invite-to-squad-success">
                <CheckmarkSuccessIcon
                    className={styles.checkmarkSuccess}
                    height="64"
                    width="64"
                />
                <Text>Invites sent successfully</Text>
            </div>
        );
    }

    function renderButtons() {
        return (
            <div className={styles.buttons}>
                <ButtonPrimary
                    classes={styles.button}
                    cypressTestId="invite-to-squad-success-continue-button"
                    onClick={handleModalClose}
                >
                    <Text>Continue</Text>
                </ButtonPrimary>
                <ButtonInvisible
                    classes={styles.button}
                    cypressTestId="invite-to-squad-success-invite-more-button"
                    onClick={handleGoToInviteMore}
                >
                    <Text>Invite More</Text>
                </ButtonInvisible>
            </div>
        );
    }

    return (
        <div className={styles.supportSquadSettingsModalCreateInviteSuccess}>
            {renderBody()}
            {renderButtons()}
        </div>
    );
};

SupportSquadSettingsModalCreateInviteSuccess.propTypes = {
    handleModalClose: PropTypes.func.isRequired,
    setCurrentSection: PropTypes.func.isRequired,
    setHeaderSubText: PropTypes.func.isRequired,
    setHeaderText: PropTypes.func.isRequired,
    setInviteEmails: PropTypes.func.isRequired,
};
