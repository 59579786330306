import React from "react";
import cx from "classnames";

// Components
import { ButtonInvisible, ButtonPrimary } from "components/Buttons/Buttons";
import { ConfirmVerificationCodeInput } from "components/Inputs/ConfirmVerificationCodeInput/ConfirmVerificationCodeInput";

// CSS
import styles from "../profile-delete-account-modal.module.scss";

export const ProfileDeleteAccountModalPhoneCodeVerify = (props) => {
    const {
        handleChangeConfirmVerificationCodeInput,
        handleSubmitConfirmVerifcationCode,
        loading,
        resendVerificationCode,
        shouldShowErrorState,
        verificationCode,
    } = props;

    function renderForm() {
        return (
            <form
                className={cx(styles.form)}
                data-action="signUpPhoneVerifySubmit"
                onSubmit={handleSubmitConfirmVerifcationCode}
            >
                <ConfirmVerificationCodeInput
                    label=""
                    length={6}
                    loading={loading}
                    onChange={handleChangeConfirmVerificationCodeInput}
                    shouldShowErrorState={shouldShowErrorState}
                />
            </form>
        );
    }

    function renderSubmitButton() {
        return loading ? (
            <ButtonPrimary
                classes={cx()}
                isDisabled={loading}
                text="Verifying..."
                type="button"
            />
        ) : (
            <ButtonPrimary
                classes={cx()}
                cypressTestId="sign-up-confirm-verify-button"
                dataAttributes={{ "data-action": "signUpPhoneVerifySubmit" }}
                isDisabled={verificationCode.length < 6}
                onClick={handleSubmitConfirmVerifcationCode}
                text="Continue"
                type="button"
            />
        );
    }

    return (
        <div className={cx(styles["phone-verify-code"])}>
            <div className={styles.top}>
                {renderForm()}
                <div className={styles.buttons}>
                    {renderSubmitButton()}
                    <div className={styles["buttons-row"]}>
                        <ButtonInvisible
                            classes={styles.button}
                            onClick={resendVerificationCode}
                            text="Resend Code"
                            type="button"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
