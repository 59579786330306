import React from "react";
import PropTypes from "prop-types";

// Components
import { ButtonInvisible, ButtonPrimary } from "components/Buttons/Buttons";
import { Modal } from "components/Modals/Modal";
import { WarningTriangleIcon } from "components/Icons/WarningTriangleIcon";

// CSS
import styles from "./support-squad-no-tokens-modal.module.scss";

export const SupportSquadNoTokensModal = (props) => {
    const {
        setShouldShowDonateTokensModal,
        setShouldShowNoTokensModal,
        shouldShowNoTokensModal,
    } = props;

    return (
        <Modal
            centered="true"
            className={styles["support-squad-no-tokens-modal"]}
            cypressTestId="no-tokens-modal"
            /* istanbul ignore next */
            onHide={() => setShouldShowNoTokensModal(false)}
            shouldHideHeader={true}
            show={shouldShowNoTokensModal}
            size="sm"
        >
            <div className={styles.icon}>
                <WarningTriangleIcon color="#345168" height="42" width="42" />
            </div>
            <div className={styles.header}>
                <span>No Available Letters</span>
            </div>
            <div className={styles.text}>
                <span>
                    Your support squad has no letters. Before you can send a
                    letter, a member must donate them to this group.
                </span>
            </div>
            <div className={styles.buttons}>
                <ButtonPrimary
                    classes={styles.button}
                    cypressTestId="no-tokens-modal-donate-button"
                    onClick={() => {
                        setShouldShowNoTokensModal(false);
                        setShouldShowDonateTokensModal(true);
                    }}
                    text="Donate Tokens"
                    type="button"
                />
                <ButtonInvisible
                    classes={styles.button}
                    cypressTestId="no-tokens-modal-cancel-button"
                    /* istanbul ignore next */
                    onClick={() => setShouldShowNoTokensModal(false)}
                    text="Cancel"
                    type="button"
                />
            </div>
        </Modal>
    );
};

SupportSquadNoTokensModal.propTypes = {
    setShouldShowDonateTokensModal: PropTypes.func.isRequired,
    setShouldShowNoTokensModal: PropTypes.func.isRequired,
    shouldShowNoTokensModal: PropTypes.bool.isRequired,
};
