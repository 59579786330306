import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// Components
import { ButtonSecondary } from "components/Buttons/Buttons";
import { LoadingSection } from "components/Loading/Loading";
import { Panel } from "components/Panel/Panel";
import { Tabs } from "components/Tabs/Tabs";
import { WalletManageListCard } from "./components/WalletManageListCard/WalletManageListCard";

// CSS
import styles from "./wallet-manage-list.module.scss";

// Loggers
import { AnalyticsLogger } from "loggers/AnalyticsLogger";

// Constants
import { WalletListTabIds } from "../../../../constants/constants";
const { MY_GIFT_CARDS, SENT_GIFT_CARDS } = WalletListTabIds;

export const WalletManageList = (props) => {
    const {
        handleSelectWalletListTab,
        hasPurchasedGiftCardsInWallet,
        hasSpendingGiftCardsInWallet,
        loading,
        purchasedGiftCards,
        selectedWalletListTabId,
        setSelectedGiftCard,
        setShouldShowAddCardModal,
        spendingGiftCards,
    } = props;

    /**
     * End Hooks
     */

    function handleAddButtonClick() {
        setShouldShowAddCardModal(true);
        AnalyticsLogger.logWalletAddGiftCardManualStart();
    }

    function renderBody() {
        const hasCards =
            selectedWalletListTabId === MY_GIFT_CARDS
                ? hasSpendingGiftCardsInWallet
                : hasPurchasedGiftCardsInWallet;
        if (!loading.wallet && !hasCards) {
            return (
                <div
                    className={cx(styles.body, {
                        [styles["body--empty"]]: !hasCards,
                    })}
                >
                    <span className={styles.text}>
                        It doesn't look like you have any gift cards. You can
                        purchase a gift card, or manually add an existing one by
                        clicking one of the buttons below. Gift cards are
                        delivered digitally, not in a letter.
                    </span>
                </div>
            );
        } else if (!loading.wallet && hasCards) {
            return renderCards();
        }
    }

    function renderCards() {
        const collection =
            selectedWalletListTabId === MY_GIFT_CARDS
                ? spendingGiftCards
                : purchasedGiftCards;
        return collection?.map((card, i) => {
            return (
                <WalletManageListCard
                    {...props}
                    card={card}
                    key={card.giftCardNumber || i}
                    onClick={() => setSelectedGiftCard(card)}
                    selectedWalletListTabId={selectedWalletListTabId}
                />
            );
        });
    }

    function renderHeader() {
        const tabsData = [
            {
                id: MY_GIFT_CARDS,
                index: 0,
                isActive: selectedWalletListTabId === MY_GIFT_CARDS,
                onClick: () => {
                    handleSelectWalletListTab(MY_GIFT_CARDS);
                    AnalyticsLogger.logMyGiftCardTabViewed();
                },
                text: "My Gift Cards",
                cyId: "my-cards-tab",
            },
            {
                id: SENT_GIFT_CARDS,
                index: 1,
                isActive: selectedWalletListTabId === SENT_GIFT_CARDS,
                onClick: () => {
                    handleSelectWalletListTab(SENT_GIFT_CARDS);
                    AnalyticsLogger.logSentGiftCardTabViewed();
                },
                text: "Sent Gift Cards",
                cyId: "sent-cards-tab",
            },
        ];
        return (
            <div className={styles.header}>
                <Tabs data={tabsData} />
            </div>
        );
    }

    function renderLoading() {
        return loading.wallet && <LoadingSection />;
    }

    return (
        <div className={styles["wallet-manage-list"]}>
            <Panel classes={cx(styles.panel, styles["panel--list"])}>
                {renderHeader()}
                {renderBody()}
                {renderLoading()}
            </Panel>
            <Panel classes={cx(styles.panel, styles["panel--add"])}>
                <ButtonSecondary
                    classes={styles.button}
                    cypressTestId="manually-add-card-button"
                    onClick={handleAddButtonClick}
                    text="Manually Add Gift Card"
                    type="button"
                />
            </Panel>
        </div>
    );
};

WalletManageList.propTypes = {
    hasPurchasedGiftCardsInWallet: PropTypes.bool.isRequired,
    hasSpendingGiftCardsInWallet: PropTypes.bool.isRequired,
    handleSelectWalletListTab: PropTypes.func.isRequired,
    loading: PropTypes.object.isRequired,
    purchasedGiftCards: PropTypes.array.isRequired,
    selectedWalletListTabId: PropTypes.number.isRequired,
    setSelectedGiftCard: PropTypes.func.isRequired,
    setShouldShowAddCardModal: PropTypes.func.isRequired,
    setShouldShowPurchaseCardModal: PropTypes.func.isRequired,
    spendingGiftCards: PropTypes.array.isRequired,
};
