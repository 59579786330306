import React from "react";
import { useHistory } from "react-router-dom";

// Components
import { CalendarColorIcon } from "components/Icons/CalendarColorIcon";
import { LineItem } from "components/LineItem/LineItem";
import { Panel } from "components/Panel/Panel";

// CSS
import styles from "./dashboard-weekly-updates.module.scss";

export const DashboardWeeklyUpdates = (props) => {
    const { weeklyUpdates } = props;

    /**
     * Router Hooks
     */

    const history = useHistory();

    /**
     * End Hooks
     */

    function handleWeeklyUpdatesClick() {
        history.push({ pathname: "/weekly-updates", state: { weeklyUpdates } });
    }

    return (
        <Panel classes={styles["dashboard__weekly-updates"]}>
            <LineItem
                cypressTestId="dashboard-weekly-updates-button"
                icon={<CalendarColorIcon />}
                onClick={handleWeeklyUpdatesClick}
                text="Weekly Updates"
            />
        </Panel>
    );
};
