import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// Components
import { ButtonPrimary, ButtonSecondary } from "components/Buttons/Buttons";
import { HandHoldingLettersIllustration } from "components/Illustrations/HandHoldingLettersIllustration/HandHoldingLettersIllustration";
import { Panel } from "../../../../components/Panel/Panel";
import { Text } from "components/Text/Text";

// CSS
import styles from "./referrals-share-link.module.scss";

// Hooks
import { useReferralsShareLink } from "./hooks/useReferralsShareLink";

export const ReferralsShareLink = ({
    earnedLetters,
    setShouldShowRedeemCodeModal,
}) => {
    /**
     * Custom Hooks
     */

    const { handleCopyReferralLinkToClipboard, referralLink } =
        useReferralsShareLink();

    /**
     * End Hooks
     */

    function renderEarnedLetterCount() {
        return (
            earnedLetters !== null && (
                <div className={styles.count}>
                    <Text isBold classes={styles.description}>
                        Earned
                    </Text>
                    <Text classes={styles.amount}>{earnedLetters} Letters</Text>
                </div>
            )
        );
    }

    function renderInput() {
        return (
            <div className={styles.link}>
                <div className={styles.wrapper}>
                    <input
                        className={cx(styles.input, "input")}
                        data-cy="referrals-share-link-input"
                        readOnly
                        type="text"
                        value={referralLink}
                    />
                    <ButtonPrimary
                        classes={styles.button}
                        cypressTestId="referrals-share-link-button"
                        onClick={handleCopyReferralLinkToClipboard}
                        text="Copy"
                        type="button"
                    />
                </div>
            </div>
        );
    }

    function renderText() {
        return (
            <div className={styles.text}>
                <div className={styles.header}>
                    <Text>Get Free Letters</Text>
                </div>
                <div className={styles.body}>
                    <Text type="body">
                        Invite friends and family to send letters with Sandboxx.
                        Once they create a new account with your code and make a
                        letter purchase, you'll both get free letters.
                    </Text>
                </div>
            </div>
        );
    }

    return (
        <Panel classes={styles.referralsShareLink}>
            {renderEarnedLetterCount()}
            <div className={styles.content}>
                {renderText()}
                {renderInput()}
                <ButtonSecondary
                    onClick={() => setShouldShowRedeemCodeModal(true)}
                >
                    <Text>Redeem Code</Text>
                </ButtonSecondary>
            </div>
            <div className={styles.image}>
                <HandHoldingLettersIllustration
                    className={styles.illustration}
                />
            </div>
        </Panel>
    );
};

ReferralsShareLink.propTypes = {
    earnedLetters: PropTypes.number,
    setShouldShowRedeemCodeModal: PropTypes.func.isRequired,
};
