import React from "react";
import PropTypes from "prop-types";

// Components
import { ButtonInvisible, ButtonPrimary } from "components/Buttons/Buttons";
import { SavedPaymentOptionsOption } from "components/PaymentOption/SavedPaymentOptions";

// Constants
import { NavigationEnum } from "../../constants/constants";
const { SELECT_PAYMENT } = NavigationEnum;

export const WalletManagePurchaseCardModalReview = (props) => {
    const {
        giftCard,
        loading,
        handlePurchase,
        selectedCard,
        setCurrentSection,
        setLoading,
        setSelectedCard,
    } = props;

    const { amount } = giftCard;

    function handleGoBack() {
        setSelectedCard(null);
        setCurrentSection(SELECT_PAYMENT);
    }

    function handleSubmit() {
        setLoading({ ...loading, purchase: true });
        handlePurchase();
    }

    function renderFee() {
        return (
            <div className="letters-compose-purchase-review-list-item">
                <span className="letters-compose-purchase-review-list-item-name">
                    Fee
                </span>
                <span className="letters-compose-purchase-review-list-item-name">
                    ${amount.fee}
                </span>
            </div>
        );
    }

    function renderGiftCard() {
        return (
            <div className="letters-compose-purchase-review-list-item">
                <span className="letters-compose-purchase-review-list-item-name">
                    Gift Card
                </span>
                <span className="letters-compose-purchase-review-list-item-name">
                    ${amount.base}
                </span>
            </div>
        );
    }

    function renderPaymentMethod() {
        return (
            selectedCard?.card && (
                <div className="letters-compose-purchase-review__payment-method">
                    <div className="letters-compose-purchase-review__payment-method__header">
                        <span>Payment Method</span>
                    </div>
                    <SavedPaymentOptionsOption
                        card={selectedCard}
                        changeCurrentSection={setCurrentSection}
                        context="wallet"
                        endItem={{ type: "edit" }}
                        index={1}
                        isSelectedCard={true}
                        setSelectedCard={setSelectedCard}
                    />
                </div>
            )
        );
    }

    function renderSubmitButton() {
        const text = loading.purchase ? "Purchasing..." : "Purchase";
        return (
            <ButtonPrimary
                cypressTestId="wallet-purchase-modal-review-submit"
                isDisabled={loading.purchase}
                onClick={handleSubmit}
                text={text}
                type="button"
            />
        );
    }

    function renderTotal() {
        return (
            <div className="letters-compose-purchase-review-list-item letters-compose-purchase-review-list-total">
                <span className="letters-compose-purchase-review-list-item-name">
                    Total
                </span>
                <span className="letters-compose-purchase-review-list-item-name">
                    ${amount.total}
                </span>
            </div>
        );
    }

    return (
        <>
            {renderPaymentMethod()}
            <div className="letters-compose-purchase-review-list-container">
                {renderGiftCard()}
                {renderFee()}
                {renderTotal()}
            </div>
            <div className="button__container force-right">
                <ButtonInvisible
                    onClick={handleGoBack}
                    text="Back"
                    type="button"
                />
                {renderSubmitButton()}
            </div>
        </>
    );
};

WalletManagePurchaseCardModalReview.propTypes = {
    giftCard: PropTypes.object.isRequired,
    handleClosePurchaseCardModal: PropTypes.func.isRequired,
    handlePurchase: PropTypes.func.isRequired,
    loading: PropTypes.object.isRequired,
    selectedCard: PropTypes.object,
    setCurrentSection: PropTypes.func.isRequired,
    setLoading: PropTypes.func.isRequired,
    setSelectedCard: PropTypes.func.isRequired,
};
