import React from "react";

// Components
import { Panel } from "components/Panel/Panel";
import { SupportSquadDetailActivityItem } from "./components/SupportSquadDetailActivityItem/SupportSquadDetailActivityItem";
import { Text } from "components/Text/Text";

// CSS
import styles from "./support-squad-detail-activity.module.scss";

export const SupportSquadDetailActivity = ({
    isFetchingSquad,
    selectedSquad,
}) => {
    function renderContent() {
        return (
            <Panel classes={styles.supportSquadDetailActivity}>
                <div className={styles.header}>
                    <Text isBold>Squad Activity</Text>
                </div>
                <div className={styles.feed} data-cy="squad-activity-feed">
                    {selectedSquad.activityFeed?.map((activity, i) => {
                        return (
                            <SupportSquadDetailActivityItem
                                activity={activity}
                                isFetchingSquad={isFetchingSquad}
                                key={i}
                            />
                        );
                    })}
                </div>
            </Panel>
        );
    }

    function renderLoading() {
        return (
            <Panel classes={styles.supportSquadDetailActivity}>
                <div className={styles.header}>
                    <Text isBold>Squad Activity</Text>
                </div>
                <div className={styles.feed} data-cy="squad-activity-feed">
                    <SupportSquadDetailActivityItem
                        isFetchingSquad={isFetchingSquad}
                    />
                    <SupportSquadDetailActivityItem
                        isFetchingSquad={isFetchingSquad}
                    />
                    <SupportSquadDetailActivityItem
                        isFetchingSquad={isFetchingSquad}
                    />
                    <SupportSquadDetailActivityItem
                        isFetchingSquad={isFetchingSquad}
                    />
                    <SupportSquadDetailActivityItem
                        isFetchingSquad={isFetchingSquad}
                    />
                </div>
            </Panel>
        );
    }

    return isFetchingSquad ? renderLoading() : renderContent();
};
