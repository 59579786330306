import React, { useRef, useState } from "react";

// Utils
import { getElementsWidth } from "../utils/utils";

export const useCarousel = ({ children, gap }) => {
    /**
     * useRef
     */

    const carouselWrapperRef = useRef();

    /**
     * useState
     */

    const [activeIndex, setActiveIndex] = useState(0);

    /**
     * Constants
     */

    const childrenCount = React.Children.count(children);
    const canGoLeft = activeIndex > 0;
    const canGoRight = activeIndex < childrenCount - 1;

    /**
     * End Hooks
     */

    function scrollToItem(itemIndex) {
        if (
            (itemIndex < activeIndex && canGoLeft) ||
            (itemIndex > activeIndex && canGoRight)
        ) {
            const items = carouselWrapperRef.current.children;
            const targetPosition = getElementsWidth({
                config: { isHTMLCollection: false },
                gap,
                items,
                maxIndex: itemIndex,
            });
            carouselWrapperRef.current.style.transform = `translateX(-${targetPosition}px)`;
            carouselWrapperRef.current.scrollLeft = targetPosition;
            setActiveIndex((prev) => itemIndex);
        }
    }

    return {
        activeIndex,
        canGoLeft,
        canGoRight,
        carouselWrapperRef,
        childrenCount,
        scrollToItem,
    };
};
