import React from "react";
import * as Sentry from "@sentry/react";

// Components
import { CarouselV2 } from "components/CarouselV2/CarouselV2";
import { DashboardMessagesPreviewCard } from "./components/DashboardMessagesPreviewCard/DashboardMessagesPreviewCard";

// CSS
import styles from "./dashboard-messages.module.scss";

// Hooks
import { useDashboardMessages } from "./hooks/useDashboardMessages";

export const DashboardMessages = () => {
    /**
     * Custom Hooks
     */

    const { isLoading, messages, shouldShowComponent } = useDashboardMessages();

    /**
     * End Hooks
     */

    function renderLoading() {
        return new Array(3).fill(
            <DashboardMessagesPreviewCard isLoading key={crypto.randomUUID()} />
        );
    }

    function renderMessages() {
        if (messages) {
            return messages?.map((message, i) => {
                return (
                    <DashboardMessagesPreviewCard
                        isLoading={isLoading}
                        key={message?.metadata?.messageId}
                        message={message}
                    />
                );
            });
        }
    }

    return (
        <Sentry.ErrorBoundary fallback={null}>
            {shouldShowComponent && (
                <div className={styles.dashboardMessages}>
                    <CarouselV2
                        classNames={{
                            container: styles.carousel,
                            item: styles.item,
                            wrapper: "",
                        }}
                        gap={24}
                        title="FOR YOU"
                    >
                        {isLoading ? renderLoading() : renderMessages()}
                    </CarouselV2>
                </div>
            )}
        </Sentry.ErrorBoundary>
    );
};
