import React from "react";
import { useHistory } from "react-router-dom";

// Components
import { ButtonPrimary } from "components/Buttons/Buttons";
import { Text } from "components/Text/Text";

// CSS
import styles from "../phone-verification-modal.module.scss";

export const PhoneVerificationModalCompleteProfile = (props) => {
    const { togglePhoneVerificationModal } = props;

    /**
     * Router Hooks
     */

    const history = useHistory();

    /**
     * End Hooks
     */

    function handleGoToLetters() {
        history.push("/letters/compose");
        togglePhoneVerificationModal(false);
    }

    return (
        <div className={styles["complete-profile"]}>
            <div className={styles.checkmark} />
            <Text classes={styles.header} type="h2">
                Your phone number has been verified!
            </Text>
            <ButtonPrimary
                color="orange"
                onClick={handleGoToLetters}
                text="Send a Letter"
                type="button"
            />
        </div>
    );
};
