import React from "react";
import PropTypes from "prop-types";

// Components
import { ButtonInvisible, ButtonPrimary } from "components/Buttons/Buttons";
import { Text } from "components/Text/Text";
import { TextFieldValidated } from "components/Inputs/TextFieldValidated/TextFieldValidated";

// CSS
import styles from "./support-squad-settings-modal-create-name.module.scss";

// Hooks
import { useSupportSquadSettingsModalCreateName } from "./hooks/useSupportSquadSettingsModalCreateName";

// Media
import squadImagePlaceholder from "media/scenes/support-squad/squad-image-placeholder.png";

// Utils
import { CurrentUser } from "utils/sandboxx";

export const SupportSquadSettingsModalCreateName = (props) => {
    const {
        currentSection,
        handleImageFileSelect,
        handleModalClose,
        handleNameChange,
        handleCreateAndUpdateSupportSquad,
        isCreatingSquad,
        isUpdatingSquad,
        isUploadingImage,
        setCurrentSection,
        setHeaderSubText,
        setHeaderText,
        squadName,
        selectedSquad,
        supportSquadImage,
    } = props;

    /**
     * Custom Hooks
     */

    const { fileInputRef, handleAddPhotoClick } =
        useSupportSquadSettingsModalCreateName({
            setHeaderText,
            setHeaderSubText,
        });

    /**
     * End Hooks
     */

    function renderButtons() {
        return (
            <div className={styles.buttons}>
                <ButtonPrimary
                    classes={styles.button}
                    config={{ shouldMaintainDimensions: true }}
                    cypressTestId="support-squad-name-next-button"
                    isDisabled={!squadName}
                    isLoading={
                        isCreatingSquad || isUpdatingSquad || isUploadingImage
                    }
                    onClick={() => {
                        currentSection === "edit-details"
                            ? handleCreateAndUpdateSupportSquad()
                            : setCurrentSection("assign");
                    }}
                >
                    <Text>Continue</Text>
                </ButtonPrimary>
                <ButtonInvisible
                    classes={styles.button}
                    onClick={handleModalClose}
                >
                    <Text>Cancel</Text>
                </ButtonInvisible>
            </div>
        );
    }

    function renderCard() {
        const { fullName } = CurrentUser.getUser();
        const image =
            supportSquadImage.imagePreviewUrl || selectedSquad?.resolvedImage;
        return (
            <div className={styles.card}>
                <div
                    className={styles.image}
                    style={{
                        backgroundImage: image
                            ? `url(${image})`
                            : `url(${squadImagePlaceholder})`,
                        filter: image ? "none" : "grayscale(1)",
                    }}
                >
                    <input
                        accept="image/*"
                        className={styles.input}
                        data-cy="letters-compose-message-content-add-photo-input"
                        id="support-squad-image-upload"
                        name="support-squad-image-upload"
                        onChange={handleImageFileSelect}
                        ref={fileInputRef}
                        type="file"
                    />
                    <ButtonPrimary
                        classes={styles.button}
                        onClick={handleAddPhotoClick}
                    >
                        <Text>{image ? "Change Photo" : "Add Photo"}</Text>
                    </ButtonPrimary>
                </div>
                <div className={styles.body}>
                    <TextFieldValidated
                        className={styles.input}
                        cypressTestId="support-squad-name-input"
                        id="supportSquadName"
                        isValid={!!squadName}
                        label="Enter Squad Name"
                        name="supportSquadName"
                        onChange={handleNameChange}
                        required
                        size="small"
                        value={squadName}
                    />
                    <div className={styles.createdBy}>
                        <Text classes={styles.label}>Created By</Text>
                        <Text isBold>{fullName}</Text>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className={styles.supportSquadSettingsModalCreateName}>
            {renderCard()}
            {renderButtons()}
        </div>
    );
};

SupportSquadSettingsModalCreateName.propTypes = {
    bannerImage: PropTypes.string,
    currentSection: PropTypes.string.isRequired,
    handleImageFileSelect: PropTypes.func.isRequired,
    handleModalClose: PropTypes.func.isRequired,
    handleNameChange: PropTypes.func.isRequired,
    handleCreateAndUpdateSupportSquad: PropTypes.func.isRequired,
    isCreatingSquad: PropTypes.bool,
    isUpdatingSquad: PropTypes.bool,
    isUploadingImage: PropTypes.bool,
    squadName: PropTypes.string.isRequired,
    setCurrentSection: PropTypes.func.isRequired,
    setHeaderSubText: PropTypes.func.isRequired,
    setHeaderText: PropTypes.func.isRequired,
    supportSquadImage: PropTypes.object,
};
