import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// Components
import { ButtonPrimary } from "components/Buttons/Buttons";
import { SupportSquadSettingsModalManageAdmins } from "../SupportSquadSettingsModalManageAdmins/SupportSquadSettingsModalManageAdmins";
import { SupportSquadSettingsModalManageMembers } from "../SupportSquadSettingsModalManageMembers/SupportSquadSettingsModalManageMembers";
import { SupportSquadSettingsModalManageUserListItem } from "../SupportSquadSettingsModalManageUserListItem/SupportSquadSettingsModalManageUserListItem";

// CSS
import styles from "./support-squad-settings-modal-manage-users.module.scss";

// Utils
import { handleKeyDown } from "utils/eventUtils";

export const SupportSquadSettingsModalManageUsers = (props) => {
    const {
        isUpdatingMembers,
        setCurrentSection,
        setModalHeaderText,
        squadAdmins,
        squadMembers,
    } = props;

    /************************************************
     * useState
     ************************************************/

    const [currentManageSection, setCurrentManageSection] = useState("default");

    /************************************************
     * useEffect
     ************************************************/

    useEffect(() => {
        setModalHeaderText(getHeaderText(currentManageSection));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentManageSection]);

    /************************************************
     * End Hooks
     ************************************************/

    function getHeaderText(section) {
        switch (section) {
            case "default":
                return "Manage Squad";
            case "edit-members":
                return "Manage Squad Members";
            case "edit-admins":
                return "Manage Squad Admins";
            default:
                return "Manage Squad";
        }
    }

    function renderAdminsList() {
        return (
            <>
                <div className={styles.header}>
                    <span className={styles.label}>Admins</span>
                    <span
                        className={styles.editOption}
                        data-cy="manage-modal-edit-admins-button"
                        onClick={() => setCurrentManageSection("edit-admins")}
                        onKeyDown={
                            /* istanbul ignore next */ (e) =>
                                handleKeyDown(e, () =>
                                    setCurrentManageSection("edit-admins")
                                )
                        }
                        tabIndex="0"
                    >
                        Edit
                    </span>
                </div>
                <div className={styles["members-list"]}>
                    {squadAdmins.map((member) => {
                        return (
                            <SupportSquadSettingsModalManageUserListItem
                                isLoading={isUpdatingMembers}
                                key={member.memberId}
                                user={member}
                            />
                        );
                    })}
                </div>
            </>
        );
    }

    function renderMembersList() {
        return (
            <>
                <div className={styles.header}>
                    <span className={styles.label}>Members</span>
                    {squadMembers.length !== 0 && (
                        <span
                            className={styles.editOption}
                            data-cy="manage-modal-edit-members-button"
                            onClick={() =>
                                setCurrentManageSection("edit-members")
                            }
                            onKeyDown={
                                /* istanbul ignore next */ (e) =>
                                    handleKeyDown(e, () =>
                                        setCurrentManageSection("edit-members")
                                    )
                            }
                            tabIndex="0"
                        >
                            Edit
                        </span>
                    )}
                </div>
                {squadMembers.length ? (
                    <div className={styles["members-list"]}>
                        {squadMembers.map((member, i) => {
                            return (
                                <SupportSquadSettingsModalManageUserListItem
                                    classes={styles.admin}
                                    isLoading={isUpdatingMembers}
                                    key={member.memberId}
                                    user={member}
                                />
                            );
                        })}
                    </div>
                ) : (
                    renderMemberEmptyState()
                )}
            </>
        );
    }

    function renderMemberEmptyState() {
        return (
            <div
                className={styles["no-members"]}
                data-cy="manage-members-empty-state"
            >
                <span className={styles.text}>
                    There are no other members in this group. Add new members by
                    clicking the button below!
                </span>
                <ButtonPrimary
                    classes={styles["invite-button"]}
                    cypressTestId="manage-members-invite-button"
                    onClick={() => setCurrentSection("invite")}
                    text="Invite Members"
                    type="button"
                />
            </div>
        );
    }

    function renderOptions() {
        return (
            <>
                {renderAdminsList()}
                {renderMembersList()}
            </>
        );
    }

    function renderModalContent() {
        switch (currentManageSection) {
            case "default":
                return renderOptions();
            case "edit-admins":
                return (
                    <SupportSquadSettingsModalManageAdmins
                        {...props}
                        setCurrentManageSection={setCurrentManageSection}
                    />
                );
            case "edit-members":
                return (
                    <SupportSquadSettingsModalManageMembers
                        {...props}
                        setCurrentManageSection={setCurrentManageSection}
                    />
                );
            default:
                break;
        }
    }

    return renderModalContent();
};

SupportSquadSettingsModalManageUsers.propTypes = {
    isUpdatingMembers: PropTypes.bool.isRequired,
    setCurrentSection: PropTypes.func.isRequired,
    setModalHeaderText: PropTypes.func.isRequired,
    squadAdmins: PropTypes.array.isRequired,
    squadMembers: PropTypes.array.isRequired,
};
