import React, { useContext, useState } from "react";

// Components
import { WalletGiftCards } from "./components/WalletGiftCards/WalletGiftCards";
import { WalletManage } from "./components/WalletManage/WalletManage";

// Context
import { NotificationBarContext } from "context/notificationBar";

// Constants
import { WalletNavigationEnum } from "./constants/constants";

// Hooks
import { useDocumentTitle } from "@uidotdev/usehooks";

export const Wallet = (props) => {
    /**
     * Custom Hooks
     */

    useDocumentTitle("Sandboxx - Gift Cards");

    /**
     * useContext
     */

    const { showNotification } = useContext(NotificationBarContext);

    /**
     * useState
     */

    const [walletCurrentSection, setWalletCurrentSection] = useState(
        WalletNavigationEnum.PURCHASE
    );

    /**
     * End Hooks
     */

    function renderCurrentSection() {
        return {
            [WalletNavigationEnum.MANAGE]: (
                <WalletManage
                    {...props}
                    setWalletCurrentSection={setWalletCurrentSection}
                    showNotification={showNotification}
                />
            ),
            [WalletNavigationEnum.PURCHASE]: (
                <WalletGiftCards
                    {...props}
                    setWalletCurrentSection={setWalletCurrentSection}
                    showNotification={showNotification}
                />
            ),
        }[walletCurrentSection];
    }

    return renderCurrentSection();
};
