// Media
import image1 from "media/photos/scenes/SignIn/sign-in-welcome-1.jpg";
import image2 from "media/photos/scenes/SignIn/sign-in-welcome-2.jpg";
import image3 from "media/photos/scenes/SignIn/sign-in-welcome-3.jpg";
import image4 from "media/photos/scenes/SignIn/sign-in-welcome-4.jpg";
import image5 from "media/photos/scenes/SignIn/sign-in-welcome-5.jpg";
import image6 from "media/photos/scenes/SignIn/sign-in-welcome-6.jpg";

export const captions = [
    "connects friends and family",
    "sends letters",
    "builds support networks",
    "cares",
];

export const delay = 2000;

export const gradientBackgroundStyle =
    "linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0) 100%)";

export const images = [image1, image2, image3, image4, image5, image6];

export const typingSpeed = 150;
