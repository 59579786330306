import React from "react";
import cx from "classnames";
import { Skeleton } from "@material-ui/lab";

// Components
import { Panel } from "components/Panel/Panel";

// CSS
import styles from "./dashboard-credits.module.scss";

// Utils
import { handleKeyDown } from "utils/eventUtils";
import { emptyFunction } from "utils/miscUtils";

export const DashboardCredits = ({
    bundles,
    credits,
    handlePurchaseModalToggle,
}) => {
    const areBundlesAvailable = bundles?.length;
    const getMoreClickHandler = areBundlesAvailable
        ? handlePurchaseModalToggle
        : emptyFunction;

    function renderCreditsNumber() {
        if (credits !== null) {
            return (
                <div className={styles.number}>
                    <span>{credits}</span>
                </div>
            );
        } else {
            return (
                <div className={styles.number}>
                    <Skeleton className={styles.loading} />
                </div>
            );
        }
    }

    return (
        <Panel classes={styles.dashboardCredits}>
            <div className="panel__header">
                <span className="panel__header__text">LETTERS</span>
            </div>
            <div
                className={styles.body}
                data-cy="dashboard-purchase-modal-button"
                onClick={getMoreClickHandler}
                onKeyDown={(e) => handleKeyDown(e, getMoreClickHandler)}
                tabIndex="0"
            >
                <div className={styles.remaining}>
                    {renderCreditsNumber()}
                    <div className={styles.text}>
                        <span>Letters</span>
                        <span>Remaining</span>
                    </div>
                </div>
                <div
                    className={cx(styles.more, {
                        [styles["more--disabled"]]: !areBundlesAvailable,
                    })}
                >
                    <span>+</span>
                </div>
            </div>
        </Panel>
    );
};
