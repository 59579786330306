// Enums
import { PurchaseModalNavigation } from "../enums/enums";

export function generateHeaderText(currentSection, purchaseMode) {
    switch (currentSection) {
        case PurchaseModalNavigation.PAYMENT:
            return {
                headerText: "Enter Payment Information",
                headerSubText: "",
            };
        case PurchaseModalNavigation.REVIEW:
            return { headerText: "Confirm Purchase", headerSubText: "" };
        default:
            return { headerText: "", headerSubText: "" };
    }
}
