import React, { useEffect } from "react";

// Components
import { LettersAddOnsCarouselItem } from "../../../components/LettersAddOnsCarousel/components/LettersAddOnsCarouselItem/LettersAddOnsCarouselItem";

// Loggers
import { AnalyticsLogger } from "loggers/AnalyticsLogger";

export const LettersComposeAddOnsSelectItems = (props) => {
    const {
        addOns,
        changeCurrentSection,
        giftCard,
        handleAddOnsModalToggle,
        handleRemoveAddOn,
        handleSelectAddOn,
        handleGiftCardClear,
    } = props;

    /************************************************
     * useEffect
     ************************************************/

    useEffect(() => {
        AnalyticsLogger.logLetterComposeAddOnViewed();
    }, []);

    /************************************************
     * Constants
     ************************************************/

    const hasAddOns = addOns?.length;

    /************************************************
     * End Hooks & Constants
     ************************************************/

    /**
     * * Only renders add ons that have not yet been selected by the user
     */
    function renderItems() {
        return (
            hasAddOns &&
            addOns.map((addOn) => (
                <LettersAddOnsCarouselItem
                    addOn={addOn}
                    addOns={addOns}
                    changeCurrentSection={changeCurrentSection}
                    giftCard={giftCard}
                    handleAddOnsModalToggle={handleAddOnsModalToggle}
                    handleRemoveAddOn={handleRemoveAddOn}
                    handleSelectAddOn={handleSelectAddOn}
                    handleGiftCardClear={handleGiftCardClear}
                    isInModal={true}
                    key={addOn.id}
                />
            ))
        );
    }

    return (
        <div className="letters-compose-add-ons__select-items">
            <div className="letters-compose-add-ons__select-items__list">
                {renderItems()}
            </div>
        </div>
    );
};
