import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// Components
import { ButtonPrimary, ButtonInvisible } from "components/Buttons/Buttons";
import { WarningCircleIcon } from "components/Icons/WarningCircleIcon";
import { SupportSquadSettingsModalManageUserListItem } from "../SupportSquadSettingsModalManageUserListItem/SupportSquadSettingsModalManageUserListItem";

// CSS
import styles from "./support-squad-settings-modal-manage-admins.module.scss";

// Enums
import { SquadRole } from "scenes/SupportSquad/enums/SquadRole";

// Utils
import { handleKeyDown } from "utils/eventUtils";
import { CurrentUser } from "utils/sandboxx";

export const SupportSquadSettingsModalManageAdmins = (props) => {
    const {
        changeSupportSquadRole,
        isUpdatingMembers,
        setCurrentManageSection,
        squadAdmins,
        squadMembers,
        selectedSquad,
    } = props;

    /************************************************
     * useState
     ************************************************/

    const [currentView, setCurrentView] = useState("default");
    const [currentUser, setCurrentUser] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [selectedAdmin, setSelectedAdmin] = useState({});
    const [selectedMember, setSelectedMember] = useState({});

    /************************************************
     * useEffect
     ************************************************/

    useEffect(() => {
        setCurrentUser(CurrentUser.getUser());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /************************************************
     * End Hooks
     ************************************************/

    function getIsChecked(member) {
        return member === selectedAdmin;
    }

    function handleChangeCheckbox(admin) {
        getIsChecked(admin) ? setSelectedAdmin({}) : setSelectedAdmin(admin);
    }

    function handleMemberSelected(member) {
        setCurrentView("add-confirmation");
        setSelectedMember(member);
    }

    function handleMemberPromotionSubmit() {
        setIsLoading(true);
        changeSupportSquadRole(
            selectedSquad.squadId,
            selectedMember.memberId,
            SquadRole.ADMIN,
            onRoleChangeSuccess
        );
    }

    function handleMemberDemotionSubmit() {
        setIsLoading(true);
        changeSupportSquadRole(
            selectedSquad.squadId,
            selectedAdmin.memberId,
            SquadRole.MEMBER,
            onRoleChangeSuccess
        );
    }

    function handleCancelPromotion() {
        setSelectedMember({});
        setCurrentView("default");
    }

    function onRoleChangeSuccess() {
        setCurrentView("default");
        setSelectedAdmin({});
        setIsLoading(false);
    }

    function renderAddAdminConfirmation() {
        return (
            <div className={styles.confirmation}>
                <div className={styles.text}>
                    <span className={styles.header}>Add Admin?</span>
                    <span className={styles.description}>
                        Confirm you would like to make{" "}
                        {selectedMember.memberFullName} an admin of{" "}
                        {selectedSquad.supporteeFirstName}'s Support Squad
                    </span>
                </div>
                <div className={styles["confirmation-buttons"]}>
                    <ButtonPrimary
                        classes={styles["confirm-remove-button"]}
                        cypressTestId="manage-admins-confirm-button"
                        isDisabled={isLoading}
                        onClick={handleMemberPromotionSubmit}
                        text={isLoading ? "Confirming..." : "Confirm"}
                        type="button"
                    />
                    <ButtonInvisible
                        classes={styles["confirm-cancel-button"]}
                        cypressTestId="manage-admins-cancel-button"
                        onClick={handleCancelPromotion}
                        text="Back"
                        type="button"
                    />
                </div>
            </div>
        );
    }

    function renderAdminsList() {
        return (
            <div className={styles["members-list"]}>
                {squadAdmins.map((member, i) => {
                    const shouldShowCheckbox =
                        currentUser.userId !== member.memberId.toString();
                    return (
                        <SupportSquadSettingsModalManageUserListItem
                            checkbox={{
                                checked: getIsChecked(member),
                                onChange: () => handleChangeCheckbox(member),
                                shouldShow: shouldShowCheckbox,
                                type: "warning",
                            }}
                            index={i}
                            isLoading={isUpdatingMembers}
                            key={member.memberId}
                            user={member}
                        />
                    );
                })}
            </div>
        );
    }

    function renderContent() {
        switch (currentView) {
            case "default":
                return renderOptions();
            case "add-confirmation":
                return renderAddAdminConfirmation();
            case "remove-confirmation":
                return renderRemoveAdminConfirmation();
            default:
                break;
        }
    }

    function renderEmptyState() {
        return (
            <>
                <div className={styles.header}>
                    <span className={styles.label}>Add Admins Below</span>
                </div>
                <span className={styles.noMembers}>
                    There are no other members in the squad.
                </span>
            </>
        );
    }

    function renderHeaderSection() {
        return (
            <div className={styles.header}>
                <span className={styles.label}>Admins</span>
                <span
                    className={styles.editOption}
                    data-cy="manange-modal-edit-admins-cancel-button"
                    onClick={() => setCurrentManageSection("default")}
                    onKeyDown={
                        /* istanbul ignore next */ (e) =>
                            handleKeyDown(e, () =>
                                setCurrentManageSection("default")
                            )
                    }
                    tabIndex="0"
                >
                    Cancel
                </span>
            </div>
        );
    }

    function renderMembersList() {
        return (
            <>
                <div className={styles.header}>
                    <span className={styles.label}>Add Admins Below</span>
                </div>
                <div className={styles["members-list"]}>
                    {squadMembers.map((member, i) => {
                        return (
                            <SupportSquadSettingsModalManageUserListItem
                                classes={styles.admin}
                                index={i}
                                isLoading={isUpdatingMembers}
                                key={member.memberId}
                                onClick={() => handleMemberSelected(member)}
                                onKeyDown={
                                    /* istanbul ignore next */ (e) =>
                                        handleKeyDown(e, () =>
                                            handleMemberSelected(member)
                                        )
                                }
                                tabIndex="0"
                                user={member}
                            />
                        );
                    })}
                </div>
            </>
        );
    }

    function renderOptions() {
        return (
            <div className={styles["members"]}>
                {squadAdmins?.length && (
                    <>
                        {renderHeaderSection()}
                        {renderAdminsList()}
                    </>
                )}
                {squadMembers.length !== 0
                    ? renderMembersList()
                    : renderEmptyState()}
                {selectedAdmin?.memberId && (
                    <ButtonPrimary
                        classes={cx(
                            styles["remove-button"],
                            "button--primary-warning"
                        )}
                        cypressTestId="manage-admins-remove-button"
                        onClick={() => setCurrentView("remove-confirmation")}
                        text="Remove Admin"
                        type="button"
                    />
                )}
            </div>
        );
    }

    function renderRemoveAdminConfirmation() {
        return (
            <div className={styles.confirmation}>
                <WarningCircleIcon
                    className={styles["warning-icon"]}
                    height="45px"
                    width="45px"
                />
                <div className={styles.text}>
                    <span className={styles.header}>Remove Admin?</span>
                    <span className={styles.description}>
                        Confirm you would like to remove{" "}
                        {selectedAdmin.memberFullName} as an admin of{" "}
                        {selectedSquad.supporteeFirstName}'s Support Squad
                    </span>
                </div>
                <div className={styles["confirmation-buttons"]}>
                    <ButtonPrimary
                        classes={cx(
                            styles["confirm-remove-button"],
                            "button--primary-warning"
                        )}
                        cypressTestId="manage-admins-confirm-button"
                        isDisabled={isLoading}
                        onClick={handleMemberDemotionSubmit}
                        text={isLoading ? "Removing..." : "Remove"}
                        type="button"
                    />
                    <ButtonInvisible
                        classes={styles["confirm-cancel-button"]}
                        cypressTestId="manage-admins-cancel-button"
                        onClick={handleCancelPromotion}
                        text="Back"
                        type="button"
                    />
                </div>
            </div>
        );
    }

    return renderContent();
};

SupportSquadSettingsModalManageAdmins.propTypes = {
    changeSupportSquadRole: PropTypes.func.isRequired,
    isUpdatingMembers: PropTypes.bool.isRequired,
    setCurrentManageSection: PropTypes.func.isRequired,
    squadAdmins: PropTypes.array.isRequired,
    squadMembers: PropTypes.array.isRequired,
    selectedSquad: PropTypes.object.isRequired,
};
