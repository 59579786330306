import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

// Account Components
import { Profile } from "../scenes/Profile/Profile";
import { SignIn } from "../scenes/SignIn/SignIn";

// Contacts Components
import { Contact } from "../scenes/Contacts/Contact/Contact";

// Dashboard
import { WrappedDashboard as Dashboard } from "../scenes/Dashboard/Dashboard";

// Digital Wallet
import { Wallet } from "scenes/Wallet/Wallet";

// Error Components
import BadRoute from "components/ErrorStates/index";

// Inbox Components
import { Inbox } from "../scenes/Inbox/Inbox";

// Letters Components
import { LettersCompose } from "../scenes/Letters/scenes/Compose/LettersCompose";
import { LettersComposeConfirmation } from "../scenes/Letters/scenes/Compose/LettersComposeConfirmation/LettersComposeConfirmation";
import { LettersSentLetter } from "../scenes/Letters/scenes/Sent/components/LettersSentLetter/LettersSentLetter";
import { LettersSentMenu } from "../scenes/Letters/scenes/Sent/LettersSentMenu";

// Memory Book Components
import { MemoryBook } from "scenes/MemoryBook/MemoryBook";

// Referral Components
import { Referrals } from "../scenes/Referrals/Referrals";

// Support Squad Components
import { SupportSquad } from "../scenes/SupportSquad/SupportSquad";

// Waypoints Components
import { Muster } from "scenes/Muster/scenes/Muster";
import { NavigationEnum as MusterNavigationEnum } from "scenes/Muster/constants/constants";

// Weekly Updates Components
import { WeeklyUpdates } from "../scenes/WeeklyUpdates/WeeklyUpdates";

// Utils
import { handleRouteSetUp } from "./utils/utils";
import { isMobile } from "../utils/mediaQueryUtils";
import { generateBaseURLComponent } from "utils/routeUtils";

export function Routes() {
    const mobileClasses = isMobile()
        ? "main-component-2-mobile"
        : "main-component-2";

    return (
        <Switch className={mobileClasses}>
            <Route
                exact
                path="/book"
                render={(props) =>
                    handleRouteSetUp(<MemoryBook {...props} />, props)
                }
            />
            <Route
                exact
                path="/bundles"
                render={(props) =>
                    handleRouteSetUp(
                        <Dashboard isUsingBundlesRoute={true} {...props} />,
                        props
                    )
                }
            />
            <Route
                exact
                path="/contact/:id"
                render={(props) =>
                    handleRouteSetUp(<Contact {...props} />, props)
                }
            />
            <Route
                exact
                path="/feed"
                render={(props) => {
                    window.location =
                        "https://www.sandboxx.us/news/?utm_source=webapp&utm_medium=navigation&utm_campaign=news&_ga=2.207752282.990096088.1627911351-1333736628.1627660927";
                    return handleRouteSetUp(<Dashboard {...props} />, props);
                }}
            />
            <Route
                exact
                path="/forgot-password"
                render={(props) =>
                    handleRouteSetUp(
                        <SignIn {...props} />,
                        {
                            ...props,
                            goToForgotPassword: true,
                        },
                        true
                    )
                }
            />
            <Route
                exact
                path="/inbox"
                render={(props) =>
                    handleRouteSetUp(<Inbox {...props} />, props)
                }
            />
            <Route
                exact
                path="/leads/upload/:depsShortCode"
                render={(props) =>
                    handleRouteSetUp(
                        <Dashboard {...props} hasDepsShortCode={true} />,
                        props
                    )
                }
            />
            <Route
                exact
                path="/letters"
                render={(props) =>
                    handleRouteSetUp(<Dashboard {...props} />, props)
                }
            />
            <Route
                exact
                path="/letters/bundles"
                render={(props) =>
                    handleRouteSetUp(
                        <Dashboard isUsingBundlesRoute={true} {...props} />,
                        props
                    )
                }
            />
            <Route
                exact
                path="/letters/compose"
                render={(props) =>
                    handleRouteSetUp(<LettersCompose {...props} />, props)
                }
            />
            <Route
                exact
                path="/letters/confirmation"
                render={(props) =>
                    handleRouteSetUp(
                        <LettersComposeConfirmation {...props} />,
                        props
                    )
                }
            />
            <Route
                exact
                path="/letters/feed"
                render={(props) =>
                    handleRouteSetUp(<Dashboard {...props} />, props)
                }
            />
            <Route
                exact
                path="/letters/referrals"
                render={(props) =>
                    handleRouteSetUp(<Referrals {...props} />, props)
                }
            />
            <Route
                exact
                path="/letters/referral-program"
                render={(props) =>
                    handleRouteSetUp(<Referrals {...props} />, props)
                }
            />
            <Route
                exact
                path="/letters/sent"
                render={(props) =>
                    handleRouteSetUp(<LettersSentMenu {...props} />, props)
                }
            />
            <Route
                exact
                path="/letters/sent/:id"
                render={(props) => {
                    const { state } = props.location || {};
                    if (state && state.letter) {
                        const { letter, letterType, replyMailId } = state;
                        const key =
                            letterType === "replied" ? replyMailId : letter.id;
                        return handleRouteSetUp(
                            <LettersSentLetter {...props} key={key} />,
                            props
                        );
                    } else {
                        return <Redirect to="/letters/sent" />;
                    }
                }}
            />
            <Route
                path="/letters/*"
                render={() => <Redirect to="/letters" />}
            />
            <Route
                exact
                path="/login"
                render={(props) =>
                    handleRouteSetUp(<SignIn {...props} />, props, true)
                }
            />
            <Route
                exact
                path="/profile"
                render={(props) =>
                    handleRouteSetUp(<Profile {...props} />, props)
                }
            />
            <Route
                path="/profile/*"
                render={() => <Redirect to="/profile" />}
            />
            <Route
                path="/muster/articles/:id"
                render={(props) =>
                    handleRouteSetUp(
                        <Muster
                            {...props}
                            initialSection={MusterNavigationEnum.MUSTER_ARTICLE}
                        />,
                        props
                    )
                }
            />
            <Route
                path="/waypoints/articles/:id"
                render={(props) =>
                    handleRouteSetUp(
                        <Muster
                            {...props}
                            initialSection={MusterNavigationEnum.MUSTER_ARTICLE}
                        />,
                        props
                    )
                }
            />
            <Route
                path="/muster/chat"
                render={(props) =>
                    handleRouteSetUp(
                        <Muster
                            {...props}
                            initialSection={
                                MusterNavigationEnum.MUSTER_OVERVIEW
                            }
                        />,
                        props
                    )
                }
            />
            <Route
                path="/waypoints/chat"
                render={(props) =>
                    handleRouteSetUp(
                        <Muster
                            {...props}
                            initialSection={
                                MusterNavigationEnum.MUSTER_OVERVIEW
                            }
                        />,
                        props
                    )
                }
            />
            <Route
                path="/muster/checklist"
                render={(props) =>
                    handleRouteSetUp(
                        <Muster
                            {...props}
                            initialSection={
                                MusterNavigationEnum.MUSTER_CHECKLIST
                            }
                        />,
                        props
                    )
                }
            />
            <Route
                path="/waypoints/checklist"
                render={(props) =>
                    handleRouteSetUp(
                        <Muster
                            {...props}
                            initialSection={
                                MusterNavigationEnum.MUSTER_CHECKLIST
                            }
                        />,
                        props
                    )
                }
            />
            <Route
                path="/muster"
                render={(props) =>
                    handleRouteSetUp(
                        <Muster
                            {...props}
                            initialSection={
                                MusterNavigationEnum.MUSTER_OVERVIEW
                            }
                        />,
                        props
                    )
                }
            />
            <Route
                path="/waypoints"
                render={(props) =>
                    handleRouteSetUp(
                        <Muster
                            {...props}
                            initialSection={
                                MusterNavigationEnum.MUSTER_OVERVIEW
                            }
                        />,
                        props
                    )
                }
            />
            <Route
                exact
                path="/recruit-invitation"
                render={(props) =>
                    handleRouteSetUp(
                        <Muster
                            {...props}
                            config={{
                                shouldShowMusterRecruitInviteModal: true,
                            }}
                            initialSection={
                                MusterNavigationEnum.MUSTER_OVERVIEW
                            }
                        />,
                        props
                    )
                }
            />
            <Route
                exact
                path="/referrals"
                render={(props) =>
                    handleRouteSetUp(<Referrals {...props} />, props)
                }
            />
            <Route
                exact
                path="/sandboxx-gift-card"
                render={(props) =>
                    handleRouteSetUp(
                        <Wallet {...props} key={crypto.randomUUID()} />,
                        props
                    )
                }
            />
            <Route
                exact
                path="/settings"
                render={(props) =>
                    handleRouteSetUp(<Profile {...props} />, props)
                }
            />
            <Route
                exact
                path="/signin"
                render={(props) =>
                    handleRouteSetUp(<SignIn {...props} />, props, true)
                }
            />
            <Route
                exact
                path="/signup"
                render={(props) =>
                    handleRouteSetUp(<SignIn {...props} />, props, true)
                }
            />
            <Route
                exact
                path="/support-squad"
                render={(props) =>
                    handleRouteSetUp(<SupportSquad {...props} />, props)
                }
            />
            <Route
                exact
                path="/verify"
                render={(props) =>
                    handleRouteSetUp(
                        <SignIn
                            goToSignUp={true}
                            goToVerify={true}
                            {...props}
                        />,
                        props
                    )
                }
            />
            <Route
                exact
                path="/weekly-updates"
                render={(props) =>
                    handleRouteSetUp(<WeeklyUpdates {...props} />, props)
                }
            />
            <Route
                exact
                path="/weekly-updates/:base/:id"
                render={(props) => <Redirect to="/weekly-updates" />}
            />
            <Route
                exact
                path="/weekly-updates/*"
                render={(props) => <Redirect to="/weekly-updates" />}
            />
            <Route
                exact
                path="/"
                render={(props) =>
                    handleRouteSetUp(generateBaseURLComponent(props), props)
                }
            />
            <Route
                render={(props) =>
                    handleRouteSetUp(<BadRoute {...props} />, props)
                }
            />
        </Switch>
    );
}
