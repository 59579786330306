export function generateBundleCardData(sandboxxPlusBundle, multiPhotoBundle) {
    return [
        {
            benefits: [
                "Add 4 photos to your letter",
                "12 letters",
                "Reply Postage",
            ],
            bundle: sandboxxPlusBundle,
            buttonText: "Upgrade For $59.99",
            buttonType: "primary",
            description:
                "Upgrade your experience to receive all of the benefits we have to offer.",
            title: "Sandboxx Plus",
            image: "",
            price: "59.99",
        },
        {
            benefits: ["Add 4 photos to your letter"],
            bundle: multiPhotoBundle,
            buttonText: "$1.99",
            buttonType: "tertiary",
            description:
                "Add multiple photos to your letter with our convenient one-time purchase.",
            title: "Single Purchase",
            image: "",
            price: "1.99",
        },
    ];
}
