import React, { createContext, useContext, useEffect, useState } from "react";

// Context
import { NotificationBarContext } from "../context/notificationBar";

// Logging
import { AnalyticsLogger } from "../loggers/AnalyticsLogger";

// Enums
import { PurchaseModalNavigation } from "components/Purchase/enums/enums";

// Platforms
import { SandboxxRestAPI } from "../utils/sandboxx";

// Utils
import { emptyFunction, isEmptyObject } from "../utils/miscUtils";

// Constants
export const PurchaseContext = createContext({});

/**
 * TODO: Extract notifications to notification logger
 * TODO: Consider extracting card information to separate context
 */
export const PurchaseProvider = (props) => {
    const { children } = props;

    /************************************************
     * useContext
     ************************************************/

    const { showNotification } = useContext(NotificationBarContext);

    /************************************************
     * useState
     ************************************************/

    const [currentSection, setCurrentSection] = useState(
        PurchaseModalNavigation.BUNDLES
    );
    const [discount, setDiscount] = useState({
        code: "",
        message: "Add Promo Code",
        isPreloaded: false,
        status: "inactive",
    });
    const [purchaseMode, setPurchaseMode] = useState("letters");
    const [onCompletePurchaseFlow, setOnCompletePurchaseFlow] = useState(
        () => emptyFunction
    );
    const [purchase, setPurchase] = useState({
        addOns: null,
        bundle: null,
        giftCard: null,
        originalBundle: null,
        totalPrice: null,
    });
    const [recentPaymentMethod, setRecentPaymentMethod] = useState({
        details: {},
        method: "",
    });
    const [savedCards, setSavedCards] = useState(null);
    const [selectedCard, setSelectedCard] = useState(null);
    const [showPurchaseModal, setShowPurchaseModal] = useState(false);

    /************************************************
     * useEffect
     ************************************************/

    useEffect(() => {
        if (recentPaymentMethod) {
            const { details, method } = recentPaymentMethod;
            if (!isEmptyObject(details) && method) {
                setSelectedCard((prevSelectedCard) => ({
                    card: details,
                    isSavedCard: true,
                }));
            }
        }
    }, [recentPaymentMethod]);

    /************************************************
     * End Hooks
     ************************************************/

    function fetchRecentPaymentMethod() {
        return SandboxxRestAPI.getRecentPaymentMethod(
            onFetchRecentPaymentMethodSuccess
        );
    }

    function fetchSavedCards() {
        SandboxxRestAPI.getBraintreeSavedCards(
            onFetchSavedCardsSuccess,
            onFetchSavedCardsFailure,
            onFetchSavedCardsFailure
        );
    }

    function handleApplyDiscount() {
        AnalyticsLogger.logPromoCodeEntered({ discount });
        setDiscount((prevDiscount) => ({ ...prevDiscount, status: "loading" }));
        const payload = { bundleId: purchase.bundle.id, code: discount.code };
        SandboxxRestAPI.applyDiscount(
            payload,
            onApplyDiscountSuccess,
            onApplyDiscountError,
            onApplyDiscountError
        );
    }

    /**
     * This method (1) resets discount to its original state and (2) removes
     * the discount that may be applied to the currently selected bundle
     */
    function handleCancelDiscount() {
        handleResetDiscount();
        setPurchase((prevPurchase) => ({
            ...prevPurchase,
            bundle: prevPurchase.originalBundle,
            originalBundle: prevPurchase.originalBundle,
            totalPrice: prevPurchase.originalBundle.price,
        }));
    }

    function handleClearPurchase() {
        setPurchase((prevPurchase) => ({
            ...prevPurchase,
            bundle: null,
            originalBundle: null,
            totalPrice: null,
        }));
    }

    function resetPurchaseContext() {
        setDiscount({
            code: "",
            isPreloaded: false,
            message: "Add Promo Code",
            status: "inactive",
        });
        setPurchase({
            addOns: null,
            bundle: null,
            giftCard: null,
            originalBundle: null,
            totalPrice: null,
        });
        setRecentPaymentMethod({
            details: {},
            method: "",
        });
        setSavedCards(null);
        setSelectedCard(null);
    }

    function handlePreloadDiscount(code) {
        setDiscount((prevDiscount) => ({
            ...prevDiscount,
            code,
            isPreloaded: true,
        }));
    }

    function handleResetDiscount() {
        setDiscount((prevDiscount) => ({
            code: "",
            isPreloaded: false,
            message: "Add Promo Code",
            status: "inactive",
        }));
    }

    function handleSelectBundle(selectedBundle) {
        setPurchase((prevPurchase) => ({
            ...prevPurchase,
            bundle: selectedBundle,
            originalBundle: selectedBundle,
            totalPrice: selectedBundle.price,
        }));
    }

    function onApplyDiscountError(err) {
        AnalyticsLogger.logPromoCodeFailure({ discount });
        const { message } = err;
        setDiscount((prevDiscount) => ({
            ...prevDiscount,
            message,
            status: "error",
        }));
    }

    function onApplyDiscountSuccess(discountedBundle) {
        AnalyticsLogger.logPromoCodeSuccess({ discount, discountedBundle });
        setDiscount((prevDiscount) => ({
            ...prevDiscount,
            message: "Promo Code Added",
            status: "active",
        }));
        setPurchase((prevPurchase) => ({
            ...prevPurchase,
            bundle: discountedBundle,
            originalBundle: purchase.bundle,
            totalPrice: discountedBundle.price,
        }));
    }

    /**
     * Stores recent payment method information in state if fetch call is succesful
     *
     * @param {object} braintreeCard Stores card information
     * @param {string} paymentMethod Indicates payment type (e.g., "STRIPE")
     *
     * TODO: Update method to account for other payment methods when Paypal or other methods are accepted
     */
    function onFetchRecentPaymentMethodSuccess({
        braintreeCard,
        paymentMethod,
    }) {
        if (paymentMethod === "BRAINTREE") {
            setRecentPaymentMethod((prevRecentPaymentMethod) => ({
                ...prevRecentPaymentMethod,
                details: braintreeCard,
                method: paymentMethod,
            }));
        }
    }

    function onFetchSavedCardsSuccess(savedCards) {
        setSavedCards(savedCards);
    }

    function onFetchSavedCardsFailure(err) {
        showNotification({
            text: "An issue was encountered retrieving your saved cards. Please wait a few minutes and try again.",
            type: "warning",
        });
    }

    const purchaseContext = {
        currentSection,
        discount,
        fetchRecentPaymentMethod,
        fetchSavedCards,
        handleApplyDiscount,
        handleCancelDiscount,
        handleClearPurchase,
        handlePreloadDiscount,
        handleResetDiscount,
        handleSelectBundle,
        onCompletePurchaseFlow,
        purchase,
        purchaseMode,
        recentPaymentMethod,
        resetPurchaseContext,
        savedCards,
        selectedCard,
        setCurrentSection,
        setDiscount,
        setOnCompletePurchaseFlow,
        setPurchase,
        setPurchaseMode,
        setSelectedCard,
        setShowPurchaseModal,
        showPurchaseModal,
    };

    return (
        <PurchaseContext.Provider value={purchaseContext}>
            {children}
        </PurchaseContext.Provider>
    );
};
