import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// Components
import { Skeleton } from "@mui/material";
import { Text } from "components/Text/Text";

// CSS
import styles from "./support-squad-detail-header-controls.module.scss";

export const SupportSquadDetailHeaderControls = ({
    handleOpenAdminSettings,
    isFetchingSquad,
    setCurrentSection,
    setShouldShowCreateModal,
    setShouldShowLeaveModal,
    selectedSquad,
}) => {
    function renderContent() {
        return (
            <div className={styles.supportSquadDetailHeaderControls}>
                {selectedSquad.isAdmin ? (
                    <div className={styles.options}>
                        <Text
                            classes={cx(styles.option, "link")}
                            cyId="detail-settings-button"
                            isBold
                            onClick={handleOpenAdminSettings}
                        >
                            Settings
                        </Text>
                        <Text
                            classes={cx(styles.option, "link")}
                            cyId="detail-invite-button"
                            isBold
                            onClick={() => {
                                setCurrentSection("invite");
                                setShouldShowCreateModal(true);
                            }}
                        >
                            Invite Members
                        </Text>
                    </div>
                ) : (
                    <div className={styles.options}>
                        <Text
                            classes={cx(styles.option, "link")}
                            cyId="detail-leave-squad-button"
                            isBold
                            onClick={() => setShouldShowLeaveModal(true)}
                        >
                            Leave Squad
                        </Text>
                    </div>
                )}
            </div>
        );
    }

    function renderLoading() {
        return (
            <div className={styles.supportSquadDetailHeaderControls}>
                <div className={styles.options}>
                    <Skeleton className={styles.skeleton} />
                </div>
            </div>
        );
    }

    return isFetchingSquad ? renderLoading() : renderContent();
};

SupportSquadDetailHeaderControls.propTypes = {
    handleOpenAdminSettings: PropTypes.func.isRequired,
    isFetchingSquad: PropTypes.bool.isRequired,
    setCurrentSection: PropTypes.func.isRequired,
    setShouldShowCreateModal: PropTypes.func.isRequired,
    setShouldShowLeaveModal: PropTypes.func.isRequired,
    selectedSquad: PropTypes.object,
};
