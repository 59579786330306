import { generateShipDateObject } from "utils/userUtils";

export function generateShipDateSelectDefaults(basicTrainingShipdate) {
    const { day, month, year } = generateShipDateObject(basicTrainingShipdate);
    return {
        day: {
            empty: !day,
            1: day === "01",
            2: day === "02",
            3: day === "03",
            4: day === "04",
            5: day === "05",
            6: day === "06",
            7: day === "07",
            8: day === "08",
            9: day === "09",
            10: day === "10",
            11: day === "11",
            12: day === "12",
            13: day === "13",
            14: day === "14",
            15: day === "15",
            16: day === "16",
            17: day === "17",
            18: day === "18",
            19: day === "19",
            20: day === "20",
            21: day === "21",
            22: day === "22",
            23: day === "23",
            24: day === "24",
            25: day === "25",
            26: day === "26",
            27: day === "27",
            28: day === "28",
            29: day === "29",
            30: day === "30",
            31: day === "31",
        },
        month: {
            empty: !month,
            january: month === "01",
            february: month === "02",
            march: month === "03",
            april: month === "04",
            may: month === "05",
            june: month === "06",
            july: month === "07",
            august: month === "08",
            september: month === "09",
            october: month === "10",
            november: month === "11",
            december: month === "12",
        },
        year: {
            empty: !year,
            2023: year === "2023",
            2024: year === "2024",
            2025: year === "2025",
            2026: year === "2026",
            2027: year === "2027",
            2028: year === "2028",
        },
    };
}
