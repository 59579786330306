import React, { useContext } from "react";

// Context
import { NotificationBarContext } from "../../context/notificationBar";
import { CheckCircleIcon } from "components/Icons/CheckCircleIcon";
import { InfoIcon } from "components/Icons/InfoIcon";
import { WarningTriangleIcon } from "components/Icons/WarningTriangleIcon";

// CSS
import "../../css/components/notification-bar/notification-bar.scss";

// Constants
const icons = {
    info: <InfoIcon className="notification-bar__icon" />,
    success: <CheckCircleIcon className="notification-bar__icon" />,
    warning: (
        <WarningTriangleIcon
            className="notification-bar__icon"
            color="#992400"
        />
    ),
};

export const NotificationBar = (props) => {
    /************************************************
     * useContext
     ************************************************/

    const { notificationBarContent, shouldShowNotificationBar } = useContext(
        NotificationBarContext
    );
    const { text, time, type } = notificationBarContent;

    const newTime = time / 1000;

    /************************************************
     * End Hooks
     ************************************************/

    const notificationBarShowClass = shouldShowNotificationBar
        ? "notification-bar--animate-open"
        : "";
    const notificationBarTypeClass = `notification-bar--${type}`;
    return (
        <div
            className={`notification-bar ${notificationBarTypeClass} ${notificationBarShowClass}`}
            style={{ animationDuration: `${newTime}s` }}
            data-cy="notification-bar-banner"
        >
            {type && icons[type]}
            {text && <span className="notification-bar__text">{text}</span>}
        </div>
    );
};
