export function generateSuccessText(purchase) {
    if (purchase.bundle.includesSandboxxPlus) {
        return {
            primaryText: "Hooray!",
            subText:
                "You've unlocked the ultimate letter-sending experience with Sandboxx Plus.",
        };
    } else if (purchase.bundle.credits === 0) {
        return {
            primaryText: "Hooray!",
            subText:
                "You've unlocked the ability to add multiple photos to your letter.",
        };
    } else {
        const bundleText = `${purchase.bundle.credits} ${
            parseInt(purchase.bundle.credits, 10) > 1
                ? "letters have"
                : "letter has"
        } been added to your account.`;

        return {
            primaryText: "Thanks for your purchase!",
            subText: bundleText,
        };
    }
}
