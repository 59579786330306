import React from "react";
import { useHistory } from "react-router";
import cx from "classnames";

// Components
import { Banner } from "components/Banner/Banner";
import { ButtonSecondary } from "components/Buttons/Buttons";
import { Text } from "components/Text/Text";

// CSS
import styles from "./letters-compose-review-credits.module.scss";

export const LettersComposeReviewCredits = (props) => {
    const {
        credits,
        handlePurchaseModalToggle,
        hasCredits,
        recipient,
        supportSquad,
    } = props;

    /**
     * Router Hooks
     */

    const history = useHistory();

    /**
     * End Hooks
     */

    function goToSupportSquads() {
        history.push("/support-squad");
    }

    function renderCreditsAmount({ amount }) {
        const creditsText = amount === 1 ? "Letter" : "Letters";
        const squadText = recipient.isSquadRecipient ? "Squad " : "";
        if (amount >= 1) {
            return `1 of ${amount} ${squadText}${creditsText}`;
        }
        if (amount === 0) {
            return "0 Letters";
        }
    }

    function renderPersonalCredits() {
        return (
            <>
                <div className="letters-compose__review__section__header">
                    <span className="letters-compose__review__section__header__text">
                        {renderCreditsAmount({ amount: credits })}
                    </span>
                </div>
                {!hasCredits && (
                    <Banner
                        classes="letters-compose__review__purchase-credits-banner"
                        config={{
                            shouldShowChevron: true,
                            shouldShowIcon: true,
                        }}
                        onClick={handlePurchaseModalToggle}
                        theme="yellow"
                    >
                        <Text isBold>Ready to send your Letter?</Text>
                        <Text>
                            A Letter purchase is required to send your message.
                            Please buy more by clicking here.
                        </Text>
                    </Banner>
                )}
                {hasCredits && (
                    <ButtonSecondary
                        classes="letters-compose__review__purchase-credits-button"
                        cypressTestId="letters-compose-review-add-tokens-button"
                        onClick={handlePurchaseModalToggle}
                        text="Purchase Letters"
                    />
                )}
            </>
        );
    }

    function renderSquadCredits() {
        return (
            <>
                <div className="letters-compose__review__section__header">
                    <span className="letters-compose__review__section__header__text">
                        {renderCreditsAmount({
                            amount: supportSquad.tokenCount,
                        })}
                    </span>
                    <span
                        className={cx(
                            "letters-compose__review__section__header__icon",
                            "letters-compose__review__section__header__icon--disabled",
                            "material-icons"
                        )}
                    >
                        add
                    </span>
                </div>
                {hasCredits && (
                    <Banner classes={styles.banner}>
                        <Text>
                            A Letter from {supportSquad?.squadName} has been
                            applied to this order.
                        </Text>
                    </Banner>
                )}
                {!hasCredits && (
                    <Banner
                        classes={styles.banner}
                        config={{ isActive: true, shouldShowChevron: true }}
                        onClick={goToSupportSquads}
                        theme="orange"
                    >
                        <span>
                            Your squad does not have enough letters. Purchase
                            more in your squad's page, or buy personal letters
                            below.
                        </span>
                    </Banner>
                )}
                <ButtonSecondary
                    classes="letters-compose__review__purchase-credits-button"
                    cypressTestId="letters-compose-review-add-tokens-button"
                    onClick={handlePurchaseModalToggle}
                    text="Purchase Credits"
                />
            </>
        );
    }

    return (
        <div className="letters-compose__review__section credits-section">
            {recipient.isSquadRecipient
                ? renderSquadCredits()
                : renderPersonalCredits()}
        </div>
    );
};
