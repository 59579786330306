export function ChevronLeftIcon({ height, width, color }) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M15 18L9 12L15 6"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

ChevronLeftIcon.defaultProps = {
    color: "#212121",
    height: "25px",
    width: "25px",
};
