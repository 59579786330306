/* istanbul ignore next */
export function generateDepContentUrl(branchId) {
    switch (branchId) {
        case "AIRFORCE":
        case "AIRFORCE_RESERVE":
            return "airforce";
        case "ARMY":
        case "ARMY_RESERVE":
            return "army";
        case "AIRFORCE_GUARD":
        case "ARMY_GUARD":
            return "armyguard";
        case "MARINES":
        case "MARINES_RESERVE":
            return "marines";
        case "NAVY":
        case "NAVY_RESERVE":
            return "navy";
        default:
            return null;
    }
}

/* istanbul ignore next */
export function generateDepContentTitle(branchId) {
    switch (branchId) {
        case "ARMY":
        case "ARMY_RESERVE":
        case "ARMY_GUARD":
            return "Recruit Sustainment Program";
        case "NAVY":
        case "NAVY_RESERVE":
            return "Navy Future Sailors Program";
        default:
            return "DEP Intel";
    }
}

/* istanbul ignore next */
export function generateDepLocationsTitle(branchId) {
    switch (branchId) {
        case "ARMY":
        case "ARMY_RESERVE":
            return "Future Soldiers Program";
        case "ARMY_GUARD":
            return "Recruit Sustainment Program";
        case "NAVY":
        case "NAVY_RESERVE":
            return "Future Sailors Program";
        default:
            return "Delayed Entry Program";
    }
}

/* istanbul ignore next */
export function generateDepLocationsUrl(branchId) {
    switch (branchId) {
        case "AIRFORCE":
        case "AIR_FORCE":
        case "AIR_FORCE_RESERVE":
            return "dep/locations/airforce";
        case "AIR_GUARD":
            return "dep/locations/airforce/national_guard";
        case "ARMY":
        case "ARMY_RESERVE":
            return "dep/locations/army";
        case "ARMY_GUARD":
            return "dep/locations/army/national_guard";
        case "COAST_GUARD":
        case "COAST_GUARD_RESERVE":
            return "dep/locations/coastguard";
        case "MARINES":
        case "MARINES_RESERVE":
            return "dep/locations/marines";
        case "NAVY":
        case "NAVY_RESERVE":
            return "dep/locations/navy";
        case "SPACE_FORCE":
            return "dep/locations/spaceforce";
        default:
            return null;
    }
}

/* istanbul ignore next */
export function isValidDepLocationBranch(branchId) {
    switch (branchId) {
        case "AIRFORCE":
        case "AIRFORCE_GUARD":
        case "ARMY":
        case "ARMY_GUARD":
        case "ARMY_RESERVE":
        case "MARINES":
        case "MARINES_RESERVE":
        case "NAVY":
        case "NAVY_RESERVE":
            return true;
        default:
            return false;
    }
}

/* istanbul ignore next */
export function isValidDepLocationRole(role) {
    switch (role) {
        case "TRAINEE":
        case "TRAINEE_GUARD":
        case "TRAINEE_RESERVE":
            return true;
        default:
            return false;
    }
}

export const headerText =
    "Choose the location you are signing up from. Start typing or select a location from the list below.";
