import { useContext, useState } from "react";

// Context
import { NotificationBarContext } from "context/notificationBar";

// Enums
import { NavigationEnum } from "scenes/SignIn/constants/constants";

// Loggers
import { AnalyticsLogger } from "loggers/AnalyticsLogger";

// Utils
import { SandboxxRestAPI } from "utils/sandboxx";
import { getRecaptchaToken } from "utils/recaptchaUtils";

export const useSignUpConfirmPhone = (props) => {
    const {
        handleChangeVerificationCode,
        handleLoadingToggle,
        setConnections,
        setCurrentSection,
        storeUserAfterUpdate,
        userInfo,
        verificationCode,
    } = props;
    const { phoneNumber, verificationType } = userInfo;

    /**
     * useContext
     */

    const { showNotification } = useContext(NotificationBarContext);

    /**
     * useState
     */

    const [shouldShowErrorState, setShouldShowErrorState] = useState(false);

    /**
     * End Hooks
     */

    function handleChangeConfirmVerificationCodeInput(value) {
        setShouldShowErrorState(false);
        handleChangeVerificationCode(value);
    }

    function handleSubmit(e) {
        e && e.preventDefault();
        handleLoadingToggle("verify", true);
        getRecaptchaToken("authPhoneVerify", (recaptchaToken) => {
            if (recaptchaToken) {
                const userPayload = {
                    phoneNumber: phoneNumber.baseNumber,
                    countryCode: phoneNumber.countryCode,
                    platform: "WEB",
                    recaptchaToken,
                    verificationCode,
                };
                SandboxxRestAPI.checkPhoneVerificationCode(
                    userPayload,
                    onCheckPhoneVerificationCodeSuccess,
                    onCheckVerificationCodeFailure,
                    onCheckVerificationCodeFailure
                );
            } else {
                console.log("Error generating recaptcha");
            }
        });
    }

    function onCheckForOnboardingSkipSuccess(connections) {
        setConnections(connections);
    }

    function onCheckVerificationCodeFailure(err) {
        setShouldShowErrorState(true);
        handleLoadingToggle("verify", false);
        showNotification({
            text: err.message,
            type: "warning",
        });
    }

    function onCheckPhoneVerificationCodeSuccess(res) {
        if (res.success) {
            // Trigger check for onboarding skip
            SandboxxRestAPI.checkForOnboardingSkip(
                onCheckForOnboardingSkipSuccess
            );

            // Store updated user data
            storeUserAfterUpdate();

            // Analytics
            AnalyticsLogger.logVerificationConfirmPhone();

            // Update UI state
            setShouldShowErrorState(false);
            handleLoadingToggle("verify", false);
            setCurrentSection(NavigationEnum.SIGN_UP_PERSONA_ROOT);
        } else {
            // Update UI state
            handleLoadingToggle("verify", false);
            setShouldShowErrorState(true);
            showNotification({
                text: "That verification code is not correct.",
                type: "warning",
            });
        }
    }

    function onResendVerificationCodeFailure() {
        showNotification({
            text: "There was a problem sending your verification code. Please try again.",
            type: "warning",
        });
    }

    function onResendVerificationCodeSuccess() {
        showNotification({
            text: `A new code has been successfully sent to ${phoneNumber.baseNumber}`,
            type: "success",
        });
    }

    function resendVerificationCode() {
        return SandboxxRestAPI.resendPhoneVerificationCode(
            { phoneNumber, verificationType },
            onResendVerificationCodeSuccess,
            onResendVerificationCodeFailure,
            onResendVerificationCodeFailure
        );
    }

    return {
        handleChangeConfirmVerificationCodeInput,
        handleSubmit,
        resendVerificationCode,
        shouldShowErrorState,
    };
};
