import { useContext, useEffect } from "react";

// Context
import { HeaderBarContext } from "context/headerBar";
import { PurchaseContext } from "context/purchase";

// Hooks
import { useDocumentTitle } from "@uidotdev/usehooks";

export const useMemoryBook = () => {
    /**
     * useContext
     */

    const { setHeaderBarContent } = useContext(HeaderBarContext);
    const { fetchSavedCards } = useContext(PurchaseContext);

    /**
     * useEffect
     */

    useEffect(() => {
        fetchSavedCards();
        setHeaderBarContent({
            backPathname: "/",
            onBackClick: null,
            text: "Memory Book",
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Custom Hooks
     */

    useDocumentTitle("Sandboxx - Memory Book");

    /**
     * End Hooks
     */

    return {};
};
