import React, { useContext, useEffect, useState } from "react";

// Components
import { HeaderBar } from "../../../../components/HeaderBar/HeaderBar";
import { ImageCropModal } from "components/ImageCropModal/ImageCropModal";
import { LettersComposeAddOnsModal } from "./AddOns/LettersComposeAddOnsModal";
import { LettersComposeMessage } from "./Message/LettersComposeMessage";
import { LettersComposeModalNoCredits } from "../../components/LettersComposeModalNoCredits";
import { LettersComposeReview } from "./Review/LettersComposeReview";
import { LettersPhotoManageModal } from "scenes/Letters/components/LettersPhotoManageModal/LettersPhotoManageModal";
import { LoadingPage } from "../../../../components/Loading/Loading";

// Context
import { BundlesContext } from "../../../../context/bundles";
import { ContactsContext } from "../../../../context/contacts";
import { CreditsContext } from "../../../../context/credits";
import { HeaderBarContext } from "../../../../context/headerBar";
import { NotificationBarContext } from "../../../../context/notificationBar";
import { PhoneVerificationContext } from "context/phoneVerification";
import { PurchaseContext } from "../../../../context/purchase";
import { TitlesContext } from "../../../../context/titles";

// CSS
import "../../../../css/letters/components/letters-modals.scss";
import "../../../../css/letters/scenes/Compose/letters-compose.scss";

// Enums
import { AddOnsNavigation, LettersComposeNavigation } from "./enums/enums";
import { ImageAspectRatio } from "components/ImageCropModal/enums/ImageAspectRatio";
import { PurchaseModalNavigation } from "components/Purchase/enums/enums";

// Hooks
import { useAddOns } from "../../hooks/useAddOns";
import { useDocumentTitle } from "@uidotdev/usehooks";
import { useGiftCard } from "../../hooks/useGiftCard";
import { useDraft } from "../../hooks/useDraft";
import { usePhotoManagement } from "scenes/Letters/hooks/usePhotoManagement";

// Loggers
import { AnalyticsLogger } from "loggers/AnalyticsLogger";

// Models
import { Contact } from "models/contacts/Contact";

// Utils
import { generateLetterSendPayload } from "utils/lettersUtils";
import { emptyFunction } from "utils/miscUtils";
import { CurrentUser, SandboxxRestAPI } from "utils/sandboxx";
import { scrollToTop } from "utils/scrollUtils";
import {
    generateShouldReverseFullName,
    generateContactFromStorage,
} from "utils/userUtils";

// Validation
import { LettersValidation } from "validation/lettersValidation";

export const LettersCompose = (props) => {
    const { history } = props;

    /**
     * useContext
     */

    const { fetchBundles } = useContext(BundlesContext);
    const {
        contacts,
        editContact,
        fetchContacts,
        fetchRecentContacts,
        loading: loadingContacts,
        recentContacts,
        toggleContactModal,
    } = useContext(ContactsContext);
    const { credits, fetchCredits } = useContext(CreditsContext);
    const { setHeaderBarContent } = useContext(HeaderBarContext);
    const { showNotification } = useContext(NotificationBarContext);
    const { togglePhoneVerificationModal } = useContext(
        PhoneVerificationContext
    );
    const {
        fetchRecentPaymentMethod,
        fetchSavedCards,
        recentPaymentMethod,
        selectedCard,
        setCurrentSection,
        setOnCompletePurchaseFlow,
        setShowPurchaseModal,
    } = useContext(PurchaseContext);
    const { fetchTitles, titles } = useContext(TitlesContext);

    /**
     * useState
     */

    const [display, setDisplay] = useState({
        showAddOnsModal: false,
        showImageCropModal: false,
        showNewRecipientOption: false,
        showNoCreditsModal: false,
    });
    const [hasMultiPhoto, setHasMultiPhoto] = useState(false);
    const [hasEditedMessage, setHasEditedMessage] = useState(false);
    const [isUploadingImage, setIsUploadingImage] = useState(false);
    const [loading, setLoading] = useState({
        addOns: true,
        giftCardOptions: false,
        sendingLetter: false,
        isSubmittingForReview: false,
        updateUserAddress: false,
    });
    const [message, setMessage] = useState({
        content: "",
        customPhotoArray: [],
        isNameReversed: true,
    });
    const [navigation, setNavigation] = useState({
        addOnsModal: AddOnsNavigation.SELECT_DESIGN,
        currentSection: LettersComposeNavigation.COMPOSE,
    });
    const [recipient, setRecipient] = useState({});
    const [sentLetter, setSentLetter] = useState(null);
    const [sender, setSender] = useState({});
    const [supportSquad, setSupportSquad] = useState({});
    const [triggerGoToReview, setTriggerGoToReview] = useState(false);

    /**
     * Custom Hooks
     */

    useDocumentTitle("Sandboxx - Letters");

    const {
        clearGiftCard,
        fetchGiftCardOptions,
        handleGiftCardClear,
        handleGiftCardSelectOption,
        handleSelectGiftCardAmount,
        hasPurchasedGiftCard,
        hasSelectedGiftCard,
        giftCard,
        setGiftCard,
    } = useGiftCard(recipient, setLoading);

    const {
        addOns,
        addOnsTotal,
        checkIfEligibleForPostagePaid,
        clearAddOns,
        fetchAddOns,
        handleAddOnsChangeSection,
        handleAddOnsModalToggle,
        handleAddOnsPurchase,
        handleAddOnsSelectPayment,
        handleRemoveAddOn,
        handleSelectAddOn,
        hasAddedPostagePaidInSession,
        hasAddOnsTotal,
        hasSelectedAddOns,
        hasSelectedCreditCard,
        hasPostagePaidInCart,
        isEligibleForPostagePaid,
        postagePaid,
        selectPostagePaid,
    } = useAddOns(
        hasPurchasedGiftCard,
        hasSelectedGiftCard,
        giftCard,
        recipient,
        selectedCard,
        sentLetter,
        setDisplay,
        setGiftCard,
        setLoading,
        setNavigation,
        showNotification
    );

    const {
        fetchDraft,
        handleInitializeSaveDraft,
        isSavingDraft,
        setIsSavingDraft,
    } = useDraft({
        hasEditedMessage,
        isUploadingImage,
        loading,
        message,
        recipient,
        setMessage,
        showNotification,
    });

    const {
        activeImage,
        handleImageCancel,
        handleImageCancelPreview,
        handleSaveCroppedImage,
        handleImageFileSelect,
        handleImageDelete,
        handleImageUpload,
        previewImage,
        setPreviewImage,
        shouldShowDeleteModal,
        shouldShowPhotoManageModal,
        setShouldShowDeleteModal,
        setShouldShowPhotoManageModal,
    } = usePhotoManagement({
        display,
        handleInitializeSaveDraft,
        hasEditedMessage,
        message,
        setDisplay,
        setHasEditedMessage,
        setIsUploadingImage,
        scrollToTop,
        setLoading,
        setMessage,
        showNotification,
    });

    /**
     * useEffect
     */

    useEffect(() => {
        (async function () {
            scrollToTop();
            setHeaderBarContent({ backPathname: "/", text: "Compose" });
            handleRecipientLoad();
            fetchAccount();
            fetchContacts();
            fetchCredits();
            fetchRecentContacts();
            fetchRecentPaymentMethod();
            fetchMultiPhotoStatus();
            fetchSavedCards();
            fetchSupportSquad();
            handleSetSender();
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        checkIfEligibleForPostagePaid();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addOns]);

    /**
     * * When a gift card `purchased` value has been set
     * * -- Submit the letter to the server
     */
    useEffect(() => {
        if (giftCard.triggerLetterSendSubmit) {
            handleLetterSendSubmit();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [giftCard.triggerLetterSendSubmit]);

    /**
     * * When the user has chosen to submit a letter...
     * * -- If the user has enough credits, generate the letter payload and send letter
     * * -- Otherwise, prompt the user to purchase credits using the "No Credits" modal
     */
    useEffect(() => {
        if (loading.sendingLetter) {
            const tokensToCheck = recipient.isSquadRecipient
                ? supportSquad.tokenCount
                : credits;
            if (tokensToCheck >= 1) {
                const payload = generateLetterSendPayload({
                    message,
                    recipient: recipient.generateContactObject(),
                    sender,
                });
                SandboxxRestAPI.sendLetter(
                    payload,
                    onLetterSendSuccess,
                    onLetterSendFailure,
                    onLetterSendFailure
                );
            } else {
                setLoading((prevLoading) => ({
                    ...prevLoading,
                    sendingLetter: false,
                }));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading.sendingLetter]);

    /**
     * * When an image has been successfully uploaded...
     * * -- Begin letter validation process
     */
    useEffect(() => {
        if (message.imageUploadUrl) {
            /* istanbul ignore next */
            validateAndSubmit();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [message.imageUploadUrl]);

    /**
     * * When a recipient has been selected...
     * * -- Place recipient in localStorage
     * * -- Fetch recipient-dependent values
     */
    useEffect(() => {
        if (recipient.firstName) {
            localStorage.setItem(
                "sandboxxMessageRecipient",
                JSON.stringify(recipient.generateContactObject())
            );
            fetchAddOns();
            fetchBundles(recipient);
            fetchGiftCardOptions();
            fetchSupportSquad();
            fetchTitles(recipient);
            setMessage((prev) => ({
                ...prev,
                isNameReversed: generateShouldReverseFullName(recipient),
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recipient]);

    /**
     * * If we detect a sent letter:
     * * -- Trigger Add-Ons purchase
     * * -- Route user to confirmation screen
     * * -- Remove recipient from localStorage
     */
    useEffect(() => {
        if (sentLetter) {
            // Send gift card purchase request
            handleAddOnsPurchase();
            history.push("/letters/confirmation", {
                recipient,
                sentLetter,
            });

            // Remove recipient from localStorage
            localStorage.removeItem("sandboxxMessageRecipient");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sentLetter]);

    /**
     * End Hooks & Constants
     */

    function changeCurrentSection(key) {
        setNavigation((prev) => ({
            ...prev,
            currentSection: key,
        }));
    }

    /* istanbul ignore next */
    function clearRecipient() {
        clearAddOns();
        clearGiftCard();
        setRecipient({});
        setSupportSquad({});
        scrollToTop();
        localStorage.removeItem("sandboxxMessageRecipient");
    }

    function fetchAccount() {
        SandboxxRestAPI.getAccount(onFetchAccountSuccess);
    }

    function fetchMultiPhotoStatus() {
        SandboxxRestAPI.getMultiphotoStatus(
            onFetchMultiPhotoStatusSuccess,
            onFetchMultiPhotoStatusFailure,
            onFetchMultiPhotoStatusFailure
        );
    }

    function onFetchMultiPhotoStatusSuccess({ hasMultiPhoto }) {
        fetchDraft(hasMultiPhoto);
        setHasMultiPhoto(hasMultiPhoto);
    }

    function onFetchMultiPhotoStatusFailure(err) {
        fetchDraft(false);
        console.log(err);
    }

    function fetchSupportSquad() {
        if (recipient.isSquadRecipient) {
            SandboxxRestAPI.getSupportSquadItem(
                recipient.id,
                onFetchSupportSquadSuccess
            );
        }
    }

    function handleLetterSendPrepare() {
        const areAddOnsSelected = addOns?.some((addOn) => addOn.isSelected);
        const areRecipientAndSenderValidated =
            LettersValidation.validateRecipientSenderFull(recipient) &&
            LettersValidation.validateRecipientSenderFull(sender);

        if (areRecipientAndSenderValidated) {
            /**
             * * This condition ensures the following before setting payment information:
             * * There are add ons selected OR
             * * There is a giftcard with value AND
             * * There is a selected credit card AND
             * * There is a non-zero total for the add ons
             */
            if (
                ((areAddOnsSelected && hasAddOnsTotal) ||
                    (hasSelectedGiftCard && giftCard.amount.base)) &&
                hasSelectedCreditCard
            ) {
                /**
                 * * Sets braintree card information in giftcard state
                 * * Sets which gift card has been purchased in state
                 * * Sends user to gift card purchase confirmation
                 *
                 * TODO: Remove purchase trigger from gift card state
                 */

                setGiftCard((prevGiftCard) => ({
                    ...prevGiftCard,
                    braintree: selectedCard.card,
                    purchased: prevGiftCard.selected,
                    triggerLetterSendSubmit: true,
                }));
            } else {
                handleLetterSendSubmit();
            }
        } else {
            showNotification({
                text: "Recipient and Sender must have a name, address, city, state and zip code. Please click the edit link above the correct panel to update.",
                type: "warning",
            });
        }
    }

    function handleLetterSendSubmit() {
        setLoading((prevLoading) => ({
            ...prevLoading,
            sendingLetter: true,
        }));
    }

    function handleMessageInputChange({ target }) {
        const { name, value } = target;
        setMessage((prev) => ({
            ...prev,
            [name]: value,
        }));
        !hasEditedMessage && setHasEditedMessage(true);
    }

    function handlePurchaseModalToggle() {
        setShowPurchaseModal((prev) => !prev);
        setOnCompletePurchaseFlow(() => emptyFunction);
    }

    // Used when selecting a recipient from the auto-suggest field
    function handleRecipientSelect(data, callback = emptyFunction) {
        AnalyticsLogger.logLetterComposeSelectRecipient();
        const recipient = new Contact(data.suggestion);
        setSupportSquad({});
        setRecipient(recipient);
        callback();
    }

    function handleSetSender() {
        const user = CurrentUser.getUser();
        setSender({
            address: {
                city: user.city,
                line1: user.line1,
                line2: user.line2,
                state: user.state,
                zipcode: user.zipcode,
            },
            firstName: user.firstName,
            fullName: user.fullName,
            lastName: user.lastName,
        });
    }

    function handleSubmitMessageForReview() {
        setLoading((prevLoading) => ({
            ...prevLoading,
            isSubmittingForReview: true,
        }));
        message.imageFile
            ? handleImageUpload(message.imageFile, validateAndSubmit)
            : validateAndSubmit();
    }

    function handleRecipientLoad() {
        const contactFromStorage = generateContactFromStorage();

        if (contactFromStorage) {
            setRecipient(() => contactFromStorage);
            setNavigation((prevNavigation) => ({
                ...prevNavigation,
                currentSection: LettersComposeNavigation.COMPOSE,
            }));
        }
    }

    function onFetchAccountSuccess(res) {
        const { onboarding, user, verification } = res;
        CurrentUser.storeUser(user, verification, onboarding);
        handleSetSender();
    }

    function onFetchSupportSquadSuccess(res) {
        setSupportSquad(res);
    }

    function onLetterSendFailure(err) {
        showNotification({
            text: "An issue was encountered when sending your letter.",
            type: "warning",
        });
        setLoading((prevLoading) => ({
            ...prevLoading,
            sendingLetter: false,
        }));
    }

    function onLetterSendSuccess(res) {
        const { letterId, responseStatusCode } = res;
        if (responseStatusCode === 200 && letterId) {
            AnalyticsLogger.logLetterComposeEnd({ recipient });
            setLoading((prevLoading) => ({
                ...prevLoading,
                sendingLetter: false,
            }));
            setSentLetter({ letterId });
        } else {
            showNotification({
                text: "An issue was encountered when sending your letter. Please wait a few minutes and try again.",
                type: "warning",
            });
        }
    }

    function onSubmitMessageForReviewFailure() {
        setLoading((prev) => ({
            ...prev,
            isSubmittingForReview: false,
        }));
    }

    function onSubmitMessageForReviewSuccess() {
        AnalyticsLogger.logLetterComposeSubmitMessage();
        setLoading((prevLoading) => ({
            ...prevLoading,
            isSubmittingForReview: false,
        }));
        setTriggerGoToReview(false);
        changeCurrentSection(LettersComposeNavigation.REVIEW);
    }

    function onUpdateUserAddressFailure() {
        setLoading((prev) => ({
            ...prev,
            updateUserAddress: false,
        }));
        showNotification({
            text: "We were unable to update your sender address. Please try again.",
            type: "warning",
        });
    }

    function onUpdateUserAddressSuccess() {
        setLoading((prev) => ({
            ...prev,
            updateUserAddress: false,
        }));
        fetchAddOns();
    }

    function showBundleHighlightModal() {
        setCurrentSection(PurchaseModalNavigation.BUNDLE_HIGHLIGHT);
        handlePurchaseModalToggle();
    }

    function updateUserAddress() {
        setLoading((prev) => ({
            ...prev,
            updateUserAddress: true,
        }));
        SandboxxRestAPI.updateUserAddress(
            sender,
            onUpdateUserAddressSuccess,
            onUpdateUserAddressFailure,
            onUpdateUserAddressFailure
        );
    }

    /**
     * If user (1) has enough credits and (2) at least selected a recipient and typed out a message,
     * then go to next screen
     */
    function validateAndSubmit() {
        const validateConfig = {
            handlePurchaseModalToggle,
            shouldShowNotification: true,
            showNotification,
        };
        const areValidInputs = () => {
            return LettersValidation.validateInputs(
                message,
                recipient,
                validateConfig
            );
        };
        const isValidRecipient = () => {
            return LettersValidation.validateRecipientInitial(
                recipient,
                validateConfig
            );
        };
        areValidInputs() && isValidRecipient()
            ? onSubmitMessageForReviewSuccess()
            : onSubmitMessageForReviewFailure();
    }

    function renderBody() {
        return !loadingContacts.contacts && !loadingContacts.recentContacts ? (
            <div className="page__body">
                {renderCurrentSection(navigation.currentSection)}
            </div>
        ) : (
            <LoadingPage />
        );
    }

    function renderCurrentSection() {
        const sections = {
            [LettersComposeNavigation.COMPOSE]: (
                <LettersComposeMessage
                    {...props}
                    addOns={addOns}
                    changeComposeCurrentSection={changeCurrentSection}
                    clearRecipient={clearRecipient}
                    contacts={contacts}
                    editContact={editContact}
                    giftCard={giftCard}
                    handleAddOnsModalToggle={handleAddOnsModalToggle}
                    handleInitializeSaveDraft={handleInitializeSaveDraft}
                    handleRemoveAddOn={handleRemoveAddOn}
                    handleSelectAddOn={handleSelectAddOn}
                    handleGiftCardClear={handleGiftCardClear}
                    handleImageCancel={handleImageCancelPreview}
                    handleImageDelete={handleImageDelete}
                    handleImageFileSelect={handleImageFileSelect}
                    handleInputChange={handleMessageInputChange}
                    handlePurchaseModalToggle={handlePurchaseModalToggle}
                    handleRecipientSelect={handleRecipientSelect}
                    handleSubmitMessageForReview={handleSubmitMessageForReview}
                    hasMultiPhoto={hasMultiPhoto}
                    history={history}
                    isSavingDraft={isSavingDraft}
                    loading={loading}
                    message={message}
                    recentContacts={recentContacts}
                    recipient={recipient}
                    setHeaderBarContent={setHeaderBarContent}
                    setIsSavingDraft={setIsSavingDraft}
                    setMessage={setMessage}
                    setPreviewImage={setPreviewImage}
                    setRecipient={setRecipient}
                    setShouldShowDeleteModal={setShouldShowDeleteModal}
                    setShouldShowPhotoManageModal={
                        setShouldShowPhotoManageModal
                    }
                    showImageCropModal={display.showImageCropModal}
                    showNewRecipientOption={display.showNewRecipientOption}
                    showNotification={showNotification}
                    toggleContactModal={toggleContactModal}
                    togglePhoneVerificationModal={togglePhoneVerificationModal}
                    triggerGoToReview={triggerGoToReview}
                />
            ),
            [LettersComposeNavigation.REVIEW]: (
                <LettersComposeReview
                    {...props}
                    addOns={addOns}
                    addOnsTotal={addOnsTotal}
                    selectPostagePaid={selectPostagePaid}
                    changeComposeCurrentSection={changeCurrentSection}
                    credits={credits}
                    giftCard={giftCard}
                    handleAddOnsModalToggle={handleAddOnsModalToggle}
                    handleRemoveAddOn={handleRemoveAddOn}
                    handleGiftCardClear={handleGiftCardClear}
                    handleImageCancel={handleImageCancelPreview}
                    handleImageFileSelect={handleImageFileSelect}
                    handleInitializeSaveDraft={handleInitializeSaveDraft}
                    handleInputChange={handleMessageInputChange}
                    handleLetterSend={handleLetterSendPrepare}
                    handlePurchaseModalToggle={handlePurchaseModalToggle}
                    handleSubmitMessageForReview={handleSubmitMessageForReview}
                    hasAddedPostagePaidInSession={hasAddedPostagePaidInSession}
                    hasAddOnsTotal={hasAddOnsTotal}
                    hasMultiPhoto={hasMultiPhoto}
                    hasPostagePaidInCart={hasPostagePaidInCart}
                    hasSelectedAddOns={hasSelectedAddOns}
                    hasSelectedCreditCard={hasSelectedCreditCard}
                    hasSelectedGiftCard={hasSelectedGiftCard}
                    isEligibleForPostagePaid={isEligibleForPostagePaid}
                    loading={loading}
                    message={message}
                    postagePaid={postagePaid}
                    recipient={recipient}
                    recentPaymentMethod={recentPaymentMethod}
                    selectedCard={selectedCard}
                    sender={sender}
                    setHeaderBarContent={setHeaderBarContent}
                    setLoading={setLoading}
                    setMessage={setMessage}
                    setPreviewImage={setPreviewImage}
                    setRecipient={setRecipient}
                    setSender={setSender}
                    setShouldShowPhotoManageModal={
                        setShouldShowPhotoManageModal
                    }
                    showNotification={showNotification}
                    supportSquad={supportSquad}
                    titles={titles}
                    updateUserAddress={updateUserAddress}
                />
            ),
        };
        return sections[navigation.currentSection];
    }

    function renderModals() {
        return (
            <>
                <ImageCropModal
                    aspect={ImageAspectRatio.LETTERS}
                    finalWidth={1200}
                    handleImageCancel={handleImageCancel}
                    handleSaveCroppedImage={handleSaveCroppedImage}
                    showImageCropModal={display.showImageCropModal}
                    imagePreview={activeImage}
                />
                <LettersComposeAddOnsModal
                    {...props}
                    addOns={addOns}
                    giftCard={giftCard}
                    handleAddOnsChangeSection={handleAddOnsChangeSection}
                    handleRemoveAddOn={handleRemoveAddOn}
                    handleSelectAddOn={handleSelectAddOn}
                    handleAddOnsSelectPayment={handleAddOnsSelectPayment}
                    handleGiftCardClear={handleGiftCardClear}
                    handleSelectGiftCardAmount={handleSelectGiftCardAmount}
                    handleGiftCardSelectOption={handleGiftCardSelectOption}
                    handleLetterSend={handleLetterSendSubmit}
                    handleAddOnsModalToggle={handleAddOnsModalToggle}
                    navigation={navigation}
                    recentPaymentMethod={recentPaymentMethod}
                    setDisplay={setDisplay}
                    setGiftCard={setGiftCard}
                    showAddOnsModal={display.showAddOnsModal}
                    showNotification={showNotification}
                />
                <LettersComposeModalNoCredits
                    {...props}
                    handlePurchaseModalToggle={handlePurchaseModalToggle}
                    setDisplay={setDisplay}
                    showNoCreditsModal={display.showNoCreditsModal}
                />
                <LettersPhotoManageModal
                    fetchMultiPhotoStatus={fetchMultiPhotoStatus}
                    handleImageFileSelect={handleImageFileSelect}
                    hasMultiPhoto={hasMultiPhoto}
                    showBundleHighlightModal={showBundleHighlightModal}
                    handleImageDelete={handleImageDelete}
                    message={message}
                    previewImage={previewImage}
                    setPreviewImage={setPreviewImage}
                    setOnCompletePurchaseFlow={setOnCompletePurchaseFlow}
                    setShouldShowDeleteModal={setShouldShowDeleteModal}
                    setShouldShowPhotoManageModal={
                        setShouldShowPhotoManageModal
                    }
                    shouldShowDeleteModal={shouldShowDeleteModal}
                    shouldShowPhotoManageModal={shouldShowPhotoManageModal}
                />
            </>
        );
    }

    return (
        <div className="letters-compose-container">
            {renderModals()}
            <HeaderBar />
            {renderBody()}
        </div>
    );
};
